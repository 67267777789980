import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

import theme from "../../styles/theme"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	dialogContainer: {
		padding: `${theme.spacing() * 6}px`
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	loading: {
		color: colors.greyScale[11]
	},
	actions: {
		display: "flex",
		flexDirection: "column",
		"& button": {
			width: "100%",
			maxWidth: 250
		}
	},
	icon: {
		maxWidth: 80,
		height: "auto",
		margin: "0 auto"
	},
	title: {
		fontWeight: "bolder",
		textAlign: "center"
	},
	description: {
		color: colors.greyScale[3],
		textAlign: "center"
	}
})

export default useStyles
