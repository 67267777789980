import React from "react"
import { Skeleton } from "@material-ui/lab"
import { List, ListItem, ListItemText, makeStyles } from "@material-ui/core"

import { Divider } from "../components"

const useStyles = makeStyles(theme => ({
	listItem: {
		paddingLeft: theme.spacing(4)
	}
}))

const MenuSkeleton = () => {
	const classes = useStyles()

	const listItems = []
	const nItems = 5

	// eslint-disable-next-line
  for (let i = 1; i <= nItems; i++) {
		listItems.push(
			<ListItem key={i} className={classes.listItem} divider={i !== nItems}>
				<ListItemText>
					<Skeleton animation="wave" width={100} height={15} />

					<Divider size="small" orientation="horizontal" />

					<Skeleton animation="wave" width={240} height={15} />
				</ListItemText>
			</ListItem>
		)
	}

	return <List>{listItems}</List>
}

export default MenuSkeleton
