const livePreviewController = `
  function handleClickBodyPageSection(event){
    event.preventDefault();
    event.stopPropagation();
    
    const id = event.target.closest("[le-page-section]").id;
    
    const payload = JSON.stringify({
      event: "CLICK_BODY_PAGE_SECTION",
      id
    });
    
    window.top.postMessage(payload, "*");
  }

  function addOrRefreshListeners(){
    const pageSections = document.querySelectorAll("[le-page-section]");

    pageSections.forEach(pageSection => {
      pageSection.removeEventListener("click", handleClickBodyPageSection);
      pageSection.addEventListener("click", handleClickBodyPageSection);
    })
  }
`

export default livePreviewController
