import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

import { isMobile } from "../../utils/checkDevice"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	loading: {
		color: colors.greyScale[11]
	},
	drawerPaper: {
		width: `${isMobile && "100vw"}`
	},
	children: {
		position: "relative",
		"&:before": {
			content: ({ cursor }) => `${cursor === "text" && "close-quote"}`,
			position: "absolute",
			top: 0,
			right: 0,
			width: "100%",
			height: "100%",
			zIndex: 9,
			cursor: ({ cursor }) => cursor
		}
	}
})

export default useStyles
