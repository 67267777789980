import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(() => ({
	icon: {
		"& svg": {
			color: colors.greyScale[5]
		}
	}
}))

export default useStyles
