import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
	containerWidget: {
		height: "calc(100vh - 60px)"
	},
	containerActivateFuncionality: {
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			overflow: "auto",
			maxHeight: "90vh"
		}
	},
	titleActivateFuncionality: {
		fontSize: "24px",
		lineHeight: "29px"
	},
	textActionActivateFuncionality: {
		fontWeight: "bold"
	},
	iframeContainer: {
		height: "calc(100vh - 60px)",
		width: "100%",
		display: "flex",
		justifyContent: "center",
		overflowY: "auto",
		[theme.breakpoints.down("sm")]: {
			height: "calc(100vh - 110px)"
		}
	},
	iframeContent: {
		flex: 1,
		height: "100%"
	}
}))

export default useStyles
