import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		height: "100%",
		marginLeft: props => props.marginLeft,
		marginRight: props => props.marginRight,
		maxWidth: props => props.maxWidth
	}
})

export default useStyles
