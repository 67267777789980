import React, { useState } from "react"
import {
	ViewCompactOutlined as LayoutIcon,
	TextFields as TypographicIcon,
	Crop75 as ButtonIcon,
	VideoLabel as FooterIcon,
	ExtensionOutlined as ThemeIcon,
	ViewCarouselOutlined as BannerIcon
} from "@material-ui/icons"

import { Divider } from "../../../../components"
import { Accordion } from "../../components"
import LayoutEditor from "./LayoutEditor"
import HeaderEditor from "./HeaderEditor"
import BannerEditor from "./BannerEditor"
import TypographyEditor from "./TypographyEditor"
import ButtonsEditor from "./ButtonsEditor"
import ThemeEditor from "./ThemeEditor"

const StylesEditor = () => {
	const [accordionExpanded, setAccordionExpanded] = useState(null)

	function handleAccordionChange(accordionName) {
		setAccordionExpanded(accordionExpanded === accordionName ? null : accordionName)
	}

	return (
		<>
			<Divider orientation="horizontal" size="xxlarge" />

			<Accordion
				icon={<LayoutIcon />}
				label="Layout"
				expanded={accordionExpanded === "layout"}
				onChange={() => handleAccordionChange("layout")}
			>
				<LayoutEditor />
			</Accordion>

			<Accordion
				icon={<FooterIcon style={{ transform: "rotateX(0.5turn)" }} />}
				label="Cabeçalho"
				expanded={accordionExpanded === "header"}
				onChange={() => handleAccordionChange("header")}
			>
				<HeaderEditor />
			</Accordion>

			<Accordion
				icon={<BannerIcon />}
				label="Banner"
				expanded={accordionExpanded === "banner"}
				onChange={() => handleAccordionChange("banner")}
			>
				<BannerEditor />
			</Accordion>

			<Accordion
				icon={<TypographicIcon />}
				label="Títulos e parágrafos"
				expanded={accordionExpanded === "typographic"}
				onChange={() => handleAccordionChange("typographic")}
			>
				<TypographyEditor />
			</Accordion>

			<Accordion
				icon={<ButtonIcon />}
				label="Botões"
				expanded={accordionExpanded === "button"}
				onChange={() => handleAccordionChange("button")}
			>
				<ButtonsEditor />
			</Accordion>

			<Accordion
				icon={<ThemeIcon />}
				label="Tema"
				expanded={accordionExpanded === "theme"}
				onChange={() => handleAccordionChange("theme")}
			>
				<ThemeEditor />
			</Accordion>

			<Divider orientation="horizontal" size="xxlarge" />
		</>
	)
}

export default StylesEditor
