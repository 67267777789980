import React from "react"
import PropTypes from "prop-types"
import { Link, useLocation } from "react-router-dom"
import { ListItem } from "@material-ui/core"

import useStyles from "./styles"

const ListItemMenu = (props) => {
	const { children, to, onClick } = props

	const classes = useStyles()

	const location = useLocation()

	const isMenuItemActive = (route) => location.pathname.includes(route)

	return (
		<ListItem
			button
			component={Link}
			to={to}
			key={to}
			onClick={onClick}
			className={isMenuItemActive(to) && classes.listItemActive}
		>
			{children}
		</ListItem>
	)
}

ListItemMenu.defaultProps = {
	onClick: () => {}
}

ListItemMenu.propTypes = {
	children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
	to: PropTypes.string.isRequired,
	onClick: PropTypes.func
}

export default ListItemMenu
