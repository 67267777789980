import React from "react"
import PropTypes from "prop-types"
import { Container, IconButton } from "@material-ui/core"

import {
	CloudUploadOutlined as CloudUploadIcon
} from "@material-ui/icons"
import Dropzone from "../Dropzone"

import useStyles from "./styles"

const InputPic = (props) => {
	const { onDrop, fileSrc } = props

	const classes = useStyles()

	if (fileSrc) {
		return (
			<Dropzone onDrop={onDrop}>
				<Container
					disableGutters
					className={classes.pictureContainer}
					style={{ backgroundImage: `url(${fileSrc})` }}
				/>
			</Dropzone>
		)
	}

	return (
		<div className={classes.container}>
			{/* Uncomment when start using media system to pick photos */}
			{/* <Grid
					container
					className={classes.buttonsContainer}
					direction="column"
					alignItems="center">
				<Grid item>
					<Button onClick={() => alert("sistema de mídias")}>Selecionar do sistema de mídia</Button>
				</Grid>

				<Grid item>
					<Button>Upload</Button>
				</Grid>
			</Grid> */}

			<Dropzone onDrop={onDrop}>
				<Container disableGutters className={classes.pictureContainer}>
					<IconButton color="primary" size="large">
						<CloudUploadIcon />
					</IconButton>
				</Container>
			</Dropzone>
		</div>
	)
}

InputPic.defaultProps = {
	fileSrc: null
}

InputPic.propTypes = {
	onDrop: PropTypes.func.isRequired,
	fileSrc: PropTypes.string
}

export default InputPic
