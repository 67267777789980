import React, { useState, cloneElement } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	Typography,
	Button,
	IconButton,
	ThemeProvider,
	Grid
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import Divider from "../Divider"

import { renderComponent } from "../../utils/node"

import useStyles from "./styles"

import theme from "../../styles/theme"

import smileIcon from "../../assets/smile_icon.png"
import sadIcon from "../../assets/sad_icon.png"
import warningIcon from "../../assets/warning_icon.png"

const InfoModal = (props) => {
	const classes = useStyles()

	const {
		title,
		description,
		showCancelButton,
		customConfirmButtons,
		callback,
		cancelButtonText,
		type
	} = props

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		callback(false)
		setOpened(false)
	}

	const handleOk = (event) => {
		const value = event.target.value || event.currentTarget.value

		callback(value || true)

		setOpened(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.dialogContainer}
				>

					{type === "success" && (<img src={smileIcon} alt="smile icon" className={classes.icon} />)}
					{type === "error" && (<img src={sadIcon} alt="sad icon" className={classes.icon} />)}
					{type === "warning" && (<img src={warningIcon} alt="warning icon" className={classes.icon} />)}

					<Divider size="large" orientation="horizontal" />

					<Typography variant="h3" className={classes.title}>
						{title}
					</Typography>

					<Divider size="medium" orientation="horizontal" />

					<Typography variant="body2" className={classes.description}>
						{description}
					</Typography>

					<Divider size="large" orientation="horizontal" />

					<Grid
						container
						direction="column"
						alignItems="center"
						className={classes.actions}
					>
						{!customConfirmButtons && (
							<>
								<Button
									onClick={handleOk}
									color="primary"
									variant="contained"
								>
									Salvar
								</Button>

								<Divider size="small" orientation="horizontal" />
							</>
						)}
						{customConfirmButtons && customConfirmButtons.map(button => (
							<>
								{cloneElement(button, {
									onClick: handleOk
								})}

								<Divider size="small" orientation="horizontal" />
							</>
						))}
						{showCancelButton && (
							<Button
								onClick={handleClose}
								color="inherit"
							>
								{cancelButtonText}
							</Button>
						)}
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

InfoModal.defaultProps = {
	showCancelButton: true,
	customConfirmButtons: null,
	cancelButtonText: "Cancelar"
}

InfoModal.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	showCancelButton: PropTypes.bool,
	customConfirmButtons: PropTypes.arrayOf([PropTypes.node]),
	cancelButtonText: PropTypes.string,
	callback: PropTypes.func.isRequired,
	type: PropTypes.oneOf(["success", "error", "warning"]).isRequired
}

InfoModal.success = ({
	title,
	description,
	showCancelButton,
	cancelButtonText,
	customConfirmButtons
}) => new Promise(callback => renderComponent(
	"info-modal",
	<InfoModal
		title={title}
		description={description}
		showCancelButton={showCancelButton}
		customConfirmButtons={customConfirmButtons}
		cancelButtonText={cancelButtonText}
		callback={callback}
		type="success"
	/>
))

InfoModal.error = ({
	title,
	description,
	showCancelButton,
	cancelButtonText,
	customConfirmButtons
}) => new Promise(callback => renderComponent(
	"info-modal",
	<InfoModal
		title={title}
		description={description}
		showCancelButton={showCancelButton}
		customConfirmButtons={customConfirmButtons}
		cancelButtonText={cancelButtonText}
		callback={callback}
		type="error"
	/>
))

InfoModal.warning = ({
	title,
	description,
	showCancelButton,
	cancelButtonText,
	customConfirmButtons
}) => new Promise(callback => renderComponent(
	"info-modal",
	<InfoModal
		title={title}
		description={description}
		showCancelButton={showCancelButton}
		customConfirmButtons={customConfirmButtons}
		cancelButtonText={cancelButtonText}
		callback={callback}
		type="warning"
	/>
))

export default InfoModal
