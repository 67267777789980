import React from "react"
import { Link } from "react-router-dom"
import { Grid, MenuItem, Typography } from "@material-ui/core"
import { Settings as SettingsIcon } from "@material-ui/icons"
import { GroupField, Select, Switch, Drawer, AlterBorder } from "../../../components"
import { Divider } from "../../../../../components"

import { getHeightInPercentageFromProportion } from "../../../../../utils/image"

import { useLiveEditorStore } from "../../../../../store"

import useLiveEditorGlobalStyles from "../../../liveEditorGlobalStyles"

function BannerEditor() {
	const liveEditorStore = useLiveEditorStore()
	const headerBanner1SectionData = liveEditorStore.getSectionByHtmlTag("header_index")

	const liveEditorClasses = useLiveEditorGlobalStyles()


	const updateSectionPropertiesOnStore = ({ sectionId, sectionProperties: properties }) => {
		const sectionProperties = {
			sectionId,
			properties
		}

		liveEditorStore.setSectionData(sectionProperties)
	}

	const handleChangeBannerFullscreen = (event) => {
		const { checked: isFullscreen } = event.target

		const sectionId = headerBanner1SectionData.id

		const sectionProperties = {
			banner_full_size: isFullscreen,
			banner_section_content_max_width: "100%"
		}

		updateSectionPropertiesOnStore({ sectionId, sectionProperties })

		const proportion = headerBanner1SectionData.properties.banner_picture_proportion || "16:9"
		const paddingBottomInPercentage = getHeightInPercentageFromProportion(proportion)

		const livePreviewChanges = [
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id}.header-banner-1-container`,
				value: {
					height: isFullscreen ? "100vh" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content`,
				value: {
					height: isFullscreen ? "100%" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-container`,
				value: {
					height: isFullscreen ? "100%" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-container .banner-content`,
				value: {
					"max-width": isFullscreen ? "100%" : "100%",
					width: isFullscreen ? "100%" : "100%",
					height: isFullscreen ? "100%" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .banner-group-container`,
				value: {
					height: isFullscreen ? "100%" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-picture-container`,
				value: {
					height: isFullscreen ? "100%" : "unset"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-picture-container .banner-picture`,
				value: {
					height: isFullscreen ? "100%" : 0,
					"padding-bottom": isFullscreen ? "unset" : `${paddingBottomInPercentage}%`
				}
			}
		]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const getBannerWidthSelectValue = () => {
		const currentValue = headerBanner1SectionData.properties.banner_section_content_max_width

		if (currentValue === "100%") {
			return "screen"
		}
		return "site"
	}

	const handleChangeBannerWidth = (event) => {
		const { value } = event.target
		const sectionId = headerBanner1SectionData.id

		const options = {
			screen: {
				livePreviewValue: {
					"max-width": "100%",
					width: "100%"
				},
				liveEditorValue: {
					banner_section_content_max_width: "100%",
					banner_section_content_width: "100%"
				}
			},
			site: {
				livePreviewValue: {
					"max-width": "var(--page-section-content-max-width)",
					width: "var(--page-section-content-width)"
				},
				liveEditorValue: {
					banner_section_content_max_width: "",
					banner_section_content_width: ""
				}
			}
		}

		const selectedOption = options[value]

		updateSectionPropertiesOnStore({
			sectionId,
			sectionProperties: selectedOption.liveEditorValue
		})

		const livePreviewChanges = [{
			action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
			selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-container .banner-content`,
			value: selectedOption.livePreviewValue
		}]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const handleChangeBannerProportion = (event) => {
		const { value } = event.target

		const sectionId = headerBanner1SectionData.id

		const sectionProperties = {
			banner_picture_proportion: value
		}

		updateSectionPropertiesOnStore({ sectionId, sectionProperties })

		const paddingBottomInPercentage = getHeightInPercentageFromProportion(value)

		const livePreviewChanges = [{
			action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
			selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .banner-picture-container .banner-picture`,
			value: {
				"padding-bottom": `${paddingBottomInPercentage}%`
			}
		}]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const handleChangeBannerBorderOnLivePreview = (borderValue, whichBorder) => {
		const livePreviewChanges = [{
			action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
			selector: `#page-section-${headerBanner1SectionData.id} .banner-group-container`,
			value: { [`border-${whichBorder}`]: `${borderValue}` }
		}]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const handleChangeBannerBorder = (borderValue, whichBorder) => {
		const sectionId = headerBanner1SectionData.id
		const properties = {
			[`banner_border_${whichBorder}`]: `${borderValue}`
		}

		liveEditorStore.setSectionData({ sectionId, properties })

		handleChangeBannerBorderOnLivePreview(borderValue, whichBorder)
	}

	const bannerWidthOptions = [
		{
			id: 0,
			value: "screen",
			label: "100% da largura tela"
		},
		{
			id: 1,
			value: "site",
			label: "Limitada à largura do site"
		}
	]

	const proportionOptions = [
		{
			id: 0,
			value: "16:9",
			label: "16:9"
		},
		{
			id: 1,
			value: "8:5",
			label: "8:5"
		},
		{
			id: 2,
			value: "4:3",
			label: "4:3"
		},
		{
			id: 3,
			value: "2:1",
			label: "2:1"
		}
	]

	const BannerEditorFields = (
		<Grid item xs={12}>
			<GroupField label="Banner">
				<Switch
					label="Ocupar a tela inteira"
					value={headerBanner1SectionData.properties.banner_full_size}
					onChange={handleChangeBannerFullscreen}
				/>

				{!headerBanner1SectionData.properties.banner_full_size && (
					<>
						<Divider orientation="horizontal" size="medium" />

						<Select
							label="Largura"
							disabled={headerBanner1SectionData.properties.banner_full_size}
							value={getBannerWidthSelectValue()}
							onChange={handleChangeBannerWidth}
						>
							{bannerWidthOptions.map(option => (
								<MenuItem key={option.id} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>

						<Divider orientation="horizontal" size="large" />

						<Select
							label="Proporção"
							disabled={headerBanner1SectionData.properties.banner_full_size}
							value={headerBanner1SectionData.properties.banner_picture_proportion || "16:9"}
							onChange={handleChangeBannerProportion}
						>
							{proportionOptions.map(option => (
								<MenuItem key={option.id} value={option.value}>
									{option.label}
								</MenuItem>
							))}
						</Select>
					</>
				)}

				<Divider orientation="horizontal" size="medium" />

				<Drawer
					label="Borda"
					icon={<SettingsIcon />}
				>

					<Divider size="medium" orientation="horizontal" />

					<GroupField label="Cima">
						<AlterBorder
							value={headerBanner1SectionData.properties.banner_border_top ?? ""}
							onChange={handleChangeBannerBorder}
							onPreviewChange={handleChangeBannerBorderOnLivePreview}
							whichBorder="top"
						/>
					</GroupField>

					<Divider size="medium" orientation="horizontal" />

					<GroupField label="Baixo">
						<AlterBorder
							value={headerBanner1SectionData.properties.banner_border_bottom ?? ""}
							onChange={handleChangeBannerBorder}
							onPreviewChange={handleChangeBannerBorderOnLivePreview}
							whichBorder="bottom"
						/>
					</GroupField>

					<Divider size="medium" orientation="horizontal" />

					<GroupField label="Direita">
						<AlterBorder
							value={headerBanner1SectionData.properties.banner_border_right ?? ""}
							onChange={handleChangeBannerBorder}
							onPreviewChange={handleChangeBannerBorderOnLivePreview}
							whichBorder="right"
						/>
					</GroupField>

					<Divider size="medium" orientation="horizontal" />

					<GroupField label="Esquerda">
						<AlterBorder
							value={headerBanner1SectionData.properties.banner_border_left ?? ""}
							onChange={handleChangeBannerBorder}
							onPreviewChange={handleChangeBannerBorderOnLivePreview}
							whichBorder="left"
						/>

						<Divider orientation="horizontal" size="large" />
					</GroupField>

				</Drawer>

				<Divider orientation="horizontal" size="medium" />

				<Grid container justify="center">
					<Link to="/banner" target="_blank">
						<Typography className={liveEditorClasses.linkTypographic} variant="h2">
							Gerenciar os banners
						</Typography>
					</Link>
				</Grid>
			</GroupField>
		</Grid>
	)

	return (
		<Grid container spacing={2}>
			<Divider orientation="horizontal" size="small" />
			<Grid item xs={12}>
				{BannerEditorFields}
			</Grid>
		</Grid>
	)
}

export default BannerEditor
