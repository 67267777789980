import React from "react"
import PropTypes from "prop-types"

import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc"

import arrayMove from "array-move"

const SortableContainer = sortableContainer(({ children }) => (
	<>
		{children}
	</>
))

const DragHandle = sortableHandle(({ children }) => (
	<>
		{children}
	</>
))

const SortableSectionItem = sortableElement(({ children }) => (
	<>
		{children}
	</>
))

const handleSortStart = async (cursorType, onSortStart) => {
	document.body.style.cursor = cursorType ?? "default"

	if (onSortStart) onSortStart()
}

const handleSortEnd = async (arrayInfo, onChangeState, putValuesOrder) => {
	const { values, oldIndex, newIndex, orderAsc } = arrayInfo

	const currentValues = values.slice()

	document.body.style.cursor = "default"

	let valuesReordered = arrayMove(currentValues, oldIndex, newIndex)

	valuesReordered = orderAsc ? valuesReordered : valuesReordered.reverse()

	valuesReordered = valuesReordered.map((value, index) => ({ ...value, order: index }))

	const valuesReorderedState = orderAsc ? valuesReordered : valuesReordered.reverse()

	await onChangeState(valuesReorderedState)

	await putValuesOrder(valuesReordered)
}

const Sortable = {}

Sortable.SectionContainer = (props) => {
	const {
		pressDelay,
		axis,
		lockAxis,
		useDragHandle,
		onSortStart,
		onSortEnd,
		children
	} = props

	return (
		<SortableContainer
			pressDelay={pressDelay}
			axis={axis}
			lockAxis={lockAxis}
			useDragHandle={useDragHandle}
			onSortStart={onSortStart}
			onSortEnd={onSortEnd}
			helperClass="SortableHelper"
		>
			{children}
		</SortableContainer>
	)
}

Sortable.DragHandle = (props) => {
	const { children } = props

	return (
		<DragHandle>
			{children}
		</DragHandle>
	)
}

Sortable.SortableSectionItem = (props) => {
	const { children, key, index, disabled } = props

	return (
		<SortableSectionItem
			key={key}
			index={index}
			disabled={disabled}
		>
			{children}
		</SortableSectionItem>
	)
}

Sortable.handleSortStart = (cursorType, onSortStart) => {
	handleSortStart(cursorType, onSortStart)
}

Sortable.handleSortEnd = (arrayInfo, onChangeState, putValuesOrder) => {
	handleSortEnd(arrayInfo, onChangeState, putValuesOrder)
}

Sortable.SectionContainer.defaultProps = {
	pressDelay: false,
	axis: "",
	lockAxis: "",
	useDragHandle: false,
	onSortEnd: () => { },
	onSortStart: () => { }
}

Sortable.SectionContainer.propTypes = {
	pressDelay: PropTypes.bool,
	axis: PropTypes.string,
	lockAxis: PropTypes.string,
	useDragHandle: PropTypes.bool,
	onSortStart: PropTypes.func,
	onSortEnd: PropTypes.func,
	children: PropTypes.node.isRequired
}

Sortable.DragHandle.propTypes = { children: PropTypes.node.isRequired }

Sortable.SortableSectionItem.defaultProps = {
	disabled: false
}

Sortable.SortableSectionItem.propTypes = {
	children: PropTypes.node.isRequired,
	key: PropTypes.oneOfType([
		PropTypes.number,
		PropTypes.string
	]).isRequired,
	index: PropTypes.number.isRequired,
	disabled: PropTypes.bool
}

// Sortable.handleSortEnd.defaultProps = {
// 	orderAsc: false
// }

// Sortable.handleSortEnd.propTypes = {
// 	arrayInfo: PropTypes.arrayOf(PropTypes.shape({
// 		values: PropTypes.arrayOf(PropTypes.number).isRequired,
// 		oldIndex: PropTypes.number.isRequired,
// 		newIndex: PropTypes.number.isRequired,
// 		orderAsc: PropTypes.bool
// 	})).isRequired,
// 	onChangeState: PropTypes.func.isRequired,
// 	putValuesOrder: PropTypes.func.isRequired
// }


export default Sortable
