import store from "../store"

const devsCustomersIds = [
	4092, // Default local user and Mota's account
	74, // Guilherme
	18927, // Marcinho (marcio@picsize.com)
	15791, // Marcinho (marciorasf@gmail.com)
	20929, // Davi (test_dev_davi_cecilio@widgets_feat.com.br)
	17967, // Davi (davicecilio@hotmail.com)
	15970 // Davi (davim.cecilio@gmail.com)
]

const themesCustomersIds = [
	21100, // Américo-Dark
	21025, // Américo-Light
	16455, // Wish
	18353, // Lovely 01
	15384, // Diamond
	15233, // Placeholder
	15392, // Lovely
	15386, // Portfolio
	20116, // Family
	20028, // Wish 2
	16449, // Now
	17574, // Modern
	21142, // Mon
	20955 // Cristal
]

const getCustomerIdFromStore = () => {
	const storeState = store.getState()
	return storeState.customer.user_data.customer_id
}

export const isDev = () => {
	const customerId = getCustomerIdFromStore()
	return devsCustomersIds.includes(customerId)
}

export const isTheme = () => {
	const customerId = getCustomerIdFromStore()
	return themesCustomersIds.includes(customerId)
}

export const isDevOrTheme = () => isDev() || isTheme()
