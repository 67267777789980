import { makeStyles, createStyles } from "@material-ui/core"

import colors from "../../../../styles/colors"

const useStyles = makeStyles(theme => createStyles({
	horizontalTab: {
		display: "flex",
		justifyContent: "center"
	},
	nonSelectedLayoutVariant: {
		border: "2px solid transparent",
		borderRadius: "5px",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	selectedLayoutVariant: {
		border: `2px solid ${colors.palette.confirm}`,
		borderRadius: "5px",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	}
}))

export default useStyles
