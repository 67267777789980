import React, { useState, cloneElement } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	DialogTitle,
	DialogContentText,
	DialogContent,
	DialogActions,
	CircularProgress,
	Button,
	Grid,
	IconButton,
	Drawer
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import useStyles from "./styles"

import { handleBackButton } from "../../utils/backButton"

import { isMobile } from "../../utils/checkDevice"

const InputDialog = (props) => {
	const {
		children,
		title,
		onOk,
		onCancel,
		onOpen,
		customInputElement,
		customAditionalElement,
		initialValue,
		onDataChange,
		fullWidth,
		openOnDrawer,
		parser,
		dataChangeEvent,
		cursor,
		description
	} = props

	const [loading, setLoading] = useState(false)
	const [opened, setOpened] = useState(false)
	const [inputData, setInputData] = useState(initialValue)

	const classes = useStyles({ cursor })

	const input = customInputElement || children

	const handleClose = async () => {
		setOpened(false)

		await onCancel()
	}

	const handleOk = async () => {
		setLoading(true)

		const result = await onOk(inputData)

		if (result === true) {
			handleClose()
		}

		setLoading(false)
	}

	const handleOpen = async () => {
		await onOpen()

		handleBackButton({
			active: isMobile && openOnDrawer,
			onBackButtonPress: handleClose
		})

		setInputData(initialValue)
		setOpened(true)
	}

	const handleChange = async (event) => {
		const data = parser(event)

		setInputData(data)

		onDataChange()
	}

	const handleSubmit = (event) => {
		event.preventDefault()

		handleOk()
	}

	const content = (
		<form onSubmit={handleSubmit}>
			{!openOnDrawer && (
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
			)}
			<DialogTitle>
				{title}
			</DialogTitle>

			<DialogContent>
				{
					description
					&& (
						<DialogContentText>
							{description}
						</DialogContentText>
					)
				}
				{
					cloneElement(input, {
						[dataChangeEvent]: handleChange,
						value: inputData,
						autoFocus: true
					})
				}
				{
					customAditionalElement && customAditionalElement
				}
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="inherit"
				>
					Cancelar
				</Button>
				<Button
					endIcon={loading && <CircularProgress size={20} className={classes.loading} />}
					onClick={handleOk}
					color="primary"
					variant="contained"
					htmlType="submit"
				>
					Salvar
				</Button>
			</DialogActions>
		</form>
	)

	return (
		<>
			<Grid
				item
				onClick={handleOpen}
				className={classes.children}
			>
				{children}
			</Grid>
			{openOnDrawer ? (
				<Drawer
					anchor="right"
					open={opened}
					onClose={handleClose}
					classes={{ paper: classes.drawerPaper }}
					style={{ width: "100vw" }}
				>
					{content}
				</Drawer>
			)
				: (
					<Dialog
						open={opened}
						onClose={handleClose}
						className={classes.dialog}
						fullWidth={fullWidth}
					>
						{content}
					</Dialog>
				)}
		</>
	)
}

InputDialog.defaultProps = {
	onCancel: () => { },
	onOpen: () => { },
	onDataChange: () => { },
	customInputElement: null,
	customAditionalElement: null,
	initialValue: "",
	openOnDrawer: false,
	parser: (event) => event.target.value,
	fullWidth: true,
	dataChangeEvent: "onChange",
	cursor: "pointer",
	description: ""
}

InputDialog.propTypes = {
	children: PropTypes.node.isRequired,
	title: PropTypes.string.isRequired,
	onOk: PropTypes.func.isRequired,
	onCancel: PropTypes.func,
	onOpen: PropTypes.func,
	onDataChange: PropTypes.func,
	customInputElement: PropTypes.node,
	customAditionalElement: PropTypes.node,
	initialValue: PropTypes
		.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
	fullWidth: PropTypes.bool,
	openOnDrawer: PropTypes.bool,
	parser: PropTypes.func,
	dataChangeEvent: PropTypes.oneOf(["onChange", "onBlur"]),
	cursor: PropTypes.oneOf(["pointer", "text"]),
	description: PropTypes.string
}

export default InputDialog
