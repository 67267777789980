import { makeStyles } from "@material-ui/core"

import colors from "../../../styles/colors"

import { getHeightInPercentageFromProportion } from "../../../utils/image"

const useStyles = makeStyles(theme => ({
	bannerPictureInfo: {
		color: colors.greyScale[4],
		fontSize: 12
	},
	bannerPictureDropzoneContainer: {
		height: 150,
		padding: theme.spacing() * 1
	},
	fab: {
		backgroundColor: ({ isBannerUploaded }) => `${isBannerUploaded ? colors.palette.tag2 : colors.palette.primary}`,
		color: colors.greyScale[11],
		"&:hover": {
			backgroundColor: ({ isBannerUploaded }) => `${isBannerUploaded ? colors.palette.tag2 : colors.palette.primary}`
		}
	},
	bannerPicture: {
		objectFit: "cover",
		width: "100%",
		height: "100%",
		maxHeight: "100%",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	bannerPictureContainer: {
		position: "relative",
		paddingBottom: props => `${getHeightInPercentageFromProportion(
			props.currentProportion || "16:9"
		)}%`,
		backgroundColor: colors.greyScale[9]
	},
	bannerPictureTextContainer: {
		position: "absolute",
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		height: "100%",
		justifyContent: "center",
		zIndex: 9
	},
	bannerPictureMainTitle: {
		color: colors.greyScale[11],
		fontSize: 24,
		fontWeight: "bold"
	},
	bannerPictureNormalText: {
		color: colors.greyScale[11],
		fontSize: 18
	},
	pictureFocusContainer: {
		display: "flex",
		alignItems: "center"
	},
	bannerPictureFocusContainer: {
		width: 36
	},
	bannerPictureFocusBlock: {
		height: 10,
		width: 10,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		cursor: "pointer",
		"&:hover": {
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			opacity: 0.7
		}
	}
}))

export default useStyles
