import { makeStyles } from "@material-ui/core"
import colors from "../../../../../styles/colors"

const dialogTitleHeight = "80px"
const dialogContentYPadding = "8px"

const useStyles = makeStyles((theme) => ({
	categoryList: {
		[theme.breakpoints.up("md")]: {
			overflowY: "scroll",
			maxHeight: `calc(100vh - ${dialogTitleHeight} - 2 * ${dialogContentYPadding})`
		}
	},
	categoryListItem: {
		position: "relative",
		fontWeight: "normal"
	},
	categoryListItemActive: {
		"&::before": {
			content: "\"\"",
			position: "absolute",
			height: "100%",
			width: 4,
			backgroundColor: colors.palette.confirm,
			top: 0,
			left: 0
		}
	},

	title: {
		textTransform: "uppercase"
	},
	card: {
		position: "relative",
		height: "100%",
		cursor: "pointer"
	},
	cardMediaContainer: {
		padding: theme.spacing(1),
		backgroundColor: colors.greyScale[9],
		dispĺay: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	cardMedia: {
		paddingTop: "100%",
		height: 0,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundColor: colors.greyScale[9]
	}
}))

export default useStyles
