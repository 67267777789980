import React from "react"
import PropTypes from "prop-types"
import { Chip } from "@material-ui/core"
import { Star as StarIcon } from "@material-ui/icons"

import useStyles from "./styles"

import theme from "../../styles/theme"
import { CheckUserPlan } from "../../utils/plan"

const PremiumChip = (props) => {
	const { disableGutters } = props

	const classes = useStyles()
	const userHasFreePlan = CheckUserPlan([26])

	if (userHasFreePlan) {
		return (
			<Chip
				avatar={<StarIcon className={classes.starIcon} fontSize="small" />}
				label="Recurso Premium"
				className={classes.premiumChip}
				style={{
					marginLeft: !disableGutters && theme.spacing() * 2,
					marginRight: !disableGutters && theme.spacing() * 2
				}}
			/>
		)
	}
	return null
}

PremiumChip.defaultProps = {
	disableGutters: false
}

PremiumChip.propTypes = {
	disableGutters: PropTypes.bool
}

export default PremiumChip
