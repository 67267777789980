import { makeStyles } from "@material-ui/core"
import colors from "../../styles/colors"

const useStyles = makeStyles(({
	tabsContainer: {
		borderBottom: `1px solid ${colors.greyScale[9]}`,
		paddingTop: 0
	},
	titleActivateFuncionality: {
		fontSize: "24px",
		lineHeight: "29px"
	},
	textActionActivateFuncionality: {
		fontWeight: "bold"
	}
}))

export default useStyles
