import React, { useState } from "react"
import { Link } from "react-router-dom"
import {
	MenuItem,
	Grid,
	Typography
} from "@material-ui/core"
import {
	Height as SpacingItemsIcon,
	PhotoSizeSelectLarge as LogoSizeIcon
} from "@material-ui/icons"

import useLiveEditorGlobalStyles from "../../../liveEditorGlobalStyles"
import { useLiveEditorStore } from "../../../../../store"
import {
	GroupField,
	Switch,
	ColorPicker,
	AlterFont,
	Slider,
	Select
} from "../../../components"
import { Divider } from "../../../../../components"

import { getElement } from "../../../../../hooks/useLivePreview"
import useDidMount from "../../../../../hooks/useDidMount"
import useValidation from "../../../../../hooks/useValidation"
import { getStatusCode } from "../../../../../utils/response"

import { buildHeaderLivePreviewStyle } from "./buildHeaderLivePreviewStyle"
import { transparentizeHexToRgba, transformRgbaStringInObject } from "../../../../../utils/color"
import api from "../../../../../services/api"


const HeaderEditor = () => {
	const liveEditorStore = useLiveEditorStore()
	const headerBanner1SectionIndexData = liveEditorStore.getSectionByHtmlTag("header_index")
	const headerBanner1SectionInsideData = liveEditorStore.getSectionByHtmlTag("header_inside")
	const generalStyleData = liveEditorStore.getStyleByType("general")
	const liveEditorClasses = useLiveEditorGlobalStyles()
	const [existsBanner, setExistsBanner] = useState([])

	const { triggerUndeterminedValidation } = useValidation()

	const updateNavbarOnLivePreview = ({
		position = headerBanner1SectionIndexData.properties.navbar_position,
		isOverlaid = headerBanner1SectionIndexData.properties.navbar_overlaid,
		isFixed = headerBanner1SectionIndexData.properties.navbar_isFixed
	} = {
		position: headerBanner1SectionIndexData.properties.navbar_position,
		isOverlaid: headerBanner1SectionIndexData.properties.navbar_overlaid,
		isFixed: headerBanner1SectionIndexData.properties.navbar_fixed
	}) => {
		const value = {
			position: "relative",
			top: position === "bottom" ? "auto !important" : "0 !important",
			bottom: position === "bottom" ? "0" : "auto",
			order: position === "top" ? 0 : 1
		}

		if (isFixed) {
			const pageContainer = getElement(".page-container")

			value.position = "fixed"
			value.top = `${pageContainer.style.marginTop} !important`
			value.bottom = "auto"
		} else if (isOverlaid) {
			value.position = "absolute"
		}

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .header-banner-1-content .navbar-container`,
				value
			}
		])
	}

	const handleChangeNavbarPosition = (event) => {
		const { value: position } = event.target

		updateNavbarOnLivePreview({ position })

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				navbar_position: position
			}
		})
	}

	const handleChangeNavbarOverlaid = (event) => {
		const { checked: isOverlaid } = event.target

		updateNavbarOnLivePreview({ isOverlaid })

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				navbar_overlaid: isOverlaid
			}
		})
	}

	const handleChangeNavbarFixedOnTop = (event) => {
		const { checked: isFixed } = event.target

		updateNavbarOnLivePreview({ isFixed })

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				navbar_fixed: isFixed,
				...isFixed ? { navbar_position: "top" } : {}
			}
		})
	}

	const transparentizeHexToRgbaInObject = (hexColor, opacity) => {
		let result = transparentizeHexToRgba(
			hexColor || "#F2F2F2",
			opacity
		)

		result = transformRgbaStringInObject(result)

		return result
	}

	const handleChangeNavbarBackgroundOnLivePreview = (color) => {
		const { rgb: rgba } = color
		const { navbar_background_color, navbar_opacity } = headerBanner1SectionIndexData.properties

		const rgbaColor = rgba
			?? transparentizeHexToRgbaInObject(
				navbar_background_color,
				navbar_opacity
			)

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .header-banner-1-content .navbar-container`,
				value: {
					"background-color": `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b},${existsBanner ? rgbaColor.a : 1})`
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .header-banner-1-content .navbar-container`,
				value: {
					"background-color": `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}, 1)`
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-drawer`,
				value: {
					"background-color": `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}, 1)`
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-drawer`,
				value: {
					"background-color": `rgba(${rgbaColor.r},${rgbaColor.g},${rgbaColor.b}, 1)`
				}
			}
		])
	}

	const handleChangeNavbarBackgroundColor = (color) => {
		const { rgb, hex } = color

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				navbar_background_color: hex,
				navbar_opacity: rgb.a
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				navbar_background_color: hex,
				navbar_opacity: 1
			}
		})

		handleChangeNavbarBackgroundOnLivePreview(color)
	}

	const handleChangeLogoAndMenuPosition = (positionValue, positionContext) => {
		let {
			logo_position: logoPosition,
			menu_position: menuPosition
		} = headerBanner1SectionIndexData.properties

		if (positionContext === "logo") {
			logoPosition = positionValue
		} else if (positionContext === "menu") {
			menuPosition = positionValue
		} else {
			return
		}

		const headerIndexLivePreviewStyle = buildHeaderLivePreviewStyle(
			headerBanner1SectionIndexData,
			logoPosition,
			menuPosition
		)

		const headerInsideLivePreviewStyle = buildHeaderLivePreviewStyle(
			headerBanner1SectionInsideData,
			logoPosition,
			menuPosition
		)

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerIndexLivePreviewStyle.navbarContainer.selector,
				value: {
					...headerIndexLivePreviewStyle.navbarContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerIndexLivePreviewStyle.desktopMenuContainer.selector,
				value: {
					...headerIndexLivePreviewStyle.desktopMenuContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerIndexLivePreviewStyle.mobileMenuContainer.selector,
				value: {
					...headerIndexLivePreviewStyle.mobileMenuContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerIndexLivePreviewStyle.logoContainer.selector,
				value: {
					...headerIndexLivePreviewStyle.logoContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerIndexLivePreviewStyle.logoContent.selector,
				value: {
					...headerIndexLivePreviewStyle.logoContent.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerInsideLivePreviewStyle.navbarContainer.selector,
				value: {
					...headerInsideLivePreviewStyle.navbarContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerInsideLivePreviewStyle.desktopMenuContainer.selector,
				value: {
					...headerInsideLivePreviewStyle.desktopMenuContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerInsideLivePreviewStyle.mobileMenuContainer.selector,
				value: {
					...headerInsideLivePreviewStyle.mobileMenuContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerInsideLivePreviewStyle.logoContainer.selector,
				value: {
					...headerInsideLivePreviewStyle.logoContainer.props
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: headerInsideLivePreviewStyle.logoContent.selector,
				value: {
					...headerInsideLivePreviewStyle.logoContent.props
				}
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				logo_position: logoPosition,
				menu_position: menuPosition
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				logo_position: logoPosition,
				menu_position: menuPosition
			}
		})
	}

	const handleChangeFontFamily = (fontFamily) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-family": `${fontFamily}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-family": `${fontFamily}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-family": `${fontFamily}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-family": `${fontFamily}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_font_family: fontFamily
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_font_family: fontFamily
			}
		})
	}

	const handleChangeFontSize = (fontSize) => {
		const fontSizeInPixel = `${fontSize}px`
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-size": `${fontSizeInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-size": `${fontSizeInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-size": `${fontSizeInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-size": `${fontSizeInPixel}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_font_size: fontSizeInPixel
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_font_size: fontSizeInPixel
			}
		})
	}

	const handleChangeFontColorOnLivePreview = (fontColor) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { color: `${fontColor}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { color: `${fontColor}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { color: `${fontColor}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { color: `${fontColor}!important` }
			}
		])
	}

	const handleChangeFontColor = (fontColor) => {
		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_font_color: fontColor
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_font_color: fontColor
			}
		})

		handleChangeFontColorOnLivePreview(fontColor)
	}

	const handleChangeFontBold = (isBold) => {
		const fontWeight = isBold ? "bold" : 400

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-weight": `${fontWeight}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-weight": `${fontWeight}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-weight": `${fontWeight}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-weight": `${fontWeight}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_font_weight: fontWeight
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_font_weight: fontWeight
			}
		})
	}

	const handleChangeFontLetterSpacing = (letterSpacing) => {
		const letterSpacingInPixel = `${letterSpacing}px`
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "letter-spacing": `${letterSpacingInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "letter-spacing": `${letterSpacingInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "letter-spacing": `${letterSpacingInPixel}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "letter-spacing": `${letterSpacingInPixel}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_letter_spacing: letterSpacingInPixel
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_letter_spacing: letterSpacingInPixel
			}
		})
	}

	const handleChangeFontTextTransform = (textTransform) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "text-transform": `${textTransform}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "text-transform": `${textTransform}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "text-transform": `${textTransform}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "text-transform": `${textTransform}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_text_transform: textTransform
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_text_transform: textTransform
			}
		})
	}

	const handleChangeFontItalic = (isItalic) => {
		const fontStyle = isItalic ? "italic" : "normal"
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-style": `${fontStyle}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { "font-style": `${fontStyle}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-style": `${fontStyle}!important` }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { "font-style": `${fontStyle}!important` }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_font_style: fontStyle
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_font_style: fontStyle
			}
		})
	}

	const handleChangeItemsMargin = (marginSize) => {
		const itemsMarginInPixels = `${marginSize}px`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .desktop-menu-container .menu-list-item-link`,
				value: { margin: itemsMarginInPixels }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .desktop-menu-container .menu-list-item-link`,
				value: { margin: itemsMarginInPixels }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionIndexData.id} .mobile-menu-container .mdc-list-item`,
				value: { margin: itemsMarginInPixels }
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .menu-list-item-link, #page-section-${headerBanner1SectionInsideData.id} .mobile-menu-container .mdc-list-item`,
				value: { margin: itemsMarginInPixels }
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				menu_items_margin: itemsMarginInPixels
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				menu_items_margin: itemsMarginInPixels
			}
		})
	}

	const handleChangeLogoSize = (logoSize) => {
		const maxLogoHeight = 118
		const maxLogoWidth = 450

		const logoIndexImgSelector = `#page-section-${headerBanner1SectionIndexData.id} .header-banner-1-content .navbar-container .navbar-content .logo-container .logo-content img`
		const logoInsideImgSelector = `#page-section-${headerBanner1SectionInsideData.id} .header-banner-1-content .navbar-container .navbar-content .logo-container .logo-content img`
		const logoIndexImgElement = getElement(logoIndexImgSelector)
		const logoInsideImgElement = getElement(logoInsideImgSelector)

		const logoWidth = (logoIndexImgElement.width || logoInsideImgElement.width)
		const logoHeight = (logoIndexImgElement.height || logoInsideImgElement.height)

		const typeToAlter = logoWidth < logoHeight ? "height" : "width"
		const percentageToDecimal = logoSize / 100

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: logoIndexImgSelector,
				value: {
					width: typeToAlter === "width" ? `${percentageToDecimal * maxLogoWidth}px` : "auto",
					height: typeToAlter === "height" ? `${percentageToDecimal * maxLogoHeight}px` : "auto"
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: logoInsideImgSelector,
				value: {
					width: typeToAlter === "width" ? `${percentageToDecimal * maxLogoWidth}px` : "auto",
					height: typeToAlter === "height" ? `${percentageToDecimal * maxLogoHeight}px` : "auto"
				}
			}
		])

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionIndexData.id,
			properties: {
				logo_size: `${logoSize}%`
			}
		})

		liveEditorStore.setSectionData({
			sectionId: headerBanner1SectionInsideData.id,
			properties: {
				logo_size: `${logoSize}%`
			}
		})
	}

	const getBanners = async () => {
		try {
			const { data } = await api.get("/site/banner/list")
			setExistsBanner(data?.banners?.length > 0)
		} catch (error) {
			if (getStatusCode(error) === 404) {
				setExistsBanner(false)
			} else {
				triggerUndeterminedValidation(error)
			}
		}
	}

	useDidMount(() => {
		getBanners()
	})

	const headerPositionOptions = [
		{
			id: 0,
			value: "top",
			label: "Acima"
		},
		{
			id: 1,
			value: "bottom",
			label: "Abaixo"
		}
	]

	const NavbarEditor = (
		<GroupField label="Barra de navegação">

			{existsBanner && (
				<>
					<Switch
						label="Fixar no topo"
						value={headerBanner1SectionIndexData.properties.navbar_fixed}
						onChange={handleChangeNavbarFixedOnTop}
					/>

					<Divider orientation="horizontal" size="medium" />

					{!headerBanner1SectionIndexData.properties.navbar_fixed && (
						<>
							<Select
								label="Posição em relação ao banner"
								value={headerBanner1SectionIndexData.properties.navbar_position}
								onChange={handleChangeNavbarPosition}
							>
								{headerPositionOptions.map(position => (
									<MenuItem key={position.id} value={position.value}>
										{position.label}
									</MenuItem>
								))}
							</Select>

							<Divider size="medium" orientation="horizontal" />

							<Switch
								label="Sobrepor banner"
								value={headerBanner1SectionIndexData.properties.navbar_overlaid}
								onChange={handleChangeNavbarOverlaid}
							/>

							<Divider size="medium" orientation="horizontal" />
						</>
					)}
				</>
			)}

			<ColorPicker
				label="Cor de fundo"
				enableOpacity={existsBanner}
				allColor
				value={
					headerBanner1SectionIndexData.properties.navbar_background_color
						? transparentizeHexToRgba(
							headerBanner1SectionIndexData.properties.navbar_background_color,
							headerBanner1SectionIndexData.properties.navbar_opacity
						) : "transparent"
				}
				onPreviewChange={handleChangeNavbarBackgroundOnLivePreview}
				onChange={handleChangeNavbarBackgroundColor}
			/>
		</GroupField>
	)

	const logoPositionOptions = [
		{
			id: 0,
			value: "left",
			label: "Esquerda"
		},
		{
			id: 1,
			value: "center",
			label: "Centro"
		},
		{
			id: 2,
			value: "right",
			label: "Direita"
		}
	]

	const LogoEditor = (
		<GroupField label="Logomarca">
			<Select
				label="Posição"
				value={headerBanner1SectionIndexData.properties.logo_position}
				onChange={({ target }) => handleChangeLogoAndMenuPosition(target.value, "logo")}
			>
				{logoPositionOptions.map(option => (
					<MenuItem key={option.id} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				icon={<LogoSizeIcon />}
				label="Tamanho"
				value={parseInt(headerBanner1SectionIndexData.properties.logo_size || 10, 10)}
				step={10}
				min={10}
				max={100}
				onChange={(_, sizeLogo) => handleChangeLogoSize(sizeLogo)}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Grid container justify="center">
				<Link to="/logo" target="_blank">
					<Typography className={liveEditorClasses.linkTypographic} variant="h2">Gerenciar a logomarca</Typography>
				</Link>
			</Grid>
		</GroupField>
	)

	const menuPositionOptions = [
		{
			id: 0,
			value: "opposite-logo",
			label: "Lado oposto da logomarca"
		},
		{
			id: 1,
			value: "right-logo",
			label: "Próximo da logomarca"
		},
		{
			id: 2,
			value: "bottom-logo",
			label: "Abaixo da logomarca"
		},
		{
			id: 3,
			value: "top-logo",
			label: "Acima da logomarca"
		}
	]

	const MenuEditor = (
		<GroupField label="Menu">
			<Select
				label="Posição em relação a logomarca"
				value={headerBanner1SectionIndexData.properties.menu_position}
				onChange={({ target }) => handleChangeLogoAndMenuPosition(target.value, "menu")}
			>
				{menuPositionOptions.map(option => (
					<MenuItem key={option.id} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>

			<Divider orientation="horizontal" size="medium" />

			<AlterFont
				label="Fonte"
				fontFamily={{
					value: headerBanner1SectionIndexData.properties.menu_font_family
						?? generalStyleData.properties.font_family,
					onChange: handleChangeFontFamily
				}}
				fontSize={{
					value: parseInt(headerBanner1SectionIndexData.properties.menu_font_size || 16, 10),
					optionsSize: [12, 14, 16, 18, 20, 22, 24, 26],
					onChange: handleChangeFontSize
				}}
				fontColor={{
					value: headerBanner1SectionIndexData.properties.menu_font_color || "#000000",
					onPreviewChange: handleChangeFontColorOnLivePreview,
					onChange: handleChangeFontColor
				}}
				additionalChanges={{
					boldFont: {
						value: headerBanner1SectionIndexData.properties.menu_font_weight === "bold",
						onChange: handleChangeFontBold
					},
					letterSpacing: {
						value: parseInt(
							headerBanner1SectionIndexData
								.properties
								.menu_letter_spacing
							|| 0, 10
						),
						onChange: handleChangeFontLetterSpacing
					},
					textTransform: {
						value: headerBanner1SectionIndexData.properties.menu_text_transform,
						onChange: handleChangeFontTextTransform
					},
					italicFont: {
						value: headerBanner1SectionIndexData.properties.menu_font_style === "italic",
						onChange: handleChangeFontItalic
					}
				}}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Espaçamento entre os itens do menu"
				icon={<SpacingItemsIcon style={{ transform: "rotate(90deg)" }} />}
				value={parseInt(
					headerBanner1SectionIndexData
						.properties
						.menu_items_margin
					|| 0, 10
				)}
				step={1}
				min={0}
				max={25}
				onChange={(_, itemsMargin) => handleChangeItemsMargin(itemsMargin)}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Grid container justify="center">
				<Link to="/menu" target="_blank">
					<Typography className={liveEditorClasses.linkTypographic} variant="h2">
						Gerenciar os itens do menu
					</Typography>
				</Link>
			</Grid>
		</GroupField>
	)

	return (
		<Grid container spacing={2}>
			<Divider orientation="horizontal" size="small" />

			<Grid item xs={12}>
				{NavbarEditor}
			</Grid>

			<Grid item xs={12}>
				{LogoEditor}
			</Grid>

			<Grid item xs={12}>
				{MenuEditor}
			</Grid>
		</Grid>
	)
}

export default HeaderEditor
