import { useCustomerStore } from "../store"

export const siteProPlansUrl = `${process.env.REACT_APP_GALLERY_PRO_URL}/checkout/site-pro/plans`

/**
	*
	* @param {Number[]} validPlans A array with plans Ex: [26]
	*/
export const CheckUserPlan = (validPlans) => {
	const customerStore = useCustomerStore()

	const userPlanId = customerStore.state.user_subscription.plan_id

	const checkPlan = validPlans.includes(userPlanId)

	return checkPlan
}
