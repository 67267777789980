import { useState } from "react"

import { Notification } from "../components"

/**
 *
 * @param {error} AxiosError
 */
const parseError = (error) => {
	// eslint-disable-next-line
	const validationMessages = error && error.response && error.response.data && error.response.data.messages
	const errorMessage = error && error.message

	return {
		validationMessages,
		errorMessage
	}
}

const useValidation = () => {
	const [validation, setValidation] = useState({})

	// eslint-disable-next-line
	const triggerUndeterminedValidation = (error) => {
		Notification.error({ message: "Algo deu errado." })
		if (process.env.NODE_ENV === "development") {
			// eslint-disable-next-line
			console.log({ error })
		}
	}

	/**
	 *
	 * @param {error} AxiosError
	 */
	const triggerValidation = (error) => {
		const data = parseError(error)

		if (!data.validationMessages) {
			triggerUndeterminedValidation(error)
		} else {
			setValidation((lastValidation) => ({
				...lastValidation,
				...data.validationMessages
			}))
		}
	}

	const clearValidation = (key) => {
		if (key in validation) {
			setValidation((lastValidation) => {
				const updatedData = { ...lastValidation }

				delete updatedData[key]

				return updatedData
			})
		}
	}

	return {
		triggerUndeterminedValidation,
		triggerValidation,
		clearValidation,
		validation
	}
}

export default useValidation
