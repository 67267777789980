import React from "react"
import PropTypes from "prop-types"

import {
	GroupField,
	LiveEditorTypography,
	TextInput
} from "../../../../components"
import ChangeLayout from "../../../../components/ChangeLayout"
import { Divider } from "../../../../../../components"

import { useLiveEditorStore } from "../../../../../../store"

import SectionDefaultDesign from "../SectionDefaultDesign"

const Image1Editor = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const sectionData = liveEditorStore.getSectionById(sectionId)

	const handleChangeText = (attrSelector, value, content) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content
		})
	}

	const handleChangeTitleText = (event) => {
		const { value } = event.target
		handleChangeText("[le-title]", value, { title: value })
	}

	const handleChangeSubtitleText = (event) => {
		const { value } = event.target
		handleChangeText("[le-subtitle]", value, { subtitle: value })
	}

	const handleChangeParagraphText = (event) => {
		const { value } = event.target
		handleChangeText("[le-paragraph]", value, { paragraph: value })
	}

	const handleChangeTextVisibility = (attrSelector, styleObject, properties) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_INLINE_STYLE,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value: styleObject
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties
		})
	}

	const handleChangeTitleTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_title: checked }

		handleChangeTextVisibility("[le-title-container]", styleObject, properties)
	}

	const handleChangeSubtitleTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_subtitle: checked }

		handleChangeTextVisibility("[le-subtitle-container]", styleObject, properties)
	}

	const handleChangeParagraphTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_paragraph: checked }

		handleChangeTextVisibility("[le-paragraph-container]", styleObject, properties)
	}

	return (
		<>
			<GroupField label="Conteúdo">
				<LiveEditorTypography type="groupFieldSubtitle">
					Texto
				</LiveEditorTypography>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Título"
					value={sectionData?.content?.title}
					onChange={handleChangeTitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_title,
						onChange: handleChangeTitleTextVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Subtítulo"
					value={sectionData?.content?.subtitle}
					onChange={handleChangeSubtitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_subtitle,
						onChange: handleChangeSubtitleTextVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Parágrafo"
					multiline
					value={sectionData?.content?.paragraph}
					onChange={handleChangeParagraphText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_paragraph,
						onChange: handleChangeParagraphTextVisibility
					}}
				/>
			</GroupField>

			<Divider orientation="horizontal" size="medium" />

			<ChangeLayout pageSectionId={sectionId} />

			<Divider orientation="horizontal" size="medium" />

			<SectionDefaultDesign sectionId={sectionId} />
		</>
	)
}

Image1Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default Image1Editor
