import React, { useState } from "react"
import { Switch, Route, useHistory, useLocation } from "react-router-dom"
import { Container } from "@material-ui/core"

// import { WhatsApp as WhatsAppIcon } from "@material-ui/icons"
// import { supportContactInfo } from "../utils/contact"

import { Menu, Header, Breadcrumb, Loading } from "../components"

import { isMobile } from "../utils/checkDevice"

import {
	initGoogleTagManager,
	triggerGoogleAnalyticsPageView,
	addDataLayerVariables
} from "../services/google"

import api from "../services/api"

import { useCustomerStore, useSiteStore } from "../store"
import useDidMount from "../hooks/useDidMount"
import useValidation from "../hooks/useValidation"

import useStyles from "./styles"

import PrivateRoutes from "./private"
import PublicRoutes from "./public"

const PrivateComponent = () => {
	const customerStore = useCustomerStore()
	const siteStore = useSiteStore()
	const classes = useStyles()
	const location = useLocation()
	const { triggerUndeterminedValidation } = useValidation()

	const [loadingInitialData, setLoadingInitialData] = useState(true)

	const loadCustomerData = async () => {
		try {
			const [{ data }] = await Promise.all([
				api.get("/site/customer/info"),
				addDataLayerVariables()
			])

			if (data.user_subscription && data.user_subscription.redirectToSolutionPage === true) {
				window.location = `${process.env.REACT_APP_GALLERY_PRO_URL}/wizard-solution-switch`
				return
			}

			customerStore.setUserData({
				customer_id: data.user_data.customer_id,
				display_name: data.user_data.display_name,
				avatar: data.user_data.avatar,
				isNewFeaturesTester: data.user_data.isNewFeaturesTester,
				authMethod: data.user_data.authMethod
			})

			customerStore.setUserUsage({
				total_works: data.user_usage.total_works,
				total_banners: data.user_usage.total_banners
			})

			customerStore.setUserSubscription({
				plan_id: data.user_subscription.plan_id
			})

			siteStore.setSiteData({
				url: data.site_data.url,
				theme_url: data.site_data.theme_url,
				picsize_site_url: data.site_data.picsize_site_url
			})

			setLoadingInitialData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	useDidMount(() => {
		loadCustomerData()
	})

	return (
		<Loading loading={loadingInitialData}>
			{location.pathname.includes("live-editor") ? (
				<Container maxWidth={null} disableGutters style={{ display: "flex", overflow: "hidden" }}>
					<PrivateRoutes />
				</Container>
			) : (
				<Container maxWidth={null} disableGutters style={{ display: "flex" }}>
					<Menu />

					<Container
						maxWidth={null}
						disableGutters
						style={{
							overflowY: location.pathname.includes("widgets") ? "hidden" : "scroll",
							paddingBottom: 80,
							marginLeft: !isMobile && 60
						}}
					>
						<Header />

						<Container
							maxWidth={null}
							style={{ overflow: "hidden" }}
							className={location.pathname.includes("widgets") && classes.containerWithoutPadding}
						>

							<Breadcrumb />

							<PrivateRoutes />

						</Container>
					</Container>

					{/* {
						!location.pathname.includes("widgets")
							&& (
								<Button
									startIcon={<WhatsAppIcon />}
									variant="contained"
									color="secondary"
									className={classes.supportButton}
									href={supportContactInfo().whatsappLink}
									target="_blank"
								>
									SUPORTE
								</Button>
							)
					} */}

				</Container>
			)}
		</Loading>
	)
}

const Routes = () => {
	const history = useHistory()

	history.listen(({ pathname }) => {
		triggerGoogleAnalyticsPageView(pathname)
	})

	useDidMount(() => {
		initGoogleTagManager()
	})

	return (
		<Switch>
			{PublicRoutes}
			<Route path="/" component={PrivateComponent} />
		</Switch>
	)
}

export default Routes
