import { useDispatch, useSelector } from "react-redux"

import { keepNotFalsyObjectParams } from "../../utils/sanitization"

export const Types = {
	SET_TESTIMONIAL_DATA: "testimonial/SET_TESTIMONIAL_DATA"
}

const initialState = {
	author: "...",
	testimonial_id: ""
}

export const useTestimonialStore = () => {
	const dispatch = useDispatch()
	const state = useSelector(globalState => globalState.testimonial)

	const setTestimonialData = ({ author, testimonial_id }) => dispatch({
		type: Types.SET_TESTIMONIAL_DATA,
		payload: keepNotFalsyObjectParams({
			author,
			testimonial_id
		})
	})

	/**
   * Work around to auto complete state variables
   */
	let updatedState = { ...initialState }
	updatedState = state

	return {
		setTestimonialData,
		state: updatedState
	}
}

const testimonial = (state = initialState, action) => {
	switch (action.type) {
	case Types.SET_TESTIMONIAL_DATA:
		return {
			...state,
			...action.payload
		}
	default:
		return state
	}
}

export default testimonial
