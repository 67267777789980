import React from "react"
import { Link } from "react-router-dom"
import {
	Breadcrumbs,
	Typography,
	Link as MaterialLink
} from "@material-ui/core"

import { useWorkStore, useBannerStore, useTestimonialStore } from "../../store"

import useStyles from "./styles"
import Divider from "../Divider"

const Breadcrumb = () => {
	const classes = useStyles()
	const workStore = useWorkStore()
	const bannerStore = useBannerStore()
	const testimonialStore = useTestimonialStore()

	const breadCrumbNameMap = {
		"/portfolio": "Meu portfólio",
		[`/portfolio/work/${workStore.state.work_id}`]: workStore.state.title,
		"/domain": "Domínio",
		"/logo": "Logotipo",
		"/about": "Sobre mim",
		"/banner": "Banners",
		"/menu": "Menu",
		"/testimonial": "Depoimentos",
		"/general": "Geral",
		[`/testimonial/edit/${testimonialStore.state.testimonial_id}`]: testimonialStore.state.author,
		[`/banner/edit/${bannerStore.state.banner_id}`]: bannerStore.state.title,
		"/turbo-seo": "Turbo SEO",
		"/integrations": "Integrações",
		[`/testimonial/edit/${testimonialStore.state.testimonial_id}`]: testimonialStore
			.state.author,
		[`/banner/edit/${bannerStore.state.banner_id}`]: bannerStore.state.title
	}

	const elements = []

	const { pathname } = window.location

	const pathParams = pathname.split("/").filter((x) => x)

	pathParams.forEach((_, index) => {
		/**
			* Verifies if the current param is the last
			*/
		const last = index === pathParams.length - 1

		/**
			* Mounts a link for the current path
			*/
		const to = `/${pathParams.slice(0, index + 1).join("/")}`

		const name = breadCrumbNameMap[to]

		if (name) {
			if (last) {
				elements.push(<Typography color="textPrimary">{name}</Typography>)
			} else {
				elements.push(
					<MaterialLink component={Link} to={to} className={classes.breadCrumbLink}>
						{name}
					</MaterialLink>
				)
			}
		}
	})

	return (
		<>
			{
				!!elements.length
				&& (
					<>
						<Divider size="large" orientation="horizontal" />
						<Breadcrumbs>{elements}</Breadcrumbs>
						<Divider size="large" orientation="horizontal" />
					</>
				)
			}
		</>
	)
}

export default Breadcrumb
