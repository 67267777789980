import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"

import About from "../../pages/About"
import Logo from "../../pages/Logo"
import Domain from "../../pages/Domain"

import Portfolio from "../../pages/Portfolio"
import PortfolioWork from "../../pages/Portfolio/Work"

import Banner from "../../pages/Banner"
import BannerEdit from "../../pages/Banner/Edit"

import Menu from "../../pages/Menu"

import Testimonial from "../../pages/Testimonial"
import TestimonialEdit from "../../pages/Testimonial/Edit"

import General from "../../pages/General"

import PageMaker from "../../pages/PageMaker"

import LiveEditor from "../../pages/LiveEditor"

import TurboSeo from "../../pages/TurboSeo"
import Integrations from "../../pages/Integrations"

import Sitemap from "../../pages/Sitemap"

import Widgets from "../../pages/Widgets"

import ThemeGallerySiteCp from "../../pages/development/ThemeGallerySiteCp"
import MediaSystem from "../../pages/development/MediaSystem"
import ColorGallery from "../../pages/development/ColorGallery"
import SectionsGallery from "../../pages/development/SectionsGallery"

const PrivateRoutes = () => (
	<Switch>
		<Route exact path="/" component={() => <Redirect to="/portfolio" />} />
		<Route exact path="/portfolio" component={Portfolio} />
		<Route exact path="/portfolio/work/:work_id" component={PortfolioWork} />
		<Route exact path="/domain" component={Domain} />
		<Route exact path="/logo" component={Logo} />
		<Route exact path="/about" component={About} />
		<Route exact path="/banner" component={Banner} />
		<Route exact path="/banner/edit/:banner_id" component={BannerEdit} />
		<Route exact path="/menu" component={Menu} />
		<Route exact path="/testimonial" component={Testimonial} />
		<Route
			exact
			path="/testimonial/edit/:testimonial_id"
			component={TestimonialEdit}
		/>
		<Route exact path="/general" component={General} />
		<Route exact path="/page-maker" component={PageMaker} />
		<Route exact path="/live-editor" component={LiveEditor} />
		<Route exact path="/turbo-seo" component={TurboSeo} />
		<Route exact path="/integrations" component={Integrations} />
		<Route exact path="/sitemap-generator" component={Sitemap} />
		<Route exact path="/widgets" component={Widgets} />

		<Route exact path="/test/theme-gallery/site-cp" component={ThemeGallerySiteCp} />
		<Route exact path="/test/media-system" component={MediaSystem} />
		<Route exact path="/test/color-gallery" component={ColorGallery} />
		<Route exact path="/test/sections-gallery" component={SectionsGallery} />
	</Switch>
)

export default PrivateRoutes
