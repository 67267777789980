import { makeStyles } from "@material-ui/core"

const mobileActionBarHeight = "52px"
export const consoleMaxWidth = "320px"

const useStyles = makeStyles(theme => ({
	publishButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none"
	},
	mobileActionBar: {
		position: "fixed",
		bottom: 0,
		width: "100%",
		zIndex: 2,
		boxShadow: "0 -1px 3px 0 rgba(63,63,68,0.1)",
		backgroundColor: "white",
		height: mobileActionBarHeight,
		paddingRight: theme.spacing(2),

		[theme.breakpoints.up("md")]: {
			zIndex: -1
		}
	}
}))

export default useStyles
