import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	container: {
		position: "relative",
		display: "flex",
		alignItems: "center",
		justifyContent: "center"
	},

	buttonsContainer: {
		position: "absolute"
	},

	pictureContainer: {
		maxWidth: "100%",
		height: 450,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		borderWidth: "2px",
		borderStyle: "dashed",
		borderRadius: "3px",
		borderColor: "lightgrey",
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	}
}))

export default useStyles
