import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, createStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => createStyles({
	skeleton: {
		padding: theme.spacing() * 1
	}
}))

const WorkPictureDropzoneSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container justify="flex-start">
			<Skeleton animation="wave" variant="rect" width="100%" height={300} className={classes.skeleton} />
		</Grid>
	)
}

export default WorkPictureDropzoneSkeleton
