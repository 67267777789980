import { createMuiTheme } from "@material-ui/core"

import colors from "./colors"

const defaultTheme = createMuiTheme()

const theme = createMuiTheme({
	palette: {
		primary: {
			main: colors.palette.primary
		},
		secondary: {
			main: colors.palette.confirm,
			contrastText: "#FFF"
		},
		info: {
			main: colors.palette.tag2
		},
		warning: {
			main: colors.palette.tag3
		},
		success: {
			main: colors.palette.confirm
		}
	},
	overrides: {
		MuiLink: {
			root: {
				color: colors.palette.link
			}
		}
	},
	typography: {
		fontFamily: "Open Sans",
		h1: {
			fontStyle: "normal",
			fontWeight: "normal",
			fontSize: "15px",
			lineHeight: "20px",
			color: colors.greyScale[2]
		},
		h2: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "14px",
			lineHeight: "19px",
			letterSpacing: "0.07em",
			color: colors.greyScale[3]
		},
		h3: {
			fontStyle: "normal",
			fontWeight: 600,
			fontSize: "16px",
			lineHeight: "19px",
			letterSpacing: "-0.03em",
			color: colors.greyScale[1]
		},
		h4: {
			fontStyle: "normal",
			fontWeight: 500,
			fontSize: "12px",
			lineHeight: "19px",
			letterSpacing: "-0.03em",
			color: colors.greyScale[3]
		}
	},
	shadows: Object.values({
		...defaultTheme.shadows,
		1: "0px 0px 10px #ECECEC"
	})
})

export default theme
