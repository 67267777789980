import { makeStyles } from "@material-ui/core"
import colors from "../../../../../styles/colors"

const useStyles = makeStyles((theme) => ({
	title: {
		textTransform: "uppercase"
	},
	card: {
		position: "relative",
		height: "100%",
		cursor: "pointer"
	},
	cardActive: {
		border: `6px solid ${colors.palette.confirm}`,
		borderRadius: theme.spacing(0.5)
	},
	cardMediaContainer: {
		padding: theme.spacing(1),
		backgroundColor: colors.greyScale[9],
		dispĺay: "flex",
		alignItems: "center",
		justifyContent: "center"
	},
	cardMedia: {
		paddingTop: "100%",
		height: 0,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundColor: colors.greyScale[9]
	}
}))

export default useStyles
