import React from "react"
import PropTypes from "prop-types"
import {
	Typography as MdTypographic,
	makeStyles,
	createStyles
} from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(theme => createStyles({
	accordionTitle: {
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "14px",
		lineHeight: "19px",
		letterSpacing: "0.07em",
		textTransform: "uppercase",
		color: colors.greyScale[2],
		marginLeft: theme.spacing(3)
	},
	groupFieldTitle: {
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "14px",
		lineHeight: "19px",
		color: colors.greyScale[3]
	},
	groupFieldSubtitle: {
		fontStyle: "normal",
		fontWeight: 600,
		fontSize: "14px",
		lineHeight: "19px",
		color: colors.greyScale[2]
	},
	fieldTitle: {
		fontStyle: "normal",
		fontWeight: 500,
		fontSize: "12px",
		lineHeight: "19px",
		color: colors.greyScale[3]
	},
	fieldDescription: {
		fontStyle: "normal",
		fontWeight: 400,
		fontSize: "12px",
		lineHeight: "16px",
		color: colors.greyScale[3]
	}
}))

const LiveEditorTypography = (props) => {
	const { type, children } = props
	const classes = useStyles()
	return (
		<MdTypographic className={classes[type]}>
			{children}
		</MdTypographic>
	)
}

LiveEditorTypography.propTypes = {
	type: PropTypes.string.isRequired,
	children: PropTypes.string.isRequired
}
export default LiveEditorTypography
