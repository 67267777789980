import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const bgColor = "#F8F9FA"
const iframeBgColor = "#F4F6F8"
const headerHeight = "48px"
const tabsHeight = "48px"
const iFrameMobileWidth = "450px"
const mobileActionBarHeight = "52px"
export const consoleMaxWidth = "320px"

const useStyles = makeStyles(theme => ({
	liveEditorContainer: {
		backgroundColor: bgColor,
		position: "relative"
	},

	consoleContainer: {
		transition: "0.3s",
		borderRight: `1px solid ${colors.greyScale[9]}`,
		maxWidth: "100%",
		transformOrigin: "left center",
		width: ({ isConsoleVisible }) => `${isConsoleVisible ? "100%" : 0}`,

		[theme.breakpoints.up("md")]: {
			maxWidth: consoleMaxWidth
		}
	},

	tabsContainer: {
		height: tabsHeight,
		borderBottom: `1px solid ${colors.greyScale[9]}`
	},

	consoleContent: {
		height: `calc(100vh - ${headerHeight} - ${tabsHeight} - ${mobileActionBarHeight})`,
		overflowY: "scroll",

		"&::-webkit-scrollbar": {
			width: "10px",
			height: "10px",
			backgroundColor: "rgba(0,0,0,0.1)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0)",
			borderRadius: "100px"
		},
		"&:hover::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.25)"
		},

		[theme.breakpoints.up("md")]: {
			height: `calc(100vh - ${headerHeight} - ${tabsHeight})`
		}
	},

	viewContainer: {
		transition: "0.3s",
		zIndex: 1,
		position: "absolute",
		width: "100%",
		transform: "translateY(100vh)",

		[theme.breakpoints.up("md")]: {
			position: "relative",
			zIndex: "initial",
			transform: "translateY(0)"
		}
	},

	isMobileLivePreviewActive: {
		transform: "translateY(0)"
	},

	actionsContainer: {
		height: "100%",
		backgroundColor: bgColor,
		borderBottom: `1px solid ${colors.greyScale[9]}`,
		display: "none",

		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},

	leftActions: {
		width: consoleMaxWidth
	},

	backButton: {
		textTransform: "none",
		marginLeft: theme.spacing(2),
		color: colors.greyScale[3]
	},

	centerActions: {
	},

	rightActions: {
		alignSelf: "stretch",
		display: "flex",
		alignItems: "center"
	},

	iframeSrcLink: {
		marginRight: theme.spacing(3),
		whiteSpace: "nowrap"
	},

	publishButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none"
	},

	discardButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none",
		padding: theme.spacing(0.75, 2),
		color: colors.greyScale[2]
	},

	desktopIcon: {
		color: ({ device }) => `${device === "desktop" ? colors.greyScale[4] : colors.greyScale[9]}`,
		transition: "0.3s"
	},

	phoneIcon: {
		color: ({ device }) => `${device === "mobile" ? colors.greyScale[4] : colors.greyScale[9]} `,
		transition: "0.3s"
	},

	fullWidthIcon: {
		color: ({ isConsoleVisible }) => `${!isConsoleVisible ? colors.greyScale[4] : colors.greyScale[9]} `,
		transition: "0.3s"
	},

	iconsDivider: {
		height: "50%",
		alignSelf: "center",
		margin: theme.spacing(0, 1.5)
	},

	iframeContainer: {
		height: `calc(100vh - ${headerHeight})`,
		width: "100%",
		display: "flex",
		justifyContent: "center",
		marginBottom: mobileActionBarHeight,
		backgroundColor: iframeBgColor,

		[theme.breakpoints.up("md")]: {
			marginBottom: 0
		}
	},

	iframe: {
		flex: 1,
		border: "none",
		maxWidth: ({ device }) => `${device === "desktop" ? "100%" : iFrameMobileWidth} `,
		transition: "0.4s",

		[theme.breakpoints.up("md")]: {
			margin: theme.spacing(2),
			boxShadow: "0 6px 8px rgba(0, 0, 0, 0.2)"
		}
	},

	mobileActionBar: {
		position: "fixed",
		bottom: 0,
		width: "100%",
		zIndex: 2,
		boxShadow: "0 -1px 3px 0 rgba(63,63,68,0.1)",
		backgroundColor: "white",
		height: mobileActionBarHeight,
		paddingRight: theme.spacing(2),

		[theme.breakpoints.up("md")]: {
			zIndex: -1
		}
	}
}))

export default useStyles
