import React from "react"
import PropTypes from "prop-types"
import {
	Grid,
	Tabs,
	Tab
} from "@material-ui/core"

import {
	Palette as PaletteIcon,
	VerticalSplit as VerticalSplitIcon
} from "@material-ui/icons"

import useStyles from "./styles"
import useLivePreview from "../../../hooks/useLivePreview"

import { Loading, Divider } from "../../../components"

import StylesEditor from "./StylesEditor"
import PagesEditor from "./PagesEditor"

import LiveEditorSkeleton from "../../../skeletons/LiveEditor"

const Console = ({
	loading,
	publishing,
	device,
	isConsoleVisible,
	editorType,
	setEditorType,
	triggerSelectSectionId
}) => {
	const classes = useStyles({ device, isConsoleVisible })

	const { deactivateAllSections } = useLivePreview()

	const handleChangeEditorType = (_, type) => {
		if (type === "styles") {
			deactivateAllSections()
		}
		setEditorType(type)
	}

	return (
		<Grid item className={classes.consoleContainer}>
			<Loading loading={loading} customLoadingElement={<LiveEditorSkeleton />}>
				<Grid container>
					<Grid className={classes.tabsContainer} item xs={12}>
						<Tabs
							textColor="inherit"
							value={editorType}
							onChange={handleChangeEditorType}
							variant="fullWidth"
							indicatorColor="primary"
						>
							<Tab
								icon={(
									<Grid container justify="center" alignItems="center">
										<PaletteIcon />
										<Divider size="small" orientation="vertical" />
										GERAL
									</Grid>
								)}
								value="styles"
							/>

							<Tab
								icon={(
									<Grid container justify="center" alignItems="center">
										<VerticalSplitIcon />
										<Divider size="small" orientation="vertical" />
										PÁGINAS
									</Grid>
								)}
								value="pages"
							/>
						</Tabs>
					</Grid>

					<Grid item xs={12} className={classes.consoleContent}>
						{editorType === "styles" && (
							<StylesEditor />
						)}

						{editorType === "pages" && (
							<PagesEditor
								triggerSelectSectionId={triggerSelectSectionId}
								publishing={publishing}
							/>
						)}
					</Grid>
				</Grid>
			</Loading>
		</Grid>
	)
}

Console.propTypes = {
	loading: PropTypes.bool.isRequired,
	publishing: PropTypes.bool.isRequired,
	device: PropTypes.string.isRequired,
	isConsoleVisible: PropTypes.bool.isRequired,
	editorType: PropTypes.string.isRequired,
	setEditorType: PropTypes.func.isRequired,
	triggerSelectSectionId: PropTypes.number.isRequired
}

export default Console
