import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	Typography,
	Button,
	IconButton,
	ThemeProvider,
	Grid,
	Link,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableRow
} from "@material-ui/core"
import {
	Close as CloseIcon,
	OpenInNew as OpenInNewIcon
} from "@material-ui/icons"

import { Divider } from "../../../../components"

import { renderComponent } from "../../../../utils/node"

import useStyles from "./styles"

import theme from "../../../../styles/theme"

import smileIcon from "../../../../assets/smile_icon.png"
import sadIcon from "../../../../assets/sad_icon.png"

const WarningUploadModal = (props) => {
	const classes = useStyles()

	const { payloadList } = props

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<Grid container direction="column" className={classes.dialogContainer}>
					<Typography variant="h3" className={classes.title}>
						Detalhe do envio das suas fotos
					</Typography>

					<Divider size="large" orientation="horizontal" />

					<Grid container justify="flex-start" alignItems="center">
						<img src={smileIcon} alt="smile icon" className={classes.icon} />

						<Divider size="medium" orientation="vertical" />

						<Typography variant="body2" className={classes.description}>
							<b>{payloadList.succededList.length}</b>
							{" "}
							fotos subiram com sucesso.
						</Typography>
					</Grid>

					<Divider size="medium" orientation="horizontal" />

					<Grid container justify="flex-start" alignItems="center">
						<img src={sadIcon} alt="smile icon" className={classes.icon} />

						<Divider size="medium" orientation="vertical" />

						<Typography variant="body2" className={classes.description}>
							Não conseguimos completar o envio de
							{" "}
							<b>{payloadList.failedList.length}</b>
							{" "}
							fotos. Veja
							<br />
							o relatório abaixo e envie novamente
						</Typography>
					</Grid>

					<Divider size="large" orientation="horizontal" />

					<Link
						className={classes.link}
						href="https://blog.picsize.com.br/erro-de-upload/"
						target="_blank"
					>
						Entenda os principais motivos que causam problemas ao subir fotos.
					</Link>

					<Divider size="small" orientation="horizontal" />

					<Grid container direction="column" className={classes.tableGrid}>
						<Typography variant="h3" className={classes.tableHeadTitle}>
							Lista de fotos que não subiram
						</Typography>

						<TableContainer className={classes.tableContainer}>
							<Table size="small" padding="default" className={classes.table}>
								<TableBody>
									{payloadList.failedList.map((picture, index) => {
										const current = +index + 1

										return (
											<TableRow>
												<TableCell
													align="left"
													style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}
												>
													{current}
												</TableCell>
												<TableCell
													align="left"
													style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}
												>
													{picture.title}
												</TableCell>
												<TableCell
													align="right"
													style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}
												>
													{picture.error}
												</TableCell>
											</TableRow>
										)
									})}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					<Divider size="large" orientation="horizontal" />

					<Grid
						container
						direction="column"
						alignItems="center"
						className={classes.actions}
					>
						<Button
							onClick={handleClose}
							color="primary"
							variant="contained"
							endIcon={<OpenInNewIcon />}
						>
							Fechar
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

WarningUploadModal.defaultProps = {
	payloadList: {
		failedList: [
			{ title: "foto001.jpg", error: "corrompida" },
			{ title: "foto002.jpg", error: "formato inválido" },
			{ title: "foto001.jpg", error: "corrompida" },
			{ title: "foto002.jpg", error: "formato inválido" },
			{ title: "foto001.jpg", error: "corrompida" },
			{ title: "foto002.jpg", error: "formato inválido" }
		],
		succededList: [{ title: "foto001.jpg", error: "corrompida" }]
	}
}

WarningUploadModal.propTypes = {
	payloadList: PropTypes.shape({
		failedList: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				error: PropTypes.string
			})
		),
		succededList: PropTypes.arrayOf(
			PropTypes.shape({
				title: PropTypes.string.isRequired,
				error: PropTypes.string
			})
		)
	})
}

WarningUploadModal.open = ({ payloadList }) => renderComponent(
	"warning-upload-modal",
	<WarningUploadModal payloadList={payloadList} />
)

export default WarningUploadModal
