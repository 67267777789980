import { makeStyles } from "@material-ui/core"

import theme from "../../../../styles/theme"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	dialogContainer: {
		padding: theme.spacing(1, 3, 3)
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	tableContainer: {
		width: "100%"
	},
	tableHeadTitle: {
		fontWeight: "bolder",
		padding: theme.spacing() * 2,
		width: "100%"
	},
	tableGrid: {
		backgroundColor: "#EDEDED",
		borderRadius: "4px",
		border: "1px solid #D4D4D4"
	}
})

export default useStyles
