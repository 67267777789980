import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, createStyles } from "@material-ui/core"

import { Divider } from "../components"

const useStyles = makeStyles(theme => createStyles({
	groupedCategoriesBox: {
		padding: theme.spacing(2, 1)
	}
}))

const GroupedCategoriesSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid
			className={classes.groupedCategoriesBox}
			container
			direction="column"
			justify="center"
		>

			<Grid
				container
				justify="space-between"
				alignItems="center"
			>
				<Grid item xs={3} lg={3}>
					<Skeleton animation="wave" variant="circle" height={35} width={35} />
				</Grid>

				<Grid item xs={8} lg={8}>
					<Skeleton animation="wave" variant="rect" height={20} />
				</Grid>
			</Grid>


			<Grid
				className={classes.groupedCategoriesBox}
				container
				justify="center"
			>
				<Skeleton animation="wave" variant="rect" width="100%" height={30} />

				<Divider orientation="horizontal" size="medium" />

				<Skeleton animation="wave" variant="rect" width="100%" height={30} />

				<Divider orientation="horizontal" size="medium" />

				<Skeleton animation="wave" variant="rect" width="100%" height={30} />

				<Divider orientation="horizontal" size="medium" />

				<Skeleton animation="wave" variant="rect" width="100%" height={30} />

				<Divider orientation="horizontal" size="medium" />

			</Grid>
		</Grid>
	)
}

export default GroupedCategoriesSkeleton
