import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, GridList, useTheme } from "@material-ui/core"

import useGridResponsiveColumns from "../hooks/useGridResponsiveColumns"

const useStyles = makeStyles({
	card: {
		width: "100%",
		height: 0,
		paddingBottom: "100%"
	}
})

const WorkPicturesSkeleton = () => {
	const classes = useStyles()
	const theme = useTheme()
	const gridResponsiveColumns = useGridResponsiveColumns({ xs: 6, md: 4, lg: 3, xl: 3 })

	const cards = []

	// eslint-disable-next-line
	for (let i = 0; i < 5; i++) {
		cards.push(
			<Grid key={i} item style={{ height: "auto" }}>
				<Skeleton animation="wave" variant="rect" className={classes.card} />
			</Grid>
		)
	}

	return (
		<GridList
			cols={gridResponsiveColumns}
			spacing={theme.spacing() * 2}
		>
			{cards}
		</GridList>
	)
}

export default WorkPicturesSkeleton
