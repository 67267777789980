import React, { useState } from "react"

import {
	ButtonGroup,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
	Tooltip,
	Zoom
} from "@material-ui/core"

import {
	FolderOutlined as FolderOutlinedIcon,
	Edit as EditIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	Add as AddIcon,
	DragIndicator as DragIndicatorYIcon
} from "@material-ui/icons"

import PropTypes from "prop-types"

import { InputDialog, Loading, Sortable } from "../../../../components"
import useValidation from "../../../../hooks/useValidation"
import GroupedCategoriesSkeleton from "../../../../skeletons/GroupedCategories"

import useStyles from "./styles"
import useCustomStyles from "../../../../styles/custom"
import CreateCategoryDialog from "../CreateCategoryDialog"


const GroupedCategories = (props) => {
	const {
		groupedCategories,
		loadingGroupedCategories,
		onChange,
		onDelete,
		onCreate,
		onSortEnd
	} = props

	const [currentCategoryActionsVisible, setCurrentCategoryActionsVisible] = useState(null)
	const [createCategoryDialogData, setCreateCategoryDialogData] = useState({
		openDialog: false
	})
	const [loadingCreateCategory, setLoadingCreateCategory] = useState(false)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const handleMouseEnterCategory = (category) => {
		setCurrentCategoryActionsVisible(category)
	}

	const handleMouseLeaveGroup = () => {
		clearValidation("categoryTitle")
		setCurrentCategoryActionsVisible(null)
	}

	const handleMouseEnterCreateCategory = () => {
		setCurrentCategoryActionsVisible(null)
	}

	const handleCategorySortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType)
	}

	const handleCategorySortEnd = (oldIndex, newIndex, groupedCategory) => {
		onSortEnd(oldIndex, newIndex, groupedCategory)
	}

	const handleChangeCategoryTitle = async (category_id, title) => {
		const isUpdated = await onChange(category_id, title)

		if (isUpdated === "titleUpdated") {
			return true
		}
		triggerValidation(isUpdated)
	}

	const handleDeleteCategory = async (category_id) => {
		await onDelete(category_id)
	}

	const handleCreateCategoryDialogData = (groupId) => {
		setCreateCategoryDialogData({
			openDialog: true,
			groupId
		})
	}
	const handleCreateCategoryDialogClose = () => {
		setCreateCategoryDialogData({
			openDialog: false
		})
	}

	const handleCreateCategory = async (group_id, title) => {
		setLoadingCreateCategory(true)

		const isCreated = await onCreate(group_id, title)

		setLoadingCreateCategory(false)

		if (isCreated === "isCreated") {
			handleCreateCategoryDialogClose()
		}

		return isCreated
	}

	return (
		<Loading
			loading={loadingGroupedCategories}
			customLoadingElement={<GroupedCategoriesSkeleton />}
		>
			<List className={classes.categoryListWrapper} disablePadding>
				{
					groupedCategories.map(group => (
						<>
							<ListItem className={classes.groupListItem} disableGutters>
								<ListItemIcon className={classes.groupIcon}>
									<FolderOutlinedIcon />
								</ListItemIcon>
								<ListItemText primary={group.group_title} />
							</ListItem>

							<Sortable.SectionContainer
								pressDelay
								axis="y"
								lockAxis="y"
								useDragHandle
								onSortStart={handleCategorySortStart}
								onSortEnd={({ oldIndex, newIndex }) => handleCategorySortEnd(
									oldIndex, newIndex, group
								)}
							>
								<List
									dense
									onMouseLeave={handleMouseLeaveGroup}
								>
									{
										group.categories.map((category, index) => (
											<Sortable.SortableSectionItem
												key={`category-${category.id}`}
												index={index}
												value={category}
												disabled={!(group.categories.length > 1)}
											>
												<ListItem
													divider
													key={category.id}
													onMouseEnter={() => handleMouseEnterCategory(category.id)}
													disableGutters
													className={classes.categoryListItem}
													classes={{
														container: classes.categoryListItemContainer
													}}
												>
													<ListItemIcon className={classes.listItemSortableIcon}>
														{
															group.categories.length > 1
															&& (
																<Sortable.DragHandle>
																	<Tooltip title="Ordenar categoria" placement="top" arrow>
																		<IconButton
																			size="small"
																		>
																			<DragIndicatorYIcon
																				className={customClasses.contentActionButtonDark}
																				style={{ cursor: "grab" }}
																			/>
																		</IconButton>
																	</Tooltip>
																</Sortable.DragHandle>
															)
														}
													</ListItemIcon>

													<ListItemText
														primary={category.title}
														className={customClasses.listItemText}
													/>
													<ListItemSecondaryAction>
														<Zoom in={currentCategoryActionsVisible === category.id}>
															<ButtonGroup size="small" classes={{ grouped: classes.actionsButtonGroup }}>
																<Grid item>
																	<InputDialog
																		onOk={
																			(title) => handleChangeCategoryTitle(category.id, title)
																		}
																		title="Mudar nome da categoria"
																		initialValue={category.title}
																		onOpen={handleMouseLeaveGroup}
																		onClose={() => clearValidation("categoryTitle")}
																		onDataChange={() => clearValidation("categoryTitle")}
																		customInputElement={(
																			<TextField
																				required
																				error={!!validation.categoryTitle}
																				helperText={validation.categoryTitle}
																				label="Categoria"
																				variant="standard"
																				color="secondary"
																				size="small"
																				fullWidth
																			/>
																		)}
																	>
																		<Tooltip title="Editar categoria" placement="top" arrow>
																			<IconButton size="small">
																				<EditIcon fontSize="small" />
																			</IconButton>
																		</Tooltip>
																	</InputDialog>
																</Grid>

																<Grid item>
																	<Tooltip title="Excluir categoria" placement="top" arrow>
																		<IconButton size="small" onClick={() => handleDeleteCategory(category.id)}>
																			<DeleteOutlinedIcon fontSize="small" />
																		</IconButton>
																	</Tooltip>
																</Grid>
															</ButtonGroup>
														</Zoom>
													</ListItemSecondaryAction>

												</ListItem>
											</Sortable.SortableSectionItem>
										))
									}
									<ListItem
										button
										key="Nova categoria"
										disableGutters
										className={classes.createCategoryListItem}
										onMouseEnter={handleMouseEnterCreateCategory}
										onClick={() => handleCreateCategoryDialogData(group.group_id)}
									>
										<ListItemIcon className={classes.listItemIcon}>
											<AddIcon />
										</ListItemIcon>
										<ListItemText primary="Nova categoria" className={customClasses.listItemText} />
									</ListItem>
								</List>
							</Sortable.SectionContainer>
						</>
					))
				}

				<CreateCategoryDialog
					groupId={createCategoryDialogData.groupId}
					isOpen={createCategoryDialogData.openDialog}
					loadingCreateCategory={loadingCreateCategory}
					onClose={handleCreateCategoryDialogClose}
					onCreate={handleCreateCategory}
				/>
			</List>
		</Loading>
	)
}

GroupedCategories.propTypes = {
	groupedCategories: PropTypes.arrayOf(
		PropTypes.shape({
			categories: PropTypes.arrayOf(PropTypes.shape({
				id: PropTypes.number,
				title: PropTypes.string,
				order: PropTypes.number
			})),
			group_id: PropTypes.number,
			group_title: PropTypes.string
		})
	).isRequired,
	loadingGroupedCategories: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired,
	onSortEnd: PropTypes.func.isRequired
}

export default GroupedCategories
