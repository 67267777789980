import React from "react"
import PropTypes from "prop-types"

import { Grid } from "@material-ui/core"

const GridItemResponsive = (props) => {
	const { xs, sm, md, lg, xl, className, children } = props

	return (
		<Grid
			item
			xs={xs}
			sm={sm}
			md={md}
			lg={lg}
			xl={xl}
			className={className}
		>
			{children}
		</Grid>
	)
}

GridItemResponsive.defaultProps = {
	className: ""
}

GridItemResponsive.propTypes = {
	xs: PropTypes.number.isRequired,
	sm: PropTypes.number.isRequired,
	md: PropTypes.number.isRequired,
	lg: PropTypes.number.isRequired,
	xl: PropTypes.number.isRequired,
	className: PropTypes.string,
	children: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default GridItemResponsive
