import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	IconButton,
	ThemeProvider,
	Grid,
	TextField,
	CircularProgress,
	FormHelperText,
	FormGroup
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { Notification, Divider, InfoModal } from "../../../components"

import { renderComponent } from "../../../utils/node"
import { getStatusCode } from "../../../utils/response"
import { siteProPlansUrl } from "../../../utils/plan"

import useCustomStyles from "../../../styles/custom"
import theme from "../../../styles/theme"

import api from "../../../services/api"

import useValidation from "../../../hooks/useValidation"

import { titleLength, descriptionLength } from "../recomendedParams"

import useStyles from "./styles"

const EditMetadataModal = (props) => {
	const classes = useStyles()

	const customClasses = useCustomStyles()

	const {
		validation,
		clearValidation,
		triggerValidation
	} = useValidation()

	// eslint-disable-next-line
  const [turboSeoPageData, setTurboSeoPageData] = useState(props.turboSeoPage)
	const [savingData, setSavingData] = useState(false)
	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	const handleInputChange = (event) => {
		const { name, value } = event.target
		setTurboSeoPageData({
			...turboSeoPageData,
			[name]: value
		})

		clearValidation(name)
	}

	const handleSaveChanges = async () => {
		setSavingData(true)

		try {
			await api.put(`/turbo-seo/page/${turboSeoPageData.id}`, turboSeoPageData)
			Notification.success({ message: "Informações alteradas com sucesso!" })

			props.updateParent()
			handleClose()
		} catch (error) {
			if (getStatusCode(error) === 402) {
				InfoModal.warning({
					title: "Ação negada",
					description: "Seu plano atual não permite utilizar essa função.",
					customConfirmButtons: [
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							VER PLANOS
						</Button>
					],
					cancelButtonText: "FECHAR"
				})
			} else {
				triggerValidation(error)
			}
		}

		setSavingData(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<DialogTitle>
					{turboSeoPageData.page_title}
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<FormGroup>
								<TextField
									id="title"
									color="secondary"
									label="Título"
									fullWidth
									multiline
									name="title"
									onChange={handleInputChange}
									value={turboSeoPageData.title}
									error={!!validation.title}
									helperText={validation.title}
								/>
								<FormHelperText>
									{`${turboSeoPageData?.title?.length || 0}/${titleLength}`}
								</FormHelperText>
							</FormGroup>
						</Grid>

						<Grid item xs={12}>
							<FormGroup>
								<TextField
									id="description"
									color="secondary"
									label="Descrição"
									fullWidth
									multiline
									name="description"
									onChange={handleInputChange}
									value={turboSeoPageData.description}
									error={!!validation.description}
									helperText={validation.description}
								/>
								<FormHelperText>
									{`${turboSeoPageData?.description?.length || 0}/${descriptionLength}`}
								</FormHelperText>
							</FormGroup>
						</Grid>
					</Grid>
				</DialogContent>

				<Divider orientation="horizontal" size="medium" />

				<DialogActions>
					<Button onClick={handleClose}>
						Cancelar
					</Button>

					<Button
						variant="contained"
						color="primary"
						onClick={handleSaveChanges}
						endIcon={savingData && <CircularProgress size={20} />}
						disabled={savingData}
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

EditMetadataModal.open = (turboSeoPage, updateParent) => renderComponent(
	"edit-metadata-modal",
	<EditMetadataModal turboSeoPage={turboSeoPage} updateParent={updateParent} />
)

EditMetadataModal.defaultProps = {
	turboSeoPage: { title: "", description: "" },
	updateParent: () => { }
}

EditMetadataModal.propTypes = {
	turboSeoPage: PropTypes.shape({
		title: PropTypes.string,
		description: PropTypes.string
	}),
	updateParent: PropTypes.func
}

export default EditMetadataModal
