/**
 *
 * @param {error} AxiosError
 */
export const getStatusCode = (error) => {
	const status = error && error.response && error.response.status

	return +status
}

/**
 *
 * @param {error} AxiosError
 */
export const getErrorDetails = (error) => {
	const errorDetails = error
		&& error.response
		&& error.response.data
		&& error.response.data.messages
		&& error.response.data.messages[0]

	return errorDetails
}
