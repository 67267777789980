import { makeStyles } from "@material-ui/core";

import colors from "./colors";

const useCustomStyles = makeStyles({
	listItemTitle: {
		color: colors.greyScale[5],
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "16px",
		letterSpacing: "-0.03em",
	},
	listItemText: {
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "14px",
		lineHeight: "19px",
		color: colors.greyScale[4],
	},
	listCaptionText: {
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "16px",
		color: colors.greyScale[3],
	},
	cancelButton: {
		fontFamily: "Open Sans",
		fontSize: "14px",
		color: "#444444",
	},
	seePlansButton: {
		backgroundColor: colors.palette.tag3,
		color: colors.greyScale[11],
		"&:hover": {
			backgroundColor: colors.palette.tag3,
			opacity: 0.9,
		},
	},
	textUppercase: {
		textTransform: "uppercase",
	},
	containerActionButtonDark: {
		borderRadius: "5px",
		backgroundColor: "rgba(0, 0, 0, 0.5)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.3)",
		},
	},
	contentActionButtonWhite: {
		color: colors.greyScale[11],
	},
	contentActionButtonDark: {
		color: colors.greyScale[3],
	},
	textEllipsis: {
		whiteSpace: "nowrap",
		overflow: "hidden",
		textOverflow: "ellipsis",
	},
	nextIcon: {
		color: colors.greyScale[5],
	},
});

export default useCustomStyles;
