import React from "react"
import { Route } from "react-router-dom"

import ThemeGalleryPublic from "../../pages/ThemeGalleryPublic"

const PublicRoutes = [
	<Route key="ex" exact path="/public-route-example" component={() => <h1>Public Route!</h1>} />,

	<Route key="theme-gallery" exact path="/test/theme-gallery/public" component={ThemeGalleryPublic} />
]

export default PublicRoutes
