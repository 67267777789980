import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "../components"

const SelectSkeleton = () => (
	<Grid container>
		<Skeleton animation="wave" variant="rect" width={30} height={10} />

		<Divider size="small" orientation="horizontal" />

		<Skeleton animation="wave" variant="rect" width="100%" height={30} />
	</Grid>
)

export default SelectSkeleton
