import { parseCSSToJSON, buildPureCSSByJSON, buildPureCSSContentByJSON } from "../utils/css"

import { parseJS, parseCSS } from "../utils/parsersSectionAssets"

export const livePreviewID = "picsize-live-preview"

const Actions = {
	CHANGE_ELEMENT_INLINE_STYLE: "changeElementInlineStyle",
	CHANGE_ELEMENT_STYLE: "changeElementStyle",
	CHANGE_ELEMENT_TEXT: "changeElementText",
	REMOVE_ELEMENTS: "removeElements",
	INSERT_PURE_CSS: "insertPureCSS",
	REPLACE_PURE_CSS: "replacePureCSS",
	REPLACE_PURE_HTML: "replacePureHTML",
	INSERT_PURE_HTML: "insertPureHTML",
	REORDER_SECTION_ELEMENT: "reorderSectionElement",
	TOGGLE_ELEMENT_HIDDEN: "toggleElementHidden",
	INSERT_SECTION_HTML: "insertSectionHTML",
	INSERT_PURE_JS: "insertPureJS"
}

export const getLivePreviewWindow = () => {
	const livePreview = document.getElementById(livePreviewID)

	return livePreview?.contentWindow
}

export const getLivePreviewDocument = () => {
	const livePreview = document.getElementById(livePreviewID)

	let livePreviewDocument

	if (livePreview && livePreview.contentWindow && livePreview.contentWindow.document) {
		livePreviewDocument = livePreview.contentWindow.document
	}

	return livePreviewDocument
}

/**
	*
	* @param {String} selector A query selector value in string. Ex: .container img
	*/
const getElements = (selector) => {
	const livePreviewDocument = getLivePreviewDocument()

	const elementsArray = []

	if (livePreviewDocument) {
		const elements = livePreviewDocument.querySelectorAll(selector)

		if (elements.length) {
			// eslint-disable-next-line
			for (let i = 0; i < elements.length; i++) {
				const element = elements[i]

				elementsArray.push(element)
			}
		}
	}

	return elementsArray
}

/**
	*
	* @param {String} selector A query selector value in string. Ex: .container h1
	*/
export const getElement = (selector) => {
	const elements = getElements(selector)

	return elements[0] || { style: {} }
}

const useLivePreview = () => {
	/**
		*
		* @param {String} selector A query selector value in string. Ex: .container img
		* @param {Object} value A object with styles to be set. Ex: { height: "30px" }
		*/
	const changeElementInlineStyle = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const element = livePreviewDocument.querySelector(selector)

			if (element) {
				const pureCSSContent = buildPureCSSContentByJSON(value)
				element.style = pureCSSContent
			}
		}
	}

	/**
		*
		* @param {String} selector A query selector value in string. Ex: .container img
		* @param {Object} value A object with styles to be set. Ex: { height: "30px" }
		*/
	const changeElementStyle = ({ selector, value }) => {
		const elementId = selector

		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			let pureCSS

			let style = livePreviewDocument.getElementById(elementId)

			if (!style) {
				style = livePreviewDocument.createElement("style")
				style.id = elementId

				pureCSS = buildPureCSSByJSON(selector, value)

				const textNode = document.createTextNode(pureCSS)
				style.appendChild(textNode)

				const livePreviewHead = livePreviewDocument.getElementsByTagName("head")[0]

				livePreviewHead.appendChild(style)
			} else {
				const existingData = parseCSSToJSON(style.innerHTML)

				const updatedData = {
					...existingData,
					...value
				}

				pureCSS = buildPureCSSByJSON(selector, updatedData)

				style.innerHTML = pureCSS
			}
		}
	}

	/**
		*
		* @param {String} selector The id of the style tag
		* @param {String} value A css in string. Ex: .body1 { height: "30px" } h1 { font-color: red }
		*/
	const insertPureCSS = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const cssCode = parseCSS(value)
			const elementId = selector

			let style = livePreviewDocument.getElementById(elementId)

			if (!style) {
				style = livePreviewDocument.createElement("style")
				style.id = elementId

				const textNode = document.createTextNode(cssCode)
				style.appendChild(textNode)

				const livePreviewHead = livePreviewDocument.getElementsByTagName("head")[0]

				livePreviewHead.appendChild(style)
			} else {
				style.innerHTML += cssCode
			}
		}
	}

	/**
		*
		* @param {String} selector The id of the style tag
		* @param {String} value A css in string. Ex: .body1 { height: "30px" } h1 { font-color: red }
		*/
	const replacePureCSS = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const cssCode = parseCSS(value)
			const elementId = selector

			let style = livePreviewDocument.getElementById(elementId)

			if (!style) {
				style = livePreviewDocument.createElement("style")
				style.id = elementId

				const textNode = document.createTextNode(cssCode)
				style.appendChild(textNode)

				const livePreviewHead = livePreviewDocument.getElementsByTagName("head")[0]

				livePreviewHead.appendChild(style)
			} else {
				style.innerHTML = cssCode
			}
		}
	}

	/**
		*
		* @param {String} selector A selector for an existent parent node. Ex: #page-section-1
		* @param {String} value A html in string. Ex: <div><p>EAE</p></div>
		*/
	const insertPureHTML = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const parentNode = livePreviewDocument.querySelector(selector)

			if (parentNode) {
				const tempParent = livePreviewDocument.createElement("div")
				tempParent.innerHTML = value

				parentNode.appendChild(tempParent.firstChild)
			}
		}
	}

	/**
		*
		* @param {String} selector A selector for a existent node. Ex: #page-section-1
		* @param {String} value A html in string. Ex: <div><p>EAE</p></div>
		*/
	const replacePureHTML = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const temp = document.createElement("div")
			temp.innerHTML = value.trim()

			const element = livePreviewDocument.querySelector(selector)

			if (element) {
				element.replaceWith(temp.firstChild)
			}
		}
	}

	/**
		*
		* @param {String} selector A query selector value in string. Ex: .container img
		* @param {String} value The next section selector
		*/
	const reorderSectionElement = ({ selector, value: nextSectionSelector }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const sectionElement = livePreviewDocument.getElementById(selector)

			if (sectionElement) {
				sectionElement.remove()
			}

			const sectionsContainerElement = livePreviewDocument.getElementsByClassName("page-content")[0]

			const nextSectionElement = livePreviewDocument.getElementById(nextSectionSelector)

			sectionsContainerElement.insertBefore(sectionElement, nextSectionElement)
		}
	}

	/**
		*
		* @param {String} selector A query selector value in string. Ex: #page-section-1
		* @param {String} value hidden state
		*/
	const toggleElementHidden = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const node = livePreviewDocument.getElementById(selector)

			if (node) {
				node.classList.toggle("is-hidden", value)
			}
		}
	}

	/**
		*
		* @param {String} selector The section's id. Used only for the history
		* @param {String} value Object containing html and nextSectionSelector
		*/
	const insertSectionHTML = ({ value }) => {
		const { html, nextSectionSelector } = value

		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const tempParentElement = livePreviewDocument.createElement("div")
			tempParentElement.innerHTML = html

			const sectionsContainerElement = livePreviewDocument.getElementsByClassName("page-content")[0]
			const nextSiblingElement = livePreviewDocument.getElementById(nextSectionSelector)

			sectionsContainerElement.insertBefore(tempParentElement.firstChild, nextSiblingElement)
		}
	}

	/**
	*
	* @param {String} selector The id of the script tag
	* @param {String} value A in string with js code. Ex.: alert("Hello World!");
		The commands must be separated by semicolons

	*/
	const insertPureJS = ({ selector, value }) => {
		const livePreviewDocument = getLivePreviewDocument()

		if (livePreviewDocument) {
			const jsCode = parseJS(value)
			const elementId = selector

			let script = livePreviewDocument.getElementById(elementId)

			if (script) {
				script.remove()
			}

			script = document.createElement("script")
			script.id = elementId

			const inlineScript = document.createTextNode(jsCode)

			script.appendChild(inlineScript)
			livePreviewDocument.querySelector("body").appendChild(script)
		}
	}

	/**
		*
		* @param {String} selector A query selector value in string. Ex: .container h1
		* @param {String} text The new text to insert inside this element
		*/
	const changeElementText = ({ selector, value }) => {
		const elements = getElements(selector)

		elements.forEach(element => {
			// eslint-disable-next-line
			element.innerText = value
		})
	}

	/**
		*
		* @param {String} selector A query selector value in string. Ex: .container h1
		*/
	const removeElements = ({ selector }) => {
		const elements = getElements(selector)

		elements.forEach(element => {
			element.remove()
		})
	}

	const addOrRefreshControllerListeners = () => {
		const livePreviewWindow = getLivePreviewWindow()
		livePreviewWindow.addOrRefreshListeners()
	}

	/**
	 * @param {String} selector A section id
	 */
	const scrollToSection = ({ selector: sectionId }) => {
		const livePreviewDocument = getLivePreviewDocument()
		const section = livePreviewDocument.getElementById(sectionId)
		if (section) {
			const { body } = livePreviewDocument
			const picsizeBrandingHeight = 50
			const distancetoPicsizeBranding = 5
			const yCoord = section.offsetTop - picsizeBrandingHeight - distancetoPicsizeBranding
			body.scrollTo({ top: yCoord, behavior: "smooth" })
		}
	}

	const deactivateAllSections = () => {
		const livePreviewDocument = getLivePreviewDocument()
		const pageSections = livePreviewDocument.querySelectorAll("[le-page-section]")

		pageSections.forEach(pageSection => {
			pageSection.classList.remove("is-active")
		})
	}

	/**
	 * @param {String} selector A section id
	 */
	const activateSection = ({ selector: sectionId }) => {
		const livePreviewDocument = getLivePreviewDocument()

		deactivateAllSections()

		const section = livePreviewDocument.getElementById(sectionId)
		if (section) {
			section.classList.add("is-active")
		}
	}

	const livePreview = {
		changeElementInlineStyle,
		changeElementStyle,
		insertPureCSS,
		replacePureCSS,
		insertPureHTML,
		replacePureHTML,
		reorderSectionElement,
		insertSectionHTML,
		toggleElementHidden,
		insertPureJS,
		changeElementText,
		removeElements,
		addOrRefreshControllerListeners,
		scrollToSection,
		deactivateAllSections,
		activateSection,
		makeBatchChanges(changes) {
			changes.forEach(change => {
				const { action, ...rest } = change

				if (action) {
					this[action](rest)
				}
			})
		},
		Actions
	}

	return livePreview
}

export default useLivePreview
