import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"
import {
	Grid,
	Fab,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Button,
	Container,
	GridList,
	GridListTile,
	CircularProgress,
	Link
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import {
	CloudUploadOutlined as CloudUploadOutlinedIcon,
	Cached as CachedIcon
} from "@material-ui/icons"


import { Loading, Portlet, ActionZone, Divider, Dropzone, Notification } from "../../../components"

import useStyles from "./styles"
import AvaliableProportionsModal from "./AvailableProportionsModal"

import colors from "../../../styles/colors"

import useValidation from "../../../hooks/useValidation"
import useDidMount from "../../../hooks/useDidMount"
import { useBannerStore } from "../../../store"

import api from "../../../services/api"

import BannerEditSkeleton from "../../../skeletons/BannerEdit"
import { getHeightInPercentageFromProportion } from "../../../utils/image"

export const bannerPictureFocusMap = {
	0: "0% 0%",
	1: "50% 0%",
	2: "100% 0%",
	3: "0% 50%",
	4: "50% 50%",
	5: "100% 50%",
	6: "0% 100%",
	7: "50% 100%",
	8: "100% 100%"
}

const Edit = () => {
	const bannerStore = useBannerStore()
	const params = useParams()
	const history = useHistory()

	const [loadingCurrentProportion, setLoadingCurrentProportion] = useState(true)
	const [loadingBannerData, setLoadingBannerData] = useState(true)
	const [savingBannerData, setSavingBannerData] = useState(false)

	/*
	* These variables were needed  to implement a workaround related to the banner proportion on fp
	*/
	const [isFullscreen, setIsFullscreen] = useState(false)
	const [currentProportion, setCurrentProportion] = useState("")

	const [bannerPictureData, setBannerPictureData] = useState(null)
	const [bannerData, setBannerData] = useState({
		picture_url: "",
		picture_focus: bannerPictureFocusMap[4],
		uppertitle: "",
		title: "",
		subtitle: "",
		external_link: "",
		open_link_in_new_tab: false
	})

	const classes = useStyles({
		isBannerUploaded: !!bannerData.picture_url,
		currentProportion
	})

	const {
		clearValidation,
		validation,
		triggerValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const { banner_id } = params

	const getBannerData = async () => {
		try {
			const { data } = await api.get(`/site/banner?id=${banner_id}`)

			setBannerData({
				...data,
				picture_focus: data.picture_focus || bannerPictureFocusMap[4]
			})

			bannerStore.setBannerData({
				title: data.title || " ",
				banner_id
			})

			setLoadingBannerData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleChangeBannerData = (key, value) => {
		setBannerData({
			...bannerData,
			[key]: value
		})

		clearValidation(key)
	}

	const handleBannerPictureDrop = async ([banner]) => {
		setBannerPictureData(banner)

		const pictureUrl = window.URL.createObjectURL(banner)
		handleChangeBannerData("picture_url", pictureUrl)
	}

	const handleSaveBannerData = async () => {
		setSavingBannerData(true)

		const promises = []

		try {
			if (bannerPictureData) {
				const formData = new FormData()
				formData.append("banner", bannerPictureData)

				promises.push(
					api.put(`/site/banner/${banner_id}/picture`, formData, {
						headers: {
							"Content-Type": "multipart/data"
						}
					}).finally(() => { })
				)
			}

			promises.push(
				api.put(`/site/banner/${banner_id}`, {
					picture_focus: bannerData.picture_focus,
					uppertitle: bannerData.uppertitle,
					title: bannerData.title,
					subtitle: bannerData.subtitle,
					external_link: bannerData.external_link,
					open_link_in_new_tab: bannerData.open_link_in_new_tab
				})
			)

			await Promise.all(promises)

			setBannerPictureData(null)

			await getBannerData()

			Notification.success({ message: "Informações salvas com sucesso!" })
		} catch (error) {
			triggerValidation(error)
		}

		setSavingBannerData(false)
	}

	const handleCloseBannerEdit = () => {
		history.push("/banner")
	}

	const getBannerProportion = async () => {
		const response = await api.get("/site/live-editor/data")
		const { sections } = response.data
		const headerBanner = sections.find(section => section.html_tag === "header_index")
		const proportion = headerBanner.properties.banner_picture_proportion || "16:9"
		const fullscreen = !!headerBanner.properties.banner_full_size
		setIsFullscreen(fullscreen)
		setCurrentProportion(fullscreen ? "16:9" : proportion)
		setLoadingCurrentProportion(true)
	}

	const calculateHeight = () => {
		const heightInPercentage = getHeightInPercentageFromProportion(currentProportion) / 100
		return Math.round(heightInPercentage * 1920)
	}

	const handleOpenAvailableProportionsModal = () => {
		AvaliableProportionsModal.open()
	}

	useDidMount(() => {
		getBannerProportion()
		getBannerData()
	})

	return (
		<Portlet>
			<Loading
				loading={loadingBannerData && loadingCurrentProportion}
				customLoadingElement={<BannerEditSkeleton />}
			>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
						<Container
							disableGutters
							maxWidth={false}
							className={classes.bannerPictureContainer}
						>
							<Container
								disableGutters
								className={classes.bannerPictureTextContainer}
							>
								<Typography className={classes.bannerPictureNormalText}>
									{bannerData.uppertitle || "Sobretítulo"}
								</Typography>

								<Divider size="small" orientation="horizontal" />

								<Typography className={classes.bannerPictureMainTitle}>
									{bannerData.title || "TÍTULO PRINCIPAL"}
								</Typography>

								<Divider size="small" orientation="horizontal" />

								<Typography className={classes.bannerPictureNormalText}>
									{bannerData.subtitle || "Subtítulo"}
								</Typography>
							</Container>

							{
								bannerData?.picture_url && (
									<img
										src={bannerData.picture_url}
										alt="banner"
										style={{
											objectPosition: bannerData.picture_focus
										}}
										className={classes.bannerPicture}
									/>
								)
							}

						</Container>

						<Divider size="medium" orientation="horizontal" />

						<Dropzone onDrop={handleBannerPictureDrop}>
							<ActionZone className={classes.bannerPictureDropzoneContainer}>
								<Fab className={classes.fab}>
									{bannerData.picture_url ? (
										<CachedIcon />
									) : (
										<CloudUploadOutlinedIcon />
									)}
								</Fab>

								<Divider size="large" orientation="horizontal" />

								<Typography variant="h3">
									{bannerData.picture_url ? (
										"Alterar foto do banner"
									) : (
										"Subir a foto para o banner"
									)}
								</Typography>
								<Typography className={classes.bannerPictureInfo}>
									Proporção:
									{" "}
									{isFullscreen ? "Tela cheia" : currentProportion}
								</Typography>
							</ActionZone>
						</Dropzone>

						<Divider size="medium" orientation="horizontal" />

						{isFullscreen && (
							<>
								<Alert severity="warning">
									<b>TELA CHEIA</b>
									<Divider size="medium" orientation="horizontal" />
									<p>
										O seu banner está configurado como tela cheia.
										{" "}
										Isso faz com que o banner ocupe 100% do tamanho da tela, logo
										{" "}
										<b>a foto será cortada</b>
										{" "}
										para se adequar a cada tamanho de tela.
									</p>
								</Alert>

								<Divider size="medium" orientation="horizontal" />
							</>
						)}


						<Alert severity="info">
							<b>RECOMENDAÇÕES</b>
							<Divider size="medium" orientation="horizontal" />
							<p>
								Padronize o tamanho das fotos dos banners do seu site,
								{" "}
								evite enviar fotos em dimensões diferentes.

								<Divider size="small" orientation="horizontal" />

								Você está utilizando a proporção:
								{" "}
								<b>{isFullscreen ? "Tela cheia" : currentProportion}</b>
								.

								<Divider size="small" orientation="horizontal" />

								Para essa proporção envie as fotos com dimensões:
								{" "}
								<b>
									1920px /
									{" "}
									{calculateHeight()}
									px
								</b>
								{" "}
								(Fotos com dimensões diferentes poderão
								{" "}
								<b>
									ser cortadas
								</b>
								{" "}
								para se ajustar à proporção utilizada).

								<Divider size="medium" orientation="horizontal" />

								<Link href="#" component="button" onClick={handleOpenAvailableProportionsModal}>
									Veja todas as proporções de banners disponíveis.
								</Link>
							</p>
						</Alert>

					</Grid>
					<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
						<Grid container alignItems="center">
							<Typography variant="h3">
								Ponto focal da foto
							</Typography>

							<Divider size="small" orientation="vertical" />

							<GridList
								cellHeight="auto"
								cols={3}
								spacing={1}
								className={classes.bannerPictureFocusContainer}
							>
								{Object.keys(bannerPictureFocusMap).map(key => (
									<GridListTile cols={1}>
										<Container
											disableGutters
											onClick={() => handleChangeBannerData("picture_focus", bannerPictureFocusMap[key])}
											style={{
												backgroundColor: (
													bannerData.picture_focus === bannerPictureFocusMap[key] ? (
														colors.palette.confirm
													) : (
														colors.greyScale[9]
													)
												)
											}}
											className={classes.bannerPictureFocusBlock}
										/>
									</GridListTile>
								))}
							</GridList>
						</Grid>


						<Divider size="medium" orientation="horizontal" />

						<TextField
							label="Sobretítulo"
							fullWidth
							color="secondary"
							value={bannerData.uppertitle}
							error={!!validation.uppertitle}
							helperText={validation.uppertitle}
							onChange={({ target }) => handleChangeBannerData("uppertitle", target.value)}
						/>

						<Divider size="medium" orientation="horizontal" />

						<TextField
							label="Título"
							fullWidth
							color="secondary"
							value={bannerData.title}
							error={!!validation.title}
							helperText={validation.title}
							onChange={({ target }) => handleChangeBannerData("title", target.value)}
						/>

						<Divider size="medium" orientation="horizontal" />

						<TextField
							label="Subtítulo"
							fullWidth
							color="secondary"
							value={bannerData.subtitle}
							error={!!validation.subtitle}
							helperText={validation.subtitle}
							onChange={({ target }) => handleChangeBannerData("subtitle", target.value)}
						/>

						<Divider size="medium" orientation="horizontal" />

						<TextField
							label="Link"
							fullWidth
							color="secondary"
							value={bannerData.external_link}
							error={!!validation.external_link}
							helperText={validation.external_link}
							onChange={({ target }) => handleChangeBannerData("external_link", target.value)}
						/>

						<Divider size="small" orientation="horizontal" />

						<FormControlLabel
							control={(
								<Checkbox
									checked={bannerData.open_link_in_new_tab}
									onChange={({ target }) => handleChangeBannerData("open_link_in_new_tab", target.checked)}
									color="secondary"
								/>
							)}
							label="Abrir em uma nova janela"
						/>

						<Divider size="medium" orientation="horizontal" />

						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Grid container spacing={2}>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<Button
										color="inherit"
										variant="text"
										fullWidth
										onClick={handleCloseBannerEdit}
									>
										Cancelar
									</Button>
								</Grid>
								<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
									<Button
										color="primary"
										variant="contained"
										onClick={handleSaveBannerData}
										endIcon={savingBannerData && <CircularProgress size={20} />}
										disabled={savingBannerData}
										fullWidth
									>
										Salvar
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Loading>
		</Portlet>
	)
}

export default Edit
