import React from "react"
import { Skeleton } from "@material-ui/lab"
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core"

import { Divider } from "../components"

const AboutGeneralSkeleton = () => {
	const listItems = []

	// eslint-disable-next-line
	for (let i = 0; i <= 5; i++) {
		listItems.push(
			<ListItem divider={i !== 5}>
				<ListItemIcon>
					<Skeleton animation="wave" variant="circle" width={35} height={35} />
				</ListItemIcon>

				<ListItemText>
					<Skeleton animation="wave" variant="react" width={100} height={15} />

					<Divider size="small" orientation="horizontal" />

					<Skeleton animation="wave" variant="react" width="100%" height={15} />
				</ListItemText>
			</ListItem>
		)
	}

	return (
		<List>
			{listItems}
		</List>
	)
}

export default AboutGeneralSkeleton
