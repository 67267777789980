import ReactDOM from "react-dom"

// eslint-disable-next-line
export const renderComponent = (id, component) => {
	let node = document.getElementById(id)

	if (node) {
		ReactDOM.unmountComponentAtNode(node)
	} else {
		node = document.createElement("div")
		node.setAttribute("id", id)
		document.body.appendChild(node)
	}

	const where = document.getElementById("root") || document.body

	const expectedRendering = ReactDOM.createPortal(component, where)

	// Set timeout is used do relocate render call to the end of call stack
	// This is needed to ensure that the component was unmounted
	setTimeout(() => {
		ReactDOM.render(expectedRendering, node)
	}, 0)
}
