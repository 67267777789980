import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Card, CardMedia, Grid, useTheme, useMediaQuery, makeStyles, Container, List, ListItem, Typography } from "@material-ui/core"


import { Divider } from "../components"
import SkeletonImage from "../assets/theme-gallery-skeleton.png"

const useStyles = makeStyles(theme => ({
	container: {
		position: "fixed",
		minHeight: "100vh",
		width: "100vw",
		top: 0,
		left: 0,
		zIndex: 1000,
		backgroundColor: "#fafafa"
	},
	galleryContainer: {
		position: "relative",
		padding: theme.spacing(6, 2),
		height: "100vh",
		overflowY: "scroll",

		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(8, 6)
		}
	},
	closeButton: {
		position: "fixed",
		top: theme.spacing(0.5),
		right: theme.spacing(0.5),

		[theme.breakpoints.up("md")]: {
			right: theme.spacing(2.5)
		}
	},
	menuContainer: {
		position: "relative"
	},
	menu: {
		position: "relative",

		[theme.breakpoints.up("md")]: {
			width: "auto",
			position: "fixed"
		}
	},
	title: {
		textTransform: "uppercase"
	},
	card: {
		backgroundColor: "transparent"
	},
	cardMedia: {
		height: 0,
		paddingTop: "110%",
		backgroundPosition: "top center",
		backgroundSize: "contain"
	},
	categoryListItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		fontWeight: "bold"
	},
	subcategoryListItem: {
		position: "relative",
		fontWeight: "normal"
	}
}))

const ThemeGallerySkeleton = () => {
	const classes = useStyles()

	const MDTheme = useTheme()
	const isSmall = useMediaQuery(MDTheme.breakpoints.down("sm"))

	const categories = [
		{
			subcategories: [0, 0, 0]
		},
		{
			subcategories: [0]
		}
	]

	const themeVariants = [1, 2, 3]

	const ThemeCard = () => (
		<>
			<Card
				elevation={0}
				className={classes.card}
			>
				<Container disableGutters>
					<CardMedia
						image={SkeletonImage}
						className={classes.cardMedia}
					/>
				</Container>
			</Card>
		</>
	)

	return (
		<Container maxWidth={false} disableGutters className={classes.container}>
			<Grid container spacing={isSmall ? 2 : 0} className={classes.galleryContainer}>
				<Grid item xs={12} md={4} lg={3} xl={2} className={classes.menuContainer}>
					<Container disableGutters maxWidth={false} className={classes.menu}>

						<Typography component="h2" className={classes.title}>Galeria de Temas</Typography>

						<Divider orientation="horizontal" size="small" />

						<List>
							{categories?.map((category, categoryIndex) => (
								<ListItem key={categoryIndex} className={classes.categoryListItem} disableGutters>
									<Skeleton height={40} width={200} />

									<List>
										{category?.subcategories?.map((subcategory, subcategoryIndex) => (
											<ListItem key={subcategoryIndex} className={classes.subcategoryListItem}>

												<Skeleton height={35} width={250} />
											</ListItem>
										)
                    )}
									</List>
								</ListItem>
							))}
						</List>
					</Container>
				</Grid>

				<Grid item xs={12} md={8} lg={9} xl={10}>
					<Grid container spacing={isSmall ? 4 : 10}>
						{themeVariants.map((themeVariant) => (
							<Grid key={themeVariant} item xs={12} lg={6}>
								{ThemeCard()}
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Container>
	)
}

export default ThemeGallerySkeleton
