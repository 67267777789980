import { createStore, compose, applyMiddleware } from "redux"
import reducers from "./ducks"

export { useCustomerStore } from "./ducks/customer"
export { useWorkStore } from "./ducks/work"
export { useSiteStore } from "./ducks/site"
export { useBannerStore } from "./ducks/banner"
export { useTestimonialStore } from "./ducks/testimonial"
export { useLiveEditorStore } from "./ducks/liveEditor"

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const store = createStore(reducers, composeEnhancers(applyMiddleware()))

export default store
