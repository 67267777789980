const colors = {
	palette: {
		primary: "#FF4949",
		confirm: "#56C568",
		tag1: "#5CC395",
		tag2: "#5BD1D7",
		tag3: "#F1C34E",
		link: "#007CBA"
	},
	greyScale: {
		1: "#000000",
		2: "#444555",
		3: "#666666",
		4: "#808080",
		5: "#969696",
		6: "#ABABAB",
		7: "#BFBFBF",
		8: "#D0D0D0",
		9: "#E1E1E1",
		10: "#F0F0F0",
		11: "#FFFFFF"
	}
}

export default colors
