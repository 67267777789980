import React from "react"
import { Grid } from "@material-ui/core"
import { GroupField, ColorPicker, AlterFont } from "../../../components"
import { Divider } from "../../../../../components"
import { changeColorBrightness, transparentizeHexToRgba } from "../../../../../utils/color"
import { useLiveEditorStore } from "../../../../../store"

function TypographyEditor() {
	const liveEditorStore = useLiveEditorStore()

	const titleStyleData = liveEditorStore.getStyleByType("title")
	const paragraphStyleData = liveEditorStore.getStyleByType("paragraph")
	const generalStyleData = liveEditorStore.getStyleByType("general")

	const updateCSSVariableOnLivePreview = (
		{
			livePreviewValue: value,
			selectorSuffix: suffix
		}
	) => {
		const livePreviewChanges = [{
			action: liveEditorStore.livePreviewActions.INSERT_PURE_CSS,
			selector: `root-css-${suffix}`,
			value
		}]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const updateStylePropertiesOnStore = ({ styleId, styleProperties: properties }) => {
		const styleProperties = {
			styleId,
			properties
		}

		liveEditorStore.setStyleProperties(styleProperties)
	}

	const handleChangeFamily = (familyName, fontId) => {
		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_font_family: familyName
			}

			livePreviewValue = `:root {
				--font-main-title-font-family: ${familyName}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_font_family: familyName
			}

			livePreviewValue = `:root {
				--font-body1-font-family: ${familyName}
			}`
		}

		const selectorSuffix = `${fontId}-font-family`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeSize = (fontSize, fontId) => {
		const fontSizeInPixels = `${fontSize}px`

		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_font_size: fontSizeInPixels
			}

			livePreviewValue = `:root {
				--font-main-title-font-size: ${fontSizeInPixels}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_font_size: fontSizeInPixels
			}

			livePreviewValue = `:root {
				--font-body1-font-size: ${fontSizeInPixels}
			}`
		}

		const selectorSuffix = `${fontId}-font-size`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeColorOnLivePreview = (color, fontId) => {
		let livePreviewValue

		if (fontId === "title") {
			livePreviewValue = `:root {
				--font-main-title-color: ${color}
			}`
		} else if (fontId === "paragraph") {
			livePreviewValue = `:root {
				--font-body1-color: ${color}
			}`
		}

		const selectorSuffix = `${fontId}-color`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })
	}

	const handleChangeColor = (color, fontId) => {
		let styleId
		let styleProperties

		handleChangeColorOnLivePreview(color, fontId)

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_font_color: color
			}
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_font_color: color
			}
		}

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeBold = (isBold, fontId) => {
		const fontWeight = isBold ? "bold" : 400

		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_font_weight: fontWeight
			}

			livePreviewValue = `:root {
				--font-main-title-font-weight: ${fontWeight}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_font_weight: fontWeight
			}

			livePreviewValue = `:root {
				--font-body1-font-weight: ${fontWeight}
			}`
		}

		const selectorSuffix = `${fontId}-font-weight`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeTextTransform = (textTransform, fontId) => {
		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_text_transform: textTransform
			}

			livePreviewValue = `:root {
				--font-main-title-text-transform: ${textTransform}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_text_transform: textTransform
			}

			livePreviewValue = `:root {
				--font-body1-text-transform: ${textTransform}
			}`
		}

		const selectorSuffix = `${fontId}-text-transform`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeLetterSpacing = (letterSpacing, fontId) => {
		const letterSpacingInPixels = `${letterSpacing}px`

		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_letter_spacing: letterSpacingInPixels
			}

			livePreviewValue = `:root {
				--font-main-title-letter-spacing: ${letterSpacingInPixels}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_letter_spacing: letterSpacingInPixels
			}

			livePreviewValue = `:root {
				--font-body1-letter-spacing: ${letterSpacingInPixels}
			}`
		}

		const selectorSuffix = `${fontId}-letter-spacing`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeLineHeight = (lineHeight, fontId) => {
		const lineHeightInPixels = `${lineHeight}px`

		let styleId
		let styleProperties
		let livePreviewValue

		if (fontId === "title") {
			styleId = titleStyleData.id

			styleProperties = {
				main_title_line_height: lineHeightInPixels
			}

			livePreviewValue = `:root {
				--font-main-title-line-height: ${lineHeightInPixels}
			}`
		} else if (fontId === "paragraph") {
			styleId = paragraphStyleData.id

			styleProperties = {
				paragraph_line_height: lineHeightInPixels
			}

			livePreviewValue = `:root {
				--font-body1-line-height: ${lineHeightInPixels}
			}`
		}

		const selectorSuffix = `${fontId}-line-height`
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeLinkHoverColor = (linkColor) => {
		let hoverColor = changeColorBrightness(linkColor, -20)

		hoverColor = transparentizeHexToRgba(hoverColor, 0.5)

		const styleId = generalStyleData.id

		const styleProperties = {
			link_hover_color: hoverColor
		}

		const livePreviewValue = `:root {
				--font-link-hover-color: ${hoverColor}
			}`

		const selectorSuffix = "link-hover-color"
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })

		updateStylePropertiesOnStore({ styleId, styleProperties })
	}

	const handleChangeLinkColorOnLivePreview = (color) => {
		const livePreviewValue = `:root {
				--font-link-color: ${color}
			}`

		const selectorSuffix = "link-color"
		updateCSSVariableOnLivePreview({ selectorSuffix, livePreviewValue })
	}

	const handleChangeLinkColor = (color) => {
		handleChangeLinkColorOnLivePreview(color)

		const styleId = generalStyleData.id

		const styleProperties = {
			link_color: color,
			primary_color: color
		}

		updateStylePropertiesOnStore({ styleId, styleProperties })

		handleChangeLinkHoverColor(color)
	}

	const TitleEditor = (
		<GroupField label="Títulos">
			<AlterFont
				label="Fonte"
				fontId="title"
				fontFamily={{
					value: titleStyleData.properties.main_title_font_family
							?? generalStyleData.properties.font_family,
					onChange: handleChangeFamily
				}}
				fontSize={{
					value: parseInt(titleStyleData.properties.main_title_font_size || 16, 10),
					optionsSize: [9, 10, 11, 12, 13, 14, 18, 24, 36, 48, 50, 64],
					onChange: handleChangeSize
				}}
				fontColor={{
					value: titleStyleData.properties.main_title_font_color
							|| generalStyleData.properties.gray_scale2
							|| "#000000",
					onPreviewChange: handleChangeColorOnLivePreview,
					onChange: handleChangeColor
				}}
				additionalChanges={{
					boldFont: {
						value: titleStyleData.properties.main_title_font_weight === "bold",
						onChange: handleChangeBold
					},
					letterSpacing: {
						value: parseInt(
							titleStyleData
								.properties
								.main_title_letter_spacing
								|| 0, 10
						),
						onChange: handleChangeLetterSpacing
					},
					textTransform: {
						value: titleStyleData.properties.main_title_text_transform,
						onChange: handleChangeTextTransform
					},
					lineHeight: {
						value: parseInt(titleStyleData.properties.main_title_line_height || 20, 10),
						onChange: handleChangeLineHeight
					}
				}}
			/>
		</GroupField>

	)

	const ParagraphEditor = (
		<GroupField label="Parágrafos">
			<AlterFont
				label="Fonte"
				fontId="paragraph"
				fontFamily={{
					value: paragraphStyleData.properties.paragraph_font_family
							?? generalStyleData.properties.font_family,
					onChange: handleChangeFamily
				}}
				fontSize={{
					value: parseInt(paragraphStyleData.properties.paragraph_font_size || 16, 10),
					optionsSize: [12, 14, 16, 18, 20, 22, 24, 26],
					onChange: handleChangeSize
				}}
				fontColor={{
					value: paragraphStyleData.properties.paragraph_font_color
							|| generalStyleData.properties.gray_scale3
							|| "#000000",
					onPreviewChange: handleChangeColorOnLivePreview,
					onChange: handleChangeColor
				}}
				additionalChanges={{
					boldFont: {
						value: paragraphStyleData.properties.paragraph_font_weight === "bold",
						onChange: handleChangeBold
					},
					textTransform: {
						value: paragraphStyleData.properties.paragraph_text_transform,
						onChange: handleChangeTextTransform
					},
					letterSpacing: {
						value: parseInt(
							paragraphStyleData.properties.paragraph_letter_spacing
								|| 0, 10
						),
						onChange: handleChangeLetterSpacing
					},
					lineHeight: {
						value: parseInt(paragraphStyleData.properties.paragraph_line_height || 20, 10),
						onChange: handleChangeLineHeight
					}
				}}
			/>

			<Divider orientation="horizontal" size="medium" />

			<ColorPicker
				value={generalStyleData.properties.link_color || "#0000ff"}
				onPreviewChange={handleChangeLinkColorOnLivePreview}
				onChange={handleChangeLinkColor}
				label="Cor dos links"
			/>
		</GroupField>
	)

	return (
		<Grid container spacing={2}>
			<Divider orientation="horizontal" size="small" />

			<Grid item xs={12}>
				{TitleEditor}
			</Grid>

			<Grid item xs={12}>
				{ParagraphEditor}
			</Grid>
		</Grid>
	)
}

export default TypographyEditor
