import React, { useState, useRef } from "react"
import {
	SwipeableDrawer as MaterialDrawer,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Typography,
	Container,
	IconButton,
	Popper,
	Paper,
	Chip
} from "@material-ui/core"
import {
	ArrowBackIos as ArrowBackIosIcon,
	Apps as AppsIcon,
	Language as LanguageIcon,
	Menu as MenuIcon,
	PersonOutlineOutlined as PersonOutlineOutlinedIcon,
	BubbleChartOutlined as BubbleChartOutlinedIcon,
	PermMediaOutlined as PermMediaOutlinedIcon,
	Devices as DevicesIcon,
	CloudUploadOutlined as CloudUploadOutlinedIcon,
	ViewCarouselOutlined as ViewCarouselOutlinedIcon,
	ChatBubbleOutline as ChatBubbleOutlineIcon,
	ListOutlined as ListOutlinedIcon,
	ExtensionOutlined as ExtensionOutlinedIcon,
	Settings as SettingsIcon,
	PaletteOutlined as PaletteIcon,
	LayersOutlined as LayersOutlinedIcon,
	SettingsEthernetOutlined as IntegrationsIcon,
	TrendingUp as TurboSeoIcon,
	StarRate as StarIcon,
	WhatsApp as WhatsAppIcon
} from "@material-ui/icons"

import Divider from "../Divider"
import ThemeGallery from "../ThemeGallerySiteCp"

import logo from "../../assets/logo_white.png"
import logoIcon from "../../assets/logo_icon.png"

import { isMobile, isApple } from "../../utils/checkDevice"
import { handleBackButton } from "../../utils/backButton"

import colors from "../../styles/colors"

import useStyles from "./styles"

import ListItemMenu from "./ListItemMenu"


const Menu = () => {
	const [drawerOpened, setDrawerOpened] = useState(false)
	const [solutionMenuOpened, setSolutionMenuOpened] = useState(false)
	const [themeGalleryOpened, setThemeGalleryOpened] = useState(false)

	const solutionsMenuElement = useRef()

	/**
		* In case it is a mobile device we treat all styles with state of 'drawerFullWidth' as true
		* in order to use the SwipeableDrawer better.
		*/
	const drawerFullWidth = drawerOpened || isMobile

	const classes = useStyles({ drawerFullWidth })

	const handleCloseDrawer = () => {
		setDrawerOpened(false)
	}

	const handleOpenDrawer = () => {
		setDrawerOpened(true)

		handleBackButton({
			active: isMobile,
			onBackButtonPress: handleCloseDrawer
		})
	}

	const handleStateButton = () => {
		setDrawerOpened((opened) => !opened)
	}

	const handleOpenSolutionMenu = () => {
		setSolutionMenuOpened(true)
	}

	const handleCloseSolutionMenu = () => {
		setSolutionMenuOpened(false)
	}

	const handleOpenThemeGallery = () => {
		setThemeGalleryOpened(true)
	}

	const handleCloseThemeGallery = () => {
		setThemeGalleryOpened(false)
	}

	const handleClickThemeGalleryListITem = () => {
		handleOpenThemeGallery()
		if (isMobile) {
			handleCloseDrawer()
		}
	}

	const SolutionsSuitePopper = (
		<Popper
			open={solutionMenuOpened}
			anchorEl={solutionsMenuElement.current}
			style={{ zIndex: 1500 }}
			placement="right-start"
			transition
		>
			<Paper>
				<List disablePadding>
					<ListItem className={classes.solutionMenuHeader}>
						<Typography variant="h2">SOLUÇÕES</Typography>
					</ListItem>
					<ListItem button divider onClick={handleCloseSolutionMenu}>
						<ListItemIcon>
							<DevicesIcon />
						</ListItemIcon>
						<ListItemText
							primary="Site Pro"
							secondary="Site, portfólio, depoimentos, blog"
						/>
					</ListItem>
					<ListItem
						button
						component="a"
						href={process.env.REACT_APP_GALLERY_PRO_URL}
						target="_self"
					>
						<ListItemIcon>
							<CloudUploadOutlinedIcon />
						</ListItemIcon>
						<ListItemText
							primary="Gallery Pro"
							secondary="Entrega em alta, prova de fotos e álbum, venda de fotos"
						/>
					</ListItem>
				</List>
			</Paper>
		</Popper>
	)

	return (
		<>
			<MaterialDrawer
				variant={isMobile ? "temporary" : "permanent"}
				open={drawerOpened}
				onClose={handleCloseDrawer}
				onOpen={handleOpenDrawer}
				anchor="left"
				className={classes.drawer}
				classes={{ paper: classes.paper }}
				onMouseEnter={handleOpenDrawer}
				onMouseLeave={handleCloseDrawer}
				disableBackdropTransition={!isApple}
				disableDiscovery={isApple}
			>
				<List>
					{drawerFullWidth ? (
						<ListItem style={{ justifyContent: "space-between", height: "40px" }}>
							<img src={logo} alt="Picsize Logo" />
							<IconButton onClick={handleStateButton} style={{ padding: 0 }}>
								<ArrowBackIosIcon className={classes.backIcon} />
							</IconButton>
						</ListItem>
					)
						: (
							<ListItem style={{ height: "40px" }}>
								<img src={logoIcon} alt="Picsize Logo" />
							</ListItem>
						)}

					<Divider orientation="horizontal" size="small" />

					<ListItem
						button
						onMouseEnter={handleOpenSolutionMenu}
						onMouseLeave={handleCloseSolutionMenu}
					>
						<Container
							disableGutters
							className={classes.solutionsContainer}
							innerRef={solutionsMenuElement}
						>
							{drawerFullWidth && (
								<Typography style={{ color: "#F0F0F0" }}>Site Pro V2.0</Typography>
							)}

							<AppsIcon style={{ color: "#DADADA" }} />
						</Container>
						{SolutionsSuitePopper}
					</ListItem>

					<Divider orientation="horizontal" size="small" />

					<ListItem>
						<Typography variant="caption" style={{ color: "#969696" }}>
							{drawerFullWidth ? "CONTEÚDO" : "........"}
						</Typography>
					</ListItem>

					<ListItemMenu
						to="/portfolio"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<PermMediaOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Portfólio" />
					</ListItemMenu>

					<ListItemMenu to="/about" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<PersonOutlineOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Sobre mim" />
					</ListItemMenu>

					<ListItemMenu to="/banner" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<ViewCarouselOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Banners" />
					</ListItemMenu>

					<ListItemMenu
						to="/testimonial"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<ChatBubbleOutlineIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Depoimentos" />
					</ListItemMenu>

					<ListItemMenu
						to="/page-maker"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<LayersOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Páginas" />
					</ListItemMenu>

					<ListItemMenu to="/menu" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<ListOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Menu" />
					</ListItemMenu>

					<Divider orientation="horizontal" size="small" />

					<ListItem>
						<Typography variant="caption" style={{ color: "#969696" }}>
							{drawerFullWidth ? "PERSONALIZAÇÃO" : "........"}
						</Typography>
					</ListItem>

					<ListItemMenu to="/logo" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<BubbleChartOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Logotipo" />
					</ListItemMenu>

					<ListItemMenu
						onClick={handleClickThemeGalleryListITem}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<ExtensionOutlinedIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Temas" />
					</ListItemMenu>

					<ListItemMenu
						to="/live-editor"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<PaletteIcon />
						</ListItemIcon>

						<ListItemText
							className={classes.listItemText}
							primary="Editor de design"
						/>

						<Divider size="small" orientation="vertical" />

						<Chip
							size="small"
							label="Novo"
							color="primary"
							className={classes.newFeatureItem}
						/>
					</ListItemMenu>

					<Divider orientation="horizontal" size="small" />

					{/* This style is needed so this item keeps the same height
          when the menu e collapsed or not */}
					<ListItem style={{ height: 40 }}>
						<Typography
							variant="caption"
							style={{ color: "#969696" }}
						>
							{drawerFullWidth ? "PLUGINS EXCLUSIVOS" : "........"}
						</Typography>

						<Divider orientation="vertical" size="small" />

						{drawerFullWidth && <StarIcon style={{ color: colors.palette.tag3 }} />}
					</ListItem>

					<ListItemMenu to="/widgets" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<WhatsAppIcon />
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="WhatsApp"
							style={{ flex: 0 }}
						/>

						<Divider size="small" orientation="vertical" />

						<Chip
							size="small"
							label="Novo"
							color="primary"
							className={classes.newFeatureItem}
						/>
					</ListItemMenu>

					<ListItemMenu to="/turbo-seo" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<TurboSeoIcon />
						</ListItemIcon>
						<ListItemText
							className={classes.listItemText}
							primary="Turbo SEO"
							style={{ flex: 0 }}
						/>
						<Divider orientation="vertical" size="small" />
						<Chip size="small" label="Novo" color="primary" className={classes.newFeatureItem} />
					</ListItemMenu>

					<Divider orientation="horizontal" size="small" />

					<ListItem>
						<Typography variant="caption" style={{ color: "#969696" }}>
							{drawerFullWidth ? "CONFIGURAÇÕES" : "........"}
						</Typography>
					</ListItem>

					<ListItemMenu to="/domain" onClick={() => isMobile && handleCloseDrawer()}>
						<ListItemIcon className={classes.listItemDefault}>
							<LanguageIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Domínio" />
					</ListItemMenu>

					<ListItemMenu
						to="/integrations"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<IntegrationsIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Integrações" />
					</ListItemMenu>

					<ListItemMenu
						to="/general"
						onClick={() => isMobile && handleCloseDrawer()}
					>
						<ListItemIcon className={classes.listItemDefault}>
							<SettingsIcon />
						</ListItemIcon>
						<ListItemText className={classes.listItemText} primary="Geral" />
					</ListItemMenu>
				</List>
			</MaterialDrawer>
			{isMobile && (
				<IconButton onClick={handleOpenDrawer} className={classes.menuIcon}>
					<MenuIcon />
				</IconButton>
			)}

			{/* Display ThemeGallery when themeGalleryOpened is true */}
			{themeGalleryOpened && (
				<ThemeGallery onClose={handleCloseThemeGallery} />
			)}
		</>
	)
}

export default Menu
