const blockCodeNames = {
	instagram_feed_1: "Feed do Instagram",
	works_category_1: "Categoria de Trabalhos",
	work_single_1: "Trabalho Individual",
	header_1: "Cabeçalho",
	header_banner_1: "Cabeçalho com Banner",
	footer_1: "Rodapé",
	category_menu_1: "Menu de Trabalhos",
	about_1: "Sobre Mim Velho",
	about_2: "Sobre Mim",
	testimonial_1: "Depoimentos",
	partner_1: "Selos e Parceiros",
	contact_1: "Contato",
	work_list_1: "Lista de Trabalhos",
	image_1: "Imagem",
	links_1: "Links"
}


const getBlockCodeNameByFile = (blockCodeFile) => {
	const title = blockCodeNames[blockCodeFile]
	return title || blockCodeFile
}

export default getBlockCodeNameByFile
