import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles((theme) => ({
	backgroundBoxContainer: {
		backgroundColor: colors.greyScale[9],
		padding: theme.spacing(1)
	},
	backgroundBox: {
		width: "100%",
		height: 0,
		paddingTop: "50%",
		paddingBottom: "50%",
		borderRadius: theme.spacing(0.5),
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundColor: colors.greyScale[9],
		overflow: "hidden"
	}
}))

export default useStyles
