import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { GroupField, TextInput } from "../../../../components"
import { Divider } from "../../../../../../components"

import { useLiveEditorStore } from "../../../../../../store"

import ChangeLayout from "../../../../components/ChangeLayout"

import SectionDefaultDesign from "../SectionDefaultDesign"

const About2Editor = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const sectionData = liveEditorStore.getSectionById(sectionId)

	const handleChangeText = (attrSelector, value, content) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content
		})
	}

	const handleChangeUppertitleText = (event) => {
		const { value } = event.target
		handleChangeText("[le-uppertitle]", value, { uppertitle: value })
	}

	const handleChangeContactButtonText = (event) => {
		const { value } = event.target
		handleChangeText("[le-contact-button]", value, { contact_button_text: value })
	}

	const handleChangeTextVisibility = (attrSelector, styleObject, properties) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value: styleObject
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties
		})
	}

	const handleChangeUppertitleTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_uppertitle: checked }

		handleChangeTextVisibility("[le-uppertitle-container]", styleObject, properties)
	}

	const handleUpdateTitlesDivider = useRef(() => { })
	handleUpdateTitlesDivider.current = () => {
		const hasSomeTitle = sectionData.properties.display_title
			|| sectionData.properties.display_subtitle

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} [le-titles-divider]`,
				value: { display: hasSomeTitle ? "block" : "none" }
			}
		])
	}

	useEffect(() => {
		handleUpdateTitlesDivider.current()
	}, [
		sectionData.properties.display_title,
		sectionData.properties.display_subtitle
	])

	return (
		<>
			<GroupField label="Conteúdo">
				<TextInput
					label="Sobretítulo"
					value={sectionData?.content?.uppertitle}
					onChange={handleChangeUppertitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_uppertitle,
						onChange: handleChangeUppertitleTextVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Texto do botão de contato"
					value={sectionData?.content?.contact_button_text}
					onChange={handleChangeContactButtonText}
				/>
			</GroupField>

			<Divider orientation="horizontal" size="medium" />

			<ChangeLayout pageSectionId={sectionId} />

			<Divider orientation="horizontal" size="medium" />

			<SectionDefaultDesign sectionId={sectionId} />
		</>
	)
}

About2Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default About2Editor
