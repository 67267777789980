import React, { useState } from "react"
import PropTypes from "prop-types"

import { Button, Divider, Grid, IconButton, Tooltip, Typography } from "@material-ui/core"

import {
	NavigateBefore as ArrowBackIcon,
	PhoneAndroid as SmartphoneIcon,
	DesktopWindows as DesktopIcon
} from "@material-ui/icons"

import { isMobile } from "../../../utils/checkDevice"

import { handleBackButton } from "../../../utils/backButton"

import useStyles from "./styles"

const Iframe = (props) => {
	const { src, themeTitle, onClosePreview, onUseThisTheme } = props

	const [iframeDevice, setIframeDevice] = useState("desktop-full")

	const classes = useStyles({ iframeDevice })

	handleBackButton({
		active: isMobile,
		onBackButtonPress: onClosePreview
	})

	return (
		<Grid container>
			<Grid item xs={12} className={classes.headerContainer}>
				<Grid container alignItems="center" justify="space-between">
					<Grid item xs={4} sm={3} lg={2} xl={1} className={classes.justifyStart}>
						<IconButton onClick={onClosePreview}>
							<ArrowBackIcon style={{ transform: "scale(1.667)" }} />
						</IconButton>
					</Grid>

					<Grid item xs={4} sm={6} lg={8} xl={10}>
						<Grid container justify="center" spacing={2}>
							<Grid item className={classes.themeNameContainer}>
								<Typography className={classes.themeName} variant="h2">
									{themeTitle}
								</Typography>
							</Grid>

							{!isMobile && (
								<>
									<Grid item className={classes.dividerContainer}>
										<Divider orientation="vertical" className={classes.divider} />
									</Grid>

									<Grid item>
										<Tooltip title="Visualização mobile" aria-label="Visualização mobile" arrow>
											<IconButton
												onClick={() => setIframeDevice("mobile")}
												className={classes.mobileIcon}
											>
												<SmartphoneIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title="Visualização desktop (1366px)" aria-label="Visualização desktop (1366px)" arrow>
											<IconButton
												onClick={() => setIframeDevice("desktop-1366px")}
												className={classes.desktop1366pxIcon}
											>
												<DesktopIcon />
											</IconButton>
										</Tooltip>

										<Tooltip title="Visualização desktop (100%)" aria-label="Visualização desktop (100%)" arrow>
											<IconButton
												onClick={() => setIframeDevice("desktop-full")}
												className={classes.desktopIcon}
											>
												<DesktopIcon />
											</IconButton>
										</Tooltip>
									</Grid>
								</>
							)}
						</Grid>
					</Grid>

					<Grid item xs={4} sm={3} lg={2} xl={1} className={classes.justifyEnd}>
						<Button variant="contained" color="secondary" onClick={onUseThisTheme}>
							Usar este
						</Button>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} className={classes.iframeContainer}>
				<iframe
					className={classes.iframe}
					title="site-preview"
					src={src}
					referrerPolicy="origin"
					allow="true"
					loading="eager"
				/>
			</Grid>
		</Grid>
	)
}

Iframe.propTypes = {
	src: PropTypes.string.isRequired,
	themeTitle: PropTypes.string.isRequired,
	onClosePreview: PropTypes.func.isRequired,
	onUseThisTheme: PropTypes.func.isRequired
}

export default Iframe
