import React, { useState } from "react"
import PropTypes from "prop-types"
import JoditEditor from "jodit-react"
import {
	Tabs,
	Tab,
	Container,
	Grid,
	Checkbox,
	FormControlLabel,
	ButtonBase
} from "@material-ui/core"
import {
	DescriptionOutlined as DescriptionOutlinedIcon,
	ViewQuiltOutlined as ViewQuiltOutlinedIcon
} from "@material-ui/icons"

import colors from "../../../../styles/colors"

import {
	Divider,
	InputDialog
} from "../../../../components"

import useStyles from "./styles"

import { isMobile } from "../../../../utils/checkDevice"

import workLayoutVariantA from "../../../../assets/work_layout_variants/a.png"
import workLayoutVariantB from "../../../../assets/work_layout_variants/b.png"
import workLayoutVariantC from "../../../../assets/work_layout_variants/c.png"
// import workLayoutVariantD from "../../../../assets/work_layout_variants/d.png"

const WORK_LAYOUTS = [
	{
		variant: "a",
		preview: workLayoutVariantA
	},
	{
		variant: "b",
		preview: workLayoutVariantB
	},
	{
		variant: "c",
		preview: workLayoutVariantC
	}
	// {
	// 	variant: "d",
	// 	preview: workLayoutVariantD
	// }
]

const EDITOR_OPTIONS = {
	showCharsCounter: false,
	showWordsCounter: false,
	height: 300,
	buttons: ["bold", "italic", "underline", "link", "|", "align", "undo", "redo"],
	showTooltip: false,
	showXPathInStatusbar: false,
	toolbarAdaptive: false,
	placeholder: "Escreva aqui a descrição desse trabalho...",
	enter: "br",
	enterBlock: "br",
	link: {
		formTemplate: () => `
			<form class="jodit_form">
				<div class="jodit_form_group">
					<input ref="url_input" class="jodit_input" required type="text" name="url" placeholder="https://picsize.com.br" type="text"/>
				</div>
				<div ref="content_input_box" class="jodit_form_group">
					<input ref="content_input" class="jodit_input" name="text" placeholder="Texto" type="text"/>
				</div>
				<label ref="target_checkbox_box">
					<input ref="target_checkbox" class="jodit_checkbox" name="target" type="checkbox"/>
					<span>Abrir em nova aba</span>
				</label>
				<div class="jodit_buttons">
					<button ref="unlink" class="jodit_button jodit_unlink_button" type="button">Remover</button>
					<button ref="change" class="jodit_button jodit_link_insert_button" type="submit">Alterar</button>
				</div>
				<div ref="insert" style="display: none"></div>
			<form/>
		`,
		followOnDblClick: false,
		processVideoLink: false,
		processPastedLink: true,
		removeLinkAfterFormat: false,
		noFollowCheckbox: false,
		openInNewTabCheckbox: true
	},
	toolbarInlineDisableFor: ["a"],
	askBeforePasteFromWord: false,
	askBeforePasteHTML: false,
	enableDragAndDropFileToEditor: false,
	defaultActionOnPaste: "insert_only_text",
	draggableTags: []
}

const AsideTabConfig = (props) => {
	const {
		workDescription,
		workLayoutVariant,
		handleUpdateDescription,
		handleUpdateWorkLayoutVariant
	} = props

	const classes = useStyles()

	const [tabType, setTabType] = useState("data")

	const handleChangeTabType = (type) => {
		setTabType(type)
	}

	return (
		<>
			<Tabs
				textColor="inherit"
				value={tabType}
				variant="fullWidth"
				onChange={(_, type) => handleChangeTabType(type)}
				indicatorColor="primary"
				TabIndicatorProps={{
					style: { backgroundColor: colors.greyScale[1] }
				}}
			>
				<Tab
					icon={(
						<Container className={classes.horizontalTab}>
							<DescriptionOutlinedIcon />
							<Divider size="small" orientation="vertical" />
							DADOS
						</Container>
					)}
					value="data"
				/>
				<Tab
					icon={(
						<Container className={classes.horizontalTab}>
							<ViewQuiltOutlinedIcon />
							<Divider size="small" orientation="vertical" />
							DESIGN
						</Container>
					)}
					value="design"
				/>
			</Tabs>

			<Divider size="large" orientation="horizontal" />

			{tabType === "data" && (
				<>
					<InputDialog
						title="Editar descrição do trabalho"
						initialValue={workDescription}
						parser={(description) => description}
						onOk={handleUpdateDescription}
						dataChangeEvent="onBlur"
						cursor="text"
						customInputElement={(
							<JoditEditor
								config={{
									...EDITOR_OPTIONS,
									autoFocus: true,
									height: isMobile ? 100 : 500,
									minHeight: 100
								}}
							/>
						)}
					>
						<JoditEditor
							value={workDescription || "Escreva aqui a descrição desse trabalho..."}
							config={{
								...EDITOR_OPTIONS,
								buttons: ["bold", "italic", "underline", "link", "|", "align"],
								readonly: true,
								useInputsPlaceholder: false,
								style: {
									cursor: "text",
									userSelect: "none",
									color: !workDescription && colors.greyScale[7]
								}
							}}
						/>
					</InputDialog>
				</>
			)}

			{tabType === "design" && (
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Grid container justify="center">
							<FormControlLabel
								control={(
									<Checkbox
										checked={workLayoutVariant === "section"}
										onChange={({ target }) => target.checked && handleUpdateWorkLayoutVariant("section")}
										color="secondary"
									/>
								)}
								label="Utilizar padrão do tema"
							/>
						</Grid>
					</Grid>

					{WORK_LAYOUTS.map(workLayout => (
						<Grid item xs={6} sm={4} md={6} lg={4} xl={4}>
							<Grid container justify="center">
								<ButtonBase onClick={() => handleUpdateWorkLayoutVariant(workLayout.variant)}>
									<img
										src={workLayout.preview}
										alt={`work layout variant ${workLayout.variant}`}
										className={workLayoutVariant === workLayout.variant ? (
											classes.selectedLayoutVariant
										) : (
											classes.nonSelectedLayoutVariant
										)}
									/>
								</ButtonBase>
							</Grid>
						</Grid>
					))}
				</Grid>
			)}
		</>
	)
}

AsideTabConfig.defaultProps = {
	workDescription: "",
	workLayoutVariant: null
}

AsideTabConfig.propTypes = {
	workDescription: PropTypes.string,
	workLayoutVariant: PropTypes.string,
	handleUpdateDescription: PropTypes.func.isRequired,
	handleUpdateWorkLayoutVariant: PropTypes.func.isRequired
}

export default AsideTabConfig
