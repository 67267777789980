import React, { useState } from "react"
import { useHistory, Link } from "react-router-dom"
import {
	Grid,
	IconButton,
	Fab,
	Card,
	CardHeader as CardFooter,
	CardMedia,
	CardActionArea,
	CircularProgress,
	Container,
	Chip,
	Typography,
	Tooltip
} from "@material-ui/core"

import {
	DeleteOutlined as DeleteOutlinedIcon,
	Add as AddIcon,
	ArrowBack as ArrowBackIcon,
	OpenWith as DragIndicatorXYIcon
} from "@material-ui/icons"

import {
	Portlet,
	Loading,
	PopConfirm,
	Notification,
	GridItemResponsive,
	Divider,
	ActionZone,
	Sortable
} from "../../components"

import WorksSkeleton from "../../skeletons/Works"
import NewWorkBarSkeleton from "../../skeletons/NewWorkBar"

import noCoverPlaceholder from "../../assets/no_cover_placeholder2.png"

import api from "../../services/api"

import { isMobile } from "../../utils/checkDevice"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import { useCustomerStore } from "../../store"

import useCustomStyles from "../../styles/custom"
import useStyles from "./styles"
// import GroupedCategories from "./components/GroupedCategories"

const Portfolio = () => {
	const {
		triggerUndeterminedValidation
	} = useValidation()

	// const [groupedCategories, setGroupedCategories] = useState([])
	const [works, setWorks] = useState([])

	// const [loadingGroupedCategories, setLoadingGroupedCategories] = useState(true)
	const [loadingCreateWork, setLoadingCreatingWork] = useState(false)
	const [loadingWorks, setLoadingWorks] = useState(true)
	const [dragStart, setDragStart] = useState(true)

	const classes = useStyles({ dragStart })
	const customClasses = useCustomStyles()
	const history = useHistory()
	const customerStore = useCustomerStore()

	const handleChangeWorks = (values) => {
		setDragStart(false)
		setWorks(values)
	}

	// const getGroupedCategoriesData = async () => {
	// 	try {
	// 		const { data } = await api.get("/site/portfolio/grouped-categories")
	// 		setGroupedCategories(data.grouped_categories)
	// 		setLoadingGroupedCategories(false)
	// 	} catch (error) {
	// 		triggerUndeterminedValidation(error)
	// 	}
	// }


	// const putCategoryOrder = async (valuesReordered) => {
	// 	const formattedValuesReordered = []
	// 	valuesReordered.map(category => formattedValuesReordered
	// 		.push({
	// 			id: category.id,
	// 			order: category.order
	// 		}))

	// 	await api.put("/site/portfolio/category/all/order", formattedValuesReordered)
	// 		.then(() => {
	// 			Notification.success({ message: "Ordem das categorias alterada com sucesso!" })
	// 		}).catch(() => {
	// 			Notification.error({ message: "Falha ao alterar a ordem das categorias..." })
	// 		})

	// 	getGroupedCategoriesData()
	// }

	const getWorksData = async () => {
		try {
			const { data } = await api.get("/site/portfolio/work/list")

			handleChangeWorks(data.works)
			setLoadingWorks(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const putWorkOrder = async (valuesReordered) => {
		await api.put("/site/portfolio/work/all/order", valuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem dos trabalhos alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem dos trabalhos..." })
			})
	}

	const getPortfolioData = async () => {
		await Promise.all([
			// getGroupedCategoriesData(),
			getWorksData()
		])
	}

	// const handleCreateCategory = async (group_id, title) => {
	// 	try {
	// 		await api.post(`/site/portfolio/group/${group_id}/category`, { title })

	// 		await getPortfolioData()

	// 		return "isCreated"
	// 	} catch (error) {
	// 		return error
	// 	}
	// }

	// const handleDeleteCategory = (category_id) => {
	// 	PopConfirm.open({
	// 		description: "Deletar uma categoria é uma ação irreversível.",
	// 		title: "Tem certeza?",
	// 		confirmButtonText: "Deletar",
	// 		onConfirm: async () => {
	// 			try {
	// 				await api.delete(`/site/portfolio/category?category_id=${category_id}`)

	// 				await getGroupedCategoriesData()

	// 				await getWorksData()

	// 				Notification.success({ message: "Categoria deletada com sucesso!" })
	// 			} catch (error) {
	// 				triggerUndeterminedValidation(error)
	// 			}
	// 		}
	// 	})
	// }

	// const handleChangeCategoryTitle = async (category_id, title) => {
	// 	try {
	// 		await api.put(`/site/portfolio/category/${category_id}`, { title })

	// 		await getPortfolioData()

	// 		Notification.success({ message: "Nome da categoria alterado com sucesso!" })

	// 		return "titleUpdated"
	// 	} catch (error) {
	// 		return error
	// 	}
	// }

	const handleCreateWork = async () => {
		setLoadingCreatingWork(true)

		const nonEditedWork = works.find(work => !work.was_edited)

		let workId

		if (nonEditedWork) {
			workId = nonEditedWork.id
		} else {
			const { data } = await api.post("/site/portfolio/work")

			workId = data.work_id
		}

		customerStore.sumUserUsageWorksCountBy(+1)

		history.push(`/portfolio/work/${workId}`)

		setLoadingCreatingWork(false)
	}

	const handleDeleteWork = (work_id) => {
		const selectedWork = works.find(work => work.id === work_id) || {}

		PopConfirm.open({
			description: "Deletar um trabalho é uma ação irreversível. Você perderá todos os dados desse trabalho.",
			title: "Tem certeza?",
			confirmButtonText: "Deletar",
			confirmationText: selectedWork.title,
			onConfirm: async () => {
				try {
					await api.delete(`/site/portfolio/work?work_id=${work_id}`)

					await getWorksData()

					Notification.success({ message: "Trabalho deletado com sucesso!" })

					customerStore.sumUserUsageWorksCountBy(-1)
				} catch (error) {
					triggerUndeterminedValidation(error)
				}
			}
		})
	}

	const worksFilterWasEdited = () => works.filter(work => work.was_edited)

	const handleWorkSortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType, setDragStart(true))
	}

	const handleWorkSortEnd = ({ oldIndex, newIndex }) => {
		const arrayInfo = {
			values: worksFilterWasEdited(),
			oldIndex,
			newIndex
		}
		Sortable.handleSortEnd(arrayInfo, handleChangeWorks, putWorkOrder)
	}

	// const handleCategorySortEnd = (oldIndex, newIndex, groupedCategory) => {
	// 	setLoadingGroupedCategories(true)

	// 	const arrayInfo = {
	// 		values: groupedCategory.categories,
	// 		oldIndex,
	// 		newIndex,
	// 		orderAsc: true
	// 	}

	// 	const handleChangeCategories = () => { }

	// 	Sortable.handleSortEnd(arrayInfo, handleChangeCategories, putCategoryOrder)
	// }

	useDidMount(() => {
		getPortfolioData()
	})


	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{loadingWorks || worksFilterWasEdited().length > 0 ? (
						<>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Portlet>
									<Loading loading={loadingWorks} customLoadingElement={<NewWorkBarSkeleton />}>
										<Fab color="primary" onClick={handleCreateWork}>
											{loadingCreateWork ? <CircularProgress style={{ color: "#FFF" }} size={20} />
												: (
													<Tooltip title="Adicionar trabalho" placement="bottom" arrow>
														<AddIcon />
													</Tooltip>
												)}
										</Fab>
									</Loading>
								</Portlet>
							</Grid>
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Loading loading={loadingWorks} customLoadingElement={<WorksSkeleton />}>
									<Sortable.SectionContainer
										pressDelay
										axis="xy"
										useDragHandle
										onSortEnd={handleWorkSortEnd}
										onSortStart={handleWorkSortStart}
									>
										<Grid container spacing={2}>
											{
												worksFilterWasEdited().map((work, index) => (
													<Sortable.SortableSectionItem
														key={`work-${work.id}`}
														index={index}
														disabled={!(worksFilterWasEdited().length > 1)}
													>
														<GridItemResponsive
															xs={12}
															sm={6}
															md={6}
															lg={4}
															xl={3}
															className={classes.workCard}
														>
															<Card elevation={1}>
																<CardActionArea className={classes.workCardActionArea}>

																	<Container
																		disableGutters
																		className={classes.workCardActionContainer}
																	>

																		<Grid container justify="space-between">
																			<Grid item>
																				{
																					worksFilterWasEdited().length > 1
																					&& (
																						<Sortable.DragHandle>
																							<Tooltip title="Ordenar trabalho" placement="top" arrow>
																								<IconButton
																									className={
																										customClasses.containerActionButtonDark
																									}
																									size="small"
																								>
																									<DragIndicatorXYIcon
																										className={
																											customClasses.contentActionButtonWhite
																										}
																										style={{ cursor: "grab" }}
																									/>
																								</IconButton>
																							</Tooltip>
																						</Sortable.DragHandle>
																					)
																				}
																			</Grid>

																			<Grid item>
																				{work.is_published ? (
																					<Chip
																						label="Publicado"
																						color="secondary"
																						className={classes.publishedChip}
																					/>
																				) : (<Chip label="Rascunho" color="inherit" className={classes.nonPublishedChip} />)}
																			</Grid>
																		</Grid>
																	</Container>

																	<Link to={`/portfolio/work/${work.id}`}>
																		<CardMedia
																			image={work.cover || noCoverPlaceholder}
																			title={work.title}
																			className={classes.workCover}
																		/>
																	</Link>
																</CardActionArea>
																<CardFooter
																	avatar={(<div />)}
																	action={(
																		<Tooltip title="Excluir trabalho" placement="bottom" arrow>
																			<IconButton onClick={() => handleDeleteWork(work.id)}>
																				<DeleteOutlinedIcon />
																			</IconButton>
																		</Tooltip>
																	)}
																	title={work.title}
																	titleTypographyProps={{
																		variant: "h3"
																	}}
																	subheader={`${work.categories.length
																		? `${work.categories.join(",").toUpperCase()} |` : ""}

																		${work.info?.total_pictures}

																		${work.info?.total_pictures === 1 ? "foto" : "fotos"}`}
																	subheaderTypographyProps={{
																		className: customClasses.listCaptionText
																	}}
																/>
															</Card>
														</GridItemResponsive>
													</Sortable.SortableSectionItem>
												))
											}
										</Grid>
									</Sortable.SectionContainer>
								</Loading>
							</Grid>
						</>
					)
						: (
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Grid container wrap="wrap">
									<Grid item className={classes.emptyWorkContainer}>
										<ActionZone onClick={handleCreateWork}>
											<Fab color="primary" className={classes.fab}>
												{loadingCreateWork ? <CircularProgress style={{ color: "#FFF" }} size={20} /> : <AddIcon />}
											</Fab>

											<Divider size="large" orientation="horizontal" />

											<Typography variant="h3">
												Novo trabalho
											</Typography>
										</ActionZone>
									</Grid>

									{!isMobile && (
										<Grid item>
											<Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
												<ArrowBackIcon className={classes.emptyWorkIcon} />
												<Typography variant="h2" className={classes.emptyWorkText}>
													Comece subindo
													<br />
													as fotos de algum
													<br />
													trabalho seu!
												</Typography>
											</Grid>
										</Grid>
									)}
								</Grid>
							</Grid>
						)}
				</Grid>
			</Grid>
			{/* <Grid
				item
				xs={12}
				sm={12}
				md={4}
				lg={2}
				xl={2}
				className={classes.categoriesContainer}
			>
				<Typography variant="h2">
					CATEGORIAS
				</Typography>

				<Divider size="large" orientation="horizontal" />

				<Portlet disablePadding>
					<GroupedCategories
						groupedCategories={groupedCategories}
						loadingGroupedCategories={loadingGroupedCategories}
						onChange={handleChangeCategoryTitle}
						onCreate={handleCreateCategory}
						onDelete={handleDeleteCategory}
						onSortEnd={handleCategorySortEnd}
					/>
				</Portlet>
			</Grid> */}
		</Grid>
	)
}

export default Portfolio
