import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
	box: {
		padding: theme.spacing() * 2,
		backgroundColor: "#FFF",
		borderRadius: "5px",
		boxShadow: theme.shadows[1],
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	}
}))

export default useStyles
