import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles({
	headerContainer: {
		height: "60px",
		backgroundColor: "#F0F0F0",
		padding: "5px 10px"
	},
	customerName: {
		color: "#707070",
		fontSize: "14px"
	},
	avatar: {
		width: "26px",
		height: "26px",
		color: colors.greyScale[3],
		border: `1px solid ${colors.greyScale[10]}`,
		boxShadow: "0 0 0 1px #5CC395"
	}
})

export default useStyles
