import React, { useState } from "react"
import PropTypes from "prop-types"
import Snackbar from "@material-ui/core/Snackbar"
import MuiAlert from "@material-ui/lab/Alert"
import { ThemeProvider } from "@material-ui/core"

import theme from "../../styles/theme"

import { renderComponent } from "../../utils/node"

import useStyles from "./styles"

const Notification = (props) => {
	const { type, message } = props

	const classes = useStyles()

	const [visible, setVisible] = useState(true)

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return
		}

		setVisible(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Snackbar
				open={visible}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "top",
					horizontal: "right"
				}}
			>
				<MuiAlert
					onClose={handleClose}
					elevation={6}
					variant="filled"
					severity={type}
					className={classes.alert}
				>
					{message}
				</MuiAlert>
			</Snackbar>
		</ThemeProvider>
	)
}

Notification.propTypes = {
	message: PropTypes.string.isRequired,
	type: PropTypes.oneOf(["success", "error", "warning", "info"]).isRequired
}

Notification.success = ({ message }) => renderComponent(
	"notification",
	<Notification type="success" message={message} />
)

Notification.error = ({ message }) => renderComponent(
	"notification",
	<Notification type="error" message={message} />
)

Notification.warning = ({ message }) => renderComponent(
	"notification",
	<Notification type="warning" message={message} />
)

Notification.info = (message) => renderComponent(
	"notification",
	<Notification type="info" message={message} />
)

export default Notification
