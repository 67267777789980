import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, makeStyles, createStyles } from "@material-ui/core"

import { GridItemResponsive } from "../components"

const useStyles = makeStyles(theme => createStyles({
	media: {
		width: "auto",
		height: 496,
		margin: theme.spacing(1)
	},
	card: {
		width: "100%",
		height: "auto"
	}
}))

const TestimonialSkeleton = () => {
	const classes = useStyles()

	const nItems = 3
	const cards = []

	// eslint-disable-next-line
  for (let i = 0; i < nItems; i++) {
		cards.push(
			<GridItemResponsive xs={12} sm={12} md={6} lg={4} xl={3}>
				<Card className={classes.card}>
					<Skeleton animation="wave" variant="rect" className={classes.media} />
				</Card>
			</GridItemResponsive>
		)
	}

	return (
		<Grid container wrap="wrap" spacing={2}>
			{cards}
		</Grid>
	)
}

export default TestimonialSkeleton
