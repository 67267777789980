import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	selectStyle: {
		fontSize: "14px !important"
	},
	menuSelectStyle: {
		"& .MuiListItem-button": {
			fontSize: "14px !important"
		}
	},
	inputStyle: {
		"& .MuiInput-root": {
			fontSize: "14px !important"
		}
	},
	linkTypographic: {
		letterSpacing: "0px",
		fontWeight: "500",
		color: "inherit"
	}
}))

export default useStyles
