import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
	Typography,
	MenuItem,
	List,
	ListItem,
	ListItemText,
	Button,
	ListItemIcon,
	FormControl,
	Grid,
	Switch,
	Tooltip,
	IconButton,
	ButtonBase,
	Container
} from "@material-ui/core"

import {
	ArrowBackIos as ArrowBackIosIcon,
	DragIndicator as DragIcon,
	AddCircleOutline as AddIcon,
	DeleteOutline as DeleteIcon
} from "@material-ui/icons"

import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc"

import arrayMove from "array-move"

import { Select } from "../../components"

import { Divider } from "../../../../components"
import { handleBackButton } from "../../../../utils/backButton"
import { isMobile } from "../../../../utils/checkDevice"
import useLivePreview, { getLivePreviewDocument } from "../../../../hooks/useLivePreview"
import useValidation from "../../../../hooks/useValidation"

import api from "../../../../services/api"

import { useLiveEditorStore } from "../../../../store"

import useStyles from "./styles"
import useCustomStyles from "../../../../styles/custom"

import BodySectionsEditorSelector from "./BodySections"

import { isDevOrTheme } from "../../../../utils/checkCustomer"

import SectionsGallery from "./SectionsGallery"

import getSectionName from "../../../../utils/getSectionName"

const availableEditors = [
	"partner_1",
	"image_1",
	"links_1",
	"contact_1",
	"work_list_1",
	"about_2",
	"testimonial_1"
]

const PagesEditor = ({ publishing, triggerSelectSectionId }) => {
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const { triggerUndeterminedValidation } = useValidation()

	const [openSectionsGallery, setOpenSectionsGallery] = useState(false)
	const [currentSectionId, setCurrentSectionId] = useState(false)

	const {
		addOrRefreshControllerListeners,
		scrollToSection,
		activateSection,
		deactivateAllSections
	} = useLivePreview()

	const liveEditorStore = useLiveEditorStore()
	const { currentPage } = liveEditorStore.state
	const currentPageSortedSections = liveEditorStore.getSortedSectionsByPageId(currentPage?.id)

	// It was used to identify the sections that have editors.
	// I kept the code here for a while because i think it may be used soon
	// eslint-disable-next-line
	const hasEditor = (sectionId) => {
		const section = liveEditorStore.getSectionById(sectionId)

		if (!availableEditors.includes(section.file)) {
			return false
		}

		if (section.file === "work_list_1" && !section.title.includes("Resume")) {
			return false
		}

		return true
	}

	const handleOpenSectionsGallery = () => {
		setOpenSectionsGallery(true)
	}

	const handleCloseSectionsGallery = () => {
		setOpenSectionsGallery(false)
	}

	const handleChangePage = (event) => {
		const { value: pageId } = event.target
		liveEditorStore.setCurrentPageData({ pageId })
	}

	const handleCloseSectionEditor = () => {
		deactivateAllSections()
		setCurrentSectionId(null)
	}

	const getNewSectionId = () => {
		const ids = []
		const { sections } = liveEditorStore.state
		sections.sort((sectionA, sectionB) => sectionA.id - sectionB.id)

		sections.forEach(section => ids.push(section.id))

		ids.sort((a, b) => a - b)
		const greaterId = ids.pop()
		return (Number.isInteger(greaterId) ? greaterId : 0) + 1
	}

	const handleSelectSection = (sectionId) => {
		const section = liveEditorStore.getSectionById(sectionId)

		if (!section.hidden) {
			activateSection({ selector: `page-section-${sectionId}` })
			scrollToSection({ selector: `page-section-${sectionId}` })
		}

		setCurrentSectionId(sectionId)

		if (sectionId) {
			handleBackButton({
				active: isMobile,
				onBackButtonPress: () => handleCloseSectionEditor()
			})
		}
	}

	const findNextAdjacentSection = (sectionId, order, sections) => {
		const nextSection = sections.find(
			section => (
				section.order >= order
				&& !section.hidden
				&& section.id !== sectionId
			)
		)
		return nextSection
	}

	// oldSectionsOrder = [firstSectionIndex, secondSectionIndex, thirdSectionIndex]
	// Change third section to the first position
	// newSectionsOrder = [thirdSectionIndex, firstSectionIndex, secondSectionIndex]
	const changeOrderOnLivePreview = (sectionId, nextSectionId, pageId) => {
		liveEditorStore.addLivePreviewChanges([
			{
				pageId,
				action: liveEditorStore.livePreviewActions.REORDER_SECTION_ELEMENT,
				selector: `page-section-${sectionId}`,
				value: `page-section-${nextSectionId}`
			}
		])
	}

	const handleSortEnd = ({ oldIndex, newIndex }) => {
		const sections = currentPageSortedSections
		let sectionsReordered = arrayMove(sections, oldIndex, newIndex)
		sectionsReordered = sectionsReordered.map((section, index) => ({ ...section, order: index }))

		// Update on store
		sectionsReordered.forEach((section) => {
			liveEditorStore.setSectionData({
				sectionId: section.id,
				order: section.order
			})
		})

		// Update on live-preview, if is needed
		const movedSection = sections[oldIndex]
		const sectionOnLivePreview = getLivePreviewDocument().getElementById(`page-section-${movedSection.id}`)
		if (sectionOnLivePreview && newIndex !== oldIndex) {
			const newNextSection = findNextAdjacentSection(movedSection.id, newIndex, sectionsReordered)
			changeOrderOnLivePreview(movedSection.id, newNextSection?.id, movedSection.page_id)
		}
	}

	const toggleSectionHiddenOnLivePreview = (sectionId, hidden) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.TOGGLE_ELEMENT_HIDDEN,
				selector: `page-section-${sectionId}`,
				value: hidden,
				pageId: currentPage?.id
			}
		])
	}

	const insertSectionOnLivePreview = async (sectionId) => {
		try {
			const section = liveEditorStore.getSectionById(sectionId)

			const response = await api.post("/site/live-editor/build/view-section/assets", section)

			const sectionAssets = response.data

			const sections = currentPageSortedSections
			const nextSection = findNextAdjacentSection(section.id, section.order, sections)

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.INSERT_SECTION_HTML,
					selector: sectionId,
					value: { html: sectionAssets.html, nextSectionSelector: `page-section-${nextSection?.id}` },
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
					selector: `style-${sectionId}`,
					value: sectionAssets.css,
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_JS,
					selector: `script-${sectionId}`,
					value: sectionAssets.js,
					pageId: currentPage.id
				}
			])
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleToggleSectionHidden = async (event, sectionId) => {
		event.stopPropagation()

		const newHiddenValue = !event.target.checked
		liveEditorStore.setSectionData({ sectionId, hidden: newHiddenValue })

		// TODO should use live-previews' getElement, but it behavior is different than expected
		const sectionElement = getLivePreviewDocument().getElementById(`page-section-${sectionId}`)
		if (sectionElement) {
			toggleSectionHiddenOnLivePreview(sectionId, newHiddenValue)
		} else if (!newHiddenValue) {
			insertSectionOnLivePreview(sectionId)
		}
	}

	const handleClickAddSection = () => {
		handleOpenSectionsGallery()
	}

	const handleAddSection = async (selectedSection) => {
		let order = 0
		if (currentPageSortedSections.length > 0) {
			order = currentPageSortedSections[currentPageSortedSections.length - 1].order + 1
		}

		const sectionId = getNewSectionId()
		const section = {
			...selectedSection,
			id: sectionId,
			order,
			page_id: currentPage.id,
			html_tag: "body",
			hidden: false
		}

		liveEditorStore.createSection({ section })

		try {
			const response = await api.post("/site/live-editor/build/view-section/assets", section)

			const sectionAssets = response.data

			const sections = currentPageSortedSections
			const nextSection = findNextAdjacentSection(section.id, section.order, sections)

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.INSERT_SECTION_HTML,
					selector: sectionId,
					value: { html: sectionAssets.html, nextSectionSelector: `page-section-${nextSection?.id}` },
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_CSS,
					selector: `style-${sectionId}`,
					value: sectionAssets.css,
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_JS,
					selector: `script-${sectionId}`,
					value: sectionAssets.js,
					pageId: currentPage.id
				}
			])

			addOrRefreshControllerListeners()
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleDeleteSection = async (event, sectionId) => {
		event.stopPropagation()

		try {
			liveEditorStore.deleteSection({ sectionId })

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.REMOVE_ELEMENTS,
					selector: `#page-section-${sectionId}`,
					pageId: currentPage.id
				}
			])

			addOrRefreshControllerListeners()
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	useEffect(() => {
		if (triggerSelectSectionId) {
			handleSelectSection(triggerSelectSectionId)
		}
	}, [triggerSelectSectionId])

	useEffect(() => {
		if (publishing) {
			handleCloseSectionEditor()
		}
	}, [publishing])

	useEffect(() => {
		handleCloseSectionEditor()
	}, [liveEditorStore.state.currentPage?.id])

	const SelectPage = (
		<FormControl style={{ display: "flex" }} className={classes.notFullWidth}>
			<Typography variant="h2">PÁGINA</Typography>

			<Divider size="small" orientation="horizontal" />

			<Select value={currentPage?.id} onChange={handleChangePage}>
				{liveEditorStore.state.pages.map(page => (
					page.title !== "Trabalho" || currentPage?.id === page.id
				) && (
					<MenuItem key={page.id} value={page.id}>
						{page.title}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	)

	const DragHandle = sortableHandle(() => (
		<Typography variant="h2" style={{ lineHeight: 0 }} className={classes.sortableItemDragHandle}>
			<DragIcon style={{ cursor: "row-resize" }} />
		</Typography>
	))

	const SortableContainer = sortableContainer(({ children }) => (
		<Container maxWidth={false} disableGutters className={classes.notFullWidth}>
			<Grid container spacing={1}>
				{children}
			</Grid>
		</Container>
	))

	const SortableItem = sortableElement(({ value: section }) => (
		<Grid item xs={12}>
			<ButtonBase
				className={classes.sortableItemContainer}
				onClick={() => handleSelectSection(section.id)}
			>
				<DragHandle />

				<Typography variant="h2" className={classes.sortableItemTitle} noWrap>
					{getSectionName(section.title)}
				</Typography>

				{isDevOrTheme() && (
					<Tooltip title="Deletar seção">
						<IconButton onClick={(event) => handleDeleteSection(event, section.id)}>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}

				<Tooltip title={section.hidden ? "Mostrar seção" : "Ocultar seção"}>
					<Switch
						onClick={(event) => handleToggleSectionHidden(event, section.id)}
						checked={!section.hidden}
					/>
				</Tooltip>
			</ButtonBase>
		</Grid>
	))

	const AddSectionButton = (
		<Grid item xs={12}>
			<Button className={classes.addSectionButton} fullWidth onClick={handleClickAddSection}>
				<Typography variant="h2" className={classes.addSectionButtonTypo}>
					<AddIcon className={classes.addSectionButtonIcon} />
					Adicionar Seção
				</Typography>
			</Button>
		</Grid>
	)

	const EditorForUnselectedSection = (
		<>
			<Divider size="xxlarge" orientation="horizontal" />

			{SelectPage}

			<Divider size="xlarge" orientation="horizontal" />

			<Grid container alignItems="center" className={classes.notFullWidth}>
				<Grid item>
					<Typography
						variant="h1"
						style={{ fontSize: "14px", textTransform: "uppercase", marginRight: 6 }}
					>
						SEÇÕES DA PÁGINA
					</Typography>
				</Grid>

				<Grid item>
					<Typography variant="h2" style={{ textTransform: "uppercase" }}>
						{liveEditorStore.state.currentPage?.title}
					</Typography>
				</Grid>
			</Grid>

			<Divider size="medium" orientation="horizontal" />

			<SortableContainer
				lockAxis="y"
				onSortEnd={handleSortEnd}
				pressDelay
				useDragHandle
			>
				{currentPageSortedSections
					.filter(section => section.html_tag === "body")
					.map((section, index) => (
						<SortableItem key={`item-${section.title}`} index={index} value={section} />
					))}

				{isDevOrTheme() && AddSectionButton}
			</SortableContainer>

			<Divider size="xxlarge" orientation="horizontal" />

			<SectionsGallery
				open={openSectionsGallery}
				onClose={handleCloseSectionsGallery}
				onSelect={handleAddSection}
			/>
		</>
	)

	const EditorForSelectedSection = () => {
		const section = liveEditorStore.getSectionById(currentSectionId)

		return (
			<>
				<List disablePadding>
					<ListItem
						className={classes.headerAndFooterListItem}
						button
						onClick={() => handleCloseSectionEditor()}
					>
						<ListItemIcon>
							<ArrowBackIosIcon />
						</ListItemIcon>
						<ListItemText
							className={`${classes.listItemText} ${customClasses.textUppercase}`}
							primaryTypographyProps={{ variant: "h2" }}
						>
							{getSectionName(section.title)}
						</ListItemText>
					</ListItem>
				</List>

				<BodySectionsEditorSelector
					sectionId={currentSectionId}
					onFinish={handleCloseSectionEditor}
				/>
			</>
		)
	}

	return (
		<>
			{currentSectionId
				? EditorForSelectedSection()
				: EditorForUnselectedSection}
		</>
	)
}

PagesEditor.propTypes = {
	publishing: PropTypes.bool.isRequired,
	triggerSelectSectionId: PropTypes.number.isRequired
}

PagesEditor.defaultProps = {
}

export default PagesEditor
