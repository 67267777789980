import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Grid,
	ButtonBase,
	Popover,
	IconButton
} from "@material-ui/core"
import { FormatColorFill as FormatColorFillIcon } from "@material-ui/icons"
import LiveEditorTypography from "../LiveEditorTypography"
import ColorPickerInput from "./ColorPickerInput"

import useStyles from "./styles"

const ColorPicker = (props) => {
	const {
		onPreviewChange,
		onChange,
		value,
		label,
		enableOpacity,
		allColor
	} = props

	const initialValue = value === "transparent" ? "rgba(255, 255, 255, 0)" : value

	const classes = useStyles({ colorSelect: initialValue })

	const [anchorEl, setAnchorEl] = useState(null)
	const [newColor, setNewColor] = useState(initialValue)
	const openColorPopover = Boolean(anchorEl)

	const openPopover = (event) => {
		setAnchorEl(event.currentTarget)
	}

	const closePopoverWithoutSaveColor = () => {
		setAnchorEl(null)
		onPreviewChange(initialValue)
	}

	const closePopoverSaveColor = () => {
		setAnchorEl(null)
		onChange(newColor)
	}

	const handleChange = (color, actionType) => {
		const { hex, rgb } = color

		const colorSelected = enableOpacity ? `rgba(${rgb.r},${rgb.g},${rgb.b},${rgb.a})` : hex

		if (allColor) {
			setNewColor(color)
			if (actionType === "completed") {
				onChange(color)
			} else {
				onPreviewChange(color)
			}
		} else {
			setNewColor(colorSelected)
			if (actionType === "completed") {
				onChange(colorSelected)
			} else {
				onPreviewChange(colorSelected)
			}
		}

		if (actionType === "completed") setAnchorEl(null)
	}

	return (
		<>
			{label
				? (
					<ButtonBase
						focusRipple
						className={
							classes.bucketColor
						}
						onClick={openPopover}
					>
						<Grid
							container
							spacing={2}
							alignItems="center"
							justify="space-between"
						>
							<Grid
								item
								xs
								style={{ textAlign: "initial" }}
							>
								<LiveEditorTypography
									type="fieldTitle"
								>
									{label}
								</LiveEditorTypography>
							</Grid>
							<Grid item>
								<IconButton>
									<FormatColorFillIcon
										className={classes.underbarColor}
									/>
								</IconButton>
							</Grid>
						</Grid>
					</ButtonBase>
				)
				: (
					<IconButton onClick={openPopover}>
						<FormatColorFillIcon
							className={classes.underbarColor}
						/>
					</IconButton>
				)}

			<Popover
				open={openColorPopover}
				anchorEl={anchorEl}
				onClose={closePopoverSaveColor}
				anchorOrigin={{
					vertical: "center",
					horizontal: "right"
				}}
				transformOrigin={{
					vertical: "center",
					horizontal: "left"
				}}
			>
				<ColorPickerInput
					disableAlpha={!enableOpacity}
					initialColor={initialValue}
					onChange={handleChange}
					closePopover={closePopoverWithoutSaveColor}
				/>
			</Popover>
		</>
	)
}

ColorPicker.defaultProps = {
	label: "",
	enableOpacity: false,
	allColor: false
}

ColorPicker.propTypes = {
	onPreviewChange: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.string.isRequired,
	label: PropTypes.string,
	enableOpacity: PropTypes.bool,
	allColor: PropTypes.bool
}

export default ColorPicker
