import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(() => ({
	placeholder: {
		height: 150,
		maxWidth: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: 25,
		position: "relative"
	},
	faviconImg: {
		maxWidth: "128px",
		maxHeight: "128px",
		objectFit: "cover",
		objectPosition: "center"
	},
	uploadPlaceholder: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: 9,
		backgroundColor: "rgba(0, 0, 0, 0.95)"
	},
	uploadPlaceholderText: {
		color: colors.greyScale[9],
		fontSize: "20px"
	}
}))

export default useStyles
