import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	loading: {
		color: colors.greyScale[11]
	}
})

export default useStyles
