// remove <script id="script-id"> and </script>
export function parseJS(jsString) {
	const jsCode = jsString.replace(/<\/?script[\w=\-" ]*>/g, "").trim()
	return jsCode
}

// remove <style id="style-id"> and </style>
export function parseCSS(cssString) {
	const cssCode = cssString.replace(/<\/?style[\w=\-" ]*>/g, "").trim()
	return cssCode
}
