import { combineReducers } from "redux"

import customer from "./customer"
import work from "./work"
import site from "./site"
import banner from "./banner"
import testimonial from "./testimonial"
import liveEditor from "./liveEditor"

export default combineReducers({
	customer,
	work,
	site,
	banner,
	testimonial,
	liveEditor
})
