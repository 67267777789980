import React, { useState } from "react"
import PropTypes from "prop-types"
import { Button, ButtonBase, Typography, Grid, Drawer as MdDrawer, IconButton } from "@material-ui/core"
import { ArrowBackIos as ArrowBackIcon } from "@material-ui/icons"
import LiveEditorTypography from "../LiveEditorTypography"

import { Divider } from "../../../../components"
import colors from "../../../../styles/colors"
import useStyles from "./styles"

const Drawer = (props) => {
	const { label, icon, imgButton, children } = props
	const classes = useStyles()
	const [openDrawer, setOpenDrawer] = useState(false)

	const toggleDrawer = (value) => (event) => {
		if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
			return
		}
		setOpenDrawer(value)
	}

	return (
		<>
			{label
				? (
					<ButtonBase
						focusRipple
						onClick={toggleDrawer(true)}
						style={{ width: "100%" }}
					>
						<Grid
							container
							spacing={1}
							alignItems="center"
							justify="space-between"
						>
							<Grid
								item
								xs
								style={{ textAlign: "initial" }}
							>
								<LiveEditorTypography
									type="fieldTitle"
								>
									{label}
								</LiveEditorTypography>
							</Grid>
							<Grid item>
								<IconButton>
									{icon}
								</IconButton>
							</Grid>
							<Grid item xs={12}>
								{
									imgButton && (
										imgButton.img
											? <img style={{ width: "100%" }} src={imgButton.img} alt={imgButton.alt} />
											: <Typography variant="h2">Clique aqui e selecione!</Typography>
									)
								}
							</Grid>
						</Grid>
					</ButtonBase>
				)
				: (
					<IconButton onClick={toggleDrawer(true)}>
						{icon}
					</IconButton>
				)}

			<MdDrawer
				anchor="left"
				variant="persistent"
				open={openDrawer}
				onClose={toggleDrawer(false)}
			>
				<Grid
					container
					direction="column"
					className={classes.containerBtnLayout}
				>
					<Grid item>
						<Button
							onClick={toggleDrawer(false)}
							startIcon={<ArrowBackIcon style={{ color: colors.greyScale[5] }} />}
							className={classes.backButton}
						>
							<Typography variant="h2">Fechar</Typography>
						</Button>
					</Grid>

					<Divider size="large" orientation="horizontal" />

					<Grid item>
						{children}
					</Grid>
				</Grid>
			</MdDrawer>
		</>
	)
}

Drawer.defaultProps = {
	label: "",
	icon: null,
	imgButton: null
}

Drawer.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.node,

	imgButton: PropTypes.shape({
		img: PropTypes.node,
		alt: PropTypes.string
	}),

	children: PropTypes.func.isRequired
}
export default Drawer
