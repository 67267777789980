import React from "react"
import { Grid } from "@material-ui/core"
import {
	Texture as ShadowIcon,
	Settings as SettingsIcon,
	Height as HorizontalIcon,
	BorderStyle as BorderStyleIcon
} from "@material-ui/icons"

import { useLiveEditorStore } from "../../../../../store"
import {
	GroupField,
	AlterBorder,
	ColorPicker,
	AlterFont,
	Drawer,
	Slider
} from "../../../components"

import { Divider } from "../../../../../components"

const ButtonsEditor = () => {
	const liveEditorStore = useLiveEditorStore()
	const buttonStyleData = liveEditorStore.getStyleByType("button")
	const generalStyleData = liveEditorStore.getStyleByType("general")

	const handleChangeFamily = (fontName) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: { "font-family": `${fontName} !important` }
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_font_family: fontName
			}
		})
	}

	const handleChangeSize = (fontSize) => {
		const fontSizeInPixel = `${fontSize}px`
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: { "font-size": `${fontSizeInPixel} !important` }
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_font_size: fontSizeInPixel
			}
		})
	}

	const handleChangeFontColorOnLivePreview = (selectedColor) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary .mdc-button__label,.button-primary .mdc-button__label:hover ",
				value: {
					color: selectedColor,
					"z-index": 1
				}
			}
		])
	}

	const handleChangeFontColor = (selectedColor) => {
		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_font_color: selectedColor
			}
		})

		handleChangeFontColorOnLivePreview(selectedColor)
	}

	const handleChangeTextTransform = (textTransform) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: { "text-transform": `${textTransform} !important` }
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_text_transform: textTransform
			}
		})
	}

	const handleChangeBold = (isBold) => {
		const fontWeight = isBold ? "bold" : 400

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: { "font-weight": `${fontWeight} !important` }
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_font_weight: fontWeight
			}
		})
	}

	const handleChangeLetterSpacing = (letterSpacing) => {
		const letterSpacingInPixels = `${letterSpacing}px`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: { "letter-spacing": `${letterSpacingInPixels} !important` }
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_letter_spacing: letterSpacingInPixels
			}
		})
	}

	const handleChangeBackgroundColorOnLivePreview = (selectedColor) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: {
					"background-color": `${selectedColor} !important`
				}
			}, {
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary:hover",
				value: {
					color: `${selectedColor} !important`
				}
			}
		])
	}

	const handleChangeBackgroundColor = (selectedColor) => {
		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_background_color: selectedColor
			}
		})

		handleChangeBackgroundColorOnLivePreview(selectedColor)
	}

	const boxShadow = buttonStyleData.properties.primary_button_box_shadow

	const getBoxShadow = typeof boxShadow === "string" ? boxShadow.split(" ")[1] ?? 0 : boxShadow

	const handleChangeShadow = (_, borderBoxShadow) => {
		const offsetY = `${borderBoxShadow}px`
		const blurRadius = `${borderBoxShadow * 5}px`
		const spreadRadius = `${borderBoxShadow}px`

		const newBoxShadow = `0px ${offsetY} ${blurRadius} ${spreadRadius} rgba(171, 171, 171, 1)`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: {
					"box-shadow": `${newBoxShadow} !important`
				}
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_box_shadow: newBoxShadow
			}
		})
	}

	const handleChangeBorderRadius = (_, borderRadius) => {
		const borderRadiusInPixel = `${borderRadius}px`
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: {
					"border-radius": `${borderRadiusInPixel} !important`
				}
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_border_radius: borderRadiusInPixel
			}
		})
	}

	const handleChangeBorderOnLivePreview = (newBorder, whichBorder) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: {
					[`border-${whichBorder}`]: `${newBorder}`
				}
			}
		])
	}

	const handleChangeBorder = (newBorder, whichBorder) => {
		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				[`primary_button_border_${whichBorder}`]: `${newBorder}`
			}
		})

		handleChangeBorderOnLivePreview(newBorder, whichBorder)
	}

	const handleChangePadding = (value, orientation) => {
		const actualPadding = buttonStyleData.properties.primary_button_padding

		const separatePadding = actualPadding.split(" ")

		const newPadding = orientation === "horizontal"
			? `${separatePadding[0] ?? "0px"} ${value}px`
			: `${value}px ${separatePadding[1] ?? "0px"}`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: ".button-primary",
				value: {
					padding: `${newPadding} !important`
				}
			}
		])

		liveEditorStore.setStyleProperties({
			styleId: buttonStyleData.id,
			properties: {
				primary_button_padding: newPadding
			}
		})
	}

	const PrimaryButtonEditor = (
		<GroupField label="Botões primários">
			<AlterFont
				label="Fonte"
				fontFamily={{
					value: buttonStyleData.properties.primary_button_font_family
								?? generalStyleData.properties.font_family,
					onChange: handleChangeFamily
				}}
				fontSize={{
					value: parseInt(buttonStyleData.properties.primary_button_font_size || 16, 10),
					optionsSize: [12, 14, 16, 18, 20, 22, 24, 26],
					onChange: handleChangeSize
				}}
				fontColor={{
					value: buttonStyleData.properties.primary_button_font_color
								|| generalStyleData.properties.gray_scale11
								|| "#000000",
					onPreviewChange: handleChangeFontColorOnLivePreview,
					onChange: handleChangeFontColor
				}}
				additionalChanges={{
					textTransform: {
						value: buttonStyleData.properties.primary_button_text_transform,
						onChange: handleChangeTextTransform
					},
					boldFont: {
						value: buttonStyleData.properties.primary_button_font_weight === "bold",
						onChange: handleChangeBold
					},
					letterSpacing: {
						value: parseInt(
							buttonStyleData
								.properties
								.primary_button_letter_spacing
									|| 16, 10
						),
						onChange: handleChangeLetterSpacing
					}
				}}

			/>

			<Divider orientation="horizontal" size="medium" />

			<ColorPicker
				label="Cor de fundo"
				enableOpacity
				value={buttonStyleData.properties.primary_button_background_color || "transparent"}
				onPreviewChange={handleChangeBackgroundColorOnLivePreview}
				onChange={handleChangeBackgroundColor}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Drawer
				label="Borda dos botões"
				icon={<SettingsIcon />}
			>
				<GroupField label="Arredondamento das bordas">
					<Slider
						icon={<BorderStyleIcon />}
						value={parseInt(buttonStyleData.properties.primary_button_border_radius || 2, 10)}
						step={2}
						min={0}
						max={50}
						onChange={handleChangeBorderRadius}
					/>
				</GroupField>

				<Divider orientation="horizontal" size="medium" />

				<GroupField label="Cima">
					<AlterBorder
						value={buttonStyleData.properties.primary_button_border_top}
						onChange={handleChangeBorder}
						onPreviewChange={handleChangeBorderOnLivePreview}
						whichBorder="top"
					/>
				</GroupField>

				<Divider orientation="horizontal" size="medium" />

				<GroupField label="Baixo">
					<AlterBorder
						value={buttonStyleData.properties.primary_button_border_bottom}
						onChange={handleChangeBorder}
						onPreviewChange={handleChangeBorderOnLivePreview}
						whichBorder="bottom"
					/>
				</GroupField>

				<Divider orientation="horizontal" size="medium" />

				<GroupField label="Esquerda">
					<AlterBorder
						value={buttonStyleData.properties.primary_button_border_left}
						onChange={handleChangeBorder}
						onPreviewChange={handleChangeBorderOnLivePreview}
						whichBorder="left"
					/>
				</GroupField>

				<Divider orientation="horizontal" size="medium" />

				<GroupField label="Direita">
					<AlterBorder
						value={buttonStyleData.properties.primary_button_border_right}
						onChange={handleChangeBorder}
						onPreviewChange={handleChangeBorderOnLivePreview}
						whichBorder="right"
					/>
				</GroupField>

			</Drawer>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Altura"
				icon={<HorizontalIcon />}
				value={parseInt(((buttonStyleData.properties.primary_button_padding).split(" "))[0] ?? 0, 10)}
				step={1}
				min={0}
				max={40}
				onChange={(_, val) => handleChangePadding(val, "vertical")}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Largura"
				icon={<HorizontalIcon style={{ transform: "rotate(90deg)" }} />}
				value={parseInt(((buttonStyleData.properties.primary_button_padding).split(" "))[1] ?? 0, 10)}
				step={1}
				min={0}
				max={40}
				onChange={(_, val) => handleChangePadding(val, "horizontal")}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Sombra"
				icon={<ShadowIcon />}
				value={parseInt(getBoxShadow, 10)}
				step={1}
				min={0}
				max={6}
				onChange={handleChangeShadow}
			/>
		</GroupField>
	)

	return (
		<Grid container spacing={2}>
			<Divider orientation="horizontal" size="small" />
			<Grid item xs={12}>
				{PrimaryButtonEditor}
			</Grid>
		</Grid>
	)
}

export default ButtonsEditor
