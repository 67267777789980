// eslint-disable-next-line
export const truncate = (originalString, nChar, addEllipsis = true) => {
	let truncatedString = originalString
	let ellipsis = ""

	if (originalString.length > nChar) {
		truncatedString = originalString.slice(0, nChar)

		if (addEllipsis) {
			ellipsis = "..."
		}
	}
	return `${truncatedString}${ellipsis}`
}

export const getOnlyNumber = (originalString) => {
	const regex = /[0-9]*/
	const result = regex.exec(originalString)
	return result[0]
}
