import { useDispatch, useSelector } from "react-redux"

import { keepNotFalsyObjectParams } from "../../utils/sanitization"

export const Types = {
	SET_USER_DATA: "customer/SET_USER_DATA",
	SET_USER_USAGE: "customer/SET_USER_USAGE",
	SET_USER_SUBSCRIPTION: "customer/SET_USER_SUBSCRIPTION",
	SUM_USER_USAGE_WORKS_COUNT_BY: "customer/SUM_USER_USAGE_WORKS_COUNT_BY",
	SUM_USER_USAGE_BANNERS_COUNT_BY: "customer/SUM_USER_USAGE_BANNERS_COUNT_BY"
}

const initialState = {
	user_data: {
		customer_id: "",
		display_name: "",
		avatar: "",
		isNewFeaturesTester: false,
		authMethod: ""
	},
	user_usage: {
		total_works: 0,
		total_banners: 0
	},
	user_subscription: {
		plan_id: null
	}
}

export const useCustomerStore = () => {
	const dispatch = useDispatch()
	const state = useSelector(globalState => globalState.customer)

	const setUserData = ({
		customer_id,
		display_name,
		avatar,
		isNewFeaturesTester,
		authMethod
	}) => dispatch({
		type: Types.SET_USER_DATA,
		payload: keepNotFalsyObjectParams({
			customer_id,
			display_name,
			avatar,
			isNewFeaturesTester,
			authMethod
		})
	})

	const setUserUsage = ({ total_works, total_banners }) => dispatch({
		type: Types.SET_USER_USAGE,
		payload: keepNotFalsyObjectParams({
			total_works,
			total_banners
		})
	})

	const setUserSubscription = ({ plan_id }) => dispatch({
		type: Types.SET_USER_SUBSCRIPTION,
		payload: keepNotFalsyObjectParams({
			plan_id
		})
	})

	const sumUserUsageWorksCountBy = (sumBy) => dispatch({
		type: Types.SUM_USER_USAGE_WORKS_COUNT_BY,
		payload: keepNotFalsyObjectParams({
			count: sumBy
		})
	})

	const sumUserUsageBannersCountBy = (sumBy) => dispatch({
		type: Types.SUM_USER_USAGE_BANNERS_COUNT_BY,
		payload: keepNotFalsyObjectParams({
			count: sumBy
		})
	})

	/**
	 * Work around to auto complete state variables
	 */
	let updatedState = { ...initialState }
	updatedState = state

	return {
		setUserData,
		setUserUsage,
		setUserSubscription,
		sumUserUsageWorksCountBy,
		sumUserUsageBannersCountBy,
		state: updatedState
	}
}

const customer = (state = initialState, action) => {
	switch (action.type) {
	case Types.SET_USER_DATA:
		return {
			...state,
			user_data: {
				...state.user_data,
				...action.payload
			}
		}
	case Types.SET_USER_USAGE:
		return {
			...state,
			user_usage: {
				...state.user_usage,
				...action.payload
			}
		}
	case Types.SET_USER_SUBSCRIPTION:
		return {
			...state,
			user_subscription: {
				...state.user_subscription,
				...action.payload
			}
		}
	case Types.SUM_USER_USAGE_WORKS_COUNT_BY:
		return {
			...state,
			user_usage: {
				...state.user_usage,
				total_works: state.user_usage.total_works + action.payload.count
			}
		}
	case Types.SUM_USER_USAGE_BANNERS_COUNT_BY:
		return {
			...state,
			user_usage: {
				...state.user_usage,
				total_banners: state.user_usage.total_banners + action.payload.count
			}
		}
	default:
		return state
	}
}

export default customer
