import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

import { isMobile } from "../../utils/checkDevice"

const useStyles = makeStyles(theme => ({
	categoriesContainer: {
		zIndex: props => (props.dragStart ? "-1" : "inherit")
	},
	workCard: {
		width: "100%"
	},
	workCover: {
		height: 0,
		paddingTop: "56.25%"
	},
	actionsButtonGroup: {
		minWidth: "auto"
	},
	workCardActionArea: {
		position: "relative"
	},
	workCardActionContainer: {
		width: "100%",
		display: "flex",
		justifyContent: "flex-end",
		position: "absolute",
		padding: theme.spacing() * 1,
		top: 0
	},
	publishedChip: {
		color: colors.greyScale[11],
		fontWeight: "bolder",
		borderRadius: "5px"
	},
	nonPublishedChip: {
		color: colors.greyScale[11],
		fontWeight: "bolder",
		backgroundColor: colors.palette.tag3,
		borderRadius: "5px"
	},
	emptyWorkContainer: {
		width: isMobile && "100%",
		maxWidth: "100%"
	},
	emptyWorkIcon: {
		fontSize: 66,
		color: colors.greyScale[5],
		margin: `0 ${theme.spacing() * 2}px`
	},
	emptyWorkText: {
		fontSize: 20,
		color: colors.greyScale[5],
		lineHeight: "27px"
	},
	fab: {
		"&:hover": {
			backgroundColor: colors.palette.primary
		}
	}
}))

export default useStyles
