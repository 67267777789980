import { makeStyles } from "@material-ui/core"

const bgColor = "#ffffff"
const borderColor = "#E3E7EB"

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%"
	},

	label: {
		marginBottom: theme.spacing(1)
	},

	fieldsContainer: {
		backgroundColor: bgColor,
		padding: theme.spacing(1),
		border: `1px solid ${borderColor}`
	}
}))

export default useStyles
