import { useDispatch, useSelector } from "react-redux"

import { keepNotFalsyObjectParams } from "../../utils/sanitization"

export const Types = {
	SET_WORK_DATA: "work/SET_WORK_DATA"
}

const initialState = {
	title: "...",
	work_id: ""
}

export const useWorkStore = () => {
	const dispatch = useDispatch()
	const state = useSelector(globalState => globalState.work)

	const setWorkData = ({ title, work_id }) => dispatch({
		type: Types.SET_WORK_DATA,
		payload: keepNotFalsyObjectParams({
			title,
			work_id
		})
	})

	/**
	 * Work around to auto complete state variables
	 */
	let updatedState = { ...initialState }
	updatedState = state

	return {
		setWorkData,
		state: updatedState
	}
}

const work = (state = initialState, action) => {
	switch (action.type) {
	case Types.SET_WORK_DATA:
		return {
			...state,
			...action.payload
		}
	default:
		return state
	}
}

export default work
