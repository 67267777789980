import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "../components"

const WorkTitleHeaderSkeleton = () => (
	<Grid container justify="flex-start">
		<Skeleton animation="wave" variant="rect" width="100%" height={58} />

		<Divider size="medium" orientation="horizontal" />

		<Skeleton animation="wave" variant="rect" width="35%" height={10} />
	</Grid>
)

export default WorkTitleHeaderSkeleton
