import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	Typography,
	IconButton,
	ThemeProvider,
	Grid,
	Link,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableRow
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { renderComponent } from "../../../../utils/node"

import useStyles from "./styles"

import theme from "../../../../styles/theme"


const WorksDescriptionsProblemsModal = (props) => {
	const { works, onClose } = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		onClose()
		setOpened(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<DialogTitle>
					Lista de trabalhos com descrição inadequada
				</DialogTitle>

				<Grid
					container
					direction="column"
					className={classes.dialogContainer}
				>

					<Grid container direction="column" className={classes.tableGrid}>
						<Typography variant="h3" className={classes.tableHeadTitle}>
							Trabalhos
						</Typography>

						<TableContainer className={classes.tableContainer}>
							<Table size="small" padding="default" className={classes.table}>
								<TableBody>
									{works.map((work, index) => (
										<TableRow>
											<TableCell
												align="left"
												style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}
											>
												{work.title}
											</TableCell>
											<TableCell
												align="right"
												style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}
											>
												<Link href={`/portfolio/work/${work.id}`} target="_blank">
													Editar trabalho
												</Link>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Grid>

				<DialogActions>
					<Button onClick={handleClose} color="primary" variant="contained">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

WorksDescriptionsProblemsModal.defaultProps = {
	onClose: () => {}
}

WorksDescriptionsProblemsModal.propTypes = {
	works: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.number.isRequired,
			title: PropTypes.string.isRequired
		})
	).isRequired,
	onClose: PropTypes.func
}

WorksDescriptionsProblemsModal.open = ({ works, onClose }) => renderComponent(
	"warning-upload-modal",
	<WorksDescriptionsProblemsModal works={works} onClose={onClose} />
)

export default WorksDescriptionsProblemsModal
