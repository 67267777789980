import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

const NewWorkBarSkeleton = () => (
	<Grid container alignItems="center">
		<Skeleton animation="wave" variant="circle" width={50} height={50} />
	</Grid>
)

export default NewWorkBarSkeleton
