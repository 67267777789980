import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		position: "fixed",
		minHeight: "100vh",
		width: "100vw",
		top: 0,
		left: 0,
		zIndex: 1000,
		backgroundColor: "#fafafa"
	},
	galleryContainer: {
		position: "relative",
		padding: theme.spacing(6, 2),
		height: "100vh",
		overflowY: "scroll",

		[theme.breakpoints.up("md")]: {
			padding: theme.spacing(8, 6)
		}
	},
	closeButton: {
		position: "fixed",
		top: theme.spacing(0.5),
		right: theme.spacing(0.5),

		[theme.breakpoints.up("md")]: {
			right: theme.spacing(2.5)
		}
	},
	menuContainer: {
		position: "relative",
		width: "100%"
	},
	menu: {
		position: "relative",

		[theme.breakpoints.up("md")]: {
			width: "auto",
			position: "fixed"
		}
	},
	title: {
		textTransform: "uppercase"
	},
	cardContainer: {
		padding: theme.spacing(3, 4),

		"&:hover": {
			backgroundColor: "white",
			boxShadow: "0 4px 8px rgba(0,0,0,0.3)",
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.shortest
			}),

			"& $cardFooterButtonsContainer": {
				opacity: 1
			}
		}
	},
	cardContainerActive: {
		backgroundColor: "white",
		boxShadow: "0 4px 4px rgba(0,0,0,0.2)",
		border: `6px solid ${colors.palette.confirm}`,
		borderRadius: theme.spacing(0.5)
	},
	card: {
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		backgroundColor: "transparent"
	},
	cardMedia: {
		height: 0,
		paddingTop: "110%",
		backgroundPosition: "top center",
		backgroundSize: "contain"
	},
	cardFooterButtonsContainer: {
		marginTop: theme.spacing(2),
		opacity: 0,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	cardSelectThisButton: {
		backgroundColor: theme.palette.info.main,
		color: "#ffffff",

		"&:hover": {
			backgroundColor: theme.palette.info.dark
		}

	},

	categoryListItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		fontWeight: "bold"
	},
	subcategoryListItem: {
		position: "relative",
		fontWeight: "normal"
	},
	subcategoryListItemActive: {
		"&::before": {
			content: "\"\"",
			position: "absolute",
			height: "100%",
			width: 4,
			backgroundColor: colors.palette.confirm,
			top: 0,
			left: 0
		}
	}
}))

export default useStyles
