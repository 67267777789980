import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

import transparentPlaceholder from "../../../../assets/transparent_placeholder.png"

const useStyles = makeStyles((theme) => ({
	backgroundBoxContainer: {
		position: "relative",
		zIndex: 0
	},
	backgroundBox: {
		position: "relative",
		width: "100%",
		height: 0,
		paddingTop: "25%",
		paddingBottom: "25%",
		borderRadius: theme.spacing(0.5),
		border: `1px dashed ${colors.greyScale[7]}`,
		backgroundSize: "contain !important", // override inline backgroundbackground
		overflow: "hidden",

		"&::before": {
			content: "\"\"",
			position: "absolute",
			width: "100%",
			height: "100%",
			top: 0,
			right: 0,
			backgroundImage: `url(${transparentPlaceholder})`,
			zIndex: -1
		}
	}
}))

export default useStyles
