import React from "react"
import ReactDOM from "react-dom"
import { ThemeProvider } from "@material-ui/core"
import { Provider as GlobalStateProvider } from "react-redux"
import * as Sentry from "@sentry/react"

import App from "./App"

import theme from "./styles/theme"
import store from "./store"

import "./styles/global.scss"

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN
})

ReactDOM.render(
	<React.StrictMode>
		<GlobalStateProvider store={store}>
			<ThemeProvider theme={theme}>
				<App />
			</ThemeProvider>
		</GlobalStateProvider>
	</React.StrictMode>,
	document.getElementById("root")
)
