import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(() => ({
	underbarColor: {
		"& path": {
			"&:last-child": {
				fill: ({ colorSelect }) => `${colorSelect ?? "#ffffff"}`,
				fillOpacity: 1
			}
		}
	},
	bucketColor: {
		width: "100%",
		color: colors.greyScale[5]
	}
}))

export default useStyles
