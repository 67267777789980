import React, { useState } from "react"
import {
	Grid,
	Typography,
	FormControlLabel,
	Switch,
	Button,
	Tooltip
} from "@material-ui/core"

import { HelpOutline as QuestionIcon } from "@material-ui/icons"

import api from "../../services/api"

import {
	Portlet,
	PremiumChip,
	Divider,
	Loading,
	InfoModal,
	Notification
} from "../../components"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import useCustomStyles from "../../styles/custom"

import { getStatusCode } from "../../utils/response"
import { isMobile } from "../../utils/checkDevice"
import { siteProPlansUrl } from "../../utils/plan"

import GeneralSkeleton from "../../skeletons/General"
import useStyles from "./styles"

const General = () => {
	const { triggerUndeterminedValidation } = useValidation()

	const [generalSettings, setGeneralSettings] = useState({
		brandingPicsize: {
			id: null,
			branding_picsize: true
		},
		whatsappChat: {
			id: null,
			whatsapp_chat: false
		},
		antiCopy: {
			id: null,
			anti_copy: false
		}
	})

	const [
		loadingGetPicsizeBrandingSettings,
		setLoadingGetPicsizeBrandingSettings
	] = useState(true)
	const [
		loadingGetWhatsappChatSettings,
		setLoadingGetWhatsappChatSettings
	] = useState(true)
	const [loadingGetAntiCopySettings, setLoadingGetAntiCopySettings] = useState(
		true
	)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const getBrandingPicsizeData = async () => {
		try {
			const { data } = await api.get("/site/settings/branding_picsize")

			setGeneralSettings((lastState) => ({
				...lastState,
				brandingPicsize: {
					id: data.id,
					branding_picsize: data.branding_picsize
				}
			}))

			setLoadingGetPicsizeBrandingSettings(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const getWhatsappChatData = async () => {
		try {
			const { data } = await api.get("/site/settings/whatsapp_chat")

			setGeneralSettings((lastState) => ({
				...lastState,
				whatsappChat: {
					id: data.id,
					whatsapp_chat: data.whatsapp_chat
				}
			}))

			setLoadingGetWhatsappChatSettings(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const getAntiCopyData = async () => {
		try {
			const { data } = await api.get("/site/settings/anti_copy")

			setGeneralSettings((lastState) => ({
				...lastState,
				antiCopy: {
					id: data.id,
					anti_copy: data.anti_copy
				}
			}))

			setLoadingGetAntiCopySettings(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const changeGeneralData = async (settings, key, value) => {
		const backup = { ...generalSettings }

		setGeneralSettings((lastState) => ({
			...lastState,
			[settings]: {
				...lastState[settings],
				[key]: value
			}
		}))

		try {
			await api.put(`/site/settings/${generalSettings[settings].id}/${key}`, {
				[key]: value
			})

			Notification.success({ message: "Configuração alterada com sucesso!" })
		} catch (error) {
			if (getStatusCode(error) === 402) {
				InfoModal.warning({
					title: "Ação negada",
					description: "Seu plano atual não permite utilizar essa função.",
					customConfirmButtons: [
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							VER PLANOS
						</Button>
					],
					cancelButtonText: "FECHAR"
				})
			} else {
				triggerUndeterminedValidation(error)
			}

			setGeneralSettings(backup)
		}
	}

	const getGeneralSettings = async () => {
		await Promise.all([
			getBrandingPicsizeData(),
			getWhatsappChatData(),
			getAntiCopyData()
		])
	}

	useDidMount(() => {
		getGeneralSettings()
	})

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Portlet>
					<Loading
						loading={loadingGetPicsizeBrandingSettings}
						customLoadingElement={<GeneralSkeleton />}
					>
						<Grid container>
							<Typography variant="h2">
								MARCA DA PICSIZE
								<PremiumChip disableGutters={isMobile} />
							</Typography>

							<Divider size="large" orientation="horizontal" />

							<FormControlLabel
								control={(
									<Switch
										checked={generalSettings.brandingPicsize.branding_picsize}
										onChange={({ target }) => changeGeneralData("brandingPicsize", target.name, target.checked)}
										name="branding_picsize"
										color="secondary"
									/>
								)}
								label="Mostrar marca da Picsize no meu site."
							/>
						</Grid>
					</Loading>
				</Portlet>
			</Grid>

			{
				(generalSettings.whatsappChat?.whatsapp_chat || loadingGetWhatsappChatSettings)
				&& (
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Portlet>
							<Loading
								loading={loadingGetWhatsappChatSettings}
								customLoadingElement={<GeneralSkeleton />}
							>
								<Grid container>
									<Typography variant="h2">
										ÍCONE DO WHATSAPP NO RODAPÉ DO SITE
										<PremiumChip disableGutters={isMobile} />
									</Typography>

									<Divider size="large" orientation="horizontal" />

									<FormControlLabel
										control={(
											<Switch
												checked={generalSettings.whatsappChat.whatsapp_chat}
												onChange={({ target }) => changeGeneralData("whatsappChat", target.name, target.checked)}
												name="whatsapp_chat"
												color="secondary"
											/>
										)}
										label="Mostrar ícone de contato via whatsapp no rodapé do site."
									/>
								</Grid>
							</Loading>
						</Portlet>
					</Grid>
				)
			}

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Portlet>
					<Loading
						loading={loadingGetAntiCopySettings}
						customLoadingElement={<GeneralSkeleton />}
					>
						<Grid container>
							<Grid item>
								<Typography variant="h2">
									PROTEÇÃO CONTRA CÓPIA
									<PremiumChip disableGutters={isMobile} />
								</Typography>
							</Grid>

							<Divider size="large" orientation="horizontal" />
							<Grid item>
								<FormControlLabel
									control={(
										<Switch
											checked={generalSettings.antiCopy.anti_copy}
											onChange={({ target }) => changeGeneralData("antiCopy", target.name, target.checked)}
											name="anti_copy"
											color="secondary"
										/>
									)}
									label={(
										<Grid spacing={1} container>
											<Grid item>Proteger minhas fotos contra cópia.</Grid>

											<Grid item>
												<Tooltip
													title="Esse recurso bloqueia o clique com botão direito do mouse, impedindo que a foto seja copiada"
													placement="top"
													arrow
												>
													<QuestionIcon className={classes.questionIcon} />
												</Tooltip>
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</Loading>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default General
