import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	IconButton,
	Card,
	CardMedia,
	Grid,
	Typography,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import colors from "./colors"


import useStyles from "./styles"

const ColorGallery = ({ onOk, onClose, open }) => {
	const [selectedColor, setSelectedColor] = useState(null)

	const classes = useStyles()

	const handleSelectColor = (color) => {
		setSelectedColor(color)
	}

	const handleClickOk = () => {
		onOk(selectedColor.value)
		onClose()
	}

	return (
		<Dialog fullScreen open={open} className={classes.dialog}>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography className={classes.title}>
						Galeria de Cores
					</Typography>

					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid container spacing={2} alignItems="stretch">
					{colors.map((color) => {
						const className = [classes.card]
						if (color?.id === selectedColor?.id) {
							className.push(classes.selectedCard)
						}

						return (
							<Grid item xs={6} sm={4} md={3} lg={2} key={color.id}>
								<Card className={className} onClick={() => handleSelectColor(color)}>
									<CardMedia
										style={{ background: `${color.value}` }}
										className={classes.cardMedia}
									/>
								</Card>
							</Grid>
						)
					})}
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button color="primary" variant="contained" onClick={handleClickOk}>OK</Button>
			</DialogActions>
		</Dialog>
	)
}

ColorGallery.propTypes = {
	onOk: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool
}

ColorGallery.defaultProps = {
	onOk: () => { },
	onClose: () => { },
	open: false
}

export default ColorGallery
