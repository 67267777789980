import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"

import { GroupField, TextInput } from "../../../../components"
import ChangeLayout from "../../../../components/ChangeLayout"
import { Divider } from "../../../../../../components"

import SectionDefaultDesign from "../SectionDefaultDesign"
import { useLiveEditorStore } from "../../../../../../store"


const Testimonial1Editor = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const sectionData = liveEditorStore.getSectionById(sectionId)
	const { currentPage } = liveEditorStore.state

	const handleChangeText = (attrSelector, value, content) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content
		})
	}

	const handleChangeTitleText = (event) => {
		const { value } = event.target
		handleChangeText("[le-title]", value, { section_title_text: value })
	}

	const handleChangeSubtitleText = (event) => {
		const { value } = event.target
		handleChangeText("[le-subtitle]", value, { section_subtitle_text: value })
	}

	const handleChangeWorkLinkText = (event) => {
		const { value } = event.target
		handleChangeText("[le-work-link]", value, { card_work_link_text: value })
	}

	const handleChangeKeepReadingText = (event) => {
		const { value } = event.target
		handleChangeText("[le-keep-reading]", value, { card_keep_reading_button_text: value })
	}

	const handleChangeShowMoreText = (event) => {
		const { value } = event.target
		handleChangeText("[le-show-more]", value, { more_button_text: value })
	}

	const handleUpdateTitlesDivider = useRef(() => { })
	handleUpdateTitlesDivider.current = () => {
		const hasSomeTitle = sectionData.properties.display_title
			|| sectionData.properties.display_subtitle

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} [le-titles-divider]`,
				value: { display: hasSomeTitle ? "block" : "none" }
			}
		])
	}

	const handleChangeTextVisibility = (attrSelector, styleObject, properties) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value: styleObject
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties
		})
	}

	const handleChangeTitleTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_title: checked }

		handleChangeTextVisibility("[le-title-container]", styleObject, properties)
	}

	const handleChangeSubtitleTextVisibility = (event) => {
		const { checked } = event.target
		const styleObject = { display: checked ? "block" : "none" }
		const properties = { display_subtitle: checked }

		handleChangeTextVisibility("[le-subtitle-container]", styleObject, properties)
	}

	useEffect(() => {
		handleUpdateTitlesDivider.current()
	}, [
		sectionData.properties.display_title,
		sectionData.properties.display_subtitle
	])

	return (
		<>
			<GroupField label="Conteúdo">
				<TextInput
					label="Título"
					value={sectionData?.content?.section_title_text}
					onChange={handleChangeTitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_title,
						onChange: handleChangeTitleTextVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Subtítulo"
					value={sectionData?.content?.section_subtitle_text}
					onChange={handleChangeSubtitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_subtitle,
						onChange: handleChangeSubtitleTextVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Texto do link para o trabalho"
					value={sectionData?.content?.card_work_link_text}
					onChange={handleChangeWorkLinkText}
				/>

				<Divider orientation="horizontal" size="medium" />

				<TextInput
					label="Texto do link para continuar lendo"
					value={sectionData?.content?.card_keep_reading_button_text}
					onChange={handleChangeKeepReadingText}
				/>

				{currentPage.type !== "testimonials" && (
					<>
						<Divider orientation="horizontal" size="medium" />

						<TextInput
							label="Texto do botão de mais depoimentos"
							value={sectionData?.content?.more_button_text}
							onChange={handleChangeShowMoreText}
						/>
					</>
				)}
			</GroupField>

			<Divider orientation="horizontal" size="medium" />

			<ChangeLayout pageSectionId={sectionId} />

			<Divider orientation="horizontal" size="medium" />

			<SectionDefaultDesign sectionId={sectionId} />
		</>
	)
}

Testimonial1Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default Testimonial1Editor
