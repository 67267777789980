import { useDispatch, useSelector } from "react-redux"

import { keepNotFalsyObjectParams } from "../../utils/sanitization"

export const Types = {
	SET_SITE_DATA: "site/SET_SITE_DATA"
}

const initialState = {
	url: "",
	theme_url: "",
	picsize_site_url: ""
}

export const useSiteStore = () => {
	const dispatch = useDispatch()
	const state = useSelector(globalState => globalState.site)

	const setSiteData = ({ url, theme_url, picsize_site_url }) => dispatch({
		type: Types.SET_SITE_DATA,
		payload: keepNotFalsyObjectParams({
			url,
			theme_url,
			picsize_site_url
		})
	})

	/**
	 * Work around to auto complete state variables
	 */
	let updatedState = { ...initialState }
	updatedState = state

	return {
		setSiteData,
		state: updatedState
	}
}

const site = (state = initialState, action) => {
	switch (action.type) {
	case Types.SET_SITE_DATA:
		return {
			...state,
			...action.payload
		}
	default:
		return state
	}
}

export default site
