import React, { useState } from "react"

import { Grid, Typography, Button, Link } from "@material-ui/core"
import { Divider, PopConfirm } from "../../../../../components"
import ThemeGallery from "../../../../../components/ThemeGallerySiteCp"

import api from "../../../../../services/api"
import useValidation from "../../../../../hooks/useValidation"

import useLiveEditorGlobalStyles from "../../../liveEditorGlobalStyles"

import { useSiteStore } from "../../../../../store"

function ThemeEditor() {
	const liveEditorClasses = useLiveEditorGlobalStyles()
	const siteStore = useSiteStore()

	const [themeGalleryOpened, setThemeGalleryOpened] = useState(false)

	const { triggerUndeterminedValidation } = useValidation()


	const resetTheme = async () => {
		try {
			await api.put("/site/theme/reset-active-theme")

			document.location.reload()
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleResetTheme = async () => {
		PopConfirm.open({
			title: "Tem certeza?",
			description: "Redefinir o tema é uma ação irreversível.",
			confirmButtonText: "Redefinir",
			onConfirm: () => {
				resetTheme()
			}
		})
	}

	const handleReloadLiveEditor = () => {
		document.location.reload()
	}

	const handleOpenThemeGallery = () => {
		setThemeGalleryOpened(true)
	}

	const handleCloseThemeGallery = (changedTheme) => {
		if (changedTheme) {
			handleReloadLiveEditor()
		}

		setThemeGalleryOpened(false)
	}


	return (
		<>
			<Grid container spacing={2}>
				<Divider orientation="horizontal" size="medium" />

				{siteStore?.state?.url !== siteStore?.state?.theme_url && (
					<Grid item xs={12}>
						<Grid container justify="center">
							<Button color="secondary" onClick={handleResetTheme} variant="contained">
								Redefinir Tema
							</Button>
						</Grid>
					</Grid>
				)}

				<Grid item xs={12}>
					<Grid container justify="center">
						<Link component="button" onClick={handleOpenThemeGallery}>
							<Typography className={liveEditorClasses.linkTypographic} variant="h2">
								Alterar tema
							</Typography>
						</Link>
					</Grid>
				</Grid>
			</Grid>

			{themeGalleryOpened && (<ThemeGallery onClose={handleCloseThemeGallery} />)}
		</>
	)
}

export default ThemeEditor
