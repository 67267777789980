import React from "react"
import PropTypes from "prop-types"

import { GroupField, TextInput } from "../../../../components"

import ChangeLayout from "../../../../components/ChangeLayout"
import { Divider } from "../../../../../../components"


import SectionDefaultDesign from "../SectionDefaultDesign"

import { useLiveEditorStore } from "../../../../../../store"

const Contact1 = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const sectionData = liveEditorStore.getSectionById(sectionId)

	const handleChangeText = (attrSelector, value, content) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} ${attrSelector}`,
				value
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content
		})
	}

	const handleChangeDescriptionText = (event) => {
		const { value } = event.target
		handleChangeText("[le-description]", value, { description: value })
	}

	return (
		<>
			<GroupField label="Conteúdo">
				<TextInput
					label="Descrição"
					multiline
					value={sectionData?.content?.description}
					onChange={handleChangeDescriptionText}
				/>
			</GroupField>

			<Divider orientation="horizontal" size="medium" />

			<ChangeLayout pageSectionId={sectionId} />

			<Divider orientation="horizontal" size="medium" />

			<SectionDefaultDesign sectionId={sectionId} />
		</>
	)
}

Contact1.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default Contact1
