import React from "react"

import { FormControlLabel, Checkbox as MuiCheckbox } from "@material-ui/core"

import PropTypes from "prop-types"
import LiveEditorTypography from "../LiveEditorTypography"

const Checkbox = ({ label, value, onChange }) => (
	<FormControlLabel
		control={(
			<MuiCheckbox
				checked={value}
				onChange={onChange}
			/>
		)}
		label={(
			<LiveEditorTypography type="fieldDescription">
				{label}
			</LiveEditorTypography>
		)}
	/>
)

Checkbox.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	value: PropTypes.bool
}

Checkbox.defaultProps = {
	label: "",
	value: false
}

export default Checkbox
