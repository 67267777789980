import React from "react"

import {
	Grid,
	Typography,
	Button,
	Chip
} from "@material-ui/core"
import {
	WhatsApp as WhatsAppIcon
} from "@material-ui/icons"
import { Portlet, Divider, CustomContainer } from "../../components"

import useStyles from "./styles"

import { supportContactInfo } from "../../utils/contact"

import illustration from "../../assets/page_maker_coming_soon.svg"

const PageMaker = () => {
	const classes = useStyles()

	return (
		<CustomContainer maxWidth="1080px">
			<Divider size="xxlarge" orientation="horizontal" />
			<Portlet>
				<Grid container spacing={2} justify="center">
					<Grid item xs={12}>
						<Chip
							label="EM BREVE"
							className={classes.comingSoonChip}
						/>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={7}>
							<Grid item xs={12} md={6}>
								<img className={classes.illustration} src={illustration} alt="page maker illustration" />
							</Grid>
							<Grid item xs={12} md={6}>
								<Typography variant="h5">
									{/* eslint-disable-next-line */}
									Em breve você poderá criar suas próprias páginas do zero ou a partir de páginas pré-definidas, utilizando nosso
									{" "}
									<b>criador de páginas</b>
									.
								</Typography>

								<Divider size="xlarge" orientation="horizontal" />

								<Typography color="secondary">
									{/* eslint-disable-next-line */}
									Enquanto não está diponível, entre em contato com nosso suporte através do Whatsapp e avaliaremos uma solução para sua necessidade.
								</Typography>

								<Divider size="xlarge" orientation="horizontal" />

								<Button
									startIcon={<WhatsAppIcon />}
									variant="contained"
									color="secondary"
									className={classes.supportButton}
									href={supportContactInfo().whatsappLink}
									target="_blank"
								>
									FALAR COM O SUPORTE
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Portlet>
		</CustomContainer>
	)
}

export default PageMaker
