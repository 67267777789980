import React, { useState } from "react"
import { useParams } from "react-router-dom"
import PropTypes from "prop-types"
import {
	Grid,
	Button,
	Container,
	Fade,
	CircularProgress,
	Typography
} from "@material-ui/core"

import {
	CloudUploadOutlined as CloudUploadOutlinedIcon
} from "@material-ui/icons"

import { Dropzone, Divider, InfoModal } from "../../../../components"

import api from "../../../../services/api"
import queue from "../../../../services/queue"

import { getPercentage } from "../../../../utils/sanitization"

import useStyles, { Progress } from "./styles"

import pictureCollection from "../../../../assets/picture_collection.png"

import WarningUploadModal from "../WarningUploadModal"

const PicturesUpload = (props) => {
	const { pictures, onUploadFinish } = props

	const params = useParams()
	const classes = useStyles()

	const [pictureUploadStatus, setPictureUploadStatus] = useState("waiting")
	const [totalPictures, setTotalPictures] = useState(0)
	const [totalSize, setTotalSize] = useState(0)
	const [picturesPortionSent, setPicturesPortionSent] = useState(0)
	const [picturesSent, setPicturesSent] = useState(0)

	const { work_id } = params

	const uploadPictures = async (files) => {
		setTotalPictures(0)
		setTotalSize(0)
		setPicturesSent(0)
		setPicturesPortionSent(0)

		const nonDuplicatedPictures = []
		let duplicatedCount = 0
		const allPictures = files
		const picturesMap = {}

		pictures.forEach(picture => {
			picturesMap[picture.title] = picture.title
		})

		setPictureUploadStatus("loading")

		// eslint-disable-next-line
		for (let i = 0; i < files.length; i++) {
			if (picturesMap[files[i].name]) {
				allPictures[i].options = {
					...(files[0].options || {}),
					overwrite: true
				}

				// eslint-disable-next-line
				duplicatedCount++
			} else {
				nonDuplicatedPictures.push(files[i])
			}
		}

		let requestedFiles = []
		let response = true

		if (duplicatedCount) {
			response = await InfoModal.warning({
				title: "Duplicadas",
				cancelButtonText: "Cancelar envio",
				description: (
					<>
						Foram encontradas
						{" "}
						{duplicatedCount}
						{" "}
						fotos duplicadas.
						<br />
						<b>O que deseja fazer?</b>
					</>
				),
				customConfirmButtons: ([
					<Button
						value="overwrite"
						variant="contained"
					>
						Substituir duplicadas
					</Button>,
					<Button
						value="ignore"
						variant="contained"
					>
						Ignorar duplicadas
					</Button>
				])
			})
		}

		let expectedPicturesCount

		if (response === "overwrite") {
			requestedFiles = allPictures
			expectedPicturesCount = nonDuplicatedPictures.length
		} else if (response === "ignore") {
			requestedFiles = nonDuplicatedPictures
			expectedPicturesCount = nonDuplicatedPictures.length
		} else if (response === false) {
			return setPictureUploadStatus("waiting")
		} else {
			requestedFiles = files
			expectedPicturesCount = files.length
		}

		if (requestedFiles.length === 0) {
			setPictureUploadStatus("waiting")

			return InfoModal.warning({
				title: "Sem fotos para enviar",
				description: "Não foram encontradas novas fotos para serem enviadas.",
				showCancelButton: false,
				customConfirmButtons: ([
					<Button
						color="primary"
						variant="contained"
					>
						Ok
					</Button>
				])
			})
		}

		const checkSpaceResponse = await api.post(`/site/portfolio/picture/work/${work_id}/check-space`, {
			requested_pictures_count: expectedPicturesCount
		})

		const { amount } = checkSpaceResponse.data

		const MAX_WORK_PICTURES = amount.max
		const requestedPicturesCount = amount.requested
		const maxPicturesCount = amount.current_available
		const availablePicturesCount = amount.after_available
		const currentPicturesCount = amount.current_pictures_count

		if (amount.block_action) {
			setPictureUploadStatus("waiting")

			return InfoModal.warning({
				title: "Envio com quantidade de fotos acima do disponível",
				description: (
					<>
						Seu portfólio tem um limite máximo de
						<b>
							{` ${MAX_WORK_PICTURES} ${MAX_WORK_PICTURES === 1 ? "foto" : "fotos"}`}
						</b>
						.
						<br />
						<br />
						Atualmente você possui
						<b>
							{` ${currentPicturesCount} ${currentPicturesCount === 1 ? "foto" : "fotos"} `}
						</b>
						no seu portfólio.
						<br />
						Seu envio possui um total de
						<b>
							{` ${requestedPicturesCount} ${requestedPicturesCount === 1 ? "foto" : "fotos"}`}
						</b>
						.
						<br />
						Ou seja, você está ultrapassando em
						<b>
							{` ${Math.abs(availablePicturesCount)} ${Math.abs(availablePicturesCount) === 1 ? "foto" : "fotos"} `}
						</b>
						o limite total.
						<br />
						<br />
						Espaço disponível
						<b>
							{` ${maxPicturesCount} ${maxPicturesCount === 1 ? "foto" : "fotos"}`}
						</b>
						.
					</>
				),
				showCancelButton: false,
				customConfirmButtons: ([
					<Button
						color="primary"
						variant="contained"
					>
						Ok
					</Button>
				])
			})
		}

		const pictures_list = []
		const fileList = []
		let totalPicturesSize = 0
		const lastPictureProgress = {}
		const payload_info = {
			failedList: [],
			succededList: []
		}

		const workHasCover = !!pictures.find(picture => picture.is_cover)

		// eslint-disable-next-line
    for (let i = 0; i < requestedFiles.length; i++) {
			pictures_list.push({
				picture_title: requestedFiles[i].name,
				size: requestedFiles[i].size,
				overwrite: requestedFiles[i].options && requestedFiles[i].options.overwrite,
				is_cover: !workHasCover && +i === 0
			})

			requestedFiles[i].index = i
			fileList.push(requestedFiles[i])
			totalPicturesSize += requestedFiles[i].size / 1024 / 1024
		}

		setTotalPictures(fileList.length)
		setTotalSize(totalPicturesSize)

		const pageTitle = document.title

		/**
		 * Set the initial tracking for upload progress, so,
		 * since it started right now, the current progress is 0
		 */
		document.title = `(0%) ${pageTitle}`

		/**
		 * Creates a payload with all the pictures expected to be uploaded and
		 * receives a hash that will be used to tag every picture of this payload.
		 */
		const { data } = await api.post(`/site/portfolio/payload/work/${work_id}`, {
			pictures_list
		})

		const { payload_id, picture_ids } = data

		// setPayloadId(payload_id);

		// window.onbeforeunload = async () => {
		// 	return await Api.put("/pictures/event/customer-closed-gallery-during-upload", {
		// 		collection_id,
		// 		payload_id
		// 	});
		// }

		const worker = async (file) => {
			const index = file.metadata.jobId
			lastPictureProgress[index] = 0

			const formData = new FormData()
			formData.append("picture", file)
			formData.append("options", JSON.stringify(file.options || {}))

			try {
				await api.post(
					`/site/portfolio/picture/${picture_ids[file.name]}/work/${work_id}/payload/${payload_id}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/data"
						},
						onUploadProgress: (progress) => {
							let currentProgress = progress.loaded / progress.total

							/**
							 * If the current progress is equal one, it means the loaded
							 * progress reached the total expected and so it means
							 * a picture has been uploaded
							 */
							if (currentProgress === 1) {
								// eslint-disable-next-line
								setPicturesSent(total => {
									document.title = `(${getPercentage(total, fileList.length)}%) ${pageTitle}`

									return total + 1
								})
							}

							/**
							 * Since the axios give us the total value of upload,
							 * we have to do it minus the last progress in order
							 * to get the uploaded percentage
							 */
							currentProgress -= lastPictureProgress[index]
							lastPictureProgress[index] = progress.loaded / progress.total

							setPicturesPortionSent(total => total + currentProgress)
						}
					}
				)

				payload_info.succededList.push({ title: file.name, error: null })
			} catch (error) {
				const message = error && error.message

				/**
				 * In case the server is down, we reject the upload an try to send it again
				 * till it reaches the maximum number of retries
				 */
				if (message === "Network Error" && file.metadata.retries < queue.info().retries) {
					return Promise.reject(error)
				}

				/**
				 * If there"s an error provided by back-end we take it
				 */
				// eslint-disable-next-line
				let errorMessage = error && error.response && error.response.data && error.response.data.message
				let errorCode = error && error.response && error.response.data && error.response.data.error

				if (!errorCode) {
					errorCode = "ConnectionBroken"
				}

				if (!errorMessage) {
					errorMessage = "Erro inesperado"
				}

				payload_info.failedList.push({ title: file.name, error: errorMessage })

				await api.put(`/site/portfolio/picture/${picture_ids[file.name]}/error`, { error: errorCode }).catch(() => {})
			}
		}

		queue.set(worker, {
			concurrency: 5,
			retries: 1,
			delay: 5000
		})

		queue.add(fileList)

		setPictureUploadStatus("uploading")
		await queue.process()

		/**
		 * Set back over the initial page title, since we were
		 * tracking the upload progress on page title
		 */
		document.title = pageTitle
		window.onbeforeunload = () => undefined

		api.put("/site/portfolio/picture/event/upload-completed", { work_id })
		api.put(`/site/portfolio/payload/${payload_id}/finish`)

		if (!payload_info.failedList.length) {
			InfoModal.success({
				title: "Maravilha!",
				showCancelButton: false,
				description: "As fotos subiram com sucesso!",
				customConfirmButtons: ([
					<Button color="inherit">
						Fechar
					</Button>
				])
			})
		} else {
			WarningUploadModal.open({ payloadList: payload_info })
		}

		setPictureUploadStatus("waiting")

		await onUploadFinish()
	}

	return (
		<Grid container justify="center" alignItems="center">
			{pictureUploadStatus === "waiting" && (
				<Fade in={pictureUploadStatus === "waiting"}>
					<Dropzone onDrop={uploadPictures}>
						<Container disableGutters className={classes.dropzoneContainer}>
							<img src={pictureCollection} alt="Pictures collection" />

							<Divider size="medium" orientation="horizontal" />

							<Button
								color="primary"
								variant="contained"
								endIcon={<CloudUploadOutlinedIcon />}
							>
								Subir fotos
							</Button>
						</Container>
					</Dropzone>
				</Fade>
			)}

			{pictureUploadStatus === "loading" && (
				<Fade in={pictureUploadStatus === "loading"}>
					<CircularProgress color="secondary" />
				</Fade>
			)}

			{pictureUploadStatus === "uploading" && (
				<Fade in={pictureUploadStatus === "uploading"}>
					<Grid container direction="column" alignItems="center">
						<Progress
							variant="determinate"
							value={(picturesPortionSent / totalPictures) * 100}
						/>

						<Divider size="medium" orientation="horizontal" />

						<Typography variant="body1" className={classes.progressTitle}>
							Subindo fotos (
							{picturesSent}
							{" / "}
							{totalPictures}
							)
						</Typography>

						<Typography variant="body2" className={classes.progressSubTitle}>
							{((picturesPortionSent / totalPictures) * totalSize).toFixed(2)}
							{"mb / "}
							{totalSize.toFixed(2)}
							mb
						</Typography>
					</Grid>
				</Fade>
			)}
		</Grid>
	)
}

PicturesUpload.defaultProps = {
	pictures: [],
	onUploadFinish: () => {}
}

PicturesUpload.propTypes = {
	pictures: PropTypes.arrayOf(PropTypes.shape({})),
	onUploadFinish: PropTypes.func
}

export default PicturesUpload
