// eslint-disable-next-line
export const getHeightInPercentageFromProportion = (proportion)=>{
	const [width, height] = proportion.trim().split(":")

	const heightInPercentage = ((+height / +width) * 100).toFixed(2)
	return heightInPercentage
}

export const convertObjectURLInFormData = async (url) => {
	const blob = await fetch(url).then(r => r.blob())

	const formData = new FormData()

	formData.append("image", blob, `${blob.name}`)

	return formData
}
