import { makeStyles } from "@material-ui/core"

import { isMobile } from "../../utils/checkDevice"

import colors from "../../styles/colors"

const DRAWER_WIDTH = 260

const useStyles = makeStyles((theme) => ({
	drawer: {
		flexShrink: 0,
		whiteSpace: "nowrap",
		width: "auto",
		height: "100%",
		position: "fixed",
		zIndex: 999
	},
	paper: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		overflowX: ({ drawerFullWidth }) => `${!drawerFullWidth && "hidden"}`,
		width: ({ drawerFullWidth }) => `${drawerFullWidth ? DRAWER_WIDTH : 60}px`,
		height: "100%",
		backgroundColor: "#444444",
		position: !isMobile && "relative",
		"&::-webkit-scrollbar": {
			width: "10px"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(255, 255, 255, 0)",
			borderRadius: "100px"
		},
		"&:hover::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(255, 255, 255, 0.25)"
		}
	},
	backIcon: {
		color: "#FFF",
		opacity: 0.7,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		}),
		transform: ({ drawerFullWidth }) => `rotate(${drawerFullWidth ? 0 : 180}deg) translateX(${drawerFullWidth ? 0 : 5}px)`
	},
	solutionsContainer: {
		borderRadius: "6px",
		flexDirection: "row",
		justifyContent: "space-between",
		display: "flex",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		padding: ({ drawerFullWidth }) => `${drawerFullWidth ? "10px 15px" : "10px 1px"}`,
		backgroundColor: ({ drawerFullWidth }) => `${drawerFullWidth ? "#666666" : "transparent"}`
	},
	menuIcon: {
		position: "absolute",
		top: "5px",
		left: "5px",
		zIndex: 999
	},
	solutionMenuHeader: {
		backgroundColor: colors.greyScale[10],
		borderTopRightRadius: 5,
		borderTopLeftRadius: 5,
		display: "flex",
		justifyContent: "center"
	},
	listItemDefault: {
		color: colors.greyScale[11],
		minWidth: theme.spacing() * 6
	},
	listItemText: {
		color: colors.greyScale[11],
		minWidth: "auto"
	},
	newFeatureItem: {
		fontWeight: "bold",
		cursor: "pointer"
	}
}))

export default useStyles
