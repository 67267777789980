import React from "react"
import PropTypes from "prop-types"

import {
	Grid,
	MenuItem
} from "@material-ui/core"
import ColorPicker from "../ColorPicker"
import Select from "../Select"
import { splitStringOnBlankSpaces } from "../../../../utils/sanitization"
import LiveEditorTypography from "../LiveEditorTypography"

const sizeOptions = [
	"0px",
	"1px",
	"2px",
	"3px",
	"4px",
	"5px",
	"6px",
	"7px",
	"8px",
	"10px",
	"12px",
	"14px",
	"16px"
]

const styleOptions = [
	{
		id: 0,
		styleValue: "dotted",
		styleName: "Pontilhada"
	},
	{
		id: 1,
		styleValue: "dashed",
		styleName: "Tracejada"
	},
	{
		id: 2,
		styleValue: "solid",
		styleName: "Sólida"
	}
]

const AlterBorder = (props) => {
	const {
		label,
		value,
		onChange,
		onPreviewChange,
		whichBorder
	} = props

	const splittedData = splitStringOnBlankSpaces(value)

	const defaultBorderValues = {
		borderSize: splittedData[0] || "1px",
		borderStyle: splittedData[1] || "solid",
		borderColor: splittedData[2] || "#393737"
	}

	const getNewBorder = (newValue) => {
		const borderSize = newValue.borderSize || defaultBorderValues.borderSize
		const borderStyle = newValue.borderStyle || defaultBorderValues.borderStyle
		const borderColor = newValue.borderColor || defaultBorderValues.borderColor
		const newBorder = `${borderSize} ${borderStyle} ${borderColor}`
		return newBorder
	}

	const handleChange = (newValue) => {
		const newBorder = getNewBorder(newValue)
		onChange(newBorder, whichBorder)
	}

	const handlePreviewChangeBorderColor = (newValue) => {
		const newBorder = getNewBorder(newValue)
		onPreviewChange(newBorder, whichBorder)
	}

	return (
		<Grid spacing={1} container alignItems="center">
			<Grid item xs={12}>
				<LiveEditorTypography
					type="fieldTitle"
				>
					{label}
				</LiveEditorTypography>
			</Grid>
			<Grid item xs={12}>
				<Grid
					spacing={2}
					container
					direction="row"
					alignItems="center"
				>
					<Grid item>
						<Select
							defaultValue=""
							value={sizeOptions.includes(defaultBorderValues.borderSize) ? defaultBorderValues.borderSize : ""}
							onChange={({ target }) => handleChange({ borderSize: target.value })}
						>
							<MenuItem disabled value="">
								<em>Selecione um tamanho</em>
							</MenuItem>
							{sizeOptions.map(size => (
								<MenuItem key={sizeOptions.indexOf(size)} value={size}>
									{size}
								</MenuItem>
							))}
						</Select>
					</Grid>

					<Grid item xs>
						<Select
							value={defaultBorderValues.borderStyle}
							onChange={({ target }) => handleChange({ borderStyle: target.value })}
						>
							{styleOptions.map(style => (
								<MenuItem
									key={style.id}
									value={style.styleValue}
								>
									{style.styleName}
								</MenuItem>
							))}
						</Select>
					</Grid>

					<Grid item>
						<ColorPicker
							value={defaultBorderValues.borderColor || "transparent"}
							onPreviewChange={(color) => handlePreviewChangeBorderColor({ borderColor: color })}
							onChange={(color) => handleChange({ borderColor: color })}
							padding={0}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

AlterBorder.defaultProps = {
	label: ""
}

AlterBorder.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onPreviewChange: PropTypes.func.isRequired,
	whichBorder: PropTypes.string.isRequired
}

export default AlterBorder
