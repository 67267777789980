import { makeStyles } from "@material-ui/core"
import colors from "../../styles/colors"

const useStyles = makeStyles({
	questionIcon: {
		color: colors.greyScale[3]
	}
})

export default useStyles
