import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => ({
	container: {
		padding: theme.spacing() * 8,
		border: `1px dashed ${colors.greyScale[6]}`,
		backgroundColor: colors.greyScale[11],
		borderRadius: "5px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		cursor: "pointer",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.05)",
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			})
		}
	}
}))

export default useStyles
