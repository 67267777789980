import React from "react"
import PropTypes from "prop-types"
import { Divider as MaterialDivider, useTheme } from "@material-ui/core"

import useStyles from "./styles"

const Divider = (props) => {
	const { orientation, size } = props

	const theme = useTheme()

	const baseSpacing = theme.spacing()

	const sizeInPixels = {
		small: baseSpacing * 1,
		medium: baseSpacing * 2,
		large: baseSpacing * 3,
		xlarge: baseSpacing * 4,
		xxlarge: baseSpacing * 5
	}

	const customProps = {
		...(orientation === "horizontal" ? { height: sizeInPixels[size], width: "100%" } : {}),
		...(orientation === "vertical" ? { height: "auto", width: sizeInPixels[size] } : {})
	}

	const classes = useStyles()

	return (
		<MaterialDivider
			orientation={orientation}
			className={classes.divider}
			style={{ ...customProps }}
		/>
	)
}

Divider.propTypes = {
	orientation: PropTypes.oneOf(["horizontal", "vertical"]).isRequired,
	size: PropTypes.oneOf(["small", "medium", "large", "xlarge", "xxlarge"]).isRequired
}

export default Divider
