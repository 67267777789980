import React from "react"
import { Skeleton } from "@material-ui/lab"
import { createStyles, Grid, makeStyles } from "@material-ui/core"

import { Portlet } from "../components"

const useStyles = makeStyles((theme) => createStyles({
	cardContainer: {
		width: "100%",
		margin: 0,
		padding: theme.spacing(2),
		textAlign: "start"
	},
	textSkeleton: {
		transform: "scale(1)"
	}
}))


const TurboSeoWaitingAuditSkeleton = () => {
	const classes = useStyles()

	return (
		<Portlet disablePadding>
			<Grid container spacing={2} alignItems="center" className={classes.cardContainer}>
				<Grid item sm={1} xs={2}>
					<Skeleton variant="circle" width={20} height={20} animation="wave" />
				</Grid>

				<Grid item sm={11} xs={10}>
					<Grid container spacing={2} alignItems="flex-start">
						<Grid item xs={12}>
							<Skeleton width="50%" height={22} animation="wave" className={classes.textSkeleton} />
						</Grid>
						<Grid item xs={12}>
							<Skeleton width="95%" height={40} animation="wave" className={classes.textSkeleton} />
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Portlet>
	)
}

export default TurboSeoWaitingAuditSkeleton
