import { makeStyles } from "@material-ui/core"
import theme from "../../styles/theme"

const useStyles = makeStyles({
	listItemTextWrapper: {
		flex: "initial",
		marginRight: theme.spacing(2),
		maxWidth: "55%"
	}
})

export default useStyles
