import React from "react"
import PropTypes from "prop-types"
import {
	Grid,
	IconButton,
	Typography,
	Button,
	CircularProgress,
	Divider as MDDivider,
	Tooltip,
	Link as MDLink
} from "@material-ui/core"

import {
	PhoneAndroid as SmartphoneIcon,
	DesktopWindows as DesktopIcon,
	ZoomOutMap as FullWidthIcon,
	OpenInNew as OpenInNewIcon,
	ArrowBackIos as ArrowBackIcon,
	DeleteOutline as DeleteIcon
} from "@material-ui/icons"

import { Link } from "react-router-dom"

import useStyles from "./styles"

import { useSiteStore, useLiveEditorStore } from "../../../store"

const ActionsTopBar = ({
	publishing,
	handlePublish,
	handleDiscardChanges,
	device,
	setDevice,
	isConsoleVisible,
	setIsConsoleVisible
}) => {
	const classes = useStyles({ device, isConsoleVisible })

	const siteStore = useSiteStore()
	const liveEditorStore = useLiveEditorStore()

	const livePreviewBaseUrl = siteStore.state.picsize_site_url
	const { changedData } = liveEditorStore.state
	const iframeUrl = `${livePreviewBaseUrl}/${liveEditorStore.state.currentPage?.currentSlug || ""}`

	const handleChangeView = (viewType) => {
		const options = {
			desktop:
			{
				changeDevice: "desktop",
				changeViewEditor: isConsoleVisible
			},
			mobile: {
				changeDevice: "mobile",
				changeViewEditor: isConsoleVisible
			},
			fullWidth: {
				changeDevice: device,
				changeViewEditor: !isConsoleVisible
			}
		}
		setDevice(options[viewType].changeDevice)
		setIsConsoleVisible(options[viewType].changeViewEditor)
	}

	const getPublishButtonText = () => {
		if (publishing) {
			return "Publicando"
		} if (liveEditorStore.state.changedData) {
			return "Publicar"
		}
		return "Publicado"
	}


	return (
		<Grid item xs={12}>
			<Grid
				className={classes.actionsContainer}
				container
				justify="space-between"
				alignItems="center"
			>
				<Grid item className={classes.leftActions}>
					<Button
						component={Link}
						to="/portfolio"
						startIcon={<ArrowBackIcon />}
						className={classes.backButton}
					>
						<Typography variant="h2">FECHAR EDITOR</Typography>
					</Button>
				</Grid>

				<Grid item className={classes.centerActions}>
					<Grid container justify="center">
						<Grid item>
							<Tooltip title="Visualização mobile" aria-label="Visualização mobile" arrow>
								<IconButton onClick={() => handleChangeView("mobile")}>
									<SmartphoneIcon className={classes.phoneIcon} />
								</IconButton>
							</Tooltip>

							<Tooltip title="Visualização desktop" aria-label="Visualização desktop" arrow>
								<IconButton onClick={() => handleChangeView("desktop")}>
									<DesktopIcon className={classes.desktopIcon} />
								</IconButton>
							</Tooltip>
						</Grid>

						<Grid item style={{ display: "flex" }}>
							<MDDivider orientation="vertical" className={classes.iconsDivider} />
						</Grid>

						<Grid item>
							<Tooltip title={isConsoleVisible ? "Esconder configurações" : "Mostrar configurações"} aria-label="Alternar visibilidade das configurações" arrow>
								<IconButton onClick={() => handleChangeView("fullWidth")}>
									<FullWidthIcon className={classes.fullWidthIcon} />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>

				<Grid item className={classes.rightActions}>
					<MDLink
						href={iframeUrl}
						target="_blank"
						rel="noopener"
						className={classes.iframeUrlLink}
					>
						{iframeUrl}
					</MDLink>

					<Button
						onClick={handleDiscardChanges}
						className={classes.discardButton}
						disabled={publishing || !changedData}
						endIcon={<DeleteIcon />}
					>
						Descartar
					</Button>

					<Button
						variant="contained"
						color="secondary"
						onClick={handlePublish}
						className={classes.publishButton}
						disabled={publishing || !changedData}
						endIcon={publishing ? (
							<CircularProgress color="secondary" size={16} />
						) : (<OpenInNewIcon />)}
					>
						{getPublishButtonText()}
					</Button>
				</Grid>
			</Grid>
		</Grid>
	)
}

ActionsTopBar.propTypes = {
	publishing: PropTypes.bool.isRequired,
	handlePublish: PropTypes.func.isRequired,
	handleDiscardChanges: PropTypes.func.isRequired,
	device: PropTypes.string.isRequired,
	setDevice: PropTypes.func.isRequired,
	isConsoleVisible: PropTypes.bool.isRequired,
	setIsConsoleVisible: PropTypes.func.isRequired
}

export default ActionsTopBar
