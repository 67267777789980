import { createStyles, makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(() => createStyles({
	comingSoonChip: {
		color: colors.greyScale[11],
		fontWeight: "bolder",
		backgroundColor: colors.palette.tag2,
		borderRadius: "5px"
	},
	illustration: {
		width: "100%"
	},
	supportButton: {
		fontWeight: "bold"
	}
}))

export default useStyles
