import { makeStyles } from "@material-ui/core"
import colors from "../../../styles/colors"

export default makeStyles((theme) => ({
	container: {
		paddingRight: theme.spacing(4),
		paddingLeft: theme.spacing(4)
	},
	description: {
		fontSize: 14,
		fontWeight: 400,
		color: colors.greyScale[4]
	}
}))
