import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => ({
	premiumChip: {
		backgroundColor: "#FFC243",
		color: colors.greyScale[11],
		fontWeight: "bolder",
		borderRadius: "3.3px",
		fontSize: 11,
		height: theme.spacing() * 3
	},
	starIcon: {
		color: `${colors.greyScale[11]} !important`,
		width: `${theme.spacing() * 2}px !important`,
		height: `${theme.spacing() * 2}px !important`
	}
}))

export default useStyles
