import React from "react"
import PropTypes from "prop-types"

import ChangeLayout from "../../../../../components/ChangeLayout"
import { Divider } from "../../../../../../../components"

import SectionDefaultDesign from "../../SectionDefaultDesign"

const WorkListOnWorkListPageEditor = ({ sectionId }) => (
	<>
		<Divider orientation="horizontal" size="medium" />

		<ChangeLayout pageSectionId={sectionId} />

		<Divider orientation="horizontal" size="medium" />

		<SectionDefaultDesign sectionId={sectionId} />
	</>
)

WorkListOnWorkListPageEditor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default WorkListOnWorkListPageEditor
