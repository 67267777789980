import React from "react"
import { Skeleton } from "@material-ui/lab"
import { makeStyles, createStyles, Container } from "@material-ui/core"

const useStyles = makeStyles(theme => createStyles({
	media: {
		width: "100%",
		height: 350
	},
	placeholder: {
		display: "flex",
		height: theme.spacing() * 10,
		width: "100%"
	}
}))

const LogoSkeleton = () => {
	const classes = useStyles()

	return (
		<>
			<Skeleton animation="wave" variant="rect" className={classes.media} />

			<Container className={classes.placeholder} />
		</>
	)
}

export default LogoSkeleton
