import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, createStyles } from "@material-ui/core"

import { Divider } from "../components"

const useStyles = makeStyles(theme => createStyles({
	titleSkeleton: {
		padding: theme.spacing() * 1,
		maxWidth: 200,
		width: "100%"
	},
	inputSkeleton: {
		maxWidth: 300,
		width: "100%"
	}
}))

const CustomDomainHelperBoxSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container direction="column">
			<Skeleton animation="wave" variant="rect" height={15} className={classes.titleSkeleton} />

			<Divider size="medium" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={20} width="40%" />

			<Divider size="small" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={20} width="20%" />

			<Divider size="large" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={20} width="40%" />

			<Divider size="small" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={20} width="20%" />
		</Grid>
	)
}

export default CustomDomainHelperBoxSkeleton
