import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"
import { consoleMaxWidth } from "../../styles"

const useStyles = makeStyles(theme => ({
	drawer: {
		width: "100%",
		flexShrink: 0,

		[theme.breakpoints.up("md")]: {
			width: consoleMaxWidth
		}
	},
	drawerPaper: {
		width: "100%",

		[theme.breakpoints.up("md")]: {
			width: consoleMaxWidth
		}
	},
	drawerContent: {
		padding: theme.spacing(2, 2),
		maxWidth: "100%"
	},
	closeButtonIcon: {
		color: colors.greyScale[5]
	}
}))

export default useStyles
