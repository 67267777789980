
import { createStyles, makeStyles } from "@material-ui/core"
import colors from "../../../styles/colors"

const useStyles = makeStyles((theme) => createStyles({
	cardContainer: {
		width: "100%",
		margin: 0,
		padding: theme.spacing(2),
		textAlign: "start"
	},
	successIcon: {
		color: "#4caf50"
	},
	errorIcon: {
		color: "#f44336"
	},
	pagesContainer: {
		padding: theme.spacing(2)
	},
	title: {
		fontSize: 14,
		fontWeight: 600,
		textTransform: "uppercase",
		color: colors.greyScale[3]
	},
	description: {
		fontSize: 14,
		fontWeight: 400,
		color: colors.greyScale[4]
	},
	descriptionLink: {
		textAlign: "start"
	}
}))

export default useStyles
