import React from "react"
import { Skeleton } from "@material-ui/lab"
import { CardContent, CardActionArea, makeStyles } from "@material-ui/core"

import { Divider } from "../components"

const useStyles = makeStyles(() => ({
	avatarContainer: {
		padding: 0,
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	}
}))

const AboutAvatarSkeleton = () => {
	const classes = useStyles()
	return (
		<CardContent className={classes.avatarContainer}>
			<CardActionArea>
				<Skeleton animation="wave" variant="react" height={300} />
			</CardActionArea>

			<Divider size="medium" orientation="horizontal" />

			<Skeleton animation="wave" variant="react" width={250} />

		</CardContent>
	)
}

export default AboutAvatarSkeleton
