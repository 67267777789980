import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Typography,
	TextField,
	Dialog,
	IconButton,
	Button,
	DialogContent,
	DialogActions,
	Link,
	DialogTitle,
	Grid
} from "@material-ui/core"

import {
	Close as CloseIcon
} from "@material-ui/icons"

import { Divider, Portlet, InputPic } from "../../../../../../components"

import { useLiveEditorStore } from "../../../../../../store"

import api from "../../../../../../services/api"
import useValidation from "../../../../../../hooks/useValidation"
import useStyles from "./styles"
import useDidMount from "../../../../../../hooks/useDidMount"

const mediaTemplate = {
	name: "",
	link: "",
	file: "",
	file_src: ""
}

const Partner1Editor = (props) => {
	const { sectionId } = props

	const classes = useStyles()

	const liveEditorStore = useLiveEditorStore()

	const [isDialogOpen, setIsDialogOpen] = useState(false)
	const [newMedia, setNewMedia] = useState(mediaTemplate)

	const { triggerValidation } = useValidation()

	const partner1SectionData = liveEditorStore.getSectionById(sectionId)

	async function initMediasSrc() {
		const medias = await Promise.all(partner1SectionData.content.medias.map(
			async media => {
				const dbMedia = await api.get("/media-system", {
					params: {
						id: media.media_file_id
					}
				})
				return {
					...media,
					media_src: dbMedia.data.mediaFile.media_src
				}
			}
		))

		liveEditorStore.setSectionData({
			sectionId,
			content: {
				medias
			}
		})
	}

	const handleChangeTitle = (title) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `body #page-section-${partner1SectionData.id} .section-title`,
				value: title
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content: {
				title
			}
		})
	}

	function openDialog() {
		setIsDialogOpen(true)
	}

	function closeDialog() {
		setIsDialogOpen(false)
		setNewMedia(mediaTemplate)
	}

	async function handleChangeNewMediaFile([file]) {
		const file_src = window.URL.createObjectURL(file)
		setNewMedia({
			...newMedia,
			file,
			file_src
		})
	}

	function handleChangeNewMediaInput(key, value) {
		setNewMedia({
			...newMedia,
			[key]: value
		})
	}

	async function updateMedias(medias) {
		liveEditorStore.setSectionData({
			sectionId,
			content: {
				medias
			}
		})

		const data = {
			...partner1SectionData,
			content: { medias }
		}

		try {
			const response = await api.post("/site/live-editor/build/view-section/assets", {
				slug: data.slug,
				...data
			})

			const newSection = response.data

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
					selector: partner1SectionData.id,
					value: newSection.css
				},
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_HTML,
					selector: `body #page-section-${partner1SectionData.id}`,
					value: newSection.html
				},
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_JS,
					selector: partner1SectionData.id,
					value: newSection.js
				}
			])
		} catch (error) {
			triggerValidation(error)
		}
	}

	async function handleAddNewMedia() {
		let media_src
		let media_file_id

		try {
			const formData = new FormData()
			formData.append("media_file", newMedia.file)

			const response = await api.post("/media-system", formData, {
				headers: {
					"Content-Type": "multipart/data"
				}
			})
			media_src = response.data.mediaFile.media_src
			media_file_id = response.data.mediaFile.id
		} catch (error) {
			triggerValidation(error)
			return
		}

		const mediaToPush = {
			name: newMedia.name,
			link: newMedia.link,
			media_file_id,
			media_src
		}

		const medias = [mediaToPush, ...partner1SectionData.content.medias.slice()]

		await updateMedias(medias)

		closeDialog()
	}

	async function handleDeleteMedia(media_file_id) {
		const medias = partner1SectionData.content.medias
			.filter(media => media.media_file_id !== media_file_id)

		await updateMedias(medias)
	}

	function dialogAddMedia() {
		return (
			<Dialog open={isDialogOpen} onClose={closeDialog}>
				<IconButton onClick={closeDialog} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					Adicionar mídia
				</DialogTitle>
				<DialogContent className={classes.dialogContainer}>

					<InputPic onDrop={handleChangeNewMediaFile} fileSrc={newMedia.file_src} />

					<Divider orientation="horizontal" size="large" />

					<TextField
						id="title"
						label="Nome"
						variant="standard"
						size="small"
						fullWidth
						required
						color="secondary"
						value={newMedia.name}
						onChange={({ target }) => handleChangeNewMediaInput("name", target.value)}
					/>

					<Divider orientation="horizontal" size="large" />

					<TextField
						id="title"
						label="Link"
						variant="standard"
						size="small"
						fullWidth
						required
						color="secondary"
						value={newMedia.link}
						onChange={({ target }) => handleChangeNewMediaInput("link", target.value)}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={closeDialog}>
						Cancelar
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={handleAddNewMedia}
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>
		)
	}

	function mediasComponent() {
		const { medias } = partner1SectionData.content

		return (
			<>
				{
					medias.map((media) => (
						<>
							<Portlet>
								<Grid container justify="center" alignItems="center">
									<img src={media.media_src} height={100} alt={media.name} style={{ margin: "auto", objectFit: "contain" }} />
								</Grid>

								<Divider orientation="horizontal" size="medium" />

								<Grid container justify="space-between">
									<Link href={media.link}>{media.link}</Link>

									<Button onClick={() => handleDeleteMedia(media.media_file_id)}>Delete</Button>
								</Grid>
							</Portlet>
							<Divider orientation="horizontal" size="medium" />
						</>
					))
				}
			</>
		)
	}

	useDidMount(() => {
		initMediasSrc()
	})

	return (
		<>
			<Typography variant="h2">Título</Typography>

			<Divider size="medium" orientation="horizontal" />

			<TextField
				color="secondary"
				value={partner1SectionData.content.title}
				onChange={({ target }) => handleChangeTitle(target.value)}
				fullWidth
			/>

			<Divider size="large" orientation="horizontal" />

			<Typography variant="h2">Upload de selos e parceiros</Typography>

			<Divider size="medium" orientation="horizontal" />

			<Link component="button" onClick={openDialog}>+ Adicionar</Link>
			{dialogAddMedia()}

			<Divider size="medium" orientation="horizontal" />

			{mediasComponent()}
		</>
	)
}

Partner1Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default Partner1Editor
