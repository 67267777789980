import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	Typography,
	Button,
	IconButton,
	ThemeProvider,
	Grid
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"

import Divider from "../../Divider"

import { renderComponent } from "../../../utils/node"

import useStyles from "./styles"

import theme from "../../../styles/theme"

import lightBulbIcon from "../../../assets/light_bulb_icon.png"

const IncompleteSiteWarningModal = (props) => {
	const { customerSiteUrl, exampleSiteUrl } = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<Grid
					container
					direction="column"
					justify="center"
					alignItems="center"
					className={classes.dialogContainer}
				>

					<img src={lightBulbIcon} alt="light bulb icon" className={classes.icon} />

					<Divider size="large" orientation="horizontal" />

					<Typography variant="h3" className={classes.title}>
						Identificamos que você ainda não cadastrou trabalhos suficiente
						<br />
						para que o seu site fique bem composto e diagramado.
					</Typography>

					<Divider size="large" orientation="horizontal" />

					<Typography variant="body2" className={classes.description}>
						Por esse motivo, sugerimos que veja um exemplo de site todo montado,
						{" "}
						para você se inspirar e
						{" "}
						também ter uma visão de como o seu site pode ficar após colocar mais
						{" "}
						trabalhos e conteúdo.
					</Typography>

					<Divider size="large" orientation="horizontal" />

					<Grid
						container
						justify="center"
					>
						<Button
							color="inherit"
							onClick={handleClose}
							href={customerSiteUrl}
							target="blank"
						>
							VER O MEU SITE
						</Button>

						<Divider size="large" orientation="vertical" />

						<Button
							color="primary"
							variant="contained"
							onClick={handleClose}
							href={exampleSiteUrl}
							target="blank"
						>
							VER SITE DE EXEMPLO
						</Button>
					</Grid>
				</Grid>
			</Dialog>
		</ThemeProvider>
	)
}

IncompleteSiteWarningModal.defaultProps = {
	customerSiteUrl: "",
	exampleSiteUrl: ""
}

IncompleteSiteWarningModal.propTypes = {
	customerSiteUrl: PropTypes.string,
	exampleSiteUrl: PropTypes.string
}

IncompleteSiteWarningModal.open = ({
	customerSiteUrl,
	exampleSiteUrl
}) => renderComponent(
	"incomplete-site-warning-modal",
	<IncompleteSiteWarningModal
		customerSiteUrl={customerSiteUrl}
		exampleSiteUrl={exampleSiteUrl}
	/>
)

export default IncompleteSiteWarningModal
