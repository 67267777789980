import React from "react"
import PropTypes from "prop-types"
import { Container } from "@material-ui/core"
import { useDropzone } from "react-dropzone"

import useStyles from "./styles"

import { isMobile } from "../../utils/checkDevice"

const Dropzone = (props) => {
	const { onDrop, children, disabled, className } = props

	const { getRootProps, getInputProps } = useDropzone({ onDrop })

	const classes = useStyles({ disabled })

	return (
		<Container
			disableGutters
			// eslint-disable-next-line
			{...getRootProps()}
			className={`${className} ${classes.container}`}
			maxWidth={null}
		>
			<input
				// eslint-disable-next-line
				{...getInputProps()}
				accept=".jpg, .jpeg, .png"
				type="file"
				hidden
			/>

			{isMobile ? (
				<Container
					disableGutters
					maxWidth={null}
					className={classes.content}
				>
					{children}
				</Container>
			) : (
				children
			)}
		</Container>
	)
}

Dropzone.defaultProps = {
	disabled: false,
	className: {}
}

Dropzone.propTypes = {
	disabled: PropTypes.bool,
	onDrop: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	className: PropTypes.shape({})
}

export default Dropzone
