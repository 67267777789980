import { Button } from "@material-ui/core"
import React, { useState } from "react"


import MediaSystemComponent from "../../../components/MediaSystem"


const MediaSystem = () => {
	const [openMediaSystem, setOpenMediaSystem] = useState(true)

	const handleOpenMediaSystem = () => {
		setOpenMediaSystem(true)
	}

	const handleCloseMediaSystem = () => {
		setOpenMediaSystem(false)
	}

	const handleOkMediaSystem = () => {
		handleCloseMediaSystem()
	}

	return (
		<>
			<Button onClick={handleOpenMediaSystem}>Open</Button>
			<MediaSystemComponent
				open={openMediaSystem}
				onClose={handleCloseMediaSystem}
				onOk={handleOkMediaSystem}
			/>
		</>
	)
}

export default MediaSystem
