import { makeStyles, createStyles } from "@material-ui/core"

import colors from "../../../styles/colors"

const useStyles = makeStyles(theme => createStyles({
	dropzoneContainer: {
		border: `1px dashed ${colors.greyScale[6]}`,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		cursor: "pointer",
		padding: theme.spacing() * 10,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		"&:hover": {
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			borderColor: theme.palette.primary.main,
			backgroundColor: "#fdfdfd"
		},
		"& img": {
			width: "126px",
			height: "auto"
		},
		"& button": {
			width: "160px"
		}
	},
	cardContentPictureDescription: {
		cursor: "pointer",
		textAlign: "center",
		padding: theme.spacing() * 2,
		"&:last-child": {
			paddingBottom: theme.spacing() * 2
		}
	},
	descriptionPictureContentText: {
		color: colors.greyScale[1]
	},
	descriptionPictureContentPlaceHolder: {
		color: colors.greyScale[5]
	},
	workPictureBox: {
		height: 0,
		paddingBottom: "100%",
		width: "100%",
		cursor: "pointer",
		borderRadius: 0,
		backgroundColor: colors.greyScale[10],
		position: "relative",
		display: "flex"
	},
	workPictureAllActionContainer: {
		zIndex: props => (props.dragStart ? "-1" : "inherit")
	},
	workPictureActionsContainerTop: {
		width: "100%",
		padding: "2px 2px 0",
		position: "absolute",
		top: 0,
		zIndex: 9
	},
	workPictureActionsContainerBottom: {
		width: "100%",
		padding: "0px 2px 2px",
		position: "absolute",
		display: "flex",
		justifyContent: "flex-end",
		bottom: 0,
		zIndex: 9
	},
	workPictureActionButton: {
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		borderRadius: "3px",
		color: colors.greyScale[11],
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.5)"
		}
	},
	workPictureActionsIcons: {
		fontSize: 20
	},
	workPictureImage: {
		position: "absolute",
		top: 0,
		left: 0,
		width: "100%",
		height: "100%",
		objectFit: "cover",
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		})
	},
	snackbar: {
		padding: "8px 24px",
		backgroundColor: colors.greyScale[3],
		width: "500px"
	},
	snackbarContent: {
		display: "flex",
		alignItems: "center"
	},
	selectAllPicturesButton: {
		color: colors.greyScale[11],
		textDecoration: "underline",
		"&:hover": {
			textDecoration: "underline"
		}
	},
	lastUpdateDate: {
		fontSize: 14,
		"&:hover": {
			textDecoration: "none"
		}
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	addCategoryIcon: {
		minWidth: theme.spacing() * 4
	},
	settignsCategoryIcon: {
		textAlign: "end"
	}
}))

export default useStyles
