import React, { useState } from "react"
import PropTypes from "prop-types"


import Notification from "../Notification"

import api from "../../services/api"

import { useSiteStore } from "../../store"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"


import ThemeGallery from "../ThemeGallery"


const ThemeGallerySiteCp = ({ onClose }) => {
	const siteStore = useSiteStore()

	const { triggerUndeterminedValidation } = useValidation()

	const [themes, setThemes] = useState([])
	const [loadingThemes, setLoadingThemes] = useState(true)

	const [currentThemeId, setCurrentThemeId] = useState(null)
	const [loadingCurrentThemeId, setLoadingCurrentThemeId] = useState(true)

	const handleChangeTheme = async (theme_id) => {
		const themeIdBackup = currentThemeId

		const oldTheme = themes.find(theme => theme?.id === currentThemeId)
		const newTheme = themes.find(theme => theme.id === theme_id)

		setCurrentThemeId(theme_id)

		siteStore.setSiteData({
			theme_url: newTheme.preview_url
		})

		try {
			await api.put(`/site/theme/${theme_id}/change-theme`)

			Notification.success({ message: "Tema alterado com sucesso!" })
		} catch (error) {
			setCurrentThemeId(themeIdBackup)

			siteStore.setSiteData({
				theme_url: oldTheme.preview_url
			})

			triggerUndeterminedValidation(error)
		}
	}

	const getThemes = async () => {
		try {
			const { data } = await api.get("/site/theme/list")

			setThemes(data?.themes)
			setLoadingThemes(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const getCurrentThemeId = async () => {
		try {
			const { data } = await api.get("/site/version/list")

			const activeVersion = data.versions.find(version => version.active) || {}

			setCurrentThemeId(activeVersion.theme_id)
			setLoadingCurrentThemeId(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	useDidMount(() => {
		getThemes()
		getCurrentThemeId()
	})

	return (
		<ThemeGallery
			onClose={onClose}
			handleChangeTheme={handleChangeTheme}
			loading={loadingThemes && loadingCurrentThemeId}
			currentThemeId={currentThemeId}
		/>
	)
}

ThemeGallerySiteCp.propTypes = {
	onClose: PropTypes.func
}

ThemeGallerySiteCp.defaultProps = {
	onClose: () => {}
}

export default ThemeGallerySiteCp
