import React, { useState } from "react"

import { Box, Typography, Grid, ButtonBase, Divider as MuiDivider, IconButton } from "@material-ui/core"
import {
	AddCircleOutline as AddIcon,
	DeleteOutline as DeleteIcon,
	DragIndicator as DragIcon
} from "@material-ui/icons"
import PropTypes from "prop-types"

import {
	sortableContainer,
	sortableElement,
	sortableHandle
} from "react-sortable-hoc"

import arrayMove from "array-move"

import { useLiveEditorStore } from "../../../../../../store"
import { LiveEditorTypography, GroupField, SimpleDrawer, TextInput } from "../../../../components"
import ChangeLayout from "../../../../components/ChangeLayout"
import useValidation from "../../../../../../hooks/useValidation"
import SectionDefaultDesign from "../SectionDefaultDesign"

import { Divider } from "../../../../../../components"

import useStyles from "./styles"
import ItemEditor from "./ItemEditor"
import api from "../../../../../../services/api"

const Links1Editor = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const [editItemDrawerOpen, setEditItemDrawerOpen] = useState(false)
	const [currentItemEditing, setCurrentItemEditing] = useState(null)

	const sectionData = liveEditorStore.getSectionById(sectionId)
	const items = sectionData?.content?.links || []

	const classes = useStyles()

	const { triggerUndeterminedValidation } = useValidation()

	const handleChangeTitleText = (event) => {
		const { value } = event.target

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} [le-title]`,
				value
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			content: { title: value }
		})
	}

	const handleChangeTitleVisibility = (event) => {
		const { checked } = event.target

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_INLINE_STYLE,
				selector: `#page-section-${sectionId} [le-title-container]`,
				value: { display: checked ? "block" : "none" }
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties: { display_title: checked }
		})
	}

	const handleOpenEditItemDrawer = () => {
		setEditItemDrawerOpen(true)
	}

	const handleCloseEditItemDrawer = () => {
		setEditItemDrawerOpen(false)
		setCurrentItemEditing(null)
	}

	const handleClickItem = (item) => {
		setCurrentItemEditing(item)
		handleOpenEditItemDrawer()
	}

	const updateItemsOnStore = (updatedItems) => {
		liveEditorStore.setSectionData({
			sectionId,
			content: { links: updatedItems }
		})
	}

	const updateItemPropOnStore = (itemId, { propName, value }) => {
		// create copy of current links
		const updatedItems = items.slice()
		const editedItemIndex = updatedItems.findIndex((item) => item.id === itemId)
		updatedItems[editedItemIndex][propName] = value

		updateItemsOnStore(updatedItems)
	}

	const handleChangeItemImage = (imageUrl) => {
		const background = imageUrl && `url('${imageUrl}') center / cover no-repeat`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_INLINE_STYLE,
				selector: `#page-section-${sectionId} [le-link-${currentItemEditing.id}] [le-link-background]`,
				value: { background }
			}
		])

		updateItemPropOnStore(currentItemEditing.id, { propName: "background", value: background })
	}

	const handleChangeItemTextField = ({ fieldName, value }) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_TEXT,
				selector: `#page-section-${sectionId} [le-link-${currentItemEditing.id}] [le-link-${fieldName}]`,
				value
			}
		])

		updateItemPropOnStore(currentItemEditing.id, { propName: fieldName, value })
	}

	const handleChangeItemOpenInNewTab = (value) => {
		updateItemPropOnStore(currentItemEditing.id, { propName: "open_in_new_tab", value })
	}

	const rerenderSection = async (updatedSectionData) => {
		try {
			const response = await api.post("/site/live-editor/build/view-section/assets", updatedSectionData)

			const sectionAssets = response.data

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_HTML,
					selector: `#page-section-${sectionId}`,
					value: sectionAssets.html,
					pageId: liveEditorStore.state.currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
					selector: `style-${sectionId}`,
					value: sectionAssets.css,
					pageId: liveEditorStore.state.currentPage.id
				}
			])
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleAddItem = async () => {
		const itemsIds = items.map(item => item.id)
		itemsIds.sort()
		const biggerId = itemsIds.pop()
		const newItemId = Number.isInteger(biggerId) ? biggerId + 1 : 0

		const newItem = {
			id: newItemId,
			background: "",
			title: "Título",
			description: "Descrição",
			url: "",
			open_in_new_tab: false
		}

		// create a copy of items
		const updatedItems = items.slice()
		updatedItems.push(newItem)

		updateItemsOnStore(updatedItems)

		const updatedSectionData = JSON.parse(JSON.stringify(sectionData))
		updatedSectionData.content.links = updatedItems

		rerenderSection(updatedSectionData)
	}

	const handleDeleteItem = async (event, itemToDelete) => {
		// prevent open edit icon
		event.stopPropagation()

		// create a copy of items and remove specific item
		const updatedItems = items.slice().filter(item => item.id !== itemToDelete.id)

		updateItemsOnStore(updatedItems)

		const updatedSectionData = JSON.parse(JSON.stringify(sectionData))
		updatedSectionData.content.links = updatedItems
		rerenderSection(updatedSectionData)
	}

	const handleSortItemsEnd = ({ oldIndex, newIndex }) => {
		const sortedItems = arrayMove(items, oldIndex, newIndex)

		updateItemsOnStore(sortedItems)

		const updatedSectionData = JSON.parse(JSON.stringify(sectionData))
		updatedSectionData.content.links = sortedItems
		rerenderSection(updatedSectionData)
	}

	const DragHandle = sortableHandle(() => (
		<Typography variant="h2" style={{ lineHeight: 0 }}>
			<DragIcon style={{ cursor: "row-resize" }} />
		</Typography>
	))

	const SortableItemsContainer = sortableContainer(({ children }) => (
		<Grid container spacing={1}>
			{children}
		</Grid>
	))

	const SortableItem = sortableElement(({ value: item }) => (
		<Grid item xs={12}>
			<ButtonBase className={classes.itemContainer} onClick={() => handleClickItem(item)}>
				<Grid container alignItems="center">
					<Grid item>
						<Box className={classes.itemImage} style={{ background: item.background }} />
					</Grid>

					<Grid item xs>
						<Typography className={classes.itemTitle}>
							{item.title}
						</Typography>
					</Grid>

					<Grid item>
						<DragHandle />
					</Grid>

					<Grid item>
						<IconButton onClick={(event) => handleDeleteItem(event, item)}>
							<DeleteIcon />
						</IconButton>
					</Grid>
				</Grid>
			</ButtonBase>
		</Grid>
	))

	return (
		<>
			<GroupField label="Conteúdo">
				<TextInput
					label="Título"
					value={sectionData?.content?.title}
					onChange={handleChangeTitleText}
					switchProps={{
						display: true,
						value: sectionData?.properties?.display_title,
						onChange: handleChangeTitleVisibility
					}}
				/>

				<Divider orientation="horizontal" size="medium" />

				<MuiDivider />

				<Divider orientation="horizontal" size="large" />

				<LiveEditorTypography type="groupFieldSubtitle">
					Links
				</LiveEditorTypography>

				<Divider orientation="horizontal" size="medium" />

				<SortableItemsContainer
					lockAxis="y"
					onSortEnd={handleSortItemsEnd}
					pressDelay
					useDragHandle
				>
					{items.map((item, index) => (
						<SortableItem key={`item-${item.id}`} index={index} value={item} />
					))}

					<Grid item xs={12}>
						<ButtonBase className={classes.addItemButton} onClick={handleAddItem}>
							<AddIcon className={classes.addItemButtonIcon} />
							adicionar link
						</ButtonBase>
					</Grid>
				</SortableItemsContainer>

				<SimpleDrawer open={editItemDrawerOpen} onClose={handleCloseEditItemDrawer}>
					<ItemEditor
						item={currentItemEditing}
						onImageChange={handleChangeItemImage}
						onTextFieldChange={handleChangeItemTextField}
						onOpenInNewTabChange={handleChangeItemOpenInNewTab}
					/>
				</SimpleDrawer>
			</GroupField>

			<Divider orientation="horizontal" size="medium" />

			<ChangeLayout pageSectionId={sectionId} />

			<Divider orientation="horizontal" size="medium" />

			<SectionDefaultDesign sectionId={sectionId} />
		</>
	)
}

Links1Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default Links1Editor
