import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	IconButton,
	Card,
	Box,
	Grid,
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	CardContent
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import useDidMount from "../../../../../hooks/useDidMount"
import useValidation from "../../../../../hooks/useValidation"

import api from "../../../../../services/api"
import { useLiveEditorStore } from "../../../../../store"

import useStyles from "./styles"

const filterSections = (sections) => {
	const allowedSectionsTitles = [
		"Imagem A",
		"Links A",
		"Depoimentos A",
		"Depoimentos B",
		"Depoimentos C",
		"Depoimentos D",
		"Depoimentos E",
		"Feed do Instagram A",
		"Sobre Mim A",
		"Sobre Mim B",
		"Sobre Mim C",
		"Sobre Mim D",
		"Sobre Mim E",
		"Sobre Mim F",
		"Categorias de Trabalhos A",
		"Trabalhos A",
		"Trabalhos B",
		"Trabalhos C",
		"Trabalhos D",
		"Trabalhos E",
		"Trabalhos F",
		"Trabalhos G",
		"Contato A",
		"Contato B",
		"Contato C",
		"Contato D",
		"Contato E"
	]

	const allowedSections = sections.filter(
		section => allowedSectionsTitles.includes(section.title)
	)

	return allowedSections
}

const AvailableLayouts = ({ sectionId, onSelect, onClose, open }) => {
	const { triggerUndeterminedValidation } = useValidation()

	const currentPageSection = useLiveEditorStore().getSectionById(sectionId)
	const { site_block_code_id } = currentPageSection

	const [sections, setSections] = useState([])

	const classes = useStyles()

	const getAvailableLayouts = async () => {
		try {
			const response = await api.get("/site/sections/block-code", {
				params: {
					site_block_code_id
				}
			})

			setSections(filterSections(response.data.sections))
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleSelectSection = (section) => {
		onSelect(section.id === currentPageSection.site_section_id ? null : section)
		onClose()
	}

	useDidMount(() => { getAvailableLayouts() })

	return (
		<Dialog fullScreen open={open}>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography className={classes.title}>
						Seções Disponíveis
					</Typography>

					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid container spacing={4} alignItems="stretch">
					<Grid item xs={12}>
						<Grid container spacing={10}>
							{sections.map(section => {
								const cardClasses = [classes.card]
								if (currentPageSection.site_section_id === section.id) {
									cardClasses.push(classes.cardActive)
								}

								return (
									<Grid item xs={12} md={6} lg={4}>
										<Card className={cardClasses} onClick={() => handleSelectSection(section)}>
											<Box className={classes.cardMediaContainer}>
												<Box
													className={classes.cardMedia}
													style={{ backgroundImage: `url(${section.cover_key})` }}
												/>
											</Box>

											<CardContent>
												{section.title}
											</CardContent>
										</Card>
									</Grid>
								)
							})}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

AvailableLayouts.propTypes = {
	sectionId: PropTypes.number.isRequired,
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool
}

AvailableLayouts.defaultProps = {
	onSelect: () => { },
	onClose: () => { },
	open: false
}

export default AvailableLayouts
