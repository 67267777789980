import { Button } from "@material-ui/core"
import React, { useState } from "react"

import ColorGalleryComponent from "../../../components/ColorGallery"

const ColorGallery = () => {
	const [openColorGallery, setOpenColorGallery] = useState(true)

	const handleOpenColorGallery = () => {
		setOpenColorGallery(true)
	}

	const handleCloseColorGallery = () => {
		setOpenColorGallery(false)
	}

	const handleOkColorGallery = () => {
		handleCloseColorGallery()
	}

	return (
		<>
			<Button onClick={handleOpenColorGallery}>Open</Button>
			<ColorGalleryComponent
				open={openColorGallery}
				onClose={handleCloseColorGallery}
				onOk={handleOkColorGallery}
			/>
		</>
	)
}

export default ColorGallery
