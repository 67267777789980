import React, { useState } from "react"
import { useHistory, Link } from "react-router-dom"

import {
	Grid,
	Card,
	CardMedia,
	Fab,
	CircularProgress,
	Typography,
	IconButton,
	Menu,
	MenuItem,
	Box,
	Container,
	Link as MaterialLink, Tooltip
} from "@material-ui/core"

import {
	Add as AddIcon,
	MoreVert as MoreVertIcon,
	VisibilityOutlined as VisibilityIcon,
	VisibilityOffOutlined as VisibilityOffIcon,
	Announcement as AnnouncementIcon,
	DeleteOutline as DeleteIcon,
	Edit as EditIcon,
	Person as PersonIcon,
	OpenWith as DragIndicatorAxisXYIcon
} from "@material-ui/icons"

import {
	Loading,
	GridItemResponsive,
	Divider,
	PopConfirm,
	ActionZone,
	Portlet,
	Notification,
	Sortable
} from "../../components"

import useDidMount from "../../hooks/useDidMount"
import useValidation from "../../hooks/useValidation"

import api from "../../services/api"

import { getStatusCode } from "../../utils/response"

import { useSiteStore } from "../../store"

import useStyles from "./styles"

import useCustomStyles from "../../styles/custom"

import TestimonialSkeleton from "../../skeletons/Testimonial"

import youtubeLogo from "../../assets/youtube_logo.svg"

const Testimonial = () => {
	const siteStore = useSiteStore()
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const history = useHistory()
	const { triggerValidation, triggerUndeterminedValidation } = useValidation()

	const [loadingTestimonials, setLoadingTestimonials] = useState(true)
	const [creatingTestimonial, setCreatingTestimonial] = useState(false)
	const [testimonialList, setTestimonialList] = useState([])
	const [pendingTestimonialList, setPendingTestimonialList] = useState([])
	const [menuData, setMenuData] = useState({ anchorEl: null })

	const handleChangeTestimonialList = (values) => {
		setTestimonialList(values)
	}

	const openMenu = (currentTarget, testimonialData) => {
		setMenuData({ anchorEl: currentTarget, currentTestimonial: testimonialData })
	}

	const closeMenu = () => {
		setMenuData({ anchorEl: null })
	}

	const getTestimonials = async () => {
		try {
			const { data } = await api.get("/site/testimonial/list")
			handleChangeTestimonialList(data.testimonials.filter(testimonial => testimonial.status !== "pending"))
			setPendingTestimonialList(
				data.testimonials.filter(testimonial => testimonial.status === "pending")
			)
			setLoadingTestimonials(false)
		} catch (error) {
			if (getStatusCode(error) === 404) {
				handleChangeTestimonialList([])
				setLoadingTestimonials(false)
			} else {
				triggerUndeterminedValidation(error)
			}
		}
	}

	const handleCreateTestimonial = async () => {
		setCreatingTestimonial(true)

		const nonEditedTestimonial = testimonialList.find(testimonial => !testimonial.was_edited)

		let testimonialId
		if (nonEditedTestimonial) {
			testimonialId = nonEditedTestimonial.id
		} else {
			try {
				const { data } = await api.post("/site/testimonial")
				testimonialId = data.testimonial_id
			} catch (err) {
				triggerUndeterminedValidation(err)
			}
		}
		setCreatingTestimonial(false)
		history.push(`/testimonial/edit/${testimonialId}`)
	}

	const handleDeleteTestimonial = async () => {
		const testimonialId = menuData.currentTestimonial.id
		closeMenu()

		PopConfirm.open({
			title: "Tem certeza?",
			description: "Excluir um depoimento é uma ação irreversível.",
			confirmButtonText: "Excluir",
			onConfirm: async () => {
				try {
					await api.delete(`/site/testimonial?id=${testimonialId}`)
					await getTestimonials()

					Notification.success({ message: "Depoimento deletado com sucesso!" })
				} catch (err) {
					triggerUndeterminedValidation(err)
				}
			}
		})
	}

	const handleEditTestimonial = () => {
		closeMenu()
		const testimonialId = menuData.currentTestimonial.id
		history.push(`/testimonial/edit/${testimonialId}`)
	}

	const putTestimonialOrder = async (valuesReordered) => {
		await api.put("/site/testimonial/all/order", valuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem dos depoimentos alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem dos depoimentos..." })
			})
	}

	const testimonialsFilterWasEdited = () => testimonialList
		.filter(testimonial => testimonial.was_edited)

	const handleSortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType)
	}

	const handleSortEnd = async ({ oldIndex, newIndex }) => {
		const arrayInfo = {
			values: testimonialsFilterWasEdited(), oldIndex, newIndex
		}
		Sortable.handleSortEnd(arrayInfo, handleChangeTestimonialList, putTestimonialOrder)
	}

	const toggleTestimonialVisibility = async testimonial => {
		const dataBackup = testimonialList

		const updatedData = testimonialList.map(item => {
			if (item.id === testimonial.id) {
				return {
					...item,
					hidden: !item.hidden
				}
			}
			return item
		})
		handleChangeTestimonialList(updatedData)

		const payload = {
			author: testimonial.author,
			id: testimonial.id,
			hidden: !testimonial.hidden
		}

		try {
			await api.put(`/site/testimonial/${testimonial.id}`, payload)
		} catch (err) {
			handleChangeTestimonialList(dataBackup)
			triggerValidation(err)
		}
	}

	useDidMount(() => {
		getTestimonials()
	})

	return (
		<Loading loading={loadingTestimonials} customLoadingElement={<TestimonialSkeleton />}>
			<Sortable.SectionContainer
				pressDelay
				axis="xy"
				useDragHandle
				onSortEnd={handleSortEnd}
				onSortStart={handleSortStart}
			>
				<Grid container spacing={2} alignItems="stretch">
					{pendingTestimonialList.length > 0 ? (
						<Grid item xs={12}>
							<Portlet>
								<Box className={classes.announcementContainer}>
									<AnnouncementIcon className={classes.announcementIcon} />
									<Typography variant="body2">
										Você tem
										<b>
											{` ${pendingTestimonialList.length}`}
											{" "}
											depoimento
											{pendingTestimonialList.length > 1 ? "s" : null}
										</b>
										{" "}
										com aprovação pendente.
										{" "}
										<Link>Clique aqui</Link>
										{" "}
										para aprová-los.
									</Typography>
								</Box>
							</Portlet>
						</Grid>
					) : null}

					<GridItemResponsive xs={12} sm={12} md={6} lg={4} xl={3}>
						<ActionZone onClick={handleCreateTestimonial} className={classes.card}>
							<Fab color="primary">
								{creatingTestimonial ? (
									<CircularProgress style={{ color: "#FFF" }} size={20} />
								)
									: (
										<AddIcon />
									)}
							</Fab>

							<Divider size="large" orientation="horizontal" />

							<Typography variant="h3">Novo depoimento</Typography>
						</ActionZone>
					</GridItemResponsive>
					{testimonialsFilterWasEdited()
						.map((testimonial, index) => (
							<Sortable.SortableSectionItem
								key={`testimonial-${testimonial.id}`}
								index={index}
								value={testimonial}
								disabled={!(testimonialsFilterWasEdited().length > 1)}
							>
								<GridItemResponsive xs={12} sm={12} md={6} lg={4} xl={3}>
									<Card elevation={1} className={classes.card}>
										<Container
											disableGutters
											className={classes.testimonialCardActionContainer}
											style={{ top: 0 }}
										>
											<Grid container justify="space-between">
												<Grid item>
													{
														testimonialsFilterWasEdited().length > 1
														&& (
															<Sortable.DragHandle>
																<Tooltip title="Ordenar depoimento" placement="top" arrow>
																	<IconButton
																		className={customClasses.containerActionButtonDark}
																		size="small"
																	>
																		<DragIndicatorAxisXYIcon
																			className={customClasses.contentActionButtonWhite}
																			style={{ cursor: "grab" }}
																		/>
																	</IconButton>
																</Tooltip>
															</Sortable.DragHandle>
														)
													}
												</Grid>
											</Grid>
										</Container>
										<MaterialLink href={testimonial.video_link} target="_blank" rel="noopener">
											<CardMedia
												image={testimonial.picture_src ? testimonial.picture_src : null}
												className={classes.cardPicture}
											>
												{!testimonial.picture_src ? (
													<PersonIcon className={classes.cardPictureAvatar} />
												) : null}

												{testimonial.video_link ? (
													<img className={classes.cardPictureIcon} src={youtubeLogo} alt="video icon" />
												) : null}
											</CardMedia>
										</MaterialLink>
										<Grid
											container
											disableGutters
											className={classes.cardContent}
											justify="space-between"
											alignItems="stretch"
										>
											<Grid item xs={9} container alignItems="center">
												<Grid item xs={12}>
													<Typography variant="h3" className={[customClasses.textEllipsis, classes.cardAuthor]}>
														{testimonial.author}
													</Typography>
												</Grid>

												{testimonial.url ? (
													<Grid xs={12}>
														<MaterialLink
															href={
																(testimonial.link_type !== "external" ? siteStore.state.url : "")
																+ testimonial.url
															}
															target="_blank"
															rel="noopener"
														>
															<Typography className={[
																customClasses.textEllipsis,
																classes.cardLink]}
															>
																{(testimonial.link_type !== "external" ? siteStore.state.url : "")
																	+ testimonial.url}
															</Typography>
														</MaterialLink>
													</Grid>
												) : null}
											</Grid>
											<Grid item xs={3} className={classes.cardHeaderButtons}>

												<Tooltip title={testimonial.hidden ? "Tornar depoimento visível" : "Ocultar depoimento"} placement="top" arrow>
													<IconButton onClick={() => toggleTestimonialVisibility(testimonial)}>
														{testimonial.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
													</IconButton>
												</Tooltip>

												<Tooltip title="Mais ações" placement="top" arrow>
													<IconButton
														onClick={({ currentTarget }) => openMenu(currentTarget, testimonial)}
													>
														<MoreVertIcon />
													</IconButton>
												</Tooltip>

												<Menu
													anchorEl={menuData.anchorEl}
													keepMounted
													open={Boolean(menuData.anchorEl)}
													onClose={closeMenu}
												>
													<MenuItem onClick={handleEditTestimonial}>
														<EditIcon className={classes.menuItemIcon} />
														<Typography variant="inherit">Editar</Typography>
													</MenuItem>
													<MenuItem onClick={handleDeleteTestimonial}>
														<DeleteIcon className={classes.menuItemIcon} />
														<Typography variant="inherit">Excluir</Typography>
													</MenuItem>
												</Menu>
											</Grid>
											<Grid item xs={12}>
												<Typography className={classes.cardText}>{testimonial.text}</Typography>
											</Grid>
										</Grid>
									</Card>
								</GridItemResponsive>
							</Sortable.SortableSectionItem>
						))}
				</Grid>
			</Sortable.SectionContainer>
		</Loading>
	)
}

export default Testimonial
