import { makeStyles } from "@material-ui/core"

import { isMobile } from "../../utils/checkDevice"

import colors from "../../styles/colors"

const useStyles = makeStyles((theme) => ({
	avatar: {
		width: "100%",
		height: "100%",
		backgroundColor: "#DDDDDD",
		position: "relative",
		borderRadius: 0
	},
	avatarContainer: {
		padding: 0
	},
	avatarGrid: {
		position: "relative",
		backgroundColor: "#ECECEC",
		width: "100%",
		height: "340px",
		margin: `${isMobile && "auto"}`
	},
	avatarPicture: {
		width: "100%",
		height: "100%",
		objectFit: "cover",
		objectPosition: "center"
	},
	avatarPlaceholder: {
		width: "100%",
		height: "100%",
		position: "absolute",
		zIndex: 9,
		backgroundColor: ({ hasAvatar }) => `${hasAvatar && "rgba(0, 0, 0, 0.5)"}`
	},
	avatarPlaceholderIcon: {
		color: ({ hasAvatar }) => `${hasAvatar ? colors.greyScale[9] : colors.greyScale[6]}`,
		fontSize: "80px"
	},
	avatarPlaceholderText: {
		color: ({ hasAvatar }) => `${hasAvatar ? colors.greyScale[9] : colors.greyScale[6]}`,
		fontSize: "30px"
	},
	avatarDropzone: {
		width: "100%",
		margin: 0,
		paddingBottom: `${isMobile && `${theme.spacing() * 1}px`}`
	},
	listItemTitle: {
		color: colors.greyScale[5],
		fontWeight: 600,
		fontSize: "12px",
		lineHeight: "16px",
		letterSpacing: "-0.03em"
	},
	nextIcon: {
		color: colors.greyScale[5]
	},
	moreIcon: {
		color: "#444444"
	},
	whatsappChatInfoListItem: {
		paddingTop: 0,
		paddingBottom: theme.spacing(2)
	},
	whatsappChatInfoList: {
		paddingTop: 0,
		paddingBottom: 0
	},
	whatsappChatInfoLink: {
		color: colors.greyScale[2],
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		"&:hover": {
			color: colors.greyScale[1]
		}
	}
}))

export default useStyles
