import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	DeleteOutline as DeleteIcon
} from "@material-ui/icons"
import { Container, Grid, IconButton, Fade, Typography } from "@material-ui/core"
import LiveEditorTypography from "../LiveEditorTypography"
import { Dropzone, Divider } from "../../../../components"

import useStyles from "./styles"
import transparentPlaceholder from "../../../../assets/transparent_placeholder.png"

const UploadField = (props) => {
	const { label, description, typeImage, imageUrl, onChange, onDelete } = props
	const [, setUploadingImage] = useState(false)
	const [showUploadFade, setShowUploadFade] = useState(false)

	const classes = useStyles()

	const onUploadEnter = () => {
		setShowUploadFade(true)
	}

	const onUploadLeave = () => {
		setShowUploadFade(false)
	}

	const changeImage = async ([newImage]) => {
		setUploadingImage(true)

		const blobURL = URL.createObjectURL(newImage)

		onChange(blobURL)

		setUploadingImage(false)
	}

	const deleteImage = async () => {
		onDelete()
	}

	return (
		<Grid container direction="column" spacing={1}>

			<Grid item>
				<Grid container alignItems="center" justify="space-between">
					<Grid item>
						<LiveEditorTypography
							type="fieldTitle"
						>
							{label}
						</LiveEditorTypography>
					</Grid>
					<Grid item>
						{
							onDelete && (
								<IconButton onClick={deleteImage}>
									<DeleteIcon />
								</IconButton>
							)
						}
					</Grid>
				</Grid>
			</Grid>

			<Grid item>
				<Dropzone
					onDrop={changeImage}
				>
					<Container
						className={classes.placeholder}
						disableGutters
						onMouseEnter={onUploadEnter}
						onMouseLeave={onUploadLeave}
						style={{ background: `url(${transparentPlaceholder})` }}
					>
						<img
							src={imageUrl}
							alt="Favicon"
							className={classes.faviconImg}
						/>
						<Fade in={showUploadFade}>
							<Grid
								container
								direction="column"
								justify="center"
								alignItems="center"
								className={classes.uploadPlaceholder}
							>
								<Typography className={classes.uploadPlaceholderText}>
									{`Alterar ${typeImage}`}
								</Typography>
							</Grid>
						</Fade>
					</Container>
				</Dropzone>

				<Divider orientation="horizontal" size="small" />

				<LiveEditorTypography
					type="fieldDescription"
				>
					{description}
				</LiveEditorTypography>
			</Grid>
		</Grid>
	)
}

UploadField.defaultProps = {
	description: null,
	onDelete: null
}

UploadField.propTypes = {
	label: PropTypes.string.isRequired,
	description: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	onDelete: PropTypes.func,
	typeImage: PropTypes.string.isRequired,
	imageUrl: PropTypes.string.isRequired
}

export default UploadField
