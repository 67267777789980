export const toBytes = (bytes, decimals) => {
	if (bytes === 0) {
		return "0 Bytes"
	}

	const k = 1024
	const dm = decimals || 2
	const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export const getPercentage = (current, total) => `${+((current / total) * 100).toFixed(2).split(".")[0]}`

export const keepNotFalsyObjectParams = (object) => {
	let updatedObject

	const isValid = (value) => value !== null && value !== undefined

	const isValidObject = isValid(object)

	if (isValidObject) {
		updatedObject = object
	} else {
		updatedObject = {}
	}

	Object.keys(updatedObject).forEach(key => {
		const isValidData = isValid(updatedObject[key])

		if (!isValidData) {
			delete updatedObject[key]
		}
	})

	return updatedObject
}

/**
	 * Split string on blank spaces and remove empty elements.
	 * Ex:
	 *  - "1px solid red  "
	 *  - Becomes: - ["1px", "solid", "red"]
	 */
export const splitStringOnBlankSpaces = (string) => string.split(/(\s+)/)
	.filter(elem => elem.trim().length > 0)
