import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, makeStyles, ButtonGroup, Button } from "@material-ui/core"

const useStyles = makeStyles({
	media: {
		width: "100%",
		height: "100%"
	},
	card: {
		width: 150,
		height: 150
	}
})

const WorkActionButtonGroup = () => {
	const classes = useStyles()

	const cards = []

	// eslint-disable-next-line
	for (let i = 0; i < 5; i++) {
		cards.push(
			<Grid item>
				<Card className={classes.card}>
					<Skeleton animation="wave" variant="rect" className={classes.media} />
				</Card>
			</Grid>
		)
	}

	return (
		<ButtonGroup fullWidth>
			<Button>
				<Skeleton animation="wave" variant="rect" width="100%" height={30} />
			</Button>
			<Button>
				<Skeleton animation="wave" variant="rect" width="100%" height={30} />
			</Button>
		</ButtonGroup>
	)
}

export default WorkActionButtonGroup
