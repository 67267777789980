/**
 * @param {String} cssString A simple css in string. Ex: .body1 { font-size: 12px }
 * Ex:
 * 	- .body1 { font-size: 12px, color: red }
 *  - Becomes: { "font-size": "12px", "color": "red" }
 */
export const parseCSSToJSON = (cssString) => {
	/**
	 * Basic sanitization on CSS.
	 * Ex:
	 *  - ".body1{ font-size: 16px; color: red }"
	 *  - Becomes: "font-size:16px;color:red"
	 */
	const cssPropertiesInString = (cssString || "")
		.replace(/ {1,}/g, " ")
		.trim()
		.split("{")
		.pop()
		.split("}")
		.shift()

	/**
	* Separate CSS props into array.
	* Ex:
	* 	- "font-size:16px;color:red"
	* 	- Becomes: ["font-size:16px", "color:red"]
	*/
	const cssPropertiesInArray = cssPropertiesInString.split(";")

	const cssPropertiesParsedInJson = {}

	/**
	* Creates an object using the array of CSS props.
	* Ex:
	* 	- ["font-size:16px", "color:red"]
	*  - Becomes: { "font-size": "12px", "color": "red" }
	*/
	cssPropertiesInArray.forEach(fullProperty => {
		const [property, value] = fullProperty.split(":")

		cssPropertiesParsedInJson[property] = value
	})

	return cssPropertiesParsedInJson
}

/**
 *
 * @param {Object} object A css object. Ex: { "font-size": "12px" }
 */
export const buildPureCSSContentByJSON = (object) => {
	/**
	 * Mounts a css content using the supplied object.
	 * Ex:
	 * 	- { "font-size": "12px", "color": "red" }
	 *  - Becomes: font-size: 12px;color: red
	 */
	const cssContent = Object.entries(object || {})
		.map(([property, value]) => `${property}: ${value}`)
		.join(";")


	return cssContent
}


/**
 *
 * @param {String} selector A css selector. Ex: .body1 h1
 * @param {Object} object A css object. Ex: { "font-size": "12px" }
 */
export const buildPureCSSByJSON = (selector, object) => {
	const cssContent = buildPureCSSContentByJSON(object)

	/**
	 * Creates a pure css string.
	 * Ex:
	 * 	- font-size: 12px;color: red
	 *  - .body1 h1
	 *  - Becomes: .body1 h1 {font-size: 12px;color: red}
	 */
	const pureCSS = `${selector}{${cssContent}}`

	return pureCSS
}
