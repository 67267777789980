import React, { useState, useRef } from "react"
import moment from "moment"
import { useParams } from "react-router-dom"
import {
	Grid,
	TextField,
	Typography,
	Link,
	Container,
	Button,
	useTheme,
	Box,
	Snackbar,
	IconButton,
	Fade,
	CircularProgress,
	Tooltip,
	CardActionArea,
	Card,
	CardContent,
	InputAdornment,
	Checkbox,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Dialog,
	DialogTitle,
	DialogContent
} from "@material-ui/core"
import {
	DeleteOutlined as DeleteOutlinedIcon,
	HighlightOff as HighlightOffIcon,
	CheckBoxOutlined as CheckedIcon,
	CheckBoxOutlineBlank as UnCheckedIcon,
	GradeOutlined as GradeOutlinedIcon,
	RemoveRedEyeOutlined as RemoveRedEyeOutlinedIcon,
	Star as StarIcon,
	OpenInNew as OpenInNewIcon,
	OpenWith as DragIndicatorAxisXYIcon,
	SettingsOutlined as SettingsIcon,
	AddOutlined as AddIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import api from "../../../services/api"

import { isMobile } from "../../../utils/checkDevice"

import useValidation from "../../../hooks/useValidation"
import useDidMount from "../../../hooks/useDidMount"

import { useWorkStore } from "../../../store"

import colors from "../../../styles/colors"

import {
	GridItemResponsive,
	Portlet,
	Divider,
	Loading,
	Notification,
	PopConfirm,
	InfoModal,
	InputDialog,
	Sortable
} from "../../../components"

import WorkTitleHeaderSkeleton from "../../../skeletons/WorkTitleHeader"
import WorkDescriptionBoxSkeleton from "../../../skeletons/WorkDescriptionBox"
import WorkPictureDropzoneSkeleton from "../../../skeletons/WorkPictureDropzone"
import WorkPicturesSkeleton from "../../../skeletons/WorkPictures"
import WorkActionButtonGroup from "../../../skeletons/WorkActionButtonGroup"

import FancyBox from "./FancyBox"
import PicturesUpload from "./PicturesUpload"
import AsideTabConfig from "./AsideTabConfig"

import useStyles from "./styles"
import useCustomStyles from "../../../styles/custom"
import GroupedCategories from "../components/GroupedCategories"
import CreateCategoryDialog from "../components/CreateCategoryDialog"
import SelectSkeleton from "../../../skeletons/Select"

const Work = () => {
	const {
		triggerValidation,
		clearValidation,
		validation,
		triggerUndeterminedValidation
	} = useValidation()

	const [pictures, setPictures] = useState([])
	const [pictureSettings, setPictureSettings] = useState({})
	const [selectedPictureIds, setSelectedPictureIds] = useState([])
	const [isDeletePicturesSnackbarOpen, setIsDeletePicturesSnackbarOpen] = useState(false)
	const [currentPictureActionsVisible, setCurrentPictureActionsVisible] = useState(null)
	const [selectedCategory, setSelectedCategory] = useState("")
	const [selectedCategoryOpen, setSelectedCategoryOpen] = useState(false)
	const [createCategoryDialogData, setCreateCategoryDialogData] = useState({
		openDialog: false
	})
	const [createCategoryLoading, setCreateCategoryLoading] = useState(false)

	const [editCategoriesDialogData, setEditCategoriesDialogData] = useState({
		openDialog: false
	})
	const [loadingGroupedCategories, setLoadingGroupedCategories] = useState(false)

	const [loadingWorkData, setLoadingWorkData] = useState(true)
	const [loadingPictures, setLoadingPictures] = useState(true)
	const [loadingPublish, setLoadingPublish] = useState(false)
	const [workDescribedDataStatus, setWorkDescribedDataStatus] = useState("waiting")

	const [workSlug, setWorkSlug] = useState("")
	const [workLink, setWorkLink] = useState("")
	const [workBaseUrl, setWorkBaseUrl] = useState("")
	const [workGroupedAndCategories, setWorkGroupedAndCategories] = useState([])
	const [workPublishDate, setWorkPublishDate] = useState(null)
	const [workLastUpdateDate, setWorkLastUpdateDate] = useState(null)
	const [workDescription, setWorkDescription] = useState("")
	const [workLayoutVariant, setWorkLayoutVariant] = useState(null)
	const [workTitle, setWorkTitle] = useState("")

	const [dragStart, setDragStart] = useState(true)

	const classes = useStyles({ dragStart })
	const customClasses = useCustomStyles()
	const theme = useTheme()
	const params = useParams()
	const workStore = useWorkStore()
	const workTitleInputRef = useRef()

	const { work_id } = params

	const getWorkData = async () => {
		try {
			const { data } = await api.get(`/site/portfolio/work?work_id=${work_id}`)

			setWorkSlug(data.linkInfo.slug)
			setWorkLink(data.linkInfo.link)
			setWorkBaseUrl(data.linkInfo.baseUrl)
			setWorkPublishDate(data.publish_date)
			setWorkLastUpdateDate(data.updated_at)
			setWorkGroupedAndCategories(data.groupedCategoriesWithUsageStatus)
			setWorkDescription(data.description || "")
			setWorkTitle(data.title)
			setWorkLayoutVariant(data.layout_variant)

			const activeCategory = data.groupedCategoriesWithUsageStatus.map(group => {
				const categorySelected = group.categories.find((category) => category.selected)

				return categorySelected
			})

			if (activeCategory.length > 0) {
				setSelectedCategory(activeCategory[0]?.id)
			}

			workStore.setWorkData({
				title: data.title || " ",
				work_id
			})

			setLoadingWorkData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleChangePictures = (values) => {
		setDragStart(false)
		setPictures(values)
	}

	const getPictures = async () => {
		try {
			const { data } = await api.get(`/site/portfolio/picture?work_id=${work_id}`)

			handleChangePictures(data.pictures)

			setLoadingPictures(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}


	const putPicturesOrder = async (valuesReordered) => {
		await api.put("/site/portfolio/picture/all/order", valuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem das imagens alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem das imagens..." })
			})
	}

	const onUploadFinish = async () => {
		await getPictures()
		setWorkLastUpdateDate(new Date())
	}

	const handleSelectPicture = (pictureId) => {
		let updatedPicturesIds = []

		if (selectedPictureIds.includes(pictureId)) {
			updatedPicturesIds = selectedPictureIds.filter(id => id !== pictureId)
		} else {
			updatedPicturesIds = [...selectedPictureIds, pictureId]
		}

		if (updatedPicturesIds.length) {
			setIsDeletePicturesSnackbarOpen(true)
		} else {
			setIsDeletePicturesSnackbarOpen(false)
		}

		setSelectedPictureIds(updatedPicturesIds)
	}

	const handleCloseDeletePicturesSnackbar = () => {
		setSelectedPictureIds([])
		setIsDeletePicturesSnackbarOpen(false)
	}

	const handleTogglePictures = () => {
		const pictureIds = pictures.map((picture) => picture.id)

		if (selectedPictureIds.length === pictureIds.length) {
			handleCloseDeletePicturesSnackbar()
		} else {
			setSelectedPictureIds(pictureIds)
		}
	}

	const handleDeletePictures = () => {
		PopConfirm.open({
			confirmButtonText: "Deletar",
			title: "Tem certeza?",
			description: "Deletar fotos é uma ação irreversível.",
			onConfirm: async () => {
				try {
					await api.delete(`/site/portfolio/picture?picture_ids=${selectedPictureIds}&work_id=${work_id}`)

					await getPictures()

					Notification.success({
						message: selectedPictureIds.length === 1 ? (
							"Foto deletada com sucesso!"
						)
							: (
								"Fotos deletadas com sucesso!"
							)
					})
				} catch (error) {
					triggerUndeterminedValidation(error)
				}

				handleCloseDeletePicturesSnackbar()
			}
		})
	}

	const handleMouseEnterPicture = (pictureId) => {
		setCurrentPictureActionsVisible(pictureId)
	}

	const handleMouseLeavePicture = () => {
		setCurrentPictureActionsVisible(null)
	}

	const createCategoryDialogClose = () => {
		setCreateCategoryDialogData({
			openDialog: false
		})
	}

	const createCategoryDialogOpen = (groupId) => {
		setCreateCategoryDialogData({
			openDialog: true,
			groupId
		})
	}

	const handleSelectOpen = () => {
		setSelectedCategoryOpen(true)
	}

	const handleSelectClose = () => {
		setSelectedCategoryOpen(false)
	}

	const handleSelectCategory = async (categorySelected) => {
		const lastCategoryId = selectedCategory ?? null

		if (typeof categorySelected === "string"
			&& categorySelected.includes("add-new-category")
		) {
			setSelectedCategory(lastCategoryId)
			handleSelectClose()
			createCategoryDialogOpen(+categorySelected.match(/\d+/)[0])
		} else {
			setSelectedCategory(categorySelected)

			try {
				await api.put(`/site/portfolio/work/${work_id}/category`, {
					category_id: categorySelected
				})

				Notification.success({ message: "Categoria alterada com sucesso!" })
			} catch (error) {
				triggerUndeterminedValidation(error)
				setSelectedCategory(lastCategoryId)
			}
		}
	}

	const editCategoryDialogClose = () => {
		setEditCategoriesDialogData({
			openDialog: false
		})
	}

	const editCategoryDialogOpen = () => {
		setEditCategoriesDialogData({
			openDialog: true
		})
	}

	const handleCreateCategory = async (groupId, categoryName) => {
		setCreateCategoryLoading(true)

		try {
			const { data } = await api.post(`/site/portfolio/group/${groupId}/category`, {
				title: categoryName
			})

			await handleSelectCategory(data.category_id)

			await getWorkData()

			setCreateCategoryLoading(false)
			createCategoryDialogClose()

			return "isCreated"
		} catch (error) {
			setCreateCategoryLoading(false)
			triggerValidation(error)

			return error
		}
	}

	const handleDeleteCategory = (category_id) => {
		PopConfirm.open({
			description: "Deletar uma categoria é uma ação irreversível.",
			title: "Tem certeza?",
			confirmButtonText: "Deletar",
			onConfirm: async () => {
				try {
					await api.delete(`/site/portfolio/category?category_id=${category_id}`)

					await getWorkData()

					Notification.success({ message: "Categoria deletada com sucesso!" })
				} catch (error) {
					triggerUndeterminedValidation(error)
				}
			}
		})
	}

	const putCategoryOrder = async (valuesReordered) => {
		const formattedValuesReordered = []
		valuesReordered.map(category => formattedValuesReordered
			.push({
				id: category.id,
				order: category.order
			}))

		await api.put("/site/portfolio/category/all/order", formattedValuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem das categorias alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem das categorias..." })
			})

		await getWorkData()
		setLoadingGroupedCategories(false)
	}

	const handleCategorySortEnd = (oldIndex, newIndex, groupedCategory) => {
		setLoadingGroupedCategories(true)

		const arrayInfo = {
			values: groupedCategory.categories,
			oldIndex,
			newIndex,
			orderAsc: true
		}

		const handleChangeCategories = () => { }

		Sortable.handleSortEnd(arrayInfo, handleChangeCategories, putCategoryOrder)
	}


	const handleChangeCategoryTitle = async (category_id, title) => {
		try {
			await api.put(`/site/portfolio/category/${category_id}`, { title })

			await getWorkData()

			Notification.success({ message: "Nome da categoria alterado com sucesso!" })

			return "titleUpdated"
		} catch (error) {
			return error
		}
	}


	const handleUpdateTitle = async () => {
		try {
			setWorkDescribedDataStatus("saving")

			await api.put(`/site/portfolio/work/${work_id}`, {
				title: workTitle
			})

			await getWorkData()

			setWorkDescribedDataStatus("saved")
		} catch (error) {
			triggerValidation(error)

			setWorkDescribedDataStatus("error")
		}

		setTimeout(() => setWorkDescribedDataStatus("waiting"), 1500)
	}

	const handleSetCover = async (picture_id) => {
		const picturesBackup = [...pictures]

		const updatedPictures = pictures.map(picture => ({
			...picture,
			is_cover: picture.id === picture_id
		}))

		handleChangePictures(updatedPictures)

		try {
			await api.put(`/site/portfolio/picture/${picture_id}/work/${work_id}/cover`)
		} catch (error) {
			triggerUndeterminedValidation(error)
			handleChangePictures(picturesBackup)
		}
	}

	const changeInfoPicture = async (picture_id, value, typeInfo) => {
		try {
			await api.put(`/site/portfolio/picture/info/${picture_id}`, {
				newPictureInfo: {
					[`${typeInfo}`]: value
				}
			})

			if (pictureSettings) {
				await api.put(`/site/portfolio/picture/settings/${picture_id}`, {
					newPictureSettings: pictureSettings
				})
			}

			await getPictures()

			Notification.success({ message: "Informação alterada com sucesso!" })

			return true
		} catch (error) {
			triggerValidation(error)
		}
	}

	const changePictureSettings = async (settingsType, value) => {
		const newSettings = {
			[`${settingsType}`]: value
		}
		setPictureSettings(newSettings)
	}

	const handleWorkPublish = async (publish) => {
		if (publish === true) {
			if (!selectedCategory) {
				return InfoModal.warning({
					title: "Ação necessária",
					description: "É obrigatório selecionar alguma categoria antes de publicar o seu trabalho.",
					showCancelButton: false,
					customConfirmButtons: [
						<Button
							variant="contained"
							color="primary"
						>
							FECHAR
						</Button>
					]
				})
			}

			if (!workTitle) {
				await InfoModal.warning({
					title: "Ação necessária",
					description: "É obrigatório adicionar algum título antes de publicar o seu trabalho.",
					showCancelButton: false,
					customConfirmButtons: [
						<Button
							variant="contained"
							color="primary"
						>
							FECHAR
						</Button>
					]
				})

				return workTitleInputRef.current.children[1].children[0].focus()
			}
		}

		setLoadingPublish(true)

		try {
			await api.put(`/site/portfolio/work/${work_id}/publish`, {
				publish
			})

			await getWorkData()

			if (publish === true) {
				Notification.success({ message: "Trabalho publicado com sucesso!" })
			} else {
				Notification.success({ message: "Trabalho despublicado com sucesso!" })
			}
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setLoadingPublish(false)
	}

	const handleUpdateDescription = async (description) => {
		try {
			await api.put(`/site/portfolio/work/${work_id}`, {
				description: description.toString("html")
			})

			await getWorkData()

			return true
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleUpdateWorkLayoutVariant = async (variant) => {
		const lastVariant = workLayoutVariant

		setWorkLayoutVariant(variant)

		try {
			await api.put(`/site/portfolio/work/${work_id}/settings`, {
				layout_variant: variant
			})

			await getWorkData()

			Notification.success({ message: "Layout do trabalho atualizado com sucesso!" })
		} catch (error) {
			setWorkLayoutVariant(lastVariant)
			triggerUndeterminedValidation(error)
		}
	}

	const handleChangeTitle = (title) => {
		setWorkTitle(title)

		clearValidation("title")
	}

	const handleSortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType, setDragStart(true))
	}

	const handleSortEnd = async ({ oldIndex, newIndex }) => {
		const arrayInfo = {
			values: pictures, oldIndex, newIndex
		}
		Sortable.handleSortEnd(arrayInfo, handleChangePictures, putPicturesOrder)
	}

	const getInitialData = async () => {
		await Promise.all([
			getWorkData(),
			getPictures()
		])
	}

	useDidMount(() => {
		getInitialData()
	})

	return (
		<>
			<Snackbar
				open={isDeletePicturesSnackbarOpen}
				anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
				ContentProps={{
					message: (
						<Container disableGutters className={classes.snackbarContent}>
							<HighlightOffIcon />

							<Divider size="small" orientation="vertical" />

							{selectedPictureIds.length}
							{selectedPictureIds.length === 1 ? " item selecionado" : " itens selecionados"}

							<Button
								variant="text"
								className={classes.selectAllPicturesButton}
								onClick={handleTogglePictures}
							>
								Selecionar todas
							</Button>
						</Container>
					),
					action: (
						<Button
							color="primary"
							variant="contained"
							endIcon={<DeleteOutlinedIcon />}
							onClick={handleDeletePictures}
						>
							Deletar
						</Button>
					),
					className: classes.snackbar
				}}
			/>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={9} lg={9} xl={9}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Portlet>
								<Loading
									loading={loadingWorkData}
									customLoadingElement={<WorkTitleHeaderSkeleton />}
								>
									<Grid item>
										<TextField
											label="Adicione um título"
											fullWidth
											color="secondary"
											value={workTitle}
											error={!!validation.title}
											helperText={validation.title}
											onBlur={handleUpdateTitle}
											ref={workTitleInputRef}
											onChange={({ target }) => handleChangeTitle(target.value)}
										/>

										<Divider size="medium" orientation="horizontal" />

										<Typography variant="body2">
											URL Amigável:
											{" "}
											<Link href={workLink} target="_blank">
												{workBaseUrl}
												/
												<b>{workSlug}</b>
											</Link>
										</Typography>
									</Grid>
								</Loading>
							</Portlet>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Portlet>
								<Loading
									loading={loadingWorkData}
									customLoadingElement={<WorkPictureDropzoneSkeleton />}
								>
									<PicturesUpload
										pictures={pictures}
										onUploadFinish={onUploadFinish}
									/>
								</Loading>
							</Portlet>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							{loadingPictures || pictures.length ? (
								<Portlet>
									<Loading
										loading={loadingPictures}
										customLoadingElement={<WorkPicturesSkeleton />}
									>
										<Sortable.SectionContainer
											pressDelay
											axis="xy"
											useDragHandle
											onSortStart={handleSortStart}
											onSortEnd={handleSortEnd}
										>
											<Grid container spacing={2}>
												{
													pictures.map((picture, index) => (
														<Sortable.SortableSectionItem
															key={`picture-${picture.id}`}
															index={index}
															value={picture}
															disabled={!(pictures.length > 1)}
														>
															<GridItemResponsive
																xs={6}
																sm={6}
																md={4}
																lg={3}
																xl={3}
															>
																<Card>
																	<CardActionArea>
																		<Box
																			className={classes.workPictureBox}
																			onMouseEnter={() => handleMouseEnterPicture(picture.id)}
																			onMouseLeave={handleMouseLeavePicture}
																		>
																			<Fade in={currentPictureActionsVisible === picture.id}>
																				<Container
																					disableGutters
																				>
																					<Grid
																						item
																						className={classes.workPictureActionsContainerTop}
																					>
																						<Grid container justify="space-between">
																							<Grid item>
																								{
																									pictures.length > 1
																									&& (
																										<Sortable.DragHandle>
																											<Tooltip title="Ordenar foto" placement="top" arrow>
																												<IconButton
																													className={
																														classes.workPictureActionButton
																													}
																													size="small"
																													style={
																														{
																															width: 23,
																															height: 23
																														}
																													}
																												>
																													<DragIndicatorAxisXYIcon
																														className={
																															customClasses.contentActionButtonWhite
																														}
																														style={{
																															fontSize: 18,
																															cursor: "grab"
																														}}
																													/>
																												</IconButton>
																											</Tooltip>
																										</Sortable.DragHandle>
																									)
																								}
																							</Grid>

																							<Grid item>
																								<Tooltip title={selectedPictureIds.includes(picture.id) ? "Remover seleção" : "Selecionar foto"} placement="top" arrow>
																									<IconButton
																										className={classes.workPictureActionButton}
																										onClick={() => handleSelectPicture(picture.id)}
																										size="small"
																									>
																										{
																											selectedPictureIds.includes(picture.id)
																												? (
																													<CheckedIcon
																														className={
																															classes.workPictureActionsIcons
																														}
																													/>
																												)
																												: (
																													<UnCheckedIcon
																														className={
																															classes.workPictureActionsIcons
																														}
																													/>
																												)
																										}
																									</IconButton>
																								</Tooltip>
																							</Grid>

																						</Grid>
																					</Grid>

																					<Grid
																						item
																						className={classes.workPictureActionsContainerBottom}
																					>
																						<Tooltip title={picture.is_cover ? "Foto de capa atual" : "Marcar como foto de capa"} placement="top" arrow>
																							<IconButton
																								size="small"
																								className={classes.workPictureActionButton}
																								onClick={() => handleSetCover(picture.id)}
																								style={{ backgroundColor: picture.is_cover && "transparent" }}
																							>
																								{picture.is_cover ? (
																									<StarIcon
																										className={classes.workPictureActionsIcons}
																										style={{ color: "transparent" }}
																									/>
																								)
																									: (
																										<GradeOutlinedIcon
																											className={classes.workPictureActionsIcons}
																										/>
																									)}
																							</IconButton>
																						</Tooltip>

																					</Grid>
																				</Container>
																			</Fade>
																			<FancyBox
																				src={picture.src}
																				caption={picture.title}
																			>
																				<img
																					className={classes.workPictureImage}
																					src={picture.thumb}
																					alt={picture.alt}
																					style={{
																						border: selectedPictureIds.includes(picture.id) ? `10px solid ${theme.palette.secondary.main}` : `0px solid ${theme.palette.secondary.main}`
																					}}
																				/>
																			</FancyBox>

																			{picture.is_cover && (
																				<Container
																					disableGutters
																					className={classes.workPictureActionsContainerBottom}
																					style={{ justifyContent: "flex-end" }}
																				>
																					<IconButton
																						size="small"
																						className={classes.workPictureActionButton}
																					>
																						<StarIcon style={{ color: "yellow" }} fontSize="small" className={classes.workPictureActionsIcons} />
																					</IconButton>
																				</Container>
																			)}

																		</Box>
																	</CardActionArea>
																	<CardContent className={classes.cardContentPictureDescription}>
																		<InputDialog
																			onOpen={() => changePictureSettings(
																				"visible_description",
																				picture
																					.picture_settings?.visible_description
																			)}
																			onCancel={
																				() => changePictureSettings(null)
																			}
																			title="Mudar dados da foto"
																			initialValue={picture.description}
																			onDataChange={() => clearValidation("description")}
																			onOk={(value) => changeInfoPicture(picture.id, value, "description")}
																			description="Essa informação é muito importante para o SEO do seu site, ela é responsável pela indexação das suas fotos no Google."
																			customInputElement={(
																				<TextField
																					defaultValue={picture.description}
																					error={!!validation.description}
																					helperText={validation.description}
																					label="Descrição"
																					variant="standard"
																					color="secondary"
																					size="small"
																					fullWidth
																					InputProps={{
																						endAdornment: <InputAdornment position="end">Máx.: 75 caracteres</InputAdornment>
																					}}
																				/>
																			)}
																			customAditionalElement={(
																				<FormControlLabel
																					control={(
																						<Checkbox
																							checked={
																								pictureSettings.visible_description
																							}
																							onChange={({ target }) => changePictureSettings("visible_description", target.checked, picture.id)}
																							name="visible_description"
																							color="secondary"
																						/>
																					)}
																					label="Mostrar descrição"
																					className={classes.checkBoxPictureSettings}
																				/>
																			)}
																			openOnDrawer={isMobile}
																		>
																			<Typography
																				variant="h4"
																				className={`${customClasses.textEllipsis} ${picture?.description
																					? classes.descriptionPictureContentText
																					: classes.descriptionPictureContentPlaceHolder}`}
																			>
																				{picture?.description
																					? picture.description : "Descrição da imagem"}
																			</Typography>
																		</InputDialog>
																	</CardContent>
																</Card>
															</GridItemResponsive>
														</Sortable.SortableSectionItem>
													))
												}
											</Grid>
										</Sortable.SectionContainer>
									</Loading>
								</Portlet>
							) : null}
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={12}
					sm={12}
					md={3}
					lg={3}
					xl={3}
					className={classes.workPictureAllActionContainer}
				>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Loading loading={loadingWorkData} customLoadingElement={<WorkActionButtonGroup />}>
								<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
									<Grid container spacing={2}>
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
											<Button
												variant="outlined"
												fullWidth
												startIcon={<RemoveRedEyeOutlinedIcon />}
												href={workLink}
												target="_blank"
											>
												Visualizar
											</Button>
										</Grid>
										<Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
											{workPublishDate ? (
												<Button
													variant="text"
													fullWidth
													startIcon={loadingPublish ? (
														<CircularProgress size={16} />
													)
														: (
															<OpenInNewIcon style={{ transform: "rotate(180deg)" }} />
														)}
													onClick={() => handleWorkPublish(false)}
												>
													{loadingPublish ? "Despublicando" : "Despublicar"}
												</Button>
											)
												: (
													<Button
														variant="contained"
														color="primary"
														fullWidth
														endIcon={loadingPublish ? (
															<CircularProgress style={{ color: colors.greyScale[11] }} size={16} />
														)
															: (
																<OpenInNewIcon />
															)}
														onClick={() => handleWorkPublish(true)}
													>
														{loadingPublish ? "Publicando" : "Publicar"}
													</Button>
												)}
										</Grid>
									</Grid>
								</Grid>
							</Loading>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							{loadingWorkData ? (
								<Typography component={Link} className={classes.lastUpdateDate}>
									Carregando última atualização...
								</Typography>
							)
								: (
									<>
										{workDescribedDataStatus === "waiting" && (
											<Typography component={Link} className={classes.lastUpdateDate}>
												Última atualização:
												{" "}
												{moment(workLastUpdateDate).format("DD/MM/YYYY HH:mm").split(" ").join(" às ")}
											</Typography>
										)}
										{workDescribedDataStatus === "error" && (
											<Typography component={Link} className={classes.lastUpdateDate}>
												Erro ao salvar!
											</Typography>
										)}
										{workDescribedDataStatus === "saving" && (
											<Typography component={Link} className={classes.lastUpdateDate}>
												Salvando...
											</Typography>
										)}
										{workDescribedDataStatus === "saved" && (
											<Typography component={Link} className={classes.lastUpdateDate}>
												Salvo!
											</Typography>
										)}
									</>
								)}
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Loading
								loading={loadingWorkData}
								customLoadingElement={<WorkDescriptionBoxSkeleton />}
							>
								<Grid container>
									<Grid xs={12} item>
										<Portlet>
											<Grid container justify="space-between">
												<Grid item xs={10} sm={10}>
													<FormControl style={{ display: "flex" }}>
														<Loading
															loading={createCategoryLoading}
															customLoadingElement={<SelectSkeleton />}
														>
															<>
																<InputLabel
																	disableAnimation
																	color="secondary"
																	id="select-category"
																>
																	Categoria
																</InputLabel>

																<Select
																	id="select-work-category"
																	labelId="select-category"
																	color="secondary"
																	fullWidth
																	onChange={({ target }) => handleSelectCategory(target.value)}
																	value={selectedCategory}
																	open={selectedCategoryOpen}
																	onClose={handleSelectClose}
																	onOpen={handleSelectOpen}
																>
																	<MenuItem disabled selected value="">
																		Selecione uma categoria
																	</MenuItem>
																	{workGroupedAndCategories.map(group => {
																		const groupedMenuItems = [
																			group.categories.map((category) => (
																				<MenuItem value={category.id}>
																					{category.title}
																				</MenuItem>
																			)),
																			<MenuItem
																				onMouseDown={() => handleSelectCategory(`add-new-category-${group.group_id}`)}
																				ontouchstart={() => handleSelectCategory(`add-new-category-${group.group_id}`)}
																				value=""
																			>
																				<ListItemIcon className={classes.addCategoryIcon}>
																					<AddIcon />
																				</ListItemIcon>

																				<ListItemText primary="Nova categoria" />
																			</MenuItem>
																		]

																		return groupedMenuItems
																	})}
																</Select>
															</>
														</Loading>

													</FormControl>

												</Grid>
												<Grid item xs={2} className={classes.settignsCategoryIcon}>
													<IconButton onClick={editCategoryDialogOpen}>
														<SettingsIcon />
													</IconButton>
												</Grid>
											</Grid>

										</Portlet>
									</Grid>

									<Divider size="large" orientation="horizontal" />

									<Grid xs={12} item>
										<Portlet>

											<AsideTabConfig
												handleUpdateDescription={handleUpdateDescription}
												handleUpdateWorkLayoutVariant={handleUpdateWorkLayoutVariant}
												workDescription={workDescription}
												workLayoutVariant={workLayoutVariant}
											/>

										</Portlet>
									</Grid>

								</Grid>
							</Loading>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<CreateCategoryDialog
				groupId={createCategoryDialogData.groupId}
				isOpen={createCategoryDialogData.openDialog}
				loadingCreateCategory={createCategoryLoading}
				onClose={createCategoryDialogClose}
				onCreate={handleCreateCategory}
			/>

			<Dialog
				fullWidth
				open={editCategoriesDialogData.openDialog}
				onClose={editCategoryDialogClose}
			>
				<IconButton onClick={editCategoryDialogClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					Editar categorias
				</DialogTitle>

				<DialogContent>
					<GroupedCategories
						groupedCategories={workGroupedAndCategories}
						loadingGroupedCategories={loadingGroupedCategories}
						onChange={handleChangeCategoryTitle}
						onCreate={handleCreateCategory}
						onDelete={handleDeleteCategory}
						onSortEnd={handleCategorySortEnd}
					/>
				</DialogContent>
			</Dialog>
		</>
	)
}

export default Work
