import React from "react"
import MuiAlert from "@material-ui/lab/Alert"
import { Divider } from "../../../../../../components"


const NoEditorInfo = () => (
	<>
		<Divider orientation="horizontal" size="small" />
		<MuiAlert
			variant="filled"
			severity="info"
		>
			Essa seção não possui configurações.
		</MuiAlert>
		<Divider orientation="horizontal" size="small" />
	</>
)

export default NoEditorInfo
