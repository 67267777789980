import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles({
	breadCrumbLink: {
		color: colors.greyScale[4]
	}
})

export default useStyles
