import { makeStyles } from "@material-ui/core"

import colors from "../../../../styles/colors"

import theme from "../../../../styles/theme"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	dialogContainer: {
		padding: `${theme.spacing() * 6}px`
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},
	loading: {
		color: colors.greyScale[11]
	},
	actions: {
		display: "flex",
		flexDirection: "column",
		"& button": {
			width: "100%",
			maxWidth: 250
		}
	},
	icon: {
		maxWidth: 40,
		height: "auto"
	},
	title: {
		fontWeight: "bolder"
	},
	description: {
		color: colors.greyScale[3]
	},
	link: {
		color: theme.palette.primary.main,
		fontSize: 14,
		textDecoration: "underline",
		textAlign: "center"
	},
	tableContainer: {
		width: "100%",
		maxHeight: 131
	},
	tableHeadTitle: {
		fontWeight: "bolder",
		padding: theme.spacing() * 2,
		width: "100%"
	},
	tableGrid: {
		backgroundColor: "#EDEDED",
		borderRadius: "4px",
		border: "1px solid #D4D4D4"
	}
})

export default useStyles
