import React from "react"
import PropTypes from "prop-types"
import { Grid, Slider as MDSlider } from "@material-ui/core"
import LiveEditorTypography from "../LiveEditorTypography"
import useStyles from "./styles"

const Slider = (props) => {
	const { label, icon, value, onChange, step, min, max } = props

	const classes = useStyles()

	return (
		<Grid
			container
			alignItems="center"
			spacing={1}
		>
			<Grid item xs={12}>
				<LiveEditorTypography
					type="fieldTitle"
				>
					{label}
				</LiveEditorTypography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2} alignItems="center">
					{icon && (
						<Grid item className={classes.icon}>
							{icon}
						</Grid>
					)}

					<Grid item xs>
						<MDSlider
							value={value}
							onChange={onChange}
							color="secondary"
							valueLabelDisplay="auto"
							step={step}
							min={min}
							max={max}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}


Slider.defaultProps = {
	label: "",
	icon: null
}

Slider.propTypes = {
	label: PropTypes.string,
	icon: PropTypes.node,
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	step: PropTypes.number.isRequired,
	min: PropTypes.number.isRequired,
	max: PropTypes.number.isRequired
}
export default Slider
