import { makeStyles } from "@material-ui/core"
import colors from "../../styles/colors"

const borderWidth = "6px"

const useStyles = makeStyles(() => ({
	title: {
		textTransform: "uppercase"
	},
	card: {
		position: "relative",
		height: "100%",
		border: `${borderWidth} solid transparent`,
		cursor: "pointer"
	},
	selectedCard: {
		border: `${borderWidth} solid ${colors.palette.confirm}`
	},
	cardMedia: {
		paddingTop: "100%",
		backgroundSize: "contain"
	}
}))

export default useStyles
