/* eslint-disable react/prop-types */
import React from "react"
import { Grid, TextField, Switch } from "@material-ui/core"
import PropTypes from "prop-types"
import useLiveEditorGlobalStyles from "../../liveEditorGlobalStyles"
import LiveEditorTypography from "../LiveEditorTypography"

const TextInput = ({ label, value, onChange, switchProps, children, ...rest }) => {
	const liveEditorClasses = useLiveEditorGlobalStyles()

	const onInputChange = (event) => {
		onChange(event)

		if (switchProps?.display) {
			switchProps.onChange({ target: { checked: true } })
		}
	}

	return (
		<Grid
			container
			justify="space-between"
			alignItems="center"
			spacing={1}
		>
			<Grid item xs={12}>
				<Grid container justify="space-between" alignItems="center">
					<LiveEditorTypography
						type="fieldTitle"
					>
						{label}
					</LiveEditorTypography>

					{switchProps.display && (
						<Switch
							checked={switchProps.value || false}
							onChange={switchProps.onChange}
							name={`${label}-switch`}
							size="small"
						/>
					)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<TextField
					value={value}
					fullWidth
					className={liveEditorClasses.inputStyle}
					color="secondary"
					onChange={onInputChange}
					// eslint-disable-next-line
					{...rest}
				/>
			</Grid>
		</Grid>
	)
}


TextInput.defaultProps = {
	label: "",
	switchProps: {
		display: false,
		value: false,
		onChange: () => {}
	}
}

TextInput.propTypes = {
	label: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	switchProps: PropTypes.shape({
		display: PropTypes.bool.isRequired,
		value: PropTypes.bool.isRequired,
		onChange: PropTypes.func.isRequired
	}),
	value: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

export default TextInput
