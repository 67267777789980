/* eslint-disable react/prop-types */
import React from "react"
import { Grid, Select as SelectMui } from "@material-ui/core"
import PropTypes from "prop-types"
import useLiveEditorGlobalStyles from "../../liveEditorGlobalStyles"
import LiveEditorTypography from "../LiveEditorTypography"

const Select = (props) => {
	const { label, value, children, ...rest } = props
	const liveEditorClasses = useLiveEditorGlobalStyles()

	return (
		<Grid
			container
			justify="space-between"
			alignItems="center"
			spacing={1}
		>
			<Grid item xs={12}>
				<LiveEditorTypography
					type="fieldTitle"
				>
					{label}
				</LiveEditorTypography>
			</Grid>
			<Grid item xs={12}>
				<SelectMui
					value={value}
					className={liveEditorClasses.selectStyle}
					MenuProps={{
						classes: {
							list: liveEditorClasses.menuSelectStyle
						}
					}}
					fullWidth
					displayEmpty
					color="secondary"
					// eslint-disable-next-line react/jsx-props-no-spreading
					{...rest}
				>
					{children}
				</SelectMui>
			</Grid>
		</Grid>
	)
}


Select.defaultProps = {
	label: ""
}

Select.propTypes = {
	label: PropTypes.string,
	value: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

export default Select
