import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => ({
	logoGrid: {
		width: "100%",
		maxWidth: 400
	},
	placeholder: {
		height: 350,
		maxWidth: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		padding: 50
	},
	logo: {
		maxWidth: "100%",
		maxHeight: 350
	},
	loading: {
		color: colors.greyScale[11]
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		height: theme.spacing() * 10,
		width: "100%"
	}
}))

export default useStyles
