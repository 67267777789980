import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles } from "@material-ui/core"
import { getHeightInPercentageFromProportion } from "../utils/image"

import { Divider } from "../components"

const useStyles = makeStyles({
	bannerPicture: {
		width: "100%",
		paddingBottom: `${getHeightInPercentageFromProportion("16:9")}%`
	},
	bannerPictureDropzone: {
		width: "100%",
		height: 150
	}
})

const BannerEditSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={8} lg={9} xl={9}>
				<Skeleton animation="wave" variant="rect" className={classes.bannerPicture} />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={30} width="40%" />
			</Grid>
			<Grid item xs={12} sm={12} md={4} lg={3} xl={3}>
				<Skeleton animation="wave" variant="rect" className={classes.bannerPictureDropzone} />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={50} width="100%" />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={50} width="100%" />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={50} width="100%" />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={50} width="100%" />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={50} width="100%" />
			</Grid>
		</Grid>
	)
}

export default BannerEditSkeleton
