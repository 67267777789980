import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "../components"

const WorkDescriptionBoxSkeleton = () => (
	<Grid container justify="flex-start">
		<Skeleton animation="wave" variant="rect" width="100%" height={60} />

		<Divider size="large" orientation="horizontal" />

		<Skeleton animation="wave" variant="rect" width="100%" height={60} />

		<Divider size="medium" orientation="horizontal" />

		<Skeleton animation="wave" variant="rect" width="100%" height={290} />
	</Grid>
)

export default WorkDescriptionBoxSkeleton
