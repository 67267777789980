import React from "react"
import PropTypes from "prop-types"

import {
	Grid,
	Button,
	Typography,
	Box
} from "@material-ui/core"

import CustomContainer from "../CustomContainer"
import PremiumChip from "../PremiumChip"
import InfoModal from "../InfoModal"

import useValidation from "../../hooks/useValidation"

import { getStatusCode } from "../../utils/response"
import { siteProPlansUrl } from "../../utils/plan"

import useCustomClasses from "../../styles/custom"

import useStyles from "./styles"
import Divider from "../Divider"

const ActivateFunctionality = (props) => {
	const {
		onActivate,
		illustrationBackground,
		illustrationMobileBackground,
		colorButton,
		titleButton,
		captionButton,
		heightContainer,
		labelContent
	} = props

	const classes = useStyles({
		illustrationBackground,
		backgroundMobile: illustrationMobileBackground.backgroundColor,
		heightContainer
	})
	const customClasses = useCustomClasses()

	const { triggerUndeterminedValidation } = useValidation()

	const handleActivate = async () => {
		try {
			await onActivate()
		} catch (error) {
			if (getStatusCode(error) === 402) {
				InfoModal.warning({
					title: "Ação negada",
					description: "Seu plano atual não permite utilizar essa função.",
					customConfirmButtons: [
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							VER PLANOS
						</Button>
					],
					cancelButtonText: "FECHAR"
				})
			} else {
				triggerUndeterminedValidation(error)
			}
		}
	}

	return (
		<CustomContainer maxWidth="1000px">
			<Grid container justify="space-between" className={classes.container}>
				<Box className={classes.premiumChipContainer}>
					<PremiumChip disableGutters />
				</Box>

				<Grid item xs={12} md={6} className={classes.contentContainer}>
					<Grid container justify="center" className={classes.contentWrapper}>
						<Grid item xs={12}>
							<Grid container className={classes.textContainer} justify="center" alignContent="center" alignItems="center">

								<Grid item xs={12}>
									{labelContent()}
								</Grid>

								<Grid item xs={12} className={classes.btnActiveContainer}>
									<Button
										size="large"
										variant="contained"
										color={colorButton || "secondary"}
										onClick={handleActivate}
										fullWidth
									>
										{titleButton || "Quero ativar"}
									</Button>
									{
										captionButton
										&& (
											<Typography variant="body1" className={classes.captionButton}>
												{captionButton}
											</Typography>
										)
									}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>

				<Grid item xs={12} md={6} className={classes.illustrationContainer}>
					<Grid container justify="flex-end" className={classes.illustrationWrapper}>
						<Grid item xs={12} className={classes.illustrationContent}>
							<Box alt="Illustration" className={classes.illustrationImage}>
								<img className={classes.illustrationImageMobile} src={illustrationMobileBackground.image} alt="" />
							</Box>
						</Grid>
					</Grid>
				</Grid>

			</Grid>

			<Divider size="xlarge" orientation="horizontal" />
		</CustomContainer>
	)
}

ActivateFunctionality.defaultProps = {
	titleButton: "",
	captionButton: "",
	colorButton: "",
	heightContainer: null,
	labelContent: <></>
}

ActivateFunctionality.propTypes = {
	onActivate: PropTypes.func.isRequired,
	illustrationBackground: PropTypes.node.isRequired,
	illustrationMobileBackground: PropTypes.shape({
		image: PropTypes.node,
		backgroundColor: PropTypes.string
	}).isRequired,
	titleButton: PropTypes.string,
	captionButton: PropTypes.string,
	labelContent: PropTypes.node,
	heightContainer: PropTypes.string,
	colorButton: PropTypes.string
}

export default ActivateFunctionality
