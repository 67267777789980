import React, { useState } from "react"
import PropTypes from "prop-types"
import {
	Dialog,
	IconButton,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	ThemeProvider,
	CircularProgress,
	TextField
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import theme from "../../styles/theme"

import { renderComponent } from "../../utils/node"

import Divider from "../Divider"

import useStyles from "./styles"

const PopConfirm = (props) => {
	const { description, title, onConfirm, confirmButtonText, confirmationText } = props

	const classes = useStyles()

	const [opened, setOpened] = useState(true)
	const [loading, setLoading] = useState(false)

	const [confirmationInputError, setConfirmationInputError] = useState("")
	const [confirmationInputText, setConfirmationInputText] = useState("")

	const handleConfirmationInputChange = (text) => {
		setConfirmationInputError("")
		setConfirmationInputText(text)
	}

	const validateConfirmationInputText = async () => {
		if (confirmationInputText === confirmationText) {
			return Promise.resolve()
		}

		if (!confirmationInputText) {
			setConfirmationInputError("O texto de validação precisar ser fornecido.")
		} else {
			setConfirmationInputError("O texto de validação digitado não é igual ao que foi requerido.")
		}

		return Promise.reject()
	}

	const handleClose = () => {
		setOpened(false)
	}

	const handleOk = async () => {
		if (confirmationText) {
			await validateConfirmationInputText()
		}

		setLoading(true)

		await onConfirm()

		setLoading(false)

		handleClose()
	}

	return (
		/**
		 * Since we're invoking this element outside react dom, we need to force the input
		 * of theme provider to get access to theme.
		 */
		<ThemeProvider theme={theme}>
			<Dialog open={opened} onClose={handleClose} className={classes.dialog} fullWidth>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>{title}</DialogTitle>
				<DialogContent dividers>
					{description}

					{confirmationText && (
						<>
							<Divider size="medium" orientation="horizontal" />

							Para confirmar, digite "
							{confirmationText}
							" no campo abaixo.

							<Divider size="small" orientation="horizontal" />

							<TextField
								placeholder={confirmationText}
								color="secondary"
								variant="outlined"
								size="small"
								error={!!confirmationInputError}
								helperText={confirmationInputError}
								onChange={({ target }) => handleConfirmationInputChange(target.value)}
								value={confirmationInputText}
								fullWidth
							/>
						</>
					)}
				</DialogContent>

				<DialogActions>
					<Button onClick={handleClose} color="inherit">
						Cancelar
					</Button>
					<Button
						endIcon={loading && <CircularProgress size={20} className={classes.loading} />}
						onClick={handleOk}
						color="primary"
						variant="contained"
						htmlType="submit"
					>
						{confirmButtonText}
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

PopConfirm.defaultProps = {
	confirmationText: null
}

PopConfirm.propTypes = {
	description: PropTypes.string.isRequired,
	confirmButtonText: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	onConfirm: PropTypes.func.isRequired,
	confirmationText: PropTypes.string
}

PopConfirm.open = ({
	description,
	confirmButtonText,
	title,
	onConfirm,
	confirmationText
}) => {
	renderComponent(
		"pop-confirm",
		<PopConfirm
			description={description}
			confirmButtonText={confirmButtonText}
			title={title}
			onConfirm={onConfirm}
			confirmationText={confirmationText}
		/>
	)
}

export default PopConfirm
