import { useEffect, useState } from "react"

import theme from "../styles/theme"

const useGridResponsive = ({ sm, md, lg, xl }) => {
	const [currentColumns, setCurrentColumns] = useState(0)

	let cache = {}

	const isUnder = (breakpoint) => window.innerWidth <= theme.breakpoints.values[breakpoint]

	const setCache = (breakpoint) => {
		cache = {}

		cache[breakpoint] = true
	}

	const setColumn = () => {
		if (isUnder("sm")) {
			if (!cache.sm) {
				setCache("sm")
				setCurrentColumns(sm)
			}
		} else if (isUnder("md")) {
			if (!cache.md) {
				setCache("md")
				setCurrentColumns(md)
			}
		} else if (isUnder("lg")) {
			if (!cache.lg) {
				setCache("lg")
				setCurrentColumns(lg)
			}
		} else if (isUnder("xl")) {
			if (!cache.xl) {
				setCache("xl")
				setCurrentColumns(xl)
			}
		} else {
			// eslint-disable-next-line
			if (!cache.xl) {
				setCache("xl")
				setCurrentColumns(xl)
			}
		}
	}

	useEffect(() => {
		setColumn()

		window.addEventListener("resize", setColumn)

		return () => window.removeEventListener("resize", setColumn)
		// eslint-disable-next-line
	}, [])

	return currentColumns
}

export default useGridResponsive
