import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, makeStyles, createStyles } from "@material-ui/core"

import { GridItemResponsive } from "../components"

import { getHeightInPercentageFromProportion } from "../utils/image"

const useStyles = makeStyles(theme => createStyles({
	media: {
		width: "auto",
		paddingBottom: `${getHeightInPercentageFromProportion("16:9")}%`,
		margin: theme.spacing(1)
	},
	card: {
		width: "100%",
		height: "auto"
	}
}))

const BannersSkeleton = () => {
	const classes = useStyles()

	const cards = []

	// eslint-disable-next-line
	for (let i = 0; i < 3; i++) {
		cards.push(
			<GridItemResponsive
				xs={12}
				sm={6}
				md={6}
				lg={3}
				xl={3}
			>
				<Card className={classes.card}>
					<Skeleton animation="wave" variant="rect" className={classes.media} />
				</Card>
			</GridItemResponsive>
		)
	}

	return (
		<Grid container wrap="wrap" spacing={2}>
			{cards}
		</Grid>
	)
}

export default BannersSkeleton
