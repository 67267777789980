import React, { useState } from "react"
import PropTypes from "prop-types"

import { Grid, Typography, Link } from "@material-ui/core"
import {
	CheckCircleOutline as SuccessIcon,
	ErrorOutline as ErrorIcon
} from "@material-ui/icons"

import { Portlet, Divider, Loading } from "../../../components"

import { useSiteStore } from "../../../store"

import api from "../../../services/api"

import useDidMount from "../../../hooks/useDidMount"
import useValidation from "../../../hooks/useValidation"

import WaitingAuditSkeleton from "../../../skeletons/TuboSeoWaitingAudit"

import { urlLength, portfolioWorkDescriptionLength } from "../recomendedParams"

import WorksDescriptionsProblemsModal from "./WorksDescriptionsProblemsModal"

import useStyles from "./styles"

const Diagnosis = (props) => {
	const { handleGoToPagesTab } = props

	const classes = useStyles()
	const { triggerUndeterminedValidation } = useValidation()

	const siteStore = useSiteStore()

	const [turboSeoPagesAudit, setTurboSeoPagesAudit] = useState(null)
	const [worksDescriptionsAudit, setWorksDescriptionsAudit] = useState(null)
	const [waitingAudits, setWaitingAudits] = useState({
		turboSeoPages: false,
		workDescriptions: false
	})

	const isDomainOk = siteStore?.state?.url !== siteStore?.state?.picsize_site_url

	const isUrlLengthOk = siteStore?.state?.url?.length <= urlLength

	const auditTurboSeoPages = async () => {
		setWaitingAudits({
			...waitingAudits,
			turboSeoPages: true
		})

		try {
			const response = await api.get("/turbo-seo/audit/pages-metadata")
			const auditResult = {
				...response?.data?.auditResult,
				isOk: response?.data?.auditResult?.status === "SUCCESS"
			}
			setTurboSeoPagesAudit(auditResult)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setWaitingAudits({
			...waitingAudits,
			turboSeoPages: false
		})
	}

	const auditPortfolioWorksDescriptions = async () => {
		setWaitingAudits({
			...waitingAudits,
			workDescriptions: true
		})

		try {
			const response = await api.get(
				"/turbo-seo/audit/portfolio-works-descriptions",
				{
					params: {
						min_description_length: portfolioWorkDescriptionLength
					}
				}
			)

			const auditResult = {
				...response?.data?.auditResult,
				isOk: response?.data?.auditResult?.status === "SUCCESS"
			}
			setWorksDescriptionsAudit(auditResult)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setWaitingAudits({
			...waitingAudits,
			workDescriptions: false
		})
	}

	const handleOpenModalWorksDescriptionProblems = () => {
		WorksDescriptionsProblemsModal.open({
			works: worksDescriptionsAudit?.works_with_insufficient_description,
			onClose: auditPortfolioWorksDescriptions
		})
	}

	useDidMount(() => {
		auditTurboSeoPages()
		auditPortfolioWorksDescriptions()
	}, [])

	const metrics = [
		{
			title: "TÍTULOS E DESCRIÇÕES DE TODAS AS PÁGINAS",
			body: (
				<Typography className={classes.description}>
					{turboSeoPagesAudit?.isOk
						? "Todas as páginas do seu site estão com títulos e descrições adequadas."
						: (
							<>
								Algumas das páginas do seu site estão sem títulos ou descrições adequadas.

								<Divider orientation="horizontal" size="small" />

								<Link
									component="button"
									onClick={handleGoToPagesTab}
									className={classes.descriptionLink}
								>
									Acesse aqui para otimizar os títulos e descrições do seu site.
								</Link>
							</>
						)}
				</Typography>
			),
			isOk: turboSeoPagesAudit?.isOk,
			isLoading: waitingAudits.turboSeoPages
		},
		{
			title: "Descrição dos trabalhos de portfólio",
			body: (
				<Typography className={classes.description}>
					{/* eslint-disable-next-line */}
					{
						worksDescriptionsAudit?.isOk
							? "Todos os trabalhos que você enviou para o seu portfólio estão com descrições adequadas."
							: (
								<>
									{worksDescriptionsAudit?.works_with_insufficient_description.length}
									{" "}
									de
									{" "}
									{worksDescriptionsAudit?.n_total_works}
									{" "}
									trabalhos que você enviou para o seu portfólio estão com descrições com menos de
									{" "}
									{portfolioWorkDescriptionLength}
									{" "}
									caracteres.

									<Divider orientation="horizontal" size="small" />

									<Link
										component="button"
										onClick={handleOpenModalWorksDescriptionProblems}
										className={classes.descriptionLink}
									>
										Veja quais os trabalhos que apresentarem problemas.
									</Link>
								</>
							)
					}
				</Typography>
			),
			isOk: worksDescriptionsAudit?.isOk,
			isLoading: waitingAudits.workDescriptions
		},
		{
			title: "Segurança do site (certificado SSL)",
			body: (
				<Typography className={classes.description}>
					O seu site está com o certificado SSL configurado corretamente!
				</Typography>
			),
			isOk: true,
			isLoading: false
		},
		{
			title: "Domínio próprio",
			body: (
				<Typography className={classes.description}>
					{/* eslint-disable-next-line */}
					Crie o seu domínio próprio, isso demostra maior profissionalismo, dando credibilidade ao seu cliente e também ao Google.

					<Divider orientation="horizontal" size="small" />

					Para registrar um domínio
					{" "}
					<b>.com.br</b>
					{" "}
					ou qualquer outro que termine com
					{" "}
					<b>.br</b>
					, acesse:

					<br />

					<Link href="https://registro.br" target="_blank" className={classes.descriptionLink}>
						https://registro.br
					</Link>

					<Divider orientation="horizontal" size="small" />

					Para registrar outro domínios, como
					{" "}
					<b>.com</b>
					{" "}
					por exemplo,a acesse:

					<br />

					<Link
						href="https://www.hostnet.com.br"
						target="_blank"
						className={classes.descriptionLink}
					>
						https://www.hostnet.com.br
					</Link>

				</Typography>
			),
			isOk: isDomainOk,
			isLoading: false
		},
		{
			title: "Velocidade do site",
			body: (
				<Typography className={classes.description}>
					{/* eslint-disable-next-line */}
					O nosso sistema otimiza o máximo possível para que o seu site seja carregado rapidamente. Ele se encontra dentro dos padrões exigidos pelo Google, no PC e no Celular.
				</Typography>
			),
			isOk: true,
			isLoading: false
		},
		{
			title: "Tamanho das fotos",
			body: (
				<Typography className={classes.description}>
					{/* eslint-disable-next-line */}
					As fotos do seu site estão no tamanho adequado para boa renderização tanto no computador quanto no celular. O nosso sistema tem um sistema agregado que de forma inteligente, compacta a sua foto mantendo a qualidade e deixando o carregamento extremamente rápido.
				</Typography>
			),
			isOk: true,
			isLoading: false
		},
		{
			title: "Tamanho da URL",
			body: (
				<Typography className={classes.description}>
					{/* eslint-disable-next-line */}
					Mecanismos de busca mostram até {urlLength} caracteres da sua URL. URLs maiores podem ter menos cliques nas páginas de resultados das buscas.
				</Typography>
			),
			isOk: isUrlLengthOk,
			isLoading: false
		}

	]

	const MetricCard = (metric) => (
		<Loading loading={metric.isLoading} customLoadingElement={<WaitingAuditSkeleton />}>
			<Portlet disablePadding>
				<Grid
					container
					spacing={2}
					alignItems="center"
					className={classes.cardContainer}
				>
					<Grid item sm={1} xs={2}>
						{metric.isOk
							? (<SuccessIcon className={classes.successIcon} />)
							: (<ErrorIcon className={classes.errorIcon} />)}
					</Grid>

					<Grid item sm={11} xs={10}>
						<Grid
							container
							spacing={2}
							alignItems="flex-start"
							className={classes.pageInfoContainer}
						>
							<Grid item xs={12}>
								<Typography className={classes.title}>
									{metric.title}
								</Typography>
							</Grid>

							<Grid item xs={12}>
								{metric.body}
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Portlet>
		</Loading>
	)

	return (
		<Grid container spacing={4} className={classes.pagesContainer}>
			{metrics.map(metric => (
				<Grid item xs={12}>
					{MetricCard(metric)}
				</Grid>
			))}
		</Grid>
	)
}

Diagnosis.propTypes = {
	handleGoToPagesTab: PropTypes.func.isRequired
}
export default Diagnosis
