// eslint-disable-next-line
export const handleBackButton = ({ active, onBackButtonPress }) => {
	const lastPathName = window.location.pathname

	if (active) {
		/**
		 * Creates a new history without changing any page data.
		 */
		window.history.pushState("forward", null, window.location.pathname)

		/**
		 * if it goes to the last pathname we trigger the callback function
		 * since it means the user has gone back with back button.
		 */
		window.onpopstate = () => {
			if (lastPathName === window.location.pathname) {
				onBackButtonPress()
			}

			// eslint-disable-next-line
			window.onpopstate = null
		}
	}
}
