import { makeStyles } from "@material-ui/core"

import colors from "../../../styles/colors"

const headerHeight = "48px"
const tabsHeight = "48px"
const mobileActionBarHeight = "52px"
export const consoleMaxWidth = "320px"

const useStyles = makeStyles(theme => ({
	consoleContainer: {
		transition: "0.3s",
		borderRight: `1px solid ${colors.greyScale[9]}`,
		maxWidth: "100%",
		transformOrigin: "left center",
		width: ({ isConsoleVisible }) => `${isConsoleVisible ? "100%" : 0}`,

		[theme.breakpoints.up("md")]: {
			maxWidth: consoleMaxWidth
		}
	},
	tabsContainer: {
		height: tabsHeight,
		borderBottom: `1px solid ${colors.greyScale[9]}`
	},
	consoleContent: {
		height: `calc(100vh - ${headerHeight} - ${tabsHeight} - ${mobileActionBarHeight})`,
		overflowY: "scroll",

		"&::-webkit-scrollbar": {
			width: "10px",
			height: "10px",
			backgroundColor: "rgba(0,0,0,0.1)"
		},
		"&::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0)",
			borderRadius: "100px"
		},
		"&:hover::-webkit-scrollbar-thumb": {
			backgroundColor: "rgba(0, 0, 0, 0.25)"
		},

		[theme.breakpoints.up("md")]: {
			height: `calc(100vh - ${headerHeight} - ${tabsHeight})`
		}
	}
}))

export default useStyles
