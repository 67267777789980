import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"

import {
	Grid,
	Card,
	Container,
	CardMedia,
	Button,
	ListItem,
	ListItemText,
	List,
	Typography,
	useMediaQuery,
	useTheme,
	IconButton
} from "@material-ui/core"

import {
	Close as CloseIcon,
	VisibilityOutlined as VisualizeIcon,
	Done as DoneIcon
} from "@material-ui/icons"

import Divider from "../Divider"
import Loading from "../Loading"

import api from "../../services/api"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import { handleBackButton } from "../../utils/backButton"

import useStyles from "./styles"

import Iframe from "./Iframe"

import ThemeGallerySkeleton from "../../skeletons/ThemeGallery"
import { isMobile } from "../../utils/checkDevice"

const ThemeGallery = ({ onClose, handleChangeTheme, loading, currentThemeId }) => {
	const classes = useStyles()

	const MDTheme = useTheme()
	const isSmall = useMediaQuery(MDTheme.breakpoints.down("sm"))
	const isExtraSmall = useMediaQuery(MDTheme.breakpoints.down("xs"))

	const { triggerUndeterminedValidation } = useValidation()

	const [themeGalleryCategories, setThemeGalleryCategories] = useState([])
	const [loadingThemeGalleryCategories, setLoadingThemeGalleryCategories] = useState(true)
	const [changedTheme, setChangedTheme] = useState(false)

	const [activeCategoryIndex, setActiveCategoryIndex] = useState(null)
	const [activeSubcategoryIndex, setActiveSubcategoryIndex] = useState(null)

	const [iframeThemeVariant, setIframeThemeVariant] = useState(null)

	const containerRef = useRef(null)

	const localHandleChangeTheme = (site_theme_id) => {
		setChangedTheme(true)
		handleChangeTheme(site_theme_id)
	}

	const localOnClose = () => {
		onClose(changedTheme)
	}

	const handleClickMenu = (categoryIndex, subcategoryIndex) => {
		setActiveCategoryIndex(categoryIndex)
		setActiveSubcategoryIndex(subcategoryIndex)

		if (!isMobile) {
			containerRef.current.scrollIntoView({ behavior: "smooth" })
		}
	}

	const handleDisplaySiteOnIframe = (themeVariant) => {
		setIframeThemeVariant(themeVariant)
	}

	const getThemeGalleryCategories = async () => {
		try {
			const { data } = await api.get("/theme-gallery/category/list")

			setThemeGalleryCategories(data?.categories)
			setActiveCategoryIndex(0)
			setActiveSubcategoryIndex(0)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const getData = async () => {
		await Promise.all([
			getThemeGalleryCategories()
		])

		setLoadingThemeGalleryCategories(false)
	}

	const onIframeClose = () => {
		setIframeThemeVariant(null)
	}

	useDidMount(() => {
		getData()
	})

	useEffect(() => {
		handleBackButton({
			active: isMobile,
			onBackButtonPress: localOnClose
		})
	}, [iframeThemeVariant])


	const ThemeCard = (theme) => {
		const cardContainerClassName = [classes.cardContainer]

		if (theme.site_theme_id === currentThemeId) {
			cardContainerClassName.push(classes.cardContainerActive)
		}

		return (
			<>
				<Container maxWidth={false} className={cardContainerClassName}>
					<Card
						elevation={0}
						className={classes.card}
					>
						<Container disableGutters>
							<CardMedia
								image={theme.desktop_cover_url}
								title={theme.title}
								className={classes.cardMedia}
							/>
						</Container>
					</Card>

					<Grid container justify="center" spacing={2} className={classes.cardFooterButtonsContainer}>
						<Grid item xs={isExtraSmall ? 12 : undefined}>
							<Button
								variant="outlined"
								onClick={() => handleDisplaySiteOnIframe(theme)}
								startIcon={<VisualizeIcon />}
								fullWidth
							>
								Visualizar
							</Button>
						</Grid>

						<Grid item xs={isExtraSmall ? 12 : undefined}>
							<Button
								className={classes.cardSelectThisButton}
								variant="contained"
								onClick={() => localHandleChangeTheme(theme.site_theme_id)}
								startIcon={<DoneIcon />}
								fullWidth
							>
								Aplicar tema
							</Button>
						</Grid>
					</Grid>
				</Container>
			</>
		)
	}

	const Gallery = () => (
		<Grid container spacing={isSmall ? 2 : 0} className={classes.galleryContainer}>
			{onClose && (
				<IconButton onClick={localOnClose} className={classes.closeButton}>
					<CloseIcon />
				</IconButton>
			)}

			<Grid item xs={12} md={4} lg={3} xl={2} className={classes.menuContainer} ref={containerRef}>
				<Container disableGutters maxWidth={false} className={classes.menu}>
					<Typography component="h2" className={classes.title}>Galeria de Temas</Typography>

					<Divider orientation="horizontal" size="small" />

					<List>
						{themeGalleryCategories?.map((category, categoryIndex) => (
							<ListItem key={category.id} className={classes.categoryListItem} disableGutters>
								<ListItemText primary={category.title} disableTypography />

								<List>
									{category?.subcategories?.map((subcategory, subcategoryIndex) => {
										const isActiveCategory = categoryIndex === activeCategoryIndex
										const isActiveSubcategory = subcategoryIndex === activeSubcategoryIndex
										const listItemClassName = [classes.subcategoryListItem]

										if (isActiveCategory && isActiveSubcategory) {
											listItemClassName.push(classes.subcategoryListItemActive)
										}

										return (
											<ListItem
												key={subcategory.id}
												className={listItemClassName}
												button
												onClick={() => handleClickMenu(categoryIndex, subcategoryIndex)}
											>
												<ListItemText primary={subcategory.title} disableTypography />
											</ListItem>
										)
									}
									)}
								</List>
							</ListItem>
						))}
					</List>
				</Container>
			</Grid>

			<Grid item xs={12} md={8} lg={9} xl={10}>
				{(activeCategoryIndex !== null && activeSubcategoryIndex !== null)
					&& (
						<Grid container spacing={isSmall ? 4 : 6}>
							{themeGalleryCategories[activeCategoryIndex]
								.subcategories[activeSubcategoryIndex]
								.theme_variants
								.map(theme_variant => (
									<Grid key={theme_variant.id} item xs={12} lg={6}>
										{ThemeCard(theme_variant)}
									</Grid>
								))}
						</Grid>
					)}
			</Grid>
		</Grid>
	)


	const IframePreview = () => (
		<Iframe
			src={iframeThemeVariant.preview_url}
			themeTitle={iframeThemeVariant.base_theme_title}
			onClosePreview={onIframeClose}
			onUseThisTheme={() => handleChangeTheme(iframeThemeVariant.site_theme_id)}
		/>
	)

	return (
		<Container maxWidth={false} disableGutters className={classes.container}>
			<Loading
				loading={loadingThemeGalleryCategories || loading}
				customLoadingElement={<ThemeGallerySkeleton />}
			>
				{iframeThemeVariant ? IframePreview() : Gallery()}
			</Loading>
		</Container>
	)
}

ThemeGallery.propTypes = {
	onClose: PropTypes.func,
	handleChangeTheme: PropTypes.func,
	loading: PropTypes.bool,
	currentThemeId: PropTypes.number
}

ThemeGallery.defaultProps = {
	onClose: () => { },
	handleChangeTheme: () => { },
	loading: false,
	currentThemeId: null
}

export default ThemeGallery
