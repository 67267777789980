import React from "react"
import PropTypes from "prop-types"

import ChangeLayout from "../../../../components/ChangeLayout"
import { Divider } from "../../../../../../components"

import SectionDefaultDesign from "../SectionDefaultDesign"

const InstagramFeed1 = ({ sectionId }) => (
	<>
		<Divider orientation="horizontal" size="medium" />

		<ChangeLayout pageSectionId={sectionId} />

		<Divider orientation="horizontal" size="medium" />

		<SectionDefaultDesign sectionId={sectionId} />
	</>
)

InstagramFeed1.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default InstagramFeed1
