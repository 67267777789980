import React, { useState } from "react"
import { Link, useHistory } from "react-router-dom"
import {
	Grid,
	Card,
	Container,
	CardMedia,
	Fab,
	CircularProgress,
	Typography,
	IconButton,
	Tooltip,
	Box
} from "@material-ui/core"

import {
	Add as AddIcon,
	Edit as EditIcon,
	DeleteOutlined as DeleteOutlinedIcon,
	OpenWith as DragIndicatorAxisXYIcon
} from "@material-ui/icons"

import {
	Loading,
	GridItemResponsive,
	Divider,
	PopConfirm,
	ActionZone,
	Notification,
	Sortable
} from "../../components"

import { bannerPictureFocusMap } from "./Edit"

import BannersSkeleton from "../../skeletons/Banners"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import { useCustomerStore } from "../../store"

import { getStatusCode } from "../../utils/response"

import api from "../../services/api"

import useStyles from "./styles"
import useCustomStyles from "../../styles/custom"

const Banner = () => {
	const [banners, setBanners] = useState([])

	const [loadingCurrentProportion, setLoadingCurrentProportion] = useState(true)
	const [loadingGetBanners, setLoadingGetBanners] = useState(true)
	const [loadingCreateBanner, setLoadingCreateBanner] = useState(false)

	/*
	* This variable was needed  to implement a workaround related to the banner proportion on fp
	*/
	const [currentProportion, setCurrentProportion] = useState("")

	const classes = useStyles({ currentProportion })
	const customClasses = useCustomStyles()
	const history = useHistory()

	const customerStore = useCustomerStore()

	const { triggerUndeterminedValidation } = useValidation()

	const handleChangeBanners = (values) => {
		setBanners(values)
	}

	const putBannerOrder = async (valuesReordered) => {
		await api.put("/site/banner/all/order", valuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem dos banners alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem dos banners..." })
			})
	}

	const getBanners = async () => {
		try {
			const { data } = await api.get("/site/banner/list")

			const orderBanners = data.banners

			handleChangeBanners(orderBanners)

			setLoadingGetBanners(false)
		} catch (error) {
			if (getStatusCode(error) === 404) {
				handleChangeBanners([])
				setLoadingGetBanners(false)
			} else {
				triggerUndeterminedValidation(error)
			}
		}
	}

	const handleCreateBanner = async () => {
		setLoadingCreateBanner(true)

		const nonEditedBanner = banners.find(banner => !banner.was_edited)

		let bannerId

		if (nonEditedBanner) {
			bannerId = nonEditedBanner.id
		} else {
			const { data } = await api.post("/site/banner")

			bannerId = data.banner_id
		}

		customerStore.sumUserUsageBannersCountBy(+1)

		history.push(`/banner/edit/${bannerId}`)

		setLoadingCreateBanner(false)
	}

	const handleDeleteBanner = async (bannerId) => {
		PopConfirm.open({
			description: "Deletar um banner é uma ação irreversível.",
			title: "Tem certeza?",
			confirmButtonText: "Deletar",
			onConfirm: async () => {
				try {
					await api.delete(`/site/banner?id=${bannerId}`)

					await getBanners()

					Notification.success({ message: "Banner deletado com sucesso!" })

					customerStore.sumUserUsageBannersCountBy(-1)
				} catch (error) {
					triggerUndeterminedValidation(error)
				}
			}
		})
	}

	const bannersFilterWasEdited = () => banners.filter(banner => banner.was_edited)

	const handleSortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType)
	}

	const handleSortEnd = async ({ oldIndex, newIndex }) => {
		const arrayInfo = {
			values: bannersFilterWasEdited(),
			oldIndex,
			newIndex
		}
		Sortable.handleSortEnd(arrayInfo, handleChangeBanners, putBannerOrder)
	}

	const getBannerProportion = async () => {
		const response = await api.get("/site/live-editor/data")
		const { sections } = response.data
		const headerBanner = sections.find(section => section.html_tag === "header_index")
		const proportion = headerBanner.properties.banner_picture_proportion || "16:9"
		const fullscreen = !!headerBanner.properties.banner_full_size
		setCurrentProportion(fullscreen ? "16:9" : proportion)
		setLoadingCurrentProportion(true)
	}

	useDidMount(() => {
		getBanners()
		getBannerProportion()
	})

	return (
		<Loading
			loading={loadingGetBanners && loadingCurrentProportion}
			customLoadingElement={<BannersSkeleton />}
		>
			<Sortable.SectionContainer
				pressDelay
				axis="xy"
				useDragHandle
				onSortStart={handleSortStart}
				onSortEnd={handleSortEnd}
			>
				<Grid container spacing={2}>
					<GridItemResponsive
						xs={12}
						sm={6}
						md={6}
						lg={3}
						xl={3}
					>
						<Box className={classes.createBannerContainer}>
							<ActionZone className={classes.createBannerActionZone} onClick={handleCreateBanner}>
								<Fab color="primary" className={classes.fab}>
									{loadingCreateBanner ? <CircularProgress style={{ color: "#FFF" }} size={20} /> : <AddIcon />}
								</Fab>

								<Divider size="large" orientation="horizontal" />

								<Typography variant="h3">
									Novo banner
								</Typography>
							</ActionZone>
						</Box>
					</GridItemResponsive>


					{
						banners
							.filter(banner => banner.was_edited)
							.map((banner, index) => (
								<Sortable.SortableSectionItem
									key={`banner-${banner.id}`}
									index={index}
									value={banner}
									disabled={!(bannersFilterWasEdited().length > 1)}
								>
									<GridItemResponsive
										xs={12}
										sm={6}
										md={6}
										lg={3}
										xl={3}
									>
										<Card
											elevation={1}
											className={classes.bannerCard}
										>
											<Container disableGutters className={classes.bannerCardActionArea}>
												<Container
													disableGutters
													className={classes.bannerCardActionContainer}
													style={{ top: 0 }}
												>
													<Grid container justify="space-between">

														<Grid item>
															{
																bannersFilterWasEdited().length > 1
																&& (
																	<Sortable.DragHandle>
																		<Tooltip title="Ordenar banner" placement="top" arrow>
																			<IconButton
																				className={customClasses.containerActionButtonDark}
																				size="small"
																			>
																				<DragIndicatorAxisXYIcon
																					className={customClasses.contentActionButtonWhite}
																					style={{ cursor: "grab" }}
																				/>
																			</IconButton>
																		</Tooltip>
																	</Sortable.DragHandle>
																)
															}
														</Grid>


														<Grid item>
															<Tooltip title="Editar banner" placement="top" arrow>
																<Link to={`/banner/edit/${banner.id}`}>
																	<IconButton
																		className={customClasses.containerActionButtonDark}
																		size="small"
																	>
																		<EditIcon className={customClasses.contentActionButtonWhite} />
																	</IconButton>
																</Link>
															</Tooltip>
														</Grid>
													</Grid>

												</Container>

												<Container disableGutters className={classes.bannerTypographyContainer}>
													<Typography className={`${customClasses.listCaptionText} ${classes.bannerTypography}`}>
														{banner.uppertitle || "Sobretítulo"}
													</Typography>

													<Divider size="small" orientation="horizontal" />

													<Typography variant="h3" className={classes.bannerTypography}>
														{banner.title || "TÍTULO PRINCIPAL"}
													</Typography>

													<Divider size="small" orientation="horizontal" />

													<Typography className={`${customClasses.listCaptionText} ${classes.bannerTypography}`}>
														{banner.subtitle || "Subtítulo"}
													</Typography>
												</Container>

												<Container
													disableGutters
													className={classes.bannerCardActionContainer}
													style={{ bottom: 0 }}
												>
													<Grid container justify="flex-end">
														<Tooltip title="Excluir banner" placement="bottom" arrow>
															<IconButton
																className={customClasses.containerActionButtonDark}
																size="small"
																onClick={() => handleDeleteBanner(banner.id)}
															>
																<DeleteOutlinedIcon className={
																	customClasses.contentActionButtonWhite
																}
																/>
															</IconButton>
														</Tooltip>
													</Grid>
												</Container>
											</Container>

											<CardMedia
												image={banner.src ?? null}
												className={`${classes.bannerPicture} 
												${!banner?.src && classes.bannerPictureWitoutPicture}`}
												style={{
													backgroundPosition: (
														banner.src ? (
															banner.picture_focus
														) : (bannerPictureFocusMap[0]))
												}}
											/>
										</Card>
									</GridItemResponsive>
								</Sortable.SortableSectionItem>
							))
					}
				</Grid>
			</Sortable.SectionContainer>
		</Loading>
	)
}

export default Banner
