import { createStyles, makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

import { getHeightInPercentageFromProportion } from "../../utils/image"

const useStyles = makeStyles(theme => createStyles({
	bannerCard: {
		width: "100%",
		position: "relative"
	},
	bannerPicture: {
		height: 0,
		paddingBottom: props => `${getHeightInPercentageFromProportion(
			props.currentProportion || "16:9"
		)}%`
	},
	bannerPictureWitoutPicture: {
		backgroundColor: colors.greyScale[9]
	},
	bannerCardActionArea: {
		position: "absolute",
		height: "100%",
		display: "flex",
		alignItems: "center"
	},
	bannerTypographyContainer: {
		textAlign: "center",
		userSelect: "none"
	},
	bannerTypography: {
		color: colors.greyScale[11]
	},
	bannerCardActionContainer: {
		position: "absolute",
		padding: theme.spacing(1)
	},
	createBannerContainer: {
		position: "relative",
		paddingBottom: props => `${getHeightInPercentageFromProportion(
			props.currentProportion || "16:9"
		)}%`
	},
	createBannerActionZone: {
		position: "absolute",
		padding: 0,
		height: "100%",
		width: "100%"
	},
	fab: {
		"&:hover": {
			backgroundColor: colors.palette.primary
		}
	}
}))

export default useStyles
