import React from "react"
import PropTypes from "prop-types"
import {
	Grid,
	Button,
	CircularProgress
} from "@material-ui/core"

import {
	OpenInNew as OpenInNewIcon,
	Edit as EditIcon,
	Visibility as VisibilityIcon
} from "@material-ui/icons"

import useStyles from "./styles"

import { useLiveEditorStore } from "../../../store"

const LiveEditor = ({
	publishing,
	handlePublish,
	isMobileLivePreviewActive,
	setIsMobileLivePreviewActive
}) => {
	const classes = useStyles()

	const liveEditorStore = useLiveEditorStore()

	const { changedData } = liveEditorStore.state

	const handleToggleMobileLivePreview = () => {
		setIsMobileLivePreviewActive(!isMobileLivePreviewActive)
	}

	const getPublishButtonText = () => {
		if (publishing) {
			return "Publicando"
		} if (liveEditorStore.state.changedData) {
			return "Publicar"
		}
		return "Publicado"
	}

	return (
		<Grid container className={classes.mobileActionBar} alignItems="center" justify="space-between">
			<Grid item style={{ alignSelf: "stretch" }}>
				<Button
					variant="contained"
					color="secondary"
					onClick={handlePublish}
					className={classes.publishButton}
					disabled={publishing || !changedData}
					endIcon={publishing ? (
						<CircularProgress color="secondary" size={16} />
					) : (<OpenInNewIcon />)}
				>
					{getPublishButtonText()}
				</Button>
			</Grid>

			<Button
				onClick={handleToggleMobileLivePreview}
				variant="contained"
				color="primary"
				startIcon={
					isMobileLivePreviewActive ? <EditIcon /> : <VisibilityIcon />
				}
			>
				{
					isMobileLivePreviewActive ? "Editar" : "Visualizar"
				}
			</Button>
		</Grid>
	)
}

LiveEditor.propTypes = {
	publishing: PropTypes.bool.isRequired,
	handlePublish: PropTypes.func.isRequired,
	isMobileLivePreviewActive: PropTypes.bool.isRequired,
	setIsMobileLivePreviewActive: PropTypes.func.isRequired
}

export default LiveEditor
