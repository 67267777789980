import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	Grid,
	MenuItem,
	IconButton,
	Tooltip,
	Popover,
	Divider as MuiDivider
} from "@material-ui/core"
import {
	FormatBold as FontBoldIcon,
	TextRotationNone as SpacingLetterIcon,
	FormatLineSpacing as LineHeightIcon,
	FormatItalic as FontItalicIcon
} from "@material-ui/icons"
import ColorPicker from "../ColorPicker"
import GroupField from "../GroupField"
import Slider from "../Slider"
import Select from "../Select"
import { useLiveEditorStore } from "../../../../store"
import LiveEditorTypography from "../LiveEditorTypography"

import useStyles from "./styles"

import noneFontIcon from "../../../../assets/designEditor/fontOptions/noneFont.svg"
import lowercaseFontIcon from "../../../../assets/designEditor/fontOptions/lowercaseFont.svg"
import uppercaseFontIcon from "../../../../assets/designEditor/fontOptions/uppercaseFont.svg"

const familyOptions = [
	"Abel",
	"Amatic SC",
	"Arial",
	"Cinzel",
	"Cormorant Garamond",
	"Do Hyeon",
	"Dosis",
	"Gochi Hand",
	"Helvetica",
	"Hind",
	"Josefin Sans",
	"Lato",
	"Lobster",
	"Marcellus",
	"Montserrat",
	"Mulish",
	"Noto Sans",
	"Open Sans",
	"Oswald",
	"Playfair Display",
	"Poiret One",
	"Poppins",
	"Questrial",
	"Raleway",
	"Roboto",
	"Roboto Condensed",
	"Roboto Slab",
	"Russo One",
	"Secular One",
	"Spectral",
	"Syne Mono",
	"Ubuntu"
]

const textTransformOptions = {
	none: noneFontIcon,
	uppercase: uppercaseFontIcon,
	lowercase: lowercaseFontIcon,
	default: noneFontIcon
}

const AlterFont = (props) => {
	const {
		label,
		fontId,
		fontFamily,
		fontSize,
		fontColor,
		additionalChanges
	} = props

	const liveEditorStore = useLiveEditorStore()

	const [popoverInputType, setInputPopoverType] = useState(null)
	const [anchorEl, setAnchorEl] = useState(null)

	const handleClick = (event, type) => {
		setAnchorEl(event.currentTarget)
		setInputPopoverType(type)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const open = Boolean(anchorEl)
	const id = open ? "simple-popover" : undefined

	const classes = useStyles()

	const handleChange = ({ value, type }) => {
		const { onChange } = type
		onChange(value, fontId)
	}

	const handlePreviewChange = ({ value, type }) => {
		const { onPreviewChange } = type
		onPreviewChange(value, fontId)
	}

	const handleFontFamily = (data) => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
				selector: `custom-fonts-${data.value}`,
				value: `@import url('https://fonts.googleapis.com/css?family=${data.value.replace(/ /g, "+")}:100, 200, 300, 400, 500, 600, 700, 800, 900 & display=swap');`
			}])
		handleChange(data)
	}

	const FontFamilyField = () => (
		<Select
			color="secondary"
			fullWidth
			displayEmpty
			value={familyOptions.includes(fontFamily.value) ? fontFamily.value : ""}
			defaultValue=""
			onChange={({ target }) => handleFontFamily({
				value: target.value,
				type: fontFamily
			})}
		>
			<MenuItem disabled value="">
				<em>Selecione uma fonte</em>
			</MenuItem>
			{familyOptions.map(fontValue => (
				<MenuItem key={familyOptions.indexOf(fontValue)} value={fontValue}>
					{fontValue}
				</MenuItem>
			))}
		</Select>
	)

	const FontSizeField = () => (
		<Select
			color="secondary"
			defaultValue=""
			value={fontSize.optionsSize.includes(fontSize.value) ? fontSize.value : ""}
			onChange={({ target }) => handleChange({
				value: target.value,
				type: fontSize
			})}
		>
			<MenuItem disabled value="">
				<em>Selecione um tamanho</em>
			</MenuItem>
			{fontSize.optionsSize && fontSize.optionsSize.map(fontValue => (
				<MenuItem key={fontSize.optionsSize.indexOf(fontValue)} value={fontValue}>
					{fontValue}
				</MenuItem>
			))}
		</Select>
	)

	const ColorField = () => (
		<ColorPicker
			value={fontColor.value || "#000000"}
			onChange={(color) => handleChange({
				value: color,
				type: fontColor
			})}
			onPreviewChange={(color) => handlePreviewChange({
				value: color,
				type: fontColor
			})}
		/>
	)

	const BoldFontField = () => (
		(
			<Tooltip title="Fonte em negrito" arrow>
				<IconButton
					onClick={() => handleChange({
						value: !(additionalChanges.boldFont.value),
						type: additionalChanges.boldFont
					})}
					size="small"
				>
					<FontBoldIcon className={
						additionalChanges.boldFont.value
						&& classes.selectedIcon
					}
					/>
				</IconButton>
			</Tooltip>
		)
	)

	const ItalicFontField = () => (
		<Tooltip title="Fonte em italico" arrow>
			<IconButton
				onClick={() => handleChange({
					value: !(additionalChanges.italicFont.value),
					type: additionalChanges.italicFont
				})}
				size="small"
			>
				<FontItalicIcon className={
					additionalChanges.italicFont.value
					&& classes.selectedIcon
				}
				/>
			</IconButton>
		</Tooltip>
	)

	const TextTransformField = () => (
		<Tooltip title="Maiúsculos e Minúsculos" arrow>
			<IconButton
				onClick={(e) => handleClick(e, "textTransform")}
				size="small"
			>
				<img
					className={classes.textTransformIcon}
					src={
						textTransformOptions[additionalChanges.textTransform.value]
						?? textTransformOptions.default
					}
					alt="Maiúsculos e Minúsculos"
				/>
			</IconButton>
		</Tooltip>
	)

	const LetterSpacingField = (
		<Tooltip
			title="Espaçamento entre letras"
			arrow
		>
			<IconButton
				onClick={(e) => handleClick(e, "letterSpacing")}
				size="small"
			>
				<SpacingLetterIcon />
			</IconButton>
		</Tooltip>
	)

	const LineHeightField = (
		<Tooltip
			title="Espaçamento entre linhas"
			arrow
		>
			<IconButton
				onClick={(e) => handleClick(e, "lineHeight")}
				size="small"
			>
				<LineHeightIcon />
			</IconButton>
		</Tooltip>
	)

	const TextTransformPopover = () => (
		<Grid
			spacing={1}
			container
			direction="row"
			alignItems="center"
		>
			<Grid item xs={12}>
				<LiveEditorTypography type="fieldTitle">
					Maiúsculo / Minúsculo
				</LiveEditorTypography>
			</Grid>

			<Grid item xs={2}>
				<Tooltip title="Manter texto conforme escrito" arrow>
					<IconButton
						onClick={() => handleChange({
							value: "none",
							type: additionalChanges.textTransform
						})}
						size="small"
						className={additionalChanges.textTransform.value === "none"
							&& classes.selectTextTransformIcon}
					>
						<img
							className={classes.textTransformIcon}
							src={noneFontIcon}
							alt="Formatação de texto padrão"
						/>
					</IconButton>
				</Tooltip>
			</Grid>

			<Grid item xs={2}>
				<Tooltip title="Fonte minúscula" arrow>
					<IconButton
						onClick={() => handleChange({
							value: "lowercase",
							type: additionalChanges.textTransform
						})}
						size="small"
						className={
							additionalChanges.textTransform.value === "lowercase"
							&& classes.selectTextTransformIcon
						}
					>
						<img
							className={classes.textTransformIcon}
							src={lowercaseFontIcon}
							alt="Todas as letras minúsculas"
						/>
					</IconButton>
				</Tooltip>
			</Grid>

			<Grid item xs={2}>
				<Tooltip title="Fonte maiúscula" arrow>
					<IconButton
						onClick={() => handleChange({
							value: "uppercase",
							type: additionalChanges.textTransform
						})}
						size="small"
						className={additionalChanges.textTransform.value === "uppercase"
							&& classes.selectTextTransformIcon}
					>
						<img
							className={classes.textTransformIcon}
							src={uppercaseFontIcon}
							alt="Todas as letras maiúsculas"
						/>
					</IconButton>
				</Tooltip>
			</Grid>

		</Grid>
	)

	const LetterSpacingPopover = () => (
		<Slider
			label="Espaçamento entre letras"
			icon={(
				<SpacingLetterIcon />
			)}
			value={additionalChanges.letterSpacing.value}
			step={1}
			min={0}
			max={15}
			onChange={(_, value) => handleChange({
				value,
				type: additionalChanges.letterSpacing
			})}
		/>
	)

	const LineHeightPopover = () => (
		<Slider
			label="Espaçamento entre linhas"
			icon={(
				<LineHeightIcon />
			)}
			value={additionalChanges.lineHeight.value}
			step={1}
			min={20}
			max={60}
			onChange={(_, value) => handleChange({
				value,
				type: additionalChanges.lineHeight
			})}
		/>
	)

	return (
		<Grid container alignItems="center">
			<Grid item xs={12}>
				<LiveEditorTypography
					type="fieldTitle"
				>
					{label}
				</LiveEditorTypography>
			</Grid>
			<Grid item xs={12}>
				<Grid container>
					<Grid item xs={12}>
						<Grid
							spacing={2}
							container
							alignItems="flex-start"
							justify="space-between"
						>
							<Grid item xs>
								{FontFamilyField()}
							</Grid>

							<Grid item xs>
								{FontSizeField()}
							</Grid>

							<Grid item>
								{ColorField()}
							</Grid>
						</Grid>
					</Grid>

					{additionalChanges && (
						<>
							<Grid item xs={12}>
								<MuiDivider className={classes.additionalContainerDivider} />
							</Grid>

							<Grid item xs={12}>
								{additionalChanges.boldFont && BoldFontField()}

								{additionalChanges.italicFont && ItalicFontField()}

								{additionalChanges.textTransform && TextTransformField()}

								{additionalChanges.letterSpacing && LetterSpacingField}

								{additionalChanges.lineHeight && LineHeightField}

								<Popover
									id={id}
									open={open}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: "center",
										horizontal: "right"
									}}
									transformOrigin={{
										vertical: "center",
										horizontal: "left"
									}}
								>
									<GroupField>
										{popoverInputType === "textTransform" && TextTransformPopover()}

										{popoverInputType === "letterSpacing" && LetterSpacingPopover()}

										{popoverInputType === "lineHeight" && LineHeightPopover()}
									</GroupField>
								</Popover>
							</Grid>
						</>
					)}
				</Grid>
			</Grid>


		</Grid>
	)
}

AlterFont.defaultProps = {
	label: "",
	additionalChanges: null,
	fontId: null
}

const defaultPropsSchema = PropTypes.shape({
	value: PropTypes.string,
	onPreviewChange: PropTypes.func
})

AlterFont.propTypes = {
	label: PropTypes.string,

	fontId: PropTypes.string,

	fontFamily: defaultPropsSchema.isRequired,

	fontSize: PropTypes.shape({
		value: PropTypes.string,
		optionsSize: PropTypes.arrayOf(PropTypes.number),
		onChange: PropTypes.func
	}).isRequired,

	fontColor: defaultPropsSchema.isRequired,

	additionalChanges: PropTypes.shape({
		boldFont: PropTypes.shape({
			value: PropTypes.bool,
			onChange: PropTypes.func
		}),

		italicFont: PropTypes.shape({
			value: PropTypes.bool,
			onChange: PropTypes.func
		}),

		textTransform: defaultPropsSchema,

		letterSpacing: PropTypes.shape({
			value: PropTypes.number,
			onChange: PropTypes.func
		}),

		lineHeight: PropTypes.shape({
			value: PropTypes.number,
			onChange: PropTypes.func
		})
	})

}

export default AlterFont
