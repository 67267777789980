import React from "react"
import PropTypes from "prop-types"

import WorkList from "./WorkList"
import WorkListOnWorkListPage from "./WorkListOnWorkListPage"

import { useLiveEditorStore } from "../../../../../../store"

const WorkList1Editor = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const { currentPage } = liveEditorStore.state

	return (
		currentPage.type === "work_list"
			? <WorkListOnWorkListPage sectionId={sectionId} />
			: <WorkList sectionId={sectionId} />
	)
}

WorkList1Editor.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default WorkList1Editor
