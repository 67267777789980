import React from "react"
import PropTypes from "prop-types"


import { Checkbox, FormControlLabel } from "@material-ui/core"
import { Divider } from "../../../../../../../components"
import { ImageSelect, LiveEditorTypography, TextInput } from "../../../../../components"

const ItemEditor = ({ item, onTextFieldChange, onImageChange, onOpenInNewTabChange }) => {
	const handleChangeTitle = (event) => {
		const { value } = event.target
		onTextFieldChange({ fieldName: "title", value })
	}

	const handleChangeDescription = (event) => {
		const { value } = event.target
		onTextFieldChange({ fieldName: "description", value })
	}

	const handleChangeUrl = (event) => {
		const { value } = event.target
		onTextFieldChange({ fieldName: "url", value })
	}

	const handleChangeOpenInNewTab = (event) => {
		const { checked } = event.target
		onOpenInNewTabChange(checked)
	}

	return (
		<>
			<ImageSelect label="Imagem" currentImage={item?.background} onSelect={onImageChange} />

			<Divider orientation="horizontal" size="medium" />

			<TextInput label="Título" value={item?.title} onChange={handleChangeTitle} />

			<Divider orientation="horizontal" size="medium" />

			<TextInput label="Descrição" value={item?.description} onChange={handleChangeDescription} />

			<Divider orientation="horizontal" size="medium" />

			<TextInput label="URL" value={item?.url} placeholder="https://" onChange={handleChangeUrl} />

			<FormControlLabel
				control={(
					<Checkbox
						checked={item?.open_in_new_tab ?? false}
						onChange={handleChangeOpenInNewTab}
					/>
				)}
				label={(
					<LiveEditorTypography type="fieldDescription">
						Abrir em nova aba
					</LiveEditorTypography>
				)}
			/>
		</>
	)
}

ItemEditor.propTypes = {
	item: PropTypes.shape({
		background: PropTypes.string,
		title: PropTypes.string,
		description: PropTypes.string,
		url: PropTypes.string,
		open_in_new_tab: PropTypes.string
	}).isRequired,
	onImageChange: PropTypes.func.isRequired,
	onTextFieldChange: PropTypes.func.isRequired,
	onOpenInNewTabChange: PropTypes.func.isRequired
}

export default ItemEditor
