import React, { useState } from "react"

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	TextField
} from "@material-ui/core"

import {
	Close as CloseIcon
} from "@material-ui/icons"

import PropTypes from "prop-types"

import useValidation from "../../../../hooks/useValidation"

import useStyles from "./styles"


const CreateCategoryDialog = (props) => {
	const {
		groupId,
		isOpen,
		loadingCreateCategory,
		onClose,
		onCreate
	} = props

	const [category, setCategory] = useState("")

	const classes = useStyles()

	const {
		validation,
		triggerValidation,
		clearValidation
	} = useValidation()

	const handleClose = () => {
		setCategory("")
		onClose()
	}

	const handleChange = (newCategory) => {
		clearValidation("categoryTitle")
		setCategory(newCategory)
	}

	const handleCreateCategory = async () => {
		const isCreated = await onCreate(groupId, category)

		if (isCreated === "isCreated") {
			setCategory("")
			return true
		}
		triggerValidation(isCreated)
	}


	return (
		<Dialog
			fullWidth
			open={isOpen}
			onClose={handleClose}
		>
			<IconButton onClick={handleClose} className={classes.closeIcon}>
				<CloseIcon />
			</IconButton>
			<DialogTitle>
				Criar categoria
			</DialogTitle>

			<DialogContent>
				<TextField
					onChange={({ target }) => handleChange(target.value)}
					error={!!validation.categoryTitle}
					helperText={validation.categoryTitle}
					label="Categoria"
					variant="standard"
					color="secondary"
					size="small"
					autoFocus
					fullWidth
					required
				/>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={handleClose}
					color="inherit"
				>
					Cancelar
				</Button>
				<Button
					endIcon={loadingCreateCategory
						&& <CircularProgress size={20} className={classes.circularProgress} />}
					onClick={handleCreateCategory}
					color="primary"
					variant="contained"
				>
					Salvar
				</Button>
			</DialogActions>
		</Dialog>
	)
}

CreateCategoryDialog.propTypes = {
	groupId: PropTypes.number.isRequired,
	isOpen: PropTypes.bool.isRequired,
	loadingCreateCategory: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	onCreate: PropTypes.func.isRequired
}

export default CreateCategoryDialog
