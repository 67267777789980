import React, { useState } from "react"
// import { Link } from "react-router-dom"
import {
	Grid,
	ListItem,
	ListItemText,
	ListItemIcon,
	List,
	ListItemSecondaryAction,
	Avatar,
	ButtonBase,
	Typography,
	Fade,
	CircularProgress,
	TextField,
	IconButton,
	Card,
	CardContent,
	CardActionArea,
	CardHeader as CardFooter
} from "@material-ui/core"
import {
	ArrowForwardIos as ArrowForwardIosIcon,
	MailOutline as MailOutlineIcon,
	WhatsApp as WhatsAppIcon,
	PhoneOutlined as PhoneOutlinedIcon,
	Instagram as InstagramIcon,
	Facebook as FacebookIcon,
	Pinterest as PinterestIcon,
	Twitter as TwitterIcon,
	AccountCircleRounded as AccountCircleIcon,
	PersonOutlined as PersonOutlinedIcon,
	MenuBookOutlined as MenuBookOutlinedIcon,
	YouTube as YouTubeIcon,
	LinkedIn as LinkedInIcon,
	AddAPhotoOutlined as AddAPhotoIcon,
	HomeOutlined as HomeIcon
} from "@material-ui/icons"

import {
	Portlet,
	Divider,
	Dropzone,
	InputDialog,
	Loading,
	Notification
} from "../../components"

import api from "../../services/api"

import { isMobile } from "../../utils/checkDevice"
import { phoneNumberMask } from "../../utils/mask"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import useStyles from "./styles"

import { useCustomerStore } from "../../store"

import AboutAvatarSkeleton from "../../skeletons/AboutAvatar"
import AboutGeneralSkeleton from "../../skeletons/AboutGeneral"
import AboutSocialMediaSkeleton from "../../skeletons/AboutSocialMedia"

const About = () => {
	const [showAvatarPlaceholder, setShowAvatarPlaceholder] = useState(false)

	const [avatar, setAvatar] = useState(null)
	const [contentId, setContentId] = useState(null)
	const [aboutData, setAboutData] = useState({})

	const [loadingAboutData, setLoadingAboutData] = useState(true)
	const [uploadingAvatar, setUploadingAvatar] = useState(false)

	const customerStore = useCustomerStore()

	const {
		validation,
		triggerValidation,
		clearValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const classes = useStyles({ hasAvatar: !!avatar })

	const onAvatarEnter = () => {
		setShowAvatarPlaceholder(true)
	}

	const onAvatarLeave = () => {
		setShowAvatarPlaceholder(false)
	}

	const getAboutData = async () => {
		try {
			const { data } = await api.get("/site/content/about")

			setAvatar(data.avatar)
			setContentId(data.id)
			setAboutData(data)

			customerStore.setUserData({
				avatar: data.avatar
			})

			setLoadingAboutData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const changeAboutData = async (key, value) => {
		try {
			await api.put(`/site/content/${contentId}/about`, {
				[key]: value || ""
			})

			await getAboutData()

			Notification.success({ message: "Informação alterada com sucesso!" })

			return true
		} catch (error) {
			triggerValidation(error)
		}
	}

	const uploadAvatar = async ([avatarPicture]) => {
		setShowAvatarPlaceholder(false)
		setUploadingAvatar(true)

		const formData = new FormData()
		formData.append("avatar", avatarPicture)

		try {
			await api.put(`/site/content/${contentId}/about/avatar`, formData, {
				headers: {
					"Content-Type": "multipart/data"
				}
			})

			await getAboutData()

			Notification.success({ message: "Avatar atualizado com sucesso!" })
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setUploadingAvatar(false)
	}

	useDidMount(() => {
		getAboutData()
	})

	return (
		<Grid container spacing={2}>

			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
						<Typography variant="h2">SUA FOTO</Typography>

						<Divider size="medium" orientation="horizontal" />

						<Card elevation={1}>
							<Loading
								loading={loadingAboutData}
								customLoadingElement={<AboutAvatarSkeleton />}
							>
								<CardContent className={classes.avatarContainer}>

									<CardActionArea>
										<Dropzone
											onDrop={uploadAvatar}
											className={classes.avatarDropzone}
										>
											<Grid item className={classes.avatarGrid}>
												<Avatar
													className={classes.avatar}
													component={ButtonBase}
													onMouseEnter={onAvatarEnter}
													onMouseLeave={onAvatarLeave}
												>
													{avatar ? (
														<img
															className={classes.avatarPicture}
															src={avatar}
															alt="avatar"
														/>
													)
														: (
															!showAvatarPlaceholder && (
																<Grid
																	container
																	direction="column"
																	justify="center"
																	alignItems="center"
																	className={classes.avatarPlaceholder}
																>
																	<AccountCircleIcon
																		className={classes.avatarPlaceholderIcon}
																	/>
																</Grid>
															)
														)}

													{uploadingAvatar && (
														<Grid
															container
															direction="column"
															justify="center"
															alignItems="center"
															className={classes.avatarPlaceholder}
														>
															<CircularProgress color="secondary" />
														</Grid>
													)}

													<Fade in={showAvatarPlaceholder}>
														<Grid
															container
															direction="column"
															justify="center"
															alignItems="center"
															className={classes.avatarPlaceholder}
														>
															<AddAPhotoIcon
																className={classes.avatarPlaceholderIcon}
															/>
															<Typography
																className={classes.avatarPlaceholderText}
															>
																{avatar ? "Alterar foto" : "Subir foto"}
															</Typography>
														</Grid>
													</Fade>
												</Avatar>
											</Grid>
										</Dropzone>
									</CardActionArea>

									<Divider size="medium" orientation="horizontal" />

									<CardFooter
										title="Aumente sua credibilidade! Suba uma foto e torne a sua conta ainda mais profissional!"
										titleTypographyProps={{
											variant: "h3",
											align: "center"
										}}
									/>

								</CardContent>
							</Loading>
						</Card>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Typography variant="h2">MEUS DADOS</Typography>

				<Divider size="medium" orientation="horizontal" />

				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Portlet>
						<Loading
							loading={loadingAboutData}
							customLoadingElement={<AboutGeneralSkeleton />}
						>
							<List>
								<InputDialog
									title="Mudar nome"
									onDataChange={() => clearValidation("name")}
									onOk={(name) => changeAboutData("name", name)}
									initialValue={aboutData.name}
									customInputElement={(
										<TextField
											error={!!validation.name}
											helperText={validation.name}
											label="Nome"
											variant="standard"
											color="secondary"
											size="small"
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem divider button>
										<ListItemIcon>
											<PersonOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary="NOME"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={aboutData.name || "Seu nome aparecerá aqui..."}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<InputDialog
									title="Mudar e-mail"
									onDataChange={() => clearValidation("email")}
									onOk={(email) => changeAboutData("email", email)}
									initialValue={aboutData.email}
									customInputElement={(
										<TextField
											error={!!validation.email}
											helperText={validation.email}
											label="E-mail"
											variant="standard"
											color="secondary"
											size="small"
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem divider button>
										<ListItemIcon>
											<MailOutlineIcon />
										</ListItemIcon>
										<ListItemText
											primary="E-MAIL"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												aboutData.email || "Seu e-mail aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<InputDialog
									title="Mudar número de whatsapp"
									onDataChange={() => clearValidation("whatsapp")}
									onOk={(whatsapp) => changeAboutData("whatsapp", whatsapp)}
									parser={({ target }) => phoneNumberMask(target.value)}
									initialValue={aboutData.whatsapp}
									customInputElement={(
										<TextField
											error={!!validation.whatsapp}
											helperText={validation.whatsapp}
											label="Whatsapp"
											variant="standard"
											color="secondary"
											size="small"
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem divider button>
										<ListItemIcon>
											<WhatsAppIcon />
										</ListItemIcon>
										<ListItemText
											primary="WHATSAPP"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												aboutData.whatsapp
												|| "Seu número de whatsapp aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>
								{/* <List className={classes.whatsappChatInfoList}>
									<ListItem
										divider
										component={Link}
										to="/general"
										className={classes.whatsappChatInfoListItem}
									>
										<Typography
											variant="caption"
											className={classes.whatsappChatInfoLink}
										>
											<b>Clique aqui </b>
											para ativar ou desativar o ícone do whatsapp no rodapé do
											seu site.
										</Typography>
									</ListItem>
								</List> */}

								<InputDialog
									title="Mudar número de telefone"
									onDataChange={() => clearValidation("phone")}
									onOk={(phone) => changeAboutData("phone", phone)}
									parser={({ target }) => phoneNumberMask(target.value)}
									initialValue={aboutData.phone}
									customInputElement={(
										<TextField
											error={!!validation.phone}
											helperText={validation.phone}
											label="Telefone"
											variant="standard"
											color="secondary"
											size="small"
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem divider button>
										<ListItemIcon>
											<PhoneOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary="TELEFONE"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												aboutData.phone
												|| "Seu número de telefone aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>

								<InputDialog
									title="Mudar endereço"
									onDataChange={() => clearValidation("address")}
									onOk={(address) => changeAboutData("address", address)}
									initialValue={aboutData.address}
									customInputElement={(
										<TextField
											error={!!validation.address}
											helperText={validation.address}
											label="Endereço"
											variant="standard"
											color="secondary"
											size="small"
											multiline
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem divider button>
										<ListItemIcon>
											<HomeIcon />
										</ListItemIcon>
										<ListItemText
											primary="ENDEREÇO"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												aboutData.address
												|| "Seu endereço aparecerá aqui..."
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>


								<InputDialog
									title="Mudar biografia"
									onDataChange={() => clearValidation("bio")}
									onOk={(bio) => changeAboutData("bio", bio)}
									initialValue={aboutData.bio}
									customInputElement={(
										<TextField
											error={!!validation.bio}
											helperText={validation.bio}
											label="Biografia"
											variant="standard"
											color="secondary"
											size="small"
											multiline
											fullWidth
										/>
									)}
									openOnDrawer={isMobile}
								>
									<ListItem button>
										<ListItemIcon>
											<MenuBookOutlinedIcon />
										</ListItemIcon>
										<ListItemText
											primary="BIOGRAFIA"
											primaryTypographyProps={{
												className: classes.listItemTitle
											}}
											secondary={
												aboutData.bio
												|| "Fale um pouco sobre você para o visitante do site!"
											}
											secondaryTypographyProps={{
												variant: "h3",
												color: "textPrimary",
												noWrap: true
											}}
										/>
										<ListItemSecondaryAction className={classes.nextIcon}>
											<IconButton>
												<ArrowForwardIosIcon />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</InputDialog>
							</List>
						</Loading>
					</Portlet>
				</Grid>
			</Grid>
			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Typography variant="h2">REDES SOCIAIS</Typography>

				<Divider size="medium" orientation="horizontal" />

				<Portlet>
					<Loading
						loading={loadingAboutData}
						customLoadingElement={<AboutSocialMediaSkeleton />}
					>
						<List>
							<InputDialog
								title="Mudar instagram"
								onDataChange={() => clearValidation("instagram")}
								onOk={(instagram) => changeAboutData("instagram", instagram)}
								initialValue={aboutData.instagram}
								customInputElement={(
									<TextField
										error={!!validation.instagram}
										helperText={validation.instagram}
										label="Instagram"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>instagram.com/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem inset divider button>
									<ListItemIcon>
										<InstagramIcon />
									</ListItemIcon>
									<ListItemText
										primary="INSTAGRAM"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`instagram.com/${aboutData.instagram || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

							<InputDialog
								title="Mudar facebook"
								onDataChange={() => clearValidation("facebook")}
								onOk={(facebook) => changeAboutData("facebook", facebook)}
								initialValue={aboutData.facebook}
								customInputElement={(
									<TextField
										error={!!validation.facebook}
										helperText={validation.facebook}
										label="Facebook"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>facebook.com/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem divider button>
									<ListItemIcon>
										<FacebookIcon />
									</ListItemIcon>
									<ListItemText
										primary="FACEBOOK"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`facebook.com/${aboutData.facebook || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

							<InputDialog
								title="Mudar pinterest"
								onDataChange={() => clearValidation("pinterest")}
								onOk={(pinterest) => changeAboutData("pinterest", pinterest)}
								initialValue={aboutData.pinterest}
								customInputElement={(
									<TextField
										error={!!validation.pinterest}
										helperText={validation.pinterest}
										label="Pinterest"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>pinterest.com/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem divider button>
									<ListItemIcon>
										<PinterestIcon />
									</ListItemIcon>
									<ListItemText
										primary="PINTEREST"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`pinterest.com/${aboutData.pinterest || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

							<InputDialog
								title="Mudar twitter"
								onDataChange={() => clearValidation("twitter")}
								onOk={(twitter) => changeAboutData("twitter", twitter)}
								initialValue={aboutData.twitter}
								customInputElement={(
									<TextField
										error={!!validation.twitter}
										helperText={validation.twitter}
										label="Twitter"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>twitter.com/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem divider button>
									<ListItemIcon>
										<TwitterIcon />
									</ListItemIcon>
									<ListItemText
										primary="TWITTER"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`twitter.com/${aboutData.twitter || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

							<InputDialog
								title="Mudar youtube"
								onDataChange={() => clearValidation("youtube")}
								onOk={(youtube) => changeAboutData("youtube", youtube)}
								initialValue={aboutData.youtube}
								customInputElement={(
									<TextField
										error={!!validation.youtube}
										helperText={validation.youtube}
										label="Youtube"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>youtube.com/channel/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem divider button>
									<ListItemIcon>
										<YouTubeIcon />
									</ListItemIcon>
									<ListItemText
										primary="YOUTUBE"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`youtube.com/channel/${aboutData.youtube || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

							<InputDialog
								title="Mudar linkedin"
								onDataChange={() => clearValidation("linkedin")}
								onOk={(linkedin) => changeAboutData("linkedin", linkedin)}
								initialValue={aboutData.linkedin}
								customInputElement={(
									<TextField
										error={!!validation.linkedin}
										helperText={validation.linkedin}
										label="Linkedin"
										variant="standard"
										color="secondary"
										size="small"
										InputProps={{
											startAdornment: (
												<Typography>
													<b>linkedin.com/in/</b>
												</Typography>
											)
										}}
										fullWidth
									/>
								)}
								openOnDrawer={isMobile}
							>
								<ListItem button>
									<ListItemIcon>
										<LinkedInIcon />
									</ListItemIcon>
									<ListItemText
										primary="LINKEDIN"
										primaryTypographyProps={{
											className: classes.listItemTitle
										}}
										secondary={`linkedin.com/in/${aboutData.linkedin || ""}`}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
									/>
									<ListItemSecondaryAction className={classes.moreIcon}>
										<IconButton>
											<ArrowForwardIosIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>

						</List>
					</Loading>
				</Portlet>
			</Grid>
		</Grid>
	)
}

export default About
