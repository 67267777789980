import { makeStyles } from "@material-ui/core"
import colors from "../../../../../../styles/colors"

const useStyles = makeStyles(() => ({
	dialogContainer: {
		width: 510
	},
	mediaContainer: {
		border: `1px solid ${colors.greyScale[4]}`
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	}
}))

export default useStyles
