import React from "react"
import { Skeleton } from "@material-ui/lab"

import { Grid, Container, makeStyles, createStyles } from "@material-ui/core"
import { isMobile } from "../utils/checkDevice"
import colors from "../styles/colors"
import { Divider } from "../components"

const useStyles = makeStyles(() => createStyles({
	pictureContainer: {
		maxWidth: "100%",
		height: isMobile ? 300 : 450,
		borderColor: colors.greyScale[7],
		backgroundColor: colors.greyScale[10]
	},
	pictureSkeleton: {
		width: "100%",
		height: "100%"
	},
	fieldSkeleton: {
		height: 48,
		width: "100%"
	}
}))

const TestimonialEditSkeleton = () => {
	const classes = useStyles()

	const nItems = 7
	const fields = []

	// eslint-disable-next-line
	for (let i = 0; i < nItems; i++) {
		fields.push(
			<Grid item xs={12}>
				<Skeleton animation="wave" variant="rect" className={classes.fieldSkeleton} />
				<Divider size="medium" orientation="horizontal" />
			</Grid>
		)
	}

	return (
		<Grid container spacing={2} alignItems="flex-start">
			<Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
				<Container className={classes.pictureContainer} disableGutters>
					<Skeleton animation="wave" variant="rect" className={classes.pictureSkeleton} />
				</Container>
			</Grid>
			<Grid item xs={12} sm={12} md={6} lg={5} xl={5} container>
				{fields}
			</Grid>
		</Grid>
	)
}

export default TestimonialEditSkeleton
