import { makeStyles } from "@material-ui/core"

import colors from "../../../../styles/colors"

const useStyles = makeStyles(theme => {
	const listItem = {
		paddingTop: theme.spacing() * 1,
		paddingBottom: theme.spacing() * 1,
		marginLeft: theme.spacing() * 0,
		width: "auto"
	}
	return {
		categoryListWrapper: {
			padding: theme.spacing(2, 1)
		},
		groupListItem: {
			padding: 0
		},
		groupIcon: {
			minWidth: theme.spacing() * 5
		},

		categoryListItem: {
			...listItem,
			borderBottom: `1px solid ${colors.greyScale[10]}`
		},
		categoryListItemContainer: {
			listStyle: "none"
		},
		createCategoryListItem: {
			...listItem
		},
		listItemIcon: {
			minWidth: theme.spacing() * 4
		},
		listItemSortableIcon: {
			minWidth: 0
		}
	}
})

export default useStyles
