import React from "react"
import PropTypes from "prop-types"
import { Box, Grid } from "@material-ui/core"
import LiveEditorTypography from "../LiveEditorTypography"

import useStyles from "./styles"

const GroupField = (props) => {
	const { label, children } = props
	const classes = useStyles()
	return (
		<Box className={classes.container}>
			<Grid container>
				{label && (
					<Grid item xs={12} className={classes.label}>
						<LiveEditorTypography
							type="groupFieldTitle"
						>
							{label}
						</LiveEditorTypography>
					</Grid>
				)}
				<Grid item xs={12} className={classes.fieldsContainer}>
					{children}
				</Grid>
			</Grid>
		</Box>
	)
}

GroupField.defaultProps = {
	label: ""
}

GroupField.propTypes = {
	label: PropTypes.string,
	children: PropTypes.node.isRequired
}
export default GroupField
