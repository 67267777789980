import { makeStyles } from "@material-ui/core"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => ({
	transparentGrid: {
		opacity: 0
	},

	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	},

	listItem: {
		paddingLeft: theme.spacing(1)
	},

	listItemTitle: {
		color: "#323232",
		fontWeight: 600,
		fontSize: "16px",
		lineHeight: "24px",
		letterSpacing: "-0.03em"
	},

	listItemTextVisibilityOff: {
		color: colors.greyScale[6]
	},

	listItemIcon: {
		minWidth: theme.spacing(5)
	},

	secondaryAction: {
		right: theme.spacing(1)
	},

	secondaryActionButton: {
		padding: theme.spacing(1) - 2
	},

	cancelButton: {
		color: colors.greyScale[4]
	},

	dialogActions: {
		marginTop: theme.spacing(2)
	},

	emptySelectLabel: {
		color: theme.palette.error.main
	}
}))

export default useStyles
