import React from "react"

import { Drawer as MdDrawer, Button, Typography, Grid } from "@material-ui/core"
import PropTypes from "prop-types"
import { ArrowBackIos as ArrowBackIcon } from "@material-ui/icons"
import useStyles from "./styles"

const Drawer = ({ open, onClose, children }) => {
	const classes = useStyles()

	return (
		<>
			<MdDrawer
				anchor="left"
				variant="persistent"
				open={open}
				className={classes.drawer}
				classes={{
					paper: classes.drawerPaper
				}}
			>
				<Grid container spacing={2} className={classes.drawerContent}>
					<Grid item xs={12}>
						<Button
							onClick={onClose}
							startIcon={<ArrowBackIcon className={classes.closeButtonIcon} />}
						>
							<Typography variant="h2">Fechar</Typography>
						</Button>
					</Grid>

					<Grid item xs={12}>
						{children}
					</Grid>
				</Grid>
			</MdDrawer>
		</>
	)
}

Drawer.defaultProps = {
	children: null
}

Drawer.propTypes = {
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	children: PropTypes.node
}
export default Drawer
