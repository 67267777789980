import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	IconButton,
	Fab,
	Card,
	CardMedia,
	Grid,
	Typography,
	Button,
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	CircularProgress
} from "@material-ui/core"
import { CloudUploadOutlined as CloudUploadIcon, Close as CloseIcon } from "@material-ui/icons"
import useDidMount from "../../hooks/useDidMount"
import useValidation from "../../hooks/useValidation"

import api from "../../services/api"

import Dropzone from "../Dropzone"
import Divider from "../Divider"

import useStyles from "./styles"

const MediaSystem = ({ onOk, onClose, open }) => {
	const { triggerUndeterminedValidation } = useValidation()

	const [medias, setMedias] = useState([])
	const [selectedMedia, setSelectedMedia] = useState(null)
	const [uploadingMedia, setUploadingMedia] = useState(false)

	const classes = useStyles()

	const getAndUpdateMedias = async () => {
		try {
			const response = await api.get("/media-system/list")

			setMedias(response.data.mediaFiles)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const uploadMedia = async ([file]) => {
		const formData = new FormData()
		formData.append("media_file", file)

		try {
			setUploadingMedia(true)
			await api.post("/media-system", formData, {
				header: {
					"Content-Type": "multipart/data"
				}
			})
		} catch (error) {
			triggerUndeterminedValidation(error)
		} finally {
			setUploadingMedia(false)
			getAndUpdateMedias()
		}
	}

	const handleSelectMedia = (media) => {
		setSelectedMedia(media)
	}

	const handleClickOk = () => {
		onOk(selectedMedia.media_src)
		onClose()
	}

	useDidMount(() => { getAndUpdateMedias() })

	return (
		<Dialog fullScreen open={open} className={classes.dialog}>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography className={classes.title}>
						Sistema de Mídias
					</Typography>

					<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid container spacing={2} alignItems="stretch">
					<Grid item xs={6} sm={4} md={3} lg={2}>
						<Card className={classes.card}>
							<Dropzone onDrop={uploadMedia} className={classes.dropzone} disabled={uploadingMedia}>
								<Grid container direction="column" justify="center" alignItems="center" className={classes.dropzoneGrid}>
									<Fab color="primary">
										{uploadingMedia ? <CircularProgress size={20} color="white" /> : <CloudUploadIcon />}
									</Fab>

									<Divider orientation="horizontal" size="medium" />

									<Typography variant="h3" className={classes.uploadText}>
										{uploadingMedia ? "Fazendo upload..." : "Fazer upload de imagem do computador"}
									</Typography>
								</Grid>
							</Dropzone>

							<CardMedia
								className={classes.cardMedia}
							/>
						</Card>
					</Grid>

					{medias.map(media => {
						const className = [classes.card]
						if (media?.id === selectedMedia?.id) {
							className.push(classes.selectedCard)
						}

						return (
							<Grid item xs={6} sm={4} md={3} lg={2} key={media.id}>
								<Card className={className} onClick={() => handleSelectMedia(media)}>
									<CardMedia
										image={media.media_src}
										className={classes.cardMedia}
									/>
								</Card>
							</Grid>
						)
					})}
				</Grid>
			</DialogContent>

			<DialogActions>
				<Button color="primary" variant="contained" onClick={handleClickOk}>OK</Button>
			</DialogActions>
		</Dialog>
	)
}

MediaSystem.propTypes = {
	onOk: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool
}

MediaSystem.defaultProps = {
	onOk: () => { },
	onClose: () => { },
	open: false
}

export default MediaSystem
