import React, { useState } from "react"
import { Grid, Container, Button, CircularProgress } from "@material-ui/core"
import {
	CloudUploadOutlined as CloudUploadOutlinedIcon
} from "@material-ui/icons"

import { Portlet, Dropzone, Loading, PopConfirm, Notification } from "../../components"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import transparentPlaceholder from "../../assets/transparent_placeholder.png"

import LogoSkeleton from "../../skeletons/Logo"

import useStyles from "./styles"

import api from "../../services/api"

const Logo = () => {
	const [contentId, setContentId] = useState(null)
	const [logoUrl, setLogoUrl] = useState(null)
	const [logoBackgroundColor, setLogoBackgroundColor] = useState("")

	const [loadingLogo, setLoadingLogo] = useState(true)
	const [uploadingLogo, setUploadingLogo] = useState(false)
	const [removingLogo, setRemovingLogo] = useState(false)

	const classes = useStyles()

	const { triggerUndeterminedValidation } = useValidation()

	const getLogoData = async () => {
		try {
			const { data } = await api.get("/site/content/logo")

			setContentId(data.id)
			setLogoUrl(data.url)
			setLogoBackgroundColor(data.background_color)

			setLoadingLogo(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const changeLogo = async ([logo]) => {
		setUploadingLogo(true)

		const formData = new FormData()
		formData.append("logo", logo)

		try {
			await api.put(`/site/content/${contentId}/logo`, formData, {
				headers: {
					"Content-Type": "multipart/data"
				}
			})

			await getLogoData()

			Notification.success({ message: "Logo alterada com sucesso!" })
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setUploadingLogo(false)
	}

	const removeLogo = async () => {
		PopConfirm.open({
			title: "Tem certeza?",
			description: "Remover a Logo é uma ação irreversível.",
			confirmButtonText: "Remover",
			onConfirm: async () => {
				setRemovingLogo(true)

				try {
					await api.delete(`/site/content/${contentId}/logo`)

					await getLogoData()

					Notification.success({ message: "Logo removida com sucesso!" })
				} catch (error) {
					triggerUndeterminedValidation(error)
				}

				setRemovingLogo(false)
			}
		})
	}

	useDidMount(() => {
		getLogoData()
	})

	return (
		<Grid item className={classes.logoGrid}>
			<Portlet>
				<Loading loading={loadingLogo} customLoadingElement={<LogoSkeleton />}>
					<Grid item>
						{logoUrl ? (
							<Container
								className={classes.placeholder}
								disableGutters
								style={{ backgroundColor: logoBackgroundColor }}
							>
								<img className={classes.logo} src={logoUrl} alt="logo" />
							</Container>
						) : (
							<Dropzone onDrop={changeLogo}>
								<Container
									className={classes.placeholder}
									disableGutters
									style={{ background: `url(${transparentPlaceholder})` }}
								>
									<Button
										color="primary"
										variant="contained"
										endIcon={uploadingLogo ? (
											<CircularProgress size={20} className={classes.loading} />
										) : (
											<CloudUploadOutlinedIcon />
										)}
									>
										{uploadingLogo ? "Subindo logo..." : "Subir logo"}
									</Button>
								</Container>
							</Dropzone>
						)}

						<Container className={classes.actions} disableGutters>
							{logoUrl && (
								<>
									<Button
										variant="text"
										color="primary"
										endIcon={removingLogo && <CircularProgress size={20} />}
										onClick={removeLogo}
									>
										{removingLogo ? "Removendo logo..." : "Remover logo"}
									</Button>

									<Grid item>
										<Dropzone onDrop={changeLogo}>
											<Button
												variant="contained"
												color="primary"
												endIcon={uploadingLogo ? (
													<CircularProgress size={20} className={classes.loading} />
												) : (
													<CloudUploadOutlinedIcon />
												)}
											>
												{uploadingLogo ? "Alterando logo..." : "Alterar logo"}
											</Button>
										</Dropzone>
									</Grid>
								</>
							)}
						</Container>
					</Grid>
				</Loading>
			</Portlet>
		</Grid>
	)
}

export default Logo
