import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(() => ({
	buttonLayout: {
		borderWidth: "2px",
		borderStyle: "solid",
		borderColor: ({ isSelect }) => (isSelect
			? `${colors.palette.confirm}`
			: `${colors.greyScale[11]}`)
	}
}))

export default useStyles
