import React, { useState } from "react"

import { Tabs, Tab, Typography, Grid } from "@material-ui/core"

import {
	Portlet,
	Divider,
	CustomContainer,
	Loading,
	ActivateFunctionality,
	Notification
} from "../../components"

import useDidMount from "../../hooks/useDidMount"
import useValidation from "../../hooks/useValidation"

import colors from "../../styles/colors"

import api from "../../services/api"

import PagesMetadata from "./PagesMetadata"
import Diagnosis from "./Diagnosis"
import Sitemap from "./Sitemap"

import useStyles from "./styles"

import turboSeoIllustration from "../../assets/illustrations/turboSeoIllustration.svg"
import turboSeoMobileIllustration from "../../assets/illustrations/turboSeoMobileIllustration.svg"

const TurboSeo = () => {
	const classes = useStyles()

	const { triggerUndeterminedValidation } = useValidation()

	const [loadingTurboSeoData, setLoadingTurboSeoData] = useState(true)
	const [turboSeoSite, setTurboSeoSite] = useState([])
	const [turboSeoPages, setTurboSeoPages] = useState([])
	const [currentTab, setCurrentTab] = useState("pages")

	const getAndUpdateTurboSeoData = async () => {
		try {
			const response = await api.get("/turbo-seo")
			const { pages, ...site } = response?.data?.turboSeoSite
			setTurboSeoSite(site)
			setTurboSeoPages(pages.filter(page => page.type === "page"))

			setLoadingTurboSeoData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	/*
		TRY CATCH SHOULD NOT BE APPLIED HERE
		The component,ActivateFunctionality, performs try catch on the call of this function
	*/
	const activateTurboSeoIntegration = async () => {
		await api.post("/turbo-seo")

		Notification.success({ message: "Turbo SEO ativado com successo!" })

		getAndUpdateTurboSeoData()
	}

	const handleTabChange = (_, newValue) => {
		setCurrentTab(newValue)
	}

	const handleGoToPagesTab = () => {
		setCurrentTab("pages")
	}

	const RenderCurrentTab = () => {
		switch (currentTab) {
		case "pages":
			return (
				<PagesMetadata
					updateParent={getAndUpdateTurboSeoData}
					turboSeoPages={turboSeoPages}
				/>
			)

		case "diagnosis":
			return (
				<Diagnosis handleGoToPagesTab={handleGoToPagesTab} />
			)

		case "sitemap":
			return (
				<Sitemap />
			)

		default:
			break
		}
	}

	const labelContentActivateFuncionality = () => (
		<>
			<Typography color="inherit" variant="h2" className={classes.titleActivateFuncionality}>
				Permita otimizar o seu site,
				melhore as suas experiências.
			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" variant="body1" className={classes.contentActivateFuncionality}>
				Você certamente já ouviu falar do Google, certo?
				Então, toda vez que uma página é publicada na internet, o Google
				(e outros buscadores) procuram indexá-la de modo que seja encontrada
				por quem a procura ou seja o seus clientes e parceiros.
			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" variant="body1" className={classes.contentActivateFuncionality}>
				Mas existem milhares de páginas sendo publicadas todos os dias na internet e
				isso faz com que haja bastante concorrência não é mesmo? Portanto,
				como fazer com que uma página fique à frente de outras?

			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" variant="body1" className={classes.contentActivateFuncionality}>
				E aí que entra o Turbo SEO (Search Engine Optimization).
				Como a própria tradução já sugere, SEO é uma otimização para os motores de busca, isto é,
				um conjunto de técnicas que influenciam os algoritmos dos buscadores a
				definir o ranking de uma página para determinada palavra-chave que foi pesquisada.
			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" align="center" variant="body1" className={classes.textActionActivateFuncionality}>
				Que tal gostou da dica?
				<br />
				Então vamos lá!
			</Typography>
		</>
	)

	useDidMount(() => {
		getAndUpdateTurboSeoData()
	})

	return (
		<Loading loading={loadingTurboSeoData} customLoadingElement={(<></>)}>
			{turboSeoSite?.status === "ACTIVE" ? (
				<CustomContainer maxWidth="800px" position="start">
					<Portlet disablePadding>
						<Tabs
							value={currentTab}
							onChange={handleTabChange}
							className={classes.tabsContainer}
							TabIndicatorProps={{
								style: { backgroundColor: colors.greyScale[1] }
							}}
						>
							<Tab label="Páginas" value="pages" />
							<Tab label="Diagnóstico" value="diagnosis" />
							<Tab label="Sitemap" value="sitemap" />
						</Tabs>

						<Divider orientation="horizontal" size="large" />

						{RenderCurrentTab()}

					</Portlet>
				</CustomContainer>
			)
				: (
					<Grid container>
						<ActivateFunctionality
							labelContent={labelContentActivateFuncionality}
							illustrationBackground={turboSeoIllustration}
							illustrationMobileBackground={{
								image: turboSeoMobileIllustration,
								backgroundColor: "linear-gradient(180deg, #514193 9.9%, rgba(255, 73, 73, 0) 100%);"
							}}
							onActivate={activateTurboSeoIntegration}
							captionButton="A instalação é grátis e simples."
							colorButton="primary"
						/>
					</Grid>

				)}
		</Loading>
	)
}

export default TurboSeo
