import { makeStyles } from "@material-ui/core"

import colors from "../../../styles/colors"

const bgColor = "#F8F9FA"
export const consoleMaxWidth = "320px"

const useStyles = makeStyles(theme => ({
	actionsContainer: {
		height: "100%",
		backgroundColor: bgColor,
		borderBottom: `1px solid ${colors.greyScale[9]}`,
		display: "none",

		[theme.breakpoints.up("md")]: {
			display: "flex"
		}
	},
	leftActions: {
		width: consoleMaxWidth
	},
	backButton: {
		textTransform: "none",
		marginLeft: theme.spacing(2),
		color: colors.greyScale[3]
	},
	centerActions: {
	},
	rightActions: {
		alignSelf: "stretch",
		display: "flex",
		alignItems: "center"
	},
	iframeUrlLink: {
		marginRight: theme.spacing(3),
		whiteSpace: "nowrap"
	},
	publishButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none"
	},
	discardButton: {
		borderRadius: 0,
		height: "100%",
		boxShadow: "none",
		padding: theme.spacing(0.75, 2),
		color: colors.greyScale[2]
	},
	desktopIcon: {
		color: ({ device }) => `${device === "desktop" ? colors.greyScale[4] : colors.greyScale[9]}`,
		transition: "0.3s"
	},
	phoneIcon: {
		color: ({ device }) => `${device === "mobile" ? colors.greyScale[4] : colors.greyScale[9]} `,
		transition: "0.3s"
	},
	fullWidthIcon: {
		color: ({ isConsoleVisible }) => `${!isConsoleVisible ? colors.greyScale[4] : colors.greyScale[9]} `,
		transition: "0.3s"
	},
	iconsDivider: {
		height: "50%",
		alignSelf: "center",
		margin: theme.spacing(0, 1.5)
	}
}))

export default useStyles
