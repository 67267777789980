import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	container: {
		pointerEvents: ({ disabled }) => disabled && "none",
		outline: "none",
		cursor: "pointer"
	},
	content: {
		pointerEvents: "none"
	}
})

export default useStyles
