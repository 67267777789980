import { useDispatch, useSelector, useStore } from "react-redux"
import _ from "lodash"

import { keepNotFalsyObjectParams } from "../../utils/sanitization"

import useLivePreview from "../../hooks/useLivePreview"

const CommitStates = {
	DELETED: "DELETED",
	UPDATED: "UPDATED",
	CREATED: "CREATED"
}

export const Types = {
	SET_LIVE_EDITOR_DATA: "liveEditor/SET_DATA",
	SET_STYLE_PROPERTIES: "liveEditor/SET_STYLE_PROPERTIES",
	SET_SECTION_DATA: "liveEditor/SET_SECTION_DATA",
	OVERRIDE_SECTION_DATA: "liveEditor/OVERRIDE_SECTION_DATA",
	SET_SITE_CONTENT: "liveEditor/SET_SITE_CONTENT",
	DELETE_SECTION: "liveEditor/DELETE_SECTION",
	CREATE_SECTION: "liveEditor/CREATE_SECTION",
	SET_CURRENT_PAGE_DATA: "liveEditor/SET_CURRENT_PAGE_DATA",
	ADD_LIVE_PREVIEW_CHANGES: "liveEditor/ADD_LIVE_PREVIEW_CHANGES",
	CLEAR_LIVE_PREVIEW_CHANGES: "liveEditor/CLEAR_LIVE_PREVIEW_CHANGES"
}

const DEFAULTS = {
	section: {
		id: null,
		file: null,
		title: null,
		html_tag: null,
		properties: {},
		content: {},
		page_id: null,
		page_title: null,
		page_type: null,
		commitState: null,
		order: null
	},
	style: {
		id: null,
		type: null,
		properties: {},
		commitState: null
	},
	siteContent: {
		favicon: {}
	},
	livePreviewChange: {
		action: null,
		selector: null,
		value: null,
		pageId: null
	},
	currentPage: {
		id: null,
		currentSlug: ""
	}
}

const initialState = {
	styles: [],
	pages: [],
	sections: [],
	siteContent: {},
	livePreviewChanges: [],
	changedData: false,
	currentPage: DEFAULTS.currentPage
}

export const useLiveEditorStore = () => {
	const dispatch = useDispatch()
	const livePreview = useLivePreview()
	const state = useSelector((globalState) => globalState.liveEditor)
	const store = useStore()

	const setLiveEditorData = ({
		styles,
		sections,
		siteContent,
		pages,
		changedData,
		currentPage
	}) => dispatch({
		type: Types.SET_LIVE_EDITOR_DATA,
		payload: keepNotFalsyObjectParams({
			styles,
			sections,
			siteContent,
			pages,
			changedData,
			currentPage
		})
	})

	const addLivePreviewChanges = (
		livePreviewChanges = [DEFAULTS.livePreviewChange]
	) => {
		livePreview.makeBatchChanges(livePreviewChanges)

		dispatch({
			type: Types.ADD_LIVE_PREVIEW_CHANGES,
			payload: keepNotFalsyObjectParams({
				livePreviewChanges
			})
		})
	}

	const commitExistentLivePreviewChanges = () => {
		const { liveEditor } = store.getState()

		const existentLivePreviewChanges = liveEditor.livePreviewChanges

		const changesOnCurrentPage = existentLivePreviewChanges.filter(
			(change) => change?.pageId === liveEditor.currentPage?.id || !change?.pageId
		)

		livePreview.makeBatchChanges(changesOnCurrentPage)
	}

	const clearLivePreviewChangesHistory = () => {
		dispatch({
			type: Types.CLEAR_LIVE_PREVIEW_CHANGES
		})
	}

	const setStyleProperties = ({ styleId, properties }) => {
		dispatch({
			type: Types.SET_STYLE_PROPERTIES,
			payload: keepNotFalsyObjectParams({
				styleId,
				properties
			})
		})
	}

	const setSectionData = ({
		sectionId,
		properties,
		content,
		order,
		hidden,
		title,
		site_section_id,
		site_block_code_id
	}) => {
		dispatch({
			type: Types.SET_SECTION_DATA,
			payload: keepNotFalsyObjectParams({
				sectionId,
				properties,
				content,
				order,
				hidden,
				title,
				site_section_id,
				site_block_code_id
			})
		})
	}

	const overrideSectionData = ({
		sectionId,
		properties,
		content,
		order,
		hidden,
		title,
		site_section_id,
		site_block_code_id
	}) => {
		dispatch({
			type: Types.OVERRIDE_SECTION_DATA,
			payload: keepNotFalsyObjectParams({
				sectionId,
				properties,
				content,
				order,
				hidden,
				title,
				site_section_id,
				site_block_code_id
			})
		})
	}

	const setSiteContent = (newSiteContent) => {
		Object.keys(newSiteContent).forEach((type) => {
			const siteContentType = newSiteContent[type]
			Object.keys(siteContentType).forEach((item) => {
				// eslint-disable-next-line no-param-reassign
				siteContentType[item].commitState = CommitStates.UPDATED
			})
		})

		dispatch({
			type: Types.SET_SITE_CONTENT,
			payload: keepNotFalsyObjectParams(newSiteContent)
		})
	}

	const deleteSection = ({ sectionId }) => {
		dispatch({
			type: Types.DELETE_SECTION,
			payload: keepNotFalsyObjectParams({
				sectionId
			})
		})
	}

	const createSection = ({ section }) => {
		dispatch({
			type: Types.CREATE_SECTION,
			payload: keepNotFalsyObjectParams({
				section
			})
		})
	}

	const getSectionById = (sectionId) => {
		const selectedSection = (state.sections || []).find(
			(section) => section.id === sectionId
		)

		if (!selectedSection) {
			return DEFAULTS.section
		}

		return selectedSection
	}

	const getSortedSectionsByPageId = (pageId) => {
		const selectedSections = (state.sections || [])
			.filter((section) => section.page_id === pageId)
			.filter((section) => section.commitState !== CommitStates.DELETED)
			.sort((A, B) => (A.order <= B.order ? -1 : 1))

		return selectedSections
	}

	const getSectionByHtmlTag = (htmlTag) => {
		const selectedSection = (state.sections || []).find(
			(section) => section.html_tag === htmlTag
		)

		if (!selectedSection) {
			return DEFAULTS.section
		}

		return selectedSection
	}

	const getStyleByType = (type) => {
		const selectedStyle = (state.styles || []).find(
			(style) => style.type === type
		)

		if (!selectedStyle) {
			return DEFAULTS.style
		}

		return selectedStyle
	}

	const getSiteContentByType = (type) => {
		const selectedSiteContent = state.siteContent[type]

		return selectedSiteContent
	}

	const setCurrentPageData = ({ pageId, currentSlug }) => {
		const currentPage = (state.pages || []).find((page) => page.id === pageId)
		if (currentSlug) {
			currentPage.currentSlug = currentSlug
		} else {
			[currentPage.currentSlug] = currentPage.slugs
		}

		dispatch({
			type: Types.SET_CURRENT_PAGE_DATA,
			payload: keepNotFalsyObjectParams({
				currentPage
			})
		})
	}

	/**
	 * Work around to auto complete state variables
	 */
	let updatedState = { ...initialState }
	updatedState = state

	return {
		setLiveEditorData,
		setStyleProperties,
		setSectionData,
		overrideSectionData,
		setSiteContent,
		deleteSection,
		createSection,
		getSectionById,
		getSortedSectionsByPageId,
		getSectionByHtmlTag,
		getStyleByType,
		getSiteContentByType,
		setCurrentPageData,
		state: updatedState,
		commitExistentLivePreviewChanges,
		CommitStates,
		addLivePreviewChanges,
		clearLivePreviewChangesHistory,
		livePreviewActions: livePreview.Actions
	}
}

const liveEditor = (state = initialState, action) => {
	switch (action.type) {
	case Types.SET_LIVE_EDITOR_DATA:
		return {
			...state,
			...action.payload
		}
	case Types.SET_STYLE_PROPERTIES:
		return {
			...state,
			changedData: true,
			styles: state.styles.map((style) => {
				if (style.id === action.payload.styleId) {
					return {
						...style,
						properties: {
							...style.properties,
							...action.payload.properties
						},
						commitState: CommitStates.UPDATED
					}
				}

				return style
			})
		}
	case Types.SET_SITE_CONTENT:
		return {
			...state,
			changedData: true,
			siteContent: {
				images: {
					...state.siteContent.images,
					...action.payload.images
				}
			}
		}
	case Types.SET_SECTION_DATA:
		return {
			...state,
			changedData: true,
			sections: state.sections.map((section) => {
				if (section.id === action.payload.sectionId) {
					return {
						...section,
						properties: {
							...section.properties,
							...action.payload.properties
						},
						content: {
							...section.content,
							...action.payload.content
						},
						title: action.payload.title || section.title,
						site_section_id: action.payload.site_section_id || section.site_section_id,
						site_block_code_id: action.payload.site_block_code_id || section.site_block_code_id,
						...(action.payload.order !== undefined && action.payload.order !== null
							? { order: action.payload.order }
							: {}),
						...(typeof action.payload.hidden === "boolean"
							? { hidden: action.payload.hidden }
							: {}),
						commitState: section.commitState === CommitStates.CREATED
							? CommitStates.CREATED
							: CommitStates.UPDATED
					}
				}

				return section
			})
		}
	case Types.OVERRIDE_SECTION_DATA:
		return {
			...state,
			changedData: true,
			sections: state.sections.map((section) => {
				if (section.id === action.payload.sectionId) {
					return {
						...section,
						...action.payload,
						commitState: section.commitState === CommitStates.CREATED
							? CommitStates.CREATED
							: CommitStates.UPDATED
					}
				}

				return section
			})
		}
	case Types.DELETE_SECTION:
		return {
			...state,
			changedData: true,
			sections: state.sections.map((section) => {
				if (section.id === action.payload.sectionId) {
					return {
						...section,
						commitState: CommitStates.DELETED
					}
				}

				return section
			})
		}
	case Types.CREATE_SECTION:
		return {
			...state,
			changedData: true,
			sections: [...state.sections, {
				...action.payload.section,
				commitState: CommitStates.CREATED
			}]
		}
	case Types.ADD_LIVE_PREVIEW_CHANGES:
		return {
			...state,
			livePreviewChanges: [
				...state.livePreviewChanges.map((oldChange) => {
					const changeMadeBefore = action.payload.livePreviewChanges.find(
						(newChange) => {
							if (
								newChange.action === oldChange.action
									&& newChange.selector === oldChange.selector
							) {
								return true
							}

							return false
						}
					)

					if (changeMadeBefore) {
						return _.merge(oldChange, changeMadeBefore)
					}

					return oldChange
				}),
				...action.payload.livePreviewChanges.filter((newChange) => {
					const changeMadeBefore = state.livePreviewChanges.find((oldChange) => {
						if (
							newChange.action === oldChange.action
								&& newChange.selector === oldChange.selector
						) {
							return true
						}

						return false
					})

					if (changeMadeBefore) {
						return false
					}

					return true
				})
			]
		}
	case Types.CLEAR_LIVE_PREVIEW_CHANGES:
		return {
			...state,
			livePreviewChanges: []
		}
	case Types.SET_CURRENT_PAGE_DATA:
		return {
			...state,
			currentPage: {
				...action.payload.currentPage
			}
		}
	default:
		return state
	}
}

export default liveEditor
