import React from "react"
import PropTypes from "prop-types"
import { Container } from "@material-ui/core"

import useStyles from "./styles"

const ActionZone = (props) => {
	const { children, onClick, className } = props

	const classes = useStyles()

	return (
		<Container
			disableGutters
			maxWidth={false}
			className={`${classes.container} ${className}`}
			onClick={onClick}
		>
			{children}
		</Container>
	)
}

ActionZone.defaultProps = {
	onClick: () => {},
	className: ""
}

ActionZone.propTypes = {
	onClick: PropTypes.func,
	className: PropTypes.string,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default ActionZone
