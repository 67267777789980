import React from "react"
import PropTypes from "prop-types"

import { Grid, IconButton, Typography, ButtonBase, Tooltip } from "@material-ui/core"
import {
	Edit as EditIcon,
	CheckCircleOutline as SuccessIcon,
	ErrorOutline as ErrorIcon
} from "@material-ui/icons"

import { Portlet, Divider } from "../../../components"

import { truncate } from "../../../utils/string"

import EditMetadataModal from "../EditMetadataModal"

import { titleLength, descriptionLength } from "../recomendedParams"

import useStyles from "./styles"

const PagesMetadata = (props) => {
	const { turboSeoPages, updateParent } = props

	const classes = useStyles()

	const handleOpenEditMetadataModal = (turboSeoPage) => {
		EditMetadataModal.open(turboSeoPage, updateParent)
	}

	const PageInfo = (turboSeoPage) => (
		<>
			<Typography variant="h2" style={{ textTransform: "uppercase" }}>
				{turboSeoPage?.page_title}
			</Typography>

			<Divider orientation="horizontal" size="small" />

			<Portlet disablePadding>
				<ButtonBase
					onClick={() => handleOpenEditMetadataModal(turboSeoPage)}
					className={classes.pageInfoButton}
				>
					<Grid
						container
						spacing={2}
						alignItems="center"
					>
						<Grid item sm={1} xs={2}>
							{(turboSeoPage.title && turboSeoPage.description)
								? (<SuccessIcon className={classes.successIcon} />)
								: (
									<Tooltip
										title={`Preencha 
                    ${!turboSeoPage.title ? "o título" : ""}
                    ${(!turboSeoPage.title && !turboSeoPage.description) ? "e" : ""} 
                    ${!turboSeoPage.description ? "a descrição" : ""}
                    `}
									>
										<ErrorIcon className={classes.errorIcon} />
									</Tooltip>
								)}
						</Grid>

						<Grid item sm={10} xs={8}>
							<Grid
								container
								spacing={1}
								alignItems="flex-start"
								className={classes.pageInfoContainer}
							>

								<Grid item xs={12}>
									<Typography className={classes.url}>
										{/* {`${siteStore.state.picsize_site_url}/${turboSeoPage.slug_name}`} */}
										{`/${turboSeoPage.slug_name}`}
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Typography className={classes.title}>
										{truncate(turboSeoPage.title || `Adicione um título (recomendado até ${titleLength} caracteres)`, titleLength)}
									</Typography>
								</Grid>

								<Grid item xs={12}>
									<Typography className={classes.description}>
										{truncate(turboSeoPage.description || `Adicione uma descrição (recomendado até ${descriptionLength} caracteres)`, descriptionLength)}
									</Typography>
								</Grid>
							</Grid>
						</Grid>

						<Grid item sm={1} xs={2}>
							<Grid container justify="flex-end">
								<IconButton onClick={() => handleOpenEditMetadataModal(turboSeoPage)}>
									<EditIcon />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</ButtonBase>
			</Portlet>
		</>
	)

	return (
		<Grid container spacing={4} className={classes.pagesContainer}>
			{turboSeoPages && turboSeoPages.map(turboSeoPage => (
				<Grid item xs={12}>
					{PageInfo(turboSeoPage)}
				</Grid>
			))}
		</Grid>
	)
}

PagesMetadata.defaultProps = {
	turboSeoPages: { title: "", description: "" },
	updateParent: () => { }
}

PagesMetadata.propTypes = {
	turboSeoPages: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string,
			description: PropTypes.string
		})
	),
	updateParent: PropTypes.func
}

export default PagesMetadata
