import React, { useState } from "react"
import PropTypes from "prop-types"
import { CustomPicker, ChromePicker as ColorSelector } from "react-color"
import {
	Grid,
	Button
} from "@material-ui/core"
import { Divider } from "../../../../../components"

import useStyles from "./styles"

const ColorPickerInput = (props) => {
	const { onChange, disableAlpha, initialColor, closePopover } = props
	const classes = useStyles()

	const [currentColor, setCurrentColor] = useState(initialColor)

	const handlePreviewChange = (color) => {
		setCurrentColor(color.rgb)
		onChange(color.rgb, "preview")
	}

	const handleSave = () => {
		onChange(currentColor, "completed")
	}

	const handleCancel = () => {
		closePopover()
	}

	return (
		<Grid container direction="column">
			<Grid item xs="12" className={classes.colorPickerContainer}>
				<ColorSelector
					disableAlpha={disableAlpha}
					color={currentColor?.rgb ?? currentColor}
					onChange={handlePreviewChange}
				/>
			</Grid>

			<Divider size="small" orientation="horizontal" />

			<Grid justify="space-between" container className={classes.buttonsContainer}>
				<Grid item>
					<Button
						className={classes.cancelButton}
						onClick={handleCancel}
						color="inherit"
					>
						Cancelar
					</Button>
				</Grid>

				<Grid item>
					<Button
						onClick={handleSave}
						variant="contained"
						color="secondary"
						disabled={currentColor === initialColor}
					>
						Salvar
					</Button>
				</Grid>
			</Grid>

			<Divider size="small" orientation="horizontal" />
		</Grid>
	)
}

ColorPickerInput.defaultProps = {
	disableAlpha: false
}

ColorPickerInput.propTypes = {
	onChange: PropTypes.func.isRequired,
	disableAlpha: PropTypes.bool,
	initialColor: PropTypes.string.isRequired,
	closePopover: PropTypes.func.isRequired
}

export default CustomPicker(ColorPickerInput)
