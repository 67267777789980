import React, { useState } from "react"
import PropTypes from "prop-types"

import {
	IconButton,
	Card,
	Grid,
	Typography,
	Dialog,
	DialogContent,
	DialogTitle,
	CardContent,
	List,
	Box,
	ListItem,
	ListItemText
} from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import useDidMount from "../../../../../hooks/useDidMount"
import useValidation from "../../../../../hooks/useValidation"

import api from "../../../../../services/api"
import getBlockCodeName from "../../../../../utils/getBlockCodeName"

import useStyles from "./styles"

const filterBlockCodes = (blockCodes) => {
	const allowedSections = ["image_1", "links_1"]
	return blockCodes.filter(section => allowedSections.includes(section.file))
}

const SectionsGallery = ({ onSelect, onClose, open }) => {
	const { triggerUndeterminedValidation } = useValidation()
	const [categories, setCategories] = useState([])
	const [activeCategoryIndex, setActiveCategoryIndex] = useState(null)

	const classes = useStyles()

	const getAndUpdateCategories = async () => {
		try {
			const response = await api.get("/site/block-codes")
			const blockCodes = filterBlockCodes(response.data.blockCodes)
			setCategories(blockCodes)
			setActiveCategoryIndex(0)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleSelectSection = (section) => {
		const currentCategory = categories[activeCategoryIndex]
		onSelect({
			...section,
			site_section_id: section.id,
			site_block_code_id: currentCategory.id,
			file: currentCategory.file,
			category: currentCategory.category
		})
		onClose()
	}

	useDidMount(() => { getAndUpdateCategories() })

	return (
		<Dialog fullScreen open={open}>
			<DialogTitle>
				<Grid container justify="space-between" alignItems="center">
					<Typography className={classes.title}>
						Galeria de Seções
					</Typography>

					<IconButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</IconButton>
				</Grid>
			</DialogTitle>

			<DialogContent>
				<Grid container spacing={4} alignItems="stretch">
					<Grid item xs={12} md={3} xl={2}>
						<List className={classes.categoryList} disablePadding>
							{categories?.map((category, index) => {
								const isActiveCategory = index === activeCategoryIndex
								const listItemClassName = [classes.categoryListItem]

								if (isActiveCategory) {
									listItemClassName.push(classes.categoryListItemActive)
								}

								return (
									<ListItem
										key={category.id}
										className={listItemClassName}
										button
										onClick={() => setActiveCategoryIndex(index)}
									>
										<ListItemText primary={getBlockCodeName(category.file)} disableTypography />
									</ListItem>
								)
							})}
						</List>
					</Grid>

					<Grid item xs={12} md={9} xl={10}>
						<Grid container spacing={10}>
							{categories[activeCategoryIndex]?.sections.map(section => (
								<Grid item xs={12} md={6} lg={4}>
									<Card className={classes.card} onClick={() => handleSelectSection(section)}>
										<Box
											className={classes.cardMediaContainer}
										>
											<Box
												className={classes.cardMedia}
												style={{ backgroundImage: `url(${section.cover_key})` }}
											/>
										</Box>

										<CardContent>
											{section.title}
										</CardContent>
									</Card>
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	)
}

SectionsGallery.propTypes = {
	onSelect: PropTypes.func,
	onClose: PropTypes.func,
	open: PropTypes.bool
}

SectionsGallery.defaultProps = {
	onSelect: () => { },
	onClose: () => { },
	open: false
}

export default SectionsGallery
