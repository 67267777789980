import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	// supportButton: {
	// 	position: "absolute",
	// 	bottom: 20,
	// 	right: 20,
	// 	boxShadow: "0px 4px 6px rgba(50, 50, 50, 0.2)",
	// 	fontWeight: "bold"
	// },
	containerWithoutPadding: {
		padding: 0
	}
})

export default useStyles
