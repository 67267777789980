import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import {
	Height as HeightIcon
} from "@material-ui/icons"

import { MenuItem } from "@material-ui/core"
import { GroupField, Select, ImageSelect, Slider, ColorPicker, LiveEditorTypography } from "../../../../components"
import { Divider } from "../../../../../../components"

import { useLiveEditorStore } from "../../../../../../store"

import { getOnlyNumber } from "../../../../../../utils/string"

const SectionDefaultDesign = ({ sectionId }) => {
	const liveEditorStore = useLiveEditorStore()

	const sectionData = liveEditorStore.getSectionById(sectionId)
	const generalStyles = liveEditorStore.getStyleByType("general")

	const [applyBackgroundTo, setApplyBackgroundTo] = useState(null)
	const [backgroundProps, setBackgroundProps] = useState(null)
	const [containerMargin, setContainerMargin] = useState(null)

	const handleChangeSectionWidth = (event) => {
		const value = event?.target?.value

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} [le-section-content]`,
				value: {
					"max-width": value === "site" ? "var(--page-section-content-max-width)" : "100%",
					width: value === "site" ? "var(--page-section-content-width)" : "100%"
				}
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties: {
				section_content_max_width: value === "site" ? "" : "100%",
				section_content_width: value === "site" ? "" : "100%"
			}
		})
	}

	const getPaddingOrMarginByDirection = (value, direction) => {
		if (typeof value !== "string") {
			return 0
		}

		const splittedValue = value.split(" ")

		// has just one value
		if (splittedValue.length === 1) {
			return splittedValue[0] || "0"

			// has more than one value
		} if (splittedValue.length > 1) {
			if (direction === "vertical") {
				return splittedValue[0]
			}
			return splittedValue[1]
		}
	}

	const handleChangeContainerMargin = (_, value) => {
		setContainerMargin(value)

		const margin = `${value}px 0`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId}[le-section-container]`,
				value: {
					margin
				}
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties: {
				section_container_margin: margin
			}
		})
	}

	const handleChangePadding = (value, direction) => {
		const currentPadding = sectionData?.properties?.section_content_padding
		const otherDirection = direction === "vertical" ? "horizontal" : "vertical"

		const paddings = {}

		paddings[direction] = `${value}px`
		paddings[otherDirection] = getPaddingOrMarginByDirection(currentPadding, otherDirection)

		const padding = `${paddings.vertical} ${paddings.horizontal}`

		liveEditorStore.setSectionData({
			sectionId,
			properties: {
				section_content_padding: padding
			}
		})

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${sectionId} [le-section-content]`,
				value: {
					padding
				}
			}
		])
	}

	const handleChangeVerticalPadding = (_, value) => {
		handleChangePadding(value, "vertical")
	}

	const handleChangeHorizontalPadding = (_, value) => {
		handleChangePadding(value, "horizontal")
	}

	const setBackground = (applyTo, { backgroundImage, backgroundImageOpacity, backgroundColor }) => {
		let selectorBackgroundColor
		let selectorBackgroundImage
		let backgroundImageProperty
		let backgroundImageOpacityProperty
		let backgroundColorProperty

		if (applyTo === "screen") {
			selectorBackgroundColor = `#page-section-${sectionId}[le-section-container]::before`
			selectorBackgroundImage = `#page-section-${sectionId}[le-section-container]::after`
			backgroundColorProperty = "section_container_background_color"
			backgroundImageProperty = "section_container_background"
			backgroundImageOpacityProperty = "section_container_background_image_opacity"
		} else {
			selectorBackgroundColor = `#page-section-${sectionId} [le-section-content]::before`
			selectorBackgroundImage = `#page-section-${sectionId} [le-section-content]::after`
			backgroundColorProperty = "section_content_background_color"
			backgroundImageProperty = "section_content_background"
			backgroundImageOpacityProperty = "section_content_background_image_opacity"
		}

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: selectorBackgroundColor,
				value: {
					"background-color": backgroundColor || sectionData.properties[backgroundColorProperty]
				}
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: selectorBackgroundImage,
				value: {
					background: backgroundImage === false
						? "url()"
						: (backgroundImage || sectionData.properties[backgroundImageProperty]),
					opacity: backgroundImageOpacity || sectionData.properties[backgroundImageOpacityProperty]
				}
			}
		])

		liveEditorStore.setSectionData({
			sectionId,
			properties: {
				[backgroundColorProperty]: backgroundColor
					|| sectionData.properties[backgroundColorProperty],
				[backgroundImageProperty]: backgroundImage === false
					? "url"
					: (backgroundImage || sectionData.properties[backgroundImageProperty]),
				[backgroundImageOpacityProperty]: backgroundImageOpacity
					|| sectionData.properties[backgroundImageOpacityProperty]
			}
		})
	}

	const handleChangeBackgroundApplyTo = (event) => {
		const value = event?.target?.value
		setApplyBackgroundTo(value)
		setBackgroundProps({ ...backgroundProps, applyTo: value })
		const otherOption = value === "screen" ? "section" : "screen"

		setBackground(value, backgroundProps)
		setBackground(otherOption, {
			backgroundImage: false,
			backgroundImageOpacity: 1,
			backgroundColor: "transparent"
		})
	}


	const handleChangeBackgroundImage = (imageUrl) => {
		const imageOrBlank = imageUrl || false
		const backgroundImage = imageOrBlank && `url(${imageOrBlank}) center / cover no-repeat`

		setBackgroundProps({
			...backgroundProps,
			backgroundImage
		})

		setBackground(backgroundProps.applyTo, { ...backgroundProps, backgroundImage })
	}

	const handleChangeBackgroundImageOpacity = (_, backgroundImageOpacity) => {
		setBackgroundProps({
			...backgroundProps,
			backgroundImageOpacity
		})

		setBackground(backgroundProps.applyTo, { ...backgroundProps, backgroundImageOpacity })
	}

	const handleChangeBackgroundColorOnPreview = (color) => {
		const selector = applyBackgroundTo === "screen"
			? `#page-section-${sectionId}[le-section-container]::before`
			: `#page-section-${sectionId} [le-section-content]::before`

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector,
				value: { "background-color": `${color}` }
			}
		])
	}

	const handleChangeBackgroundColor = (backgroundColor) => {
		setBackgroundProps({
			...backgroundProps,
			backgroundColor
		})

		setBackground(backgroundProps.applyTo, { ...backgroundProps, backgroundColor })
	}

	useEffect(() => {
		if (!backgroundProps && sectionData?.properties) {
			const applyTo = (
				sectionData.properties.section_container_background
				&& sectionData.properties.section_container_background !== "url"
			) || (
				sectionData.properties.section_container_background_color
					&& sectionData.properties.section_container_background_color !== "transparent"
			) ? "screen" : "section"

			if (applyTo === "screen") {
				setBackgroundProps({
					applyTo,
					backgroundImage: sectionData.properties.section_container_background,
					backgroundImageOpacity: sectionData.properties.section_container_background_image_opacity,
					backgroundColor: sectionData.properties.section_container_background_color
				})
			} else {
				setBackgroundProps({
					applyTo,
					backgroundImage: sectionData.properties.section_content_background,
					backgroundImageOpacity: sectionData.properties.section_content_background_image_opacity,
					backgroundColor: sectionData.properties.section_content_background_color
				})
			}

			setApplyBackgroundTo(
				sectionData.properties.section_container_background ? "screen" : "section"
			)
		}

		if (!containerMargin) {
			setContainerMargin(
				getOnlyNumber(
					getPaddingOrMarginByDirection(
						sectionData?.properties?.section_container_margin
						|| generalStyles?.properties?.page_section_container_margin,
						"vertical"
					)
				)
			)
		}
	}, [sectionData, sectionData.properties])

	return (
		<GroupField label="Design">
			<Select
				label="Largura da Seção"
				onChange={handleChangeSectionWidth}
				value={sectionData?.properties?.section_content_max_width ? "screen" : "site"}
			>
				<MenuItem key="site" value="site">
					Limitada à largura do site
				</MenuItem>
				<MenuItem key="screen" value="screen">
					100% da largura da tela
				</MenuItem>
			</Select>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Distância para as seções vizinhas"
				onChange={handleChangeContainerMargin}
				value={containerMargin}
				icon={<HeightIcon />}
				min={0}
				max={128}
				step={4}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Preenchimento"
				onChange={handleChangeVerticalPadding}
				value={getOnlyNumber(
					getPaddingOrMarginByDirection(sectionData.properties.section_content_padding, "vertical")
				)}
				icon={<HeightIcon />}
				min={0}
				max={128}
				step={4}
			/>

			<Slider
				icon={<HeightIcon style={{ transform: "rotate(90deg)" }} />}
				onChange={handleChangeHorizontalPadding}
				value={getOnlyNumber(
					getPaddingOrMarginByDirection(sectionData.properties.section_content_padding, "horizontal")
				)}
				min={0}
				max={128}
				step={4}
			/>

			<Divider orientation="horizontal" size="large" />

			<LiveEditorTypography type="groupFieldSubtitle">
				Background
			</LiveEditorTypography>

			<Divider orientation="horizontal" size="medium" />

			<Select
				label="Aplicar a"
				value={applyBackgroundTo}
				onChange={handleChangeBackgroundApplyTo}
			>
				<MenuItem key="section" value="section">
					Limitada à largura da seção
				</MenuItem>
				<MenuItem key="screen" value="screen">
					100% da largura da tela
				</MenuItem>
			</Select>

			<Divider orientation="horizontal" size="medium" />

			<ImageSelect
				label="Imagem de fundo"
				onSelect={handleChangeBackgroundImage}
				currentImage={backgroundProps?.backgroundImage}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Slider
				label="Opacidade da imagem"
				value={
					backgroundProps?.backgroundImageOpacity || 1
				}
				onChange={handleChangeBackgroundImageOpacity}
				min={0}
				max={1}
				step={0.01}
			/>

			<Divider orientation="horizontal" size="medium" />

			<ColorPicker
				label="Cor de fundo"
				onChange={handleChangeBackgroundColor}
				onPreviewChange={handleChangeBackgroundColorOnPreview}
				enableOpacity
				value={backgroundProps?.backgroundColor}
			/>
		</GroupField>
	)
}

SectionDefaultDesign.propTypes = {
	sectionId: PropTypes.number.isRequired
}

export default SectionDefaultDesign
