import { makeStyles } from "@material-ui/core"
import colors from "../../../styles/colors"

const useStyles = makeStyles(theme => {
	const iframeMobileWidth = "450px"
	const headerHeight = "56px"

	return {
		headerContainer: {
			backgroundColor: "#ffffff",
			padding: theme.spacing(0.5, 1),
			height: headerHeight
		},
		iframeContainer: {
			height: `calc(100vh - ${headerHeight})`,
			width: "100%",
			display: "flex",
			justifyContent: "center"
		},
		iframe: {
			flex: 1,
			border: "none",
			maxWidth: ({ iframeDevice }) => {
				if (iframeDevice === "desktop-full") {
					return "100%"
				} if (iframeDevice === "desktop-1366px") {
					return "1366px"
				}

				return iframeMobileWidth
			}
		},
		justifyStart: {
			display: "flex",
			justifyContent: "flex-start",
			alignItems: "center"
		},
		justifyEnd: {
			display: "flex",
			justifyContent: "flex-end",
			alignItems: "center"
		},
		themeNameContainer: {
			display: "flex",
			alignItems: "center"
		},
		themeName: {
			textTransform: "uppercase",
			color: colors.greyScale[5],
			paddingRight: theme.spacing(1.5)
		},
		dividerContainer: {
			display: "flex",
			alignItems: "center"
		},
		divider: {
			height: "60%"
		},
		desktopIcon: {
			color: ({ iframeDevice }) => (iframeDevice === "desktop-full" ? colors.greyScale[4] : colors.greyScale[9]),
			transition: "0.3s"
		},
		desktop1366pxIcon: {
			color: ({ iframeDevice }) => (iframeDevice === "desktop-1366px" ? colors.greyScale[4] : colors.greyScale[9]),
			transition: "0.3s"
		},
		mobileIcon: {
			color: ({ iframeDevice }) => (iframeDevice === "mobile" ? colors.greyScale[4] : colors.greyScale[9]),
			transition: "0.3s"
		}
	}
})

export default useStyles
