import { makeStyles } from "@material-ui/core"

import { isMobile } from "../../../utils/checkDevice"

import colors from "../../../styles/colors"

const useStyles = makeStyles(theme => ({
	externalLinkLabel: {
		marginRight: 0
	},
	emptySelectLabel: {
		color: theme.palette.error.main
	},
	pictureContainer: {
		maxWidth: "100%",
		height: isMobile ? 300 : 450,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		borderColor: colors.greyScale[7],
		borderWidth: "2px",
		borderStyle: "dashed",
		borderRadius: "3px",
		backgroundSize: "cover",
		backgroundRepeat: "noRepeat",
		backgroundPosition: "center",
		backgroundColor: colors.greyScale[10]
	},
	noBorder: {
		border: 0
	},
	pictureUploadButton: {
		backgroundColor: theme.palette.primary.main,
		color: "#fff",

		"&:hover": {
			backgroundColor: theme.palette.primary.dark
		}
	},
	pictureHelperText: {
		color: colors.greyScale[4],
		fontSize: 12
	},
	radioGroup: {
		display: "flex",
		justifyContent: "space-between"
	}
}))

export default useStyles
