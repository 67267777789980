import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
	dialog: {
		position: "relative"
	},
	closeIcon: {
		position: "absolute",
		top: 0,
		right: 0
	}
})

export default useStyles
