import React from "react"
import PropTypes from "prop-types"

import {
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails
} from "@material-ui/core"

import {
	ExpandMore as ExpandMoreIcon
} from "@material-ui/icons"
import LiveEditorTypography from "../LiveEditorTypography"
import useStyles from "./styles"

const Accordion = (props) => {
	const { expanded, onChange, label, icon, children } = props
	const classes = useStyles()
	return (
		<>
			<ExpansionPanel
				classes={{
					expanded: classes.expanded
				}}
				className={classes.panelContainer}
				expanded={expanded}
				onChange={onChange}
			>
				<ExpansionPanelSummary
					expandIcon={<ExpandMoreIcon />}
					aria-controls={`accordion-${label}`}
					id={`accordion-${label}`}
					className={classes.panelSummary}
				>
					{icon}
					<LiveEditorTypography
						type="accordionTitle"
						className={classes.title}
					>
						{label}
					</LiveEditorTypography>
				</ExpansionPanelSummary>

				<ExpansionPanelDetails className={classes.childrenContainer}>
					{children}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</>
	)
}

Accordion.defaultProps = {
	icon: null,
	children: null
}

Accordion.propTypes = {
	expanded: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	icon: PropTypes.node,
	children: PropTypes.node
}

export default Accordion
