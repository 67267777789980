import React, { useRef, useState, useEffect } from "react"
import {
	Grid,
	Button
} from "@material-ui/core"

import Console from "./Console"
import ActionsTopBar from "./ActionsTopBar"
import MobileBottomBar from "./MobileBottomBar"

import useStyles from "./styles"

import useDidMount from "../../hooks/useDidMount"
import useValidation from "../../hooks/useValidation"
import useLivePreview, { livePreviewID, getLivePreviewDocument } from "../../hooks/useLivePreview"

import { Notification, InfoModal, CustomContainer, PopConfirm } from "../../components"

import { useSiteStore, useLiveEditorStore } from "../../store"

import { isDevOrTheme } from "../../utils/checkCustomer"

import api from "../../services/api"

import {
	livePreviewBaseInjectedStyles,
	livePreviewDevInjectedStyles,
	exitButtonInjectedStyles
} from "./livePreviewInjectedStyles"
import livePreviewController from "./livePreviewController"

import useCustomStyles from "../../styles/custom"

import { getStatusCode } from "../../utils/response"
import { CheckUserPlan, siteProPlansUrl } from "../../utils/plan"
import { convertObjectURLInFormData } from "../../utils/image"

const iframeMessageEvents = {
	clickBodySection: "CLICK_BODY_PAGE_SECTION"
}

const LiveEditor = () => {
	const livePreviewRef = useRef()

	const { triggerUndeterminedValidation } = useValidation()
	const { addOrRefreshControllerListeners } = useLivePreview()

	const [isConsoleVisible, setIsConsoleVisible] = useState(true)
	const [editorType, setEditorType] = useState("styles")
	const [device, setDevice] = useState("desktop")
	const [loading, setLoading] = useState(true)
	const [publishing, setPublishing] = useState(false)
	const [isMobileLivePreviewActive, setIsMobileLivePreviewActive] = useState(false)
	const [iframeCurrentSlug, setIframeCurrentSlug] = useState("")
	const [triggerSelectSectionId, setTriggerSelectSectionId] = useState(null)

	const classes = useStyles({ device, isConsoleVisible })
	const customClasses = useCustomStyles()

	const siteStore = useSiteStore()
	const liveEditorStore = useLiveEditorStore()

	const { clearLivePreviewChangesHistory } = liveEditorStore
	const storePages = liveEditorStore.state.pages
	const storeCurrentPage = liveEditorStore.state.currentPage

	const livePreviewBaseUrl = siteStore.state.picsize_site_url
	const userHasFreePlan = CheckUserPlan([26])

	const viewWarningModalPlan = () => InfoModal.warning({
		title: "Ação negada",
		description: "Seu plano atual não permite utilizar essa função.",
		customConfirmButtons: [
			<Button
				variant="contained"
				className={customClasses.seePlansButton}
				href={siteProPlansUrl}
				target="blank"
			>
				VER PLANOS
			</Button>
		],
		cancelButtonText: "FECHAR"
	})


	const iframeUrl = `${livePreviewBaseUrl}/${iframeCurrentSlug}`

	const sortSections = (sections) => {
		const sortedSections = sections
			.sort((A, B) => A.order - B.order)
		return sortedSections
	}

	const getAndUpdateLiveEditorData = async (currentPage) => {
		try {
			const response = await api.get("/site/live-editor/data")

			const sortedSections = sortSections(response.data.sections)

			liveEditorStore.setLiveEditorData({
				sections: sortedSections,
				styles: response.data.styles,
				siteContent: response.data.siteContent,
				pages: response.data.pages,
				currentPage: currentPage || response.data.pages[0]
			})
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const initLivePreview = async () => {
		try {
			if (process.env.NODE_ENV === "development") {
				document.domain = "picsize.localhost"
			} else {
				document.domain = "picsize.com.br"
			}

			await new Promise(resolve => {
				livePreviewRef.current.onload = resolve
			})
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const reloadLiveEditorData = () => {
		getAndUpdateLiveEditorData(storeCurrentPage)
	}

	const reloadLivePreview = () => {
		// eslint-disable-next-line
		livePreviewRef.current.src = livePreviewRef.current.src
	}

	const addPlanMethod = () => {
		if (userHasFreePlan) {
			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_HTML,
					selector: ".page-widgets .branding-picsize-1-content",
					value: "<span id='exit-plans-live-preview' class='branding-exit-button'></span>"
				}
			])

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_CSS,
					selector: "style-exit-button-in-branding",
					value: exitButtonInjectedStyles,
					pageId: liveEditorStore.state.currentPage.id
				}
			])
		}
	}

	const viewWarningWhenButtonCloseClicked = () => {
		const iframeLivePreview = getLivePreviewDocument()
		const divSelector = iframeLivePreview?.getElementById("exit-plans-live-preview")

		if (divSelector) {
			divSelector.addEventListener("click", () => {
				viewWarningModalPlan()
			})
		}
	}

	const initLiveEditor = async () => {
		await Promise.all([
			initLivePreview(),
			getAndUpdateLiveEditorData(),
			addPlanMethod()
		])

		setLoading(false)
	}

	const getIframePathname = () => {
		const { location } = livePreviewRef.current.contentWindow
		if (location.origin !== livePreviewBaseUrl) {
			throw Error("location.origin !== baseURL")
		}
		return location.pathname.slice(1)
	}

	const handleIframeChangeLocation = () => {
		try {
			const iframePathname = getIframePathname()

			if (iframePathname !== (storeCurrentPage?.currentSlug || "")) {
				let currentPage = storePages?.find(
					page => page.slugs.includes(iframePathname)
				)

				if (!currentPage) {
					currentPage = storePages?.find(page => page.type === "home")
				}

				liveEditorStore.setCurrentPageData({ pageId: currentPage.id, currentSlug: iframePathname })
			}
		} catch (error) {
			// Ensure that the iframe reload
			// reloadLivePreview()

			// viewWarningModalPlan()
			// throw Error()
			console.error(error)
		}
	}

	const addLiveEditorStyles = () => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
				selector: "live-editor-styles",
				value: livePreviewBaseInjectedStyles,
				pageId: liveEditorStore.state.currentPage.id
			}
		])

		if (isDevOrTheme()) {
			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
					selector: "live-editor-dev-styles",
					value: livePreviewDevInjectedStyles,
					pageId: liveEditorStore.state.currentPage.id
				}
			])
		}
	}

	const initLivePreviewController = () => {
		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.INSERT_PURE_JS,
				selector: "le-controller-handler",
				value: livePreviewController,
				pageId: liveEditorStore.state.currentPage.id
			}
		])

		addOrRefreshControllerListeners()
	}

	const handleIframeOnload = () => {
		setTriggerSelectSectionId(null)
		// This try is used to treat cases where the user try to access an external url
		try {
			handleIframeChangeLocation()
			addLiveEditorStyles()
			initLivePreviewController()
			liveEditorStore.commitExistentLivePreviewChanges()

			viewWarningWhenButtonCloseClicked()
			// eslint-disable-next-line
		} catch (error) {
			console.error(error)
		}
	}

	const handlePublish = async () => {
		setPublishing(true)

		try {
			const { sections, styles, siteContent } = liveEditorStore.state
			const changedSections = sections
				.filter(section => section.commitState)

			const changedStyles = styles
				.filter(style => style.commitState)

			const changedSiteContent = {}

			Object.keys(siteContent).forEach((type) => {
				const siteContentType = siteContent[type]
				Object.keys(siteContentType).forEach((item) => {
					if (siteContentType[item].commitState) {
						const newSiteContent = siteContentType[item]
						changedSiteContent[type] = {
							[item]: {
								newSiteContent
							}
						}
					}
				})
			})

			await api.put("/site/live-editor/publish", {
				sections: changedSections,
				styles: changedStyles,
				siteContent: changedSiteContent
			})

			const siteContentImages = siteContent.images
			await Promise.all(
				Object.keys(siteContentImages).map(async (item) => {
					if (siteContentImages[item].commitState) {
						const newSiteContent = siteContentImages[item]

						if (newSiteContent.content?.url) {
							const imageData = await convertObjectURLInFormData(
								newSiteContent
									.content
									.url
							)

							await api.put(`/site/content/contentImage/${siteContentImages[item].id}`, imageData, {
								headers: {
									"Content-Type": "multipart/data"
								}
							})
						}
					}
				})
			)

			clearLivePreviewChangesHistory()
			reloadLiveEditorData()
			reloadLivePreview()

			Notification.success({ message: "Alterações publicadas com sucesso!" })

			liveEditorStore.setLiveEditorData({ changedData: false })
		} catch (error) {
			if (getStatusCode(error) === 402) {
				InfoModal.warning({
					title: "Ação negada",
					description: "Seu plano atual não permite utilizar essa função.",
					customConfirmButtons: [
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							VER PLANOS
						</Button>
					],
					cancelButtonText: "FECHAR"
				})
			} else {
				triggerUndeterminedValidation(error)
			}
		}

		setPublishing(false)
	}

	const handleDiscardChanges = () => {
		PopConfirm.open({
			title: "Tem certeza?",
			description: "Descartar alterações é uma ação irreversível.",
			confirmButtonText: "Descartar",
			onConfirm: () => {
				document.location.reload()
			}
		})
	}

	const handleReceiveIframeMessage = (message) => {
		const isFromIframe = message.origin === livePreviewBaseUrl
		if (isFromIframe) {
			const { id, event } = JSON.parse(message.data)

			if (event === iframeMessageEvents.clickBodySection) {
				const sectionId = parseInt(id.split("-")[2], 10)
				setEditorType("pages")

				// Set to null first to trigger effect
				setTriggerSelectSectionId(null)
				setTriggerSelectSectionId(sectionId)
			}
		}
	}

	useDidMount(() => {
		initLiveEditor()

		window.addEventListener("message", handleReceiveIframeMessage)
	})

	useEffect(() => {
		// Update iframe src only if the iframe's current pathname is not equal currentPage.slug
		try {
			const iframePathname = getIframePathname()
			if (iframePathname !== (storeCurrentPage?.currentSlug || "")) {
				setIframeCurrentSlug(storeCurrentPage.currentSlug || "")

				// Ensure that the iframe reload
				// eslint-disable-next-line
				livePreviewRef.current.src = livePreviewRef.current.src
			}
			// eslint-disable-next-line
		} catch (error) { }
	}, [liveEditorStore.state.currentPage])

	return (
		<CustomContainer maxWidth={false}>
			<Grid container className={classes.liveEditorContainer}>
				<ActionsTopBar
					publishing={publishing}
					handlePublish={handlePublish}
					handleDiscardChanges={handleDiscardChanges}
					device={device}
					setDevice={setDevice}
					isConsoleVisible={isConsoleVisible}
					setIsConsoleVisible={setIsConsoleVisible}
				/>

				<Console
					loading={loading}
					publishing={publishing}
					device={device}
					isConsoleVisible={isConsoleVisible}
					editorType={editorType}
					setEditorType={setEditorType}
					triggerSelectSectionId={triggerSelectSectionId}
				/>

				<Grid
					item
					className={[
						classes.viewContainer,
						isMobileLivePreviewActive ? classes.isMobileLivePreviewActive : null
					]}
					xs
				>
					<Grid container>
						<Grid item xs={12}>
							<Grid container className={classes.iframeContainer}>
								<iframe
									id={livePreviewID}
									ref={livePreviewRef}
									className={classes.iframe}
									title="site-preview"
									src={iframeUrl}
									referrerPolicy="same-origin"
									allow="true"
									loading="eager"
									onLoad={handleIframeOnload}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{!loading && (
				<MobileBottomBar
					publishing={publishing}
					handlePublish={handlePublish}
					isMobileLivePreviewActive={isMobileLivePreviewActive}
					setIsMobileLivePreviewActive={setIsMobileLivePreviewActive}
				/>
			)}
		</CustomContainer>
	)
}

export default LiveEditor
