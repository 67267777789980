import { makeStyles } from "@material-ui/core"
import colors from "../../../../../../styles/colors"

const itemImageSize = 60

const useStyles = makeStyles((theme) => ({
	itemContainer: {
		width: "100%",
		backgroundColor: colors.greyScale[9]
	},
	itemImage: {
		width: itemImageSize,
		height: itemImageSize,

		// Below properties need !important to override inline background
		backgroundColor: `${colors.greyScale[10]} !important`
	},
	itemTitle: {
		marginLeft: theme.spacing(2),
		textAlign: "left"
	},
	addItemButton: {
		height: itemImageSize,
		width: "100%",
		border: `2px dashed ${colors.greyScale[8]}`,
		textTransform: "uppercase",
		color: colors.greyScale[2]
	},
	addItemButtonIcon: {
		marginRight: theme.spacing(1)
	}
}))

export default useStyles
