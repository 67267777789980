const sectionNames = {
	"About A": "Sobre Mim",
	"About B": "Sobre Mim",
	"About C": "Sobre Mim",
	"About D": "Sobre Mim",
	"About Full A": "Sobre Mim",
	"About Full B": "Sobre Mim",
	"About Full C": "Sobre Mim",
	"Sobre Mim A": "Sobre Mim",
	"Sobre Mim B": "Sobre Mim",
	"Sobre Mim C": "Sobre Mim",
	"Sobre Mim D": "Sobre Mim",
	"Sobre Mim E": "Sobre Mim",
	"Sobre Mim F": "Sobre Mim",

	"Header A": "Cabeçalho",
	"Header Banner A": "Cabeçalho",
	"Header Banner B": "Cabeçalho",

	"Footer A": "Rodapé",

	"Work A": "Trabalhos",
	"Categorias de Trabalhos A": "Categorias de Trabalhos",

	"Work Resume A": "Trabalhos",
	"Work Resume B": "Trabalhos",
	"Work Resume C": "Trabalhos",
	"Work List A": "Trabalhos",
	"Work List B": "Trabalhos",
	"Work List C": "Trabalhos",
	"Work List D": "Trabalhos",
	"Work List E": "Trabalhos",
	"Trabalhos A": "Trabalhos",
	"Trabalhos B": "Trabalhos",
	"Trabalhos C": "Trabalhos",
	"Trabalhos D": "Trabalhos",
	"Trabalhos E": "Trabalhos",
	"Trabalhos F": "Trabalhos",
	"Trabalhos G": "Trabalhos",

	"Work Single A": "Trabalho",

	"Category Menu A": "Menu de Trabalhos",

	"Testimonial Resume A": "Depoimentos",
	"Testimonial Resume B": "Depoimentos",
	"Testimonial Resume C": "Depoimentos",
	"Testimonial List A": "Depoimentos",
	"Testimonial List B": "Depoimentos",
	"Testimonial List C": "Depoimentos",
	"Depoimentos A": "Depoimentos",
	"Depoimentos B": "Depoimentos",
	"Depoimentos C": "Depoimentos",
	"Depoimentos D": "Depoimentos",
	"Depoimentos E": "Depoimentos",

	"Partner A": "Selos e Parceiros",

	"Contato A": "Contato",
	"Contato B": "Contato",
	"Contato C": "Contato",
	"Contato D": "Contato",
	"Contato E": "Contato",

	"Feed do Instagram A": "Feed do Instagram",

	"Imagem A": "Imagem",

	"Links A": "Links"
}


const getSectionName = (sectionTitle) => {
	const title = sectionNames[sectionTitle]
	return title || "Seção"
}

export default getSectionName
