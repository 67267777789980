import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "../components"

const LiveEditorSkeleton = () => (
	<Grid container>
		<Divider size="medium" orientation="horizontal" />
		<Grid container justify="space-evenly">
			<Skeleton animation="wave" variant="rect" width="45%" height={40} />
			<Skeleton animation="wave" variant="rect" width="45%" height={40} />
		</Grid>

		<Divider size="xlarge" orientation="horizontal" />

		<Grid item xs={12}>
			<Grid container justify="center">

				<Skeleton animation="wave" variant="rect" width="90%" height={52} />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" width="90%" height={52} />
				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" width="90%" height={52} />
				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" width="90%" height={52} />
				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" width="90%" height={52} />

			</Grid>
		</Grid>
	</Grid>
)

export default LiveEditorSkeleton
