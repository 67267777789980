import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, makeStyles, createStyles } from "@material-ui/core"

import { Divider } from "../components"

const useStyles = makeStyles(theme => createStyles({
	titleSkeleton: {
		padding: theme.spacing() * 1,
		maxWidth: 200,
		width: "100%"
	},
	inputSkeleton: {
		maxWidth: 300,
		width: "100%"
	}
}))

const CustomDomainBoxSkeleton = () => {
	const classes = useStyles()

	return (
		<Grid container direction="column">
			<Skeleton animation="wave" variant="rect" height={15} className={classes.titleSkeleton} />

			<Divider size="medium" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={45} className={classes.inputSkeleton} />

			<Divider size="large" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={50} width="50%" />

			<Divider size="medium" orientation="horizontal" />

			<Skeleton animation="wave" variant="rect" height={25} width="40%" />
		</Grid>
	)
}

export default CustomDomainBoxSkeleton
