import React, { useState } from "react"
import { Grid, Typography, TextField, Link, Button } from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { HelpOutline as HelpOutlineIcon } from "@material-ui/icons"

import {
	Portlet,
	Divider,
	InputDialog,
	Loading,
	PremiumChip,
	Notification
} from "../../components"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import useCustomStyles from "../../styles/custom"
import useStyles from "./styles"

import { useSiteStore } from "../../store"

import api from "../../services/api"

import { getStatusCode } from "../../utils/response"
import { isMobile } from "../../utils/checkDevice"
import { siteProPlansUrl } from "../../utils/plan"
import { supportContactInfo } from "../../utils/contact"

import SubdomainBoxSkeleton from "../../skeletons/SubdomainBox"
import CustomDomainBoxSkeleton from "../../skeletons/CustomDomainBox"
import CustomDomainHelperBoxSkeleton from "../../skeletons/CustomDomainHelperBox"

const Domain = () => {
	const siteStore = useSiteStore()

	const [subdomain, setSubdomain] = useState("")
	const [loadingSubdomain, setLoadingSubdomain] = useState(true)

	const [canUseCustomDomain, setCanUseCustomDomain] = useState(true)
	const [domainData, setDomainData] = useState({ domain: "", status: null })
	const [loadingDomain, setLoadingDomain] = useState(true)

	const customClasses = useCustomStyles()
	const classes = useStyles()

	const {
		validation,
		clearValidation,
		triggerValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const getSiteUrl = async () => {
		try {
			const { data } = await api.get("/site/url")

			siteStore.setSiteData({
				url: data.url
			})
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const getDomainData = async () => {
		try {
			const { data } = await api.get("/site/domain")

			setDomainData({
				domain: data.domain,
				status: data.status
			})

			setLoadingDomain(false)
		} catch (error) {
			/**
			 * In case customer does not has access to this feature,
			 * we update state and show a message
			 */
			if (getStatusCode(error) === 402) {
				const { data } = error.response

				setCanUseCustomDomain(false)

				setDomainData({
					domain: data.domain,
					status: data.status
				})

				setLoadingDomain(false)
			} else {
				triggerUndeterminedValidation(error)
			}
		}
	}

	const getSubdomainData = async () => {
		try {
			const { data } = await api.get("/site/subdomain")

			setSubdomain(data.subdomain)

			setLoadingSubdomain(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleUpdateSubdomain = async (newSubdomain) => {
		try {
			await api.put("/site/subdomain", { subdomain: newSubdomain })

			await Promise.all([getSubdomainData(), getSiteUrl()])

			Notification.success({ message: "Subdomínio alterado com sucesso!" })

			return true
		} catch (error) {
			triggerValidation(error)
		}
	}

	const handleUpdateDomain = async (newDomain) => {
		try {
			await api.put("/site/domain", { domain: newDomain })

			await Promise.all([getDomainData(), getSiteUrl()])

			Notification.success({ message: "Domínio alterado com sucesso!" })

			return true
		} catch (error) {
			triggerValidation(error)
		}
	}

	const getData = async () => {
		await Promise.all([getSubdomainData(), getDomainData()])
	}

	const DOMAIN_STATUS = {
		activated: {
			severity: "success",
			message: "O seu domínio já está conectado com a Picsize."
		},
		"not-pointed": {
			severity: "warning",
			message: (
				<p className={classes.alertMessage}>
					Feita a conexão do domínio, aguarde um período de até 48 horas para que ele seja
					{" "}
					propagado em toda a internet.
				</p>
			)
		},
		"not-found": {
			severity: "error",
			message: (
				<p className={classes.alertMessage}>
					Verificarmos que esse domínio ainda não está registrado em nenhuma entidade
					registradora. Nessa tela, logo abaixo tem a explicação para registrar um domínio
					próprio.
				</p>
			)
		},
		error: {
			severity: "error",
			message: (
				<p className={classes.alertMessage}>
					Verificarmos que esse domínio ainda não está registrado em nenhuma entidade
					registradora. Nessa tela, logo abaixo tem a explicação para registrar um domínio
					próprio.
				</p>
			)
		},
		"not-on-plan": {
			severity: "warning",
			message: (
				<Grid container alignItems="center">
					<p className={classes.alertMessage}>
						O domínio personalizado não está liberado para o plano gratuito, portanto para
						que tudo funcione perfeitamente, faça agora mesmo o upgrade.
					</p>

					<Divider size="medium" orientation="vertical" />

					<Button
						variant="contained"
						className={customClasses.seePlansButton}
						href={siteProPlansUrl}
						target="blank"
					>
						VER PLANOS
					</Button>
				</Grid>
			)
		}
	}

	useDidMount(() => {
		getData()
	})

	return (
		<Grid container spacing={2}>
			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Portlet>
					<Loading
						loading={loadingSubdomain}
						customLoadingElement={<SubdomainBoxSkeleton />}
					>
						<Grid container>
							<Typography variant="h2">DOMÍNIO DA PICISZE</Typography>

							<Divider size="large" orientation="horizontal" />

							<InputDialog
								title="Mudar subdomínio"
								onOk={handleUpdateSubdomain}
								onDataChange={() => clearValidation("subdomain")}
								initialValue={subdomain}
								fullWidth={false}
								cursor="text"
								customInputElement={(
									<TextField
										InputProps={{
											endAdornment: (
												<Typography>
													<b>.picsize.com.br</b>
												</Typography>
											)
										}}
										color="secondary"
										error={!!validation.subdomain}
										helperText={validation.subdomain}
									/>
								)}
							>
								<TextField
									InputProps={{
										endAdornment: (
											<Typography>
												<b>.picsize.com.br</b>
											</Typography>
										)
									}}
									color="secondary"
									value={subdomain}
								/>
							</InputDialog>
						</Grid>
					</Loading>
				</Portlet>
			</Grid>

			<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
				<Portlet>
					<Loading
						loading={loadingDomain}
						customLoadingElement={<CustomDomainBoxSkeleton />}
					>
						<Grid direction="column" container>
							<Typography variant="h2">
								DOMÍNIO PRÓPRIO
								<PremiumChip disableGutters={isMobile} />
							</Typography>

							<Divider size="large" orientation="horizontal" />

							<InputDialog
								title="Mudar domínio"
								onOk={handleUpdateDomain}
								onDataChange={() => clearValidation("domain")}
								initialValue={domainData.domain}
								fullWidth={false}
								cursor="text"
								customInputElement={(
									<TextField
										InputProps={{
											startAdornment: (
												<Typography>
													<b>https://www.</b>
												</Typography>
											)
										}}
										color="secondary"
										error={!!validation.domain}
										helperText={validation.domain}
									/>
								)}
							>
								<TextField
									InputProps={{
										startAdornment: (
											<Typography>
												<b>https://www.</b>
											</Typography>
										)
									}}
									color="secondary"
									value={domainData.domain}
								/>
							</InputDialog>

							{domainData.domain && DOMAIN_STATUS[domainData.status] && (
								<>
									<Divider size="large" orientation="horizontal" />

									<Alert severity={DOMAIN_STATUS[domainData.status].severity}>
										{DOMAIN_STATUS[domainData.status].message}
									</Alert>
								</>
							)}

							{domainData.domain && domainData.status === "activated" && !canUseCustomDomain && (
								<>
									<Divider size="large" orientation="horizontal" />

									<Alert severity={DOMAIN_STATUS["not-on-plan"].severity}>
										{DOMAIN_STATUS["not-on-plan"].message}
									</Alert>
								</>
							)}

							{(!domainData.domain || domainData.status !== "activated") && (
								<>
									<Divider size="large" orientation="horizontal" />

									<Typography variant="body2">
										Após incluir o seu domínio, preenchendo o campo acima, será necessário conectar
										o seu domínio com a Picsize.
									</Typography>

									<Divider size="small" orientation="horizontal" />

									<Link
										href="https://blog.picsize.com.br/alterar-id-tecnico-registro-br/"
										target="blank"
									>
										Veja como conectar o seu domínio com a Picsize.
									</Link>

									<Divider size="medium" orientation="horizontal" />

									<Alert severity={DOMAIN_STATUS["not-pointed"].severity}>
										{DOMAIN_STATUS["not-pointed"].message}
									</Alert>
								</>
							)}

							<Grid container alignItems="center">
								<Divider size="large" orientation="horizontal" />

								<HelpOutlineIcon />

								<Divider size="small" orientation="vertical" />

								<Typography variant="body2">
									Tem dúvida? Fale com nosso
									{" "}
									<Link href={supportContactInfo().whatsappLink} target="blank">
										suporte
									</Link>
									.
								</Typography>
							</Grid>
						</Grid>
					</Loading>
				</Portlet>
			</Grid>

			{(!domainData.domain || domainData.status === "not-found") && (
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Portlet>
						<Loading
							loading={loadingDomain}
							customLoadingElement={<CustomDomainHelperBoxSkeleton />}
						>
							<Grid container>
								<Typography variant="h2">AINDA NÃO TEM DOMÍNIO PRÓPRIO?</Typography>

								<Divider size="large" orientation="horizontal" />

								<Typography variant="body2">
									Para registrar um domínio
									<b> .com.br </b>
									ou qualquer outro que termine com
									<b> .br</b>
									, acesse:
								</Typography>

								<Divider size="small" orientation="horizontal" />

								<Link href="https://registro.br" target="blank">
									https://registro.br
								</Link>

								<Divider size="large" orientation="horizontal" />

								<Typography variant="body2">
									Para registrar outro domínios, como
									<b> .com </b>
									por exemplo,a acesse:
								</Typography>

								<Divider size="small" orientation="horizontal" />

								<Link href="https://www.hostnet.com.br" target="blank">
									https://www.hostnet.com.br
								</Link>
							</Grid>
						</Loading>
					</Portlet>
				</Grid>
			)}
		</Grid>
	)
}

export default Domain
