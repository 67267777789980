const colors = [
	{
		id: 0,
		value: "#000000"
	},
	{
		id: 1,
		value: "#111111"
	},
	{
		id: 2,
		value: "#222222"
	},
	{
		id: 3,
		value: "#333333"
	},
	{
		id: 4,
		value: "linear-gradient(90deg, #F05F57, #360940)"
	},
	{
		id: 5,
		value: "linear-gradient(-45deg, #F05F57, #360940)"
	},
	{
		id: 6,
		value: "linear-gradient(90deg, #92ffc0, #002661)"
	},
	{
		id: 7,
		value: "linear-gradient(-45deg, #92ffc0, #002661)"
	},
	{
		id: 8,
		value: "linear-gradient(90deg, #fdd819, #e80505)"
	},
	{
		id: 9,
		value: "linear-gradient(-45deg, #fdd819, #e80505)"
	},
	{
		id: 10,
		value: "linear-gradient(90deg, #79f1a4, #0e5cad)"
	},
	{
		id: 11,
		value: "linear-gradient(-45deg, #79f1a4, #0e5cad)"
	},
	{
		id: 12,
		value: "linear-gradient(90deg, #e8d07a, #4312d6)"
	},
	{
		id: 13,
		value: "linear-gradient(-45deg, #e8d07a, #4312d6)"
	}
]

export default colors
