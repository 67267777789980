
import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => createStyles({
	pageInfoButton: {
		width: "100%",
		margin: 0,
		padding: theme.spacing(2),
		textAlign: "start",
		borderRadius: 5
	},
	successIcon: {
		color: "#4caf50"
	},
	errorIcon: {
		color: "#f44336"
	},
	pagesContainer: {
		padding: theme.spacing(2)
	},
	url: {
		fontSize: "14px",
		color: "rgb(60,64,67)",
		fontFamily: "arial, sans-serif",
		wordWrap: "break-word"
	},
	title: {
		fontSize: "20px",
		color: "rgb(26,13,171)",
		fontFamily: "arial, sans-serif",
		wordWrap: "break-word"
	},
	description: {
		fontSize: "13px",
		color: "rgb(60,64,67)",
		fontFamily: "arial, sans-serif",
		wordWrap: "break-word"
	}
}))

export default useStyles
