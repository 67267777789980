import React, { useState } from "react"
import {
	Grid,
	Typography,
	Button,
	TextField,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	ListItemSecondaryAction,
	IconButton,
	Tooltip
} from "@material-ui/core"
import {
	HelpOutline as HelpQuestionIcon,
	ArrowForwardIosOutlined as ArrowForwardIcon
} from "@material-ui/icons"

import api from "../../services/api"

import {
	Portlet,
	PremiumChip,
	Divider,
	Loading,
	InfoModal,
	Notification,
	InputDialog
} from "../../components"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"

import useCustomStyles from "../../styles/custom"

import { getStatusCode } from "../../utils/response"
import { isMobile } from "../../utils/checkDevice"
import { siteProPlansUrl } from "../../utils/plan"

import IntegrationsSkeleton from "../../skeletons/Integrations"

import googleAnalyticsIcon from "../../assets/integrations/googleAnalyticsIcon.svg"
import pixelFacebookIcon from "../../assets/integrations/pixelFacebookIcon.svg"

import useStyles from "./styles"

const Integrations = () => {
	const {
		validation,
		triggerValidation,
		clearValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const [integrationsSettings, setIntegrationsSettings] = useState({})

	const [loadingGetIntegrations, setLoadingGetIntegrations] = useState(true)

	const classes = useStyles()
	const customClasses = useCustomStyles()

	const getIntegrationsData = async () => {
		try {
			const { data } = await api.get("/site/integrations")
			setIntegrationsSettings(data.integrations ?? {})
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
		setLoadingGetIntegrations(false)
	}

	const changeGeneralData = async (integration_type, key, value) => {
		const backup = { ...integrationsSettings }

		setIntegrationsSettings((lastState) => ({
			...lastState,
			[integration_type]: {
				...lastState[integration_type],
				[key]: value
			}
		}))

		try {
			await api.put(`/site/integrations/${integration_type}`, {
				site_integration_id: integrationsSettings[integration_type]?.id ?? null,
				integration_data: {
					[key]: value
				}
			})

			Notification.success({ message: "Configuração alterada com sucesso!" })

			return true
		} catch (error) {
			if (getStatusCode(error) === 402) {
				InfoModal.warning({
					title: "Ação negada",
					description: "Seu plano atual não permite utilizar essa função.",
					customConfirmButtons: [
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							VER PLANOS
						</Button>
					],
					cancelButtonText: "FECHAR"
				})
			} else {
				triggerValidation(error)
			}

			setIntegrationsSettings(backup)
		}
	}

	useDidMount(() => {
		getIntegrationsData()
	})

	return (
		<Loading
			loading={loadingGetIntegrations}
			customLoadingElement={<IntegrationsSkeleton />}
		>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
					<Typography variant="h2">
						GOOGLE
						<PremiumChip disableGutters={isMobile} />
					</Typography>

					<Divider size="medium" orientation="horizontal" />

					<Portlet disablePadding>
						<List>
							<InputDialog
								title="Google Analytics ID"
								onDataChange={() => clearValidation("integration_id")}
								onOk={(integration_id) => changeGeneralData(
									"google_analytics",
									"integration_id",
									integration_id.toUpperCase()
								)}
								initialValue={integrationsSettings?.google_analytics?.integration_id}
								customInputElement={(
									<TextField
										error={!!validation.integration_id}
										helperText={validation.integration_id}
										color="secondary"
										placeholder="Ex.: UA-XXXXXXXX-1"
										fullWidth
									/>
								)}
								fullWidth
								openOnDrawer={isMobile}
							>
								<ListItem button>
									<ListItemIcon>
										<img src={googleAnalyticsIcon} alt="Google Analytics" />
									</ListItemIcon>

									<ListItemText
										primary="GOOGLE ANALYTICS"
										primaryTypographyProps={{
											className: customClasses.listItemTitle
										}}
										secondary={
											integrationsSettings?.google_analytics?.integration_id
											|| "Insira seu ID aqui..."
										}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
										classes={{
											root: classes.listItemTextWrapper
										}}
									/>

									<ListItemText
										primary={(
											<Tooltip
												title="Google Analytics é importante para auxiliar no monitoramento do trafego dos seus
												atuais e futuros clientes."
												placement="top"
												arrow
											>
												<HelpQuestionIcon />
											</Tooltip>
										)}
										primaryTypographyProps={{
											className: customClasses.listItemTitle
										}}
									/>

									<ListItemSecondaryAction className={customClasses.nextIcon}>
										<IconButton>
											<ArrowForwardIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>
						</List>
					</Portlet>
				</Grid>

				<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
					<Typography variant="h2">
						FACEBOOK
						<PremiumChip disableGutters={isMobile} />
					</Typography>

					<Divider size="medium" orientation="horizontal" />

					<Portlet disablePadding>
						<List>
							<InputDialog
								title="Identificação Pixel do Facebook"
								onDataChange={() => clearValidation("integration_id")}
								onOk={(integration_id) => changeGeneralData("pixel_facebook", "integration_id", integration_id)}
								initialValue={integrationsSettings?.pixel_facebook?.integration_id}
								customInputElement={(
									<TextField
										error={!!validation.integration_id}
										helperText={validation.integration_id}
										color="secondary"
										placeholder="Ex.: XXXXXXXXXXXXX"
										fullWidth
									/>
								)}
								fullWidth
								openOnDrawer={isMobile}
							>
								<ListItem button>
									<ListItemIcon>
										<img src={pixelFacebookIcon} alt="Pixel Do Facebook" />
									</ListItemIcon>

									<ListItemText
										primary="PIXEL DO FACEBOOK"
										primaryTypographyProps={{
											className: customClasses.listItemTitle
										}}
										secondary={
											integrationsSettings?.pixel_facebook?.integration_id
											|| "Insira seu ID aqui..."
										}
										secondaryTypographyProps={{
											variant: "h3",
											color: "textPrimary",
											noWrap: true
										}}
										classes={{
											root: classes.listItemTextWrapper
										}}
									/>

									<ListItemText
										primary={(
											<Tooltip
												title="Com o pixel do Facebook, poderá monitorar conversões do seu site e criar públicos para as campanhas de anúncios."
												placement="top"
												arrow
											>
												<HelpQuestionIcon />
											</Tooltip>
										)}
										primaryTypographyProps={{
											className: customClasses.listItemTitle
										}}
									/>

									<ListItemSecondaryAction className={customClasses.nextIcon}>
										<IconButton>
											<ArrowForwardIcon />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</InputDialog>
						</List>
					</Portlet>
				</Grid>
			</Grid>
		</Loading>
	)
}

export default Integrations
