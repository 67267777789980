import React from "react"
import PropTypes from "prop-types"

import { Container, Button } from "@material-ui/core"
import { Divider } from "../../../../../components"
import Partner1Editor from "./PartnerA"
import Image1Editor from "./Image1"
import Links1Editor from "./Links1"
import Contact1Editor from "./Contact1"
import WorkList1Editor from "./WorkList1"
import About2Editor from "./About2"
import Testimonial1Editor from "./Testimonial1"
import InstagramFeed1Editor from "./InstagramFeed1"

import useStyles from "./styles"
import NoEditorInfo from "./NoEditorInfo"
import { useLiveEditorStore } from "../../../../../store"

const BodySectionsEditorSelector = ({ sectionId, onFinish }) => {
	const liveEditorStore = useLiveEditorStore()

	const section = liveEditorStore.getSectionById(sectionId)
	const { file } = section

	const classes = useStyles()

	const bodyDict = {
		partner_1: (
			<Partner1Editor
				sectionId={sectionId}
			/>
		),
		image_1: (
			<Image1Editor
				sectionId={sectionId}
			/>
		),
		links_1: (
			<Links1Editor
				sectionId={sectionId}
			/>
		),
		contact_1: (
			<Contact1Editor
				sectionId={sectionId}
			/>
		),
		work_list_1: (
			<WorkList1Editor
				sectionId={sectionId}
			/>
		),
		about_2: (
			<About2Editor
				sectionId={sectionId}
			/>
		),
		testimonial_1: (
			<Testimonial1Editor
				sectionId={sectionId}
			/>
		),
		instagram_feed_1: (
			<InstagramFeed1Editor
				sectionId={sectionId}
			/>
		)
	}

	const content = bodyDict[file] || <NoEditorInfo />
	return (
		<Container disableGutters maxWidth={false} className={classes.container}>
			<Divider orientation="horizontal" size="small" />

			{content}

			<Divider orientation="horizontal" size="medium" />

			<Button fullWidth color="secondary" variant="contained" onClick={onFinish}>Concluir</Button>
		</Container>
	)
}

BodySectionsEditorSelector.propTypes = {
	sectionId: PropTypes.number.isRequired,
	onFinish: PropTypes.func.isRequired
}
export default BodySectionsEditorSelector
