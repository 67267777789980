import React, { useState } from "react"
import { Box, Grid, Typography } from "@material-ui/core"
import { Loading, ActivateFunctionality, Notification, Divider } from "../../components"
import useDidMount from "../../hooks/useDidMount"

import useValidation from "../../hooks/useValidation"
import api from "../../services/api"
import { useSiteStore } from "../../store"

import useStyles from "./styles"

import whatsTriggerIllustration from "../../assets/illustrations/whatsTriggerIllustration.svg"
import whatsTriggerMobileIllustration from "../../assets/illustrations/whatsTriggerMobileIllustration.svg"

const Widget = () => {
	const [loadingWidgetIntegration, setLoadingWidgetIntegration] = useState(true)
	const [widgetIntegration, setWidgetIntegration] = useState(null)
	const [appURL, setAppURL] = useState(null)

	const { triggerUndeterminedValidation } = useValidation()
	const siteStore = useSiteStore()
	const classes = useStyles()

	const getWidgetIntegration = async () => {
		setLoadingWidgetIntegration(true)

		try {
			const { data } = await api.get("/plugin-integration/widget?widget_type=chatWhatsApp")

			setAppURL(data.appUrl)

			if (data.widgetIntegration) {
				const { integration_auth, integration_info } = data.widgetIntegration

				const authToken = integration_auth.loginToken
				const widgetId = integration_info
					.integrations_auth
					.chatWhatsApp
					.id

				setWidgetIntegration({
					authToken,
					widgetId
				})
			}
		} catch (error) {
			triggerUndeterminedValidation(error)
		}

		setLoadingWidgetIntegration(false)
	}

	const labelContentActivateFuncionality = () => (
		<Box style={{ maxWidth: "372px", margin: "0 auto" }}>
			<Typography color="inherit" variant="h2" className={classes.titleActivateFuncionality}>
				Permita o contato pelo WhatsApp com apenas
				um clique
			</Typography>

			<Divider size="medium" orientation="horizontal" />

			<Typography color="inherit" variant="body1" className={classes.contentActivateFuncionality}>
				Que tal aumentar a suas vendas com a integração do plugin do whatsapp, que permite receber
				mensagens diretamente em seu app com apenas um clique.
			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" variant="body1" className={classes.contentActivateFuncionality}>
				Além do mais, com esse plugin
				você poderá oferecer um melhor suporte aos seus clientes e garantir uma
				boa experiência durante o contato com a sua marca.
			</Typography>

			<Divider size="small" orientation="horizontal" />

			<Typography color="inherit" align="center" variant="body1" className={classes.textActionActivateFuncionality}>
				Vamos lá!
			</Typography>
		</Box>
	)

	/*
		TRY CATCH SHOULD NOT BE APPLIED HERE
		The component,ActivateFunctionality, performs try catch on the call of this function
	*/
	const activateWidgetIntegration = async () => {
		const { data } = await api.post("/plugin-integration/widget/chatWhatsApp")

		const { integration_auth, widget } = data.widgetIntegration

		const authToken = integration_auth.loginToken
		const widgetId = widget.integrations_auth.chatWhatsApp.id

		setWidgetIntegration({
			authToken,
			widgetId
		})

		Notification.success({ message: "Widget ativado" })
	}

	useDidMount(() => {
		getWidgetIntegration()
	})

	return (
		<Grid container className={classes.containerWidget}>
			<Loading loading={loadingWidgetIntegration}>
				{
					!widgetIntegration
						? (
							<Grid container className={classes.containerActivateFuncionality}>
								<ActivateFunctionality
									labelContent={labelContentActivateFuncionality}
									illustrationBackground={whatsTriggerIllustration}
									illustrationMobileBackground={{
										image: whatsTriggerMobileIllustration,
										backgroundColor: "linear-gradient(180deg, #17C006 9.9%, rgba(211, 243, 208, 0) 100%)"
									}}
									heightContainer="calc(100vh - 92px)"
									onActivate={activateWidgetIntegration}
									captionButton="A instalação é grátis e simples."
								/>
							</Grid>
						)
						: (
							<Grid container className={classes.iframeContainer}>
								<iframe
									src={
										`${appURL}/integration/login/${widgetIntegration.authToken}/${widgetIntegration.widgetId}?widget_type=chatWhatsApp&customer_site=${siteStore.state.url}`
									}
									title="Widget Preview"
									frameBorder="0"
									className={classes.iframeContent}
								/>
							</Grid>
						)
				}
			</Loading>
		</Grid>
	)
}

export default Widget
