import React from "react"
import PropTypes from "prop-types"

/**
 * Work around to load FancyBox on React.js
 */
// eslint-disable-next-line
window.jQuery = require("jquery")
require("@fancyapps/fancybox")
require("@fancyapps/fancybox/dist/jquery.fancybox.css")

const FancyBox = (props) => {
	const { children, src, caption } = props

	return (
		<a data-fancybox="gallery" href={src} data-caption={caption}>
			{children}
		</a>
	)
}

FancyBox.defaultProps = {
	caption: ""
}

FancyBox.propTypes = {
	children: PropTypes.node.isRequired,
	src: PropTypes.string.isRequired,
	caption: PropTypes.string
}

export default FancyBox
