import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"

const useStyles = makeStyles(theme => ({
	expanded: {
		"&$expanded": {
			marginTop: 0,
			"&.MuiExpansionPanel-root.Mui-expanded::before": {
				opacity: 1
			}
		}
	},
	panelContainer: {
		boxShadow: "none",
		backgroundColor: "transparent"
	},
	panelSummary: {
		backgroundColor: "#ffffff",
		color: colors.greyScale[5]
	},
	childrenContainer: {
		padding: theme.spacing(1, 1),
		height: "100%"
	}
}))

export default useStyles
