export const hexToRgb = (hexColor) => {
	const components = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor)

	if (!components) {
		return null
	}
	return {
		r: parseInt(components[1], 16),
		g: parseInt(components[2], 16),
		b: parseInt(components[3], 16)
	}
}

export const transparentizeHexToRgba = (hexColor, opacity) => {
	// Verify with regex if props is color HEX
	const isValidHex = /^#[0-9A-F]{6}$/i.test(hexColor)

	if (isValidHex) {
		const {
			r,
			g,
			b
		} = hexToRgb(hexColor)

		return `rgba(${r ?? 0},${g ?? 0},${b ?? 0},${opacity})`
	}

	return hexColor
}


export const transformRgbaStringInObject = (rgba) => {
	const getOnlyNumberInRgba = rgba.replace(/[^0-9,.]+/g, "")
	const transformInArray = getOnlyNumberInRgba.split(",")

	return {
		r: transformInArray[0],
		g: transformInArray[1],
		b: transformInArray[2],
		a: transformInArray[3]
	}
}

// AMT -> Control brightness [ x > 0 === LIGHTER && x < 0 === DARKER]

export const changeColorBrightness = (color, amt) => {
	let R = parseInt(color.substring(1, 3), 16)
	let G = parseInt(color.substring(3, 5), 16)
	let B = parseInt(color.substring(5, 7), 16)

	R = parseInt((R * (100 + amt)) / 100, 10)
	G = parseInt((G * (100 + amt)) / 100, 10)
	B = parseInt((B * (100 + amt)) / 100, 10)

	R = (R < 255) ? R : 255
	G = (G < 255) ? G : 255
	B = (B < 255) ? B : 255

	const RR = ((R.toString(16).length === 1) ? `0${R.toString(16)}` : R.toString(16))
	const GG = ((G.toString(16).length === 1) ? `0${G.toString(16)}` : G.toString(16))
	const BB = ((B.toString(16).length === 1) ? `0${B.toString(16)}` : B.toString(16))

	const newHex = `#${RR}${GG}${BB}`

	return newHex
}
