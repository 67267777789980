import React from "react"

import { Grid, Link, Typography } from "@material-ui/core"

import { Divider } from "../../../components"

import { useSiteStore } from "../../../store"

import useStyles from "./styles"

const Sitemap = () => {
	const classes = useStyles()

	const siteStore = useSiteStore()

	return (
		<>
			<Grid container spacing={2} className={classes.container}>
				<Grid item xs={12}>
					<Typography component="p" className={classes.description}>
						O
						{" "}
						<b>sitemap</b>
						{" "}
						é um arquivo que ajuda o Google a rastrear seu site de maneira mais inteligente.
						{" "}
						Nossa plataforma gera automaticamente o
						{" "}
						<b>sitemap</b>
						{" "}
						para o seu site.
						{" "}
						Contudo, é necessário que você o envie ao Google.
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography component="p" className={classes.description}>
						<Link
							href="https://blog.picsize.com.br/tutorial-sitemap/"
							target="blank"
							rel="noreferrer"
						>
							Veja como enviar seu sitemap para o Google.
						</Link>
					</Typography>
				</Grid>

				<Grid item xs={12}>
					<Typography component="p" className={classes.description}>
						<Link href={`${siteStore.state.url}/sitemap.xml`} target="blank" rel="noreferrer">
							Veja seu sitemap.
						</Link>
					</Typography>
				</Grid>
			</Grid>

			<Divider orientation="horizontal" size="large" />
		</>
	)
}


export default Sitemap
