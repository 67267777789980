import colors from "../../styles/colors"
import { transparentizeHexToRgba } from "../../utils/color"

export const livePreviewBaseInjectedStyles = `
	.is-hidden{
		max-height: 0;
		z-index:-1;
		opacity:0;
		margin: 0 !important;
	}

	body{
		overflow-x: hidden;
	}

	[le-page-section]::after{
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		right: 0;
		box-sizing: border-box;
	}

	[le-page-section]:hover::after{
		content: "";
		background-color: ${transparentizeHexToRgba(colors.palette.tag1, 0.05)};
		border: 2px solid ${colors.palette.tag1};
		cursor: pointer;
	}

	[le-page-section].is-active::after{
		content: "";
		border: 4px solid ${colors.palette.confirm};
		background-color: transparent;
	}
`

export const livePreviewDevInjectedStyles = `
	
`

export const exitButtonInjectedStyles = `
.branding-picsize-1-content {
width: 100%;
position: relative;
}

.branding-picsize-1-content > .branding-exit-button {
	position: absolute;
	right: 2.5%;
	width: 42px;
	height: 37px;
	opacity: 0.5;

	cursor: pointer;
			
	display: flex;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.branding-picsize-1-content > .branding-exit-button:before, 
.branding-picsize-1-content > .branding-exit-button:after {
position: absolute;
content: ' ';
height: 16px;
width: 2px;
background-color: #000000;
cursor: pointer;
}

.branding-picsize-1-content > .branding-exit-button:before {
	transform: rotate(45deg);
}
.branding-picsize-1-content > .branding-exit-button:after {
	transform: rotate(-45deg);
}

.branding-picsize-1-content > .branding-exit-button:hover,
.branding-picsize-1-content > .branding-exit-button:active,
.branding-picsize-1-content > .branding-exit-button:focus {
	opacity: 1;
}
`
