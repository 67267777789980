import React, { useEffect, useState } from "react"

import { Button, Grid, Box } from "@material-ui/core"

import PropTypes from "prop-types"
import LiveEditorTypography from "../LiveEditorTypography"
import useStyles from "./styles"
import { GroupField } from ".."
import api from "../../../../services/api"
import useValidation from "../../../../hooks/useValidation"
import { useLiveEditorStore } from "../../../../store"
import useLivePreview from "../../../../hooks/useLivePreview"
import AvailableLayouts from "./AvailableLayouts"

const ChangeLayout = ({ pageSectionId, label }) => {
	const classes = useStyles()

	const { triggerUndeterminedValidation } = useValidation()

	const { addOrRefreshControllerListeners, activateSection, scrollToSection } = useLivePreview()

	const liveEditorStore = useLiveEditorStore()
	const pageSection = liveEditorStore.getSectionById(pageSectionId)
	const { currentPage } = liveEditorStore.state
	const [openAvailableLayouts, setOpenAvailableLayouts] = useState(false)

	const [sectionMockupImage, setSectionMockupImage] = useState(null)

	const getProperties = (properties) => {
		if (currentPage.type === "work_list" && pageSection.file === "work_list_1") {
			return {
				...properties,
				work_resume_count_limitation: false
			}
		}

		if (currentPage.type === "about" && pageSection.file === "about_2") {
			return {
				...properties,
				bio_characters_count_limitation: false
			}
		}

		if (currentPage.type === "testimonials" && pageSection.file === "testimonial_1") {
			return {
				...properties,
				testimonial_count_limitation: false
			}
		}

		return properties
	}

	const getAndUpdateCurrentSectionMockup = async () => {
		try {
			const response = await api.get("/site/section", {
				params: {
					id: pageSection.site_section_id
				}
			})

			setSectionMockupImage(response?.data?.section?.cover_key)
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleSelectLayout = async (newSection) => {
		if (!newSection) {
			return
		}

		const properties = getProperties(newSection.properties)

		try {
			const updatedSection = {
				...pageSection,
				sectionId: pageSectionId,
				properties,
				site_section_id: newSection.id,
				site_block_code_id: newSection.site_block_code_id,
				title: newSection.title
			}

			liveEditorStore.overrideSectionData(updatedSection)

			const response = await api.post("/site/live-editor/build/view-section/assets", updatedSection)
			const assets = response.data

			liveEditorStore.addLivePreviewChanges([
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_HTML,
					selector: `#page-section-${pageSectionId}`,
					value: assets.html,
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.REPLACE_PURE_CSS,
					selector: `style-${pageSectionId}`,
					value: assets.css,
					pageId: currentPage.id
				},
				{
					action: liveEditorStore.livePreviewActions.INSERT_PURE_JS,
					selector: `script-${pageSectionId}`,
					value: assets.js,
					pageId: currentPage.id
				}
			])

			addOrRefreshControllerListeners()
			activateSection({ selector: `page-section-${pageSectionId}` })
			scrollToSection({ selector: `page-section-${pageSectionId}` })
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const handleOpenAvailableLayouts = () => {
		setOpenAvailableLayouts(true)
	}

	const handleCloseAvailableLayouts = () => {
		setOpenAvailableLayouts(false)
	}

	useEffect(() => {
		getAndUpdateCurrentSectionMockup()
	}, [pageSection])

	return (
		<>
			<GroupField label="Layout">
				<Grid
					container
					justify="space-between"
					alignItems="center"
					spacing={1}
				>
					<Grid item xs={12}>
						<LiveEditorTypography
							type="fieldTitle"
						>
							{label}
						</LiveEditorTypography>
					</Grid>

					<Grid item xs={12}>
						<Box className={classes.backgroundBoxContainer}>
							<Box
								style={{ backgroundImage: `url(${sectionMockupImage})` }}
								className={classes.backgroundBox}
							/>
						</Box>
					</Grid>

					<Grid item xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<Button
									color="secondary"
									variant="contained"
									fullWidth
									onClick={handleOpenAvailableLayouts}
								>
									Alterar
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</GroupField>

			<AvailableLayouts
				open={openAvailableLayouts}
				onClose={handleCloseAvailableLayouts}
				sectionId={pageSectionId}
				onSelect={handleSelectLayout}
			/>
		</>
	)
}

ChangeLayout.propTypes = {
	label: PropTypes.string,
	pageSectionId: PropTypes.number.isRequired
}

ChangeLayout.defaultProps = {
	label: ""
}

export default ChangeLayout
