import React from "react"
import PropTypes from "prop-types"
import { CircularProgress, Grid, Fade } from "@material-ui/core"

const Loading = (props) => {
	const { children, loading, customLoadingElement } = props

	let component

	if (loading) {
		if (customLoadingElement) {
			component = customLoadingElement
		} else {
			component = (
				<Grid container justify="center" alignItems="center">
					<CircularProgress color="secondary" />
				</Grid>
			)
		}
	} else {
		component = (
			<Fade in={!loading}>
				{children}
			</Fade>
		)
	}

	return component
}

Loading.defaultProps = {
	customLoadingElement: null
}

Loading.propTypes = {
	children: PropTypes.node.isRequired,
	customLoadingElement: PropTypes.node,
	loading: PropTypes.bool.isRequired
}

export default Loading
