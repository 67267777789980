import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid, Card, CardHeader as CardFooter, makeStyles, createStyles } from "@material-ui/core"

import { GridItemResponsive } from "../components"

const useStyles = makeStyles(theme => createStyles({
	media: {
		width: "auto",
		height: 0,
		paddingTop: "56.25%",
		margin: theme.spacing(1)
	},
	card: {
		width: "100%",
		height: "auto"
	}
}))

const WorksSkeleton = () => {
	const classes = useStyles()

	const cards = []

	// eslint-disable-next-line
	for (let i = 0; i < 3; i++) {
		cards.push(
			<GridItemResponsive
				xs={12}
				sm={6}
				md={6}
				lg={4}
				xl={3}
				className={classes.workCard}
			>
				<Card className={classes.card}>
					<Skeleton animation="wave" variant="rect" className={classes.media} />

					<CardFooter
						avatar={<div />}
						title={(
							<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />
						)}
						subheader={(
							<Skeleton animation="wave" height={10} width="40%" />
						)}
						action={(
							<Skeleton animation="wave" height={36} width={36} variant="circle" />
						)}
					/>
				</Card>
			</GridItemResponsive>
		)
	}

	return (
		<Grid container wrap="wrap" spacing={2}>
			{cards}
		</Grid>
	)
}

export default WorksSkeleton
