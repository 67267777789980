import React from "react"
import PropTypes from "prop-types"

import ThemeGallery from "../ThemeGallery"

const ThemeGalleryPublic = ({ onClose }) => {
	const handleChangeTheme = (theme_id) => {
		window.parent.postMessage({ theme_id }, "*")
	}

	return (
		<ThemeGallery onClose={onClose} handleChangeTheme={handleChangeTheme} />
	)
}

ThemeGalleryPublic.propTypes = {
	onClose: PropTypes.func
}

ThemeGalleryPublic.defaultProps = {
	onClose: () => {}
}

export default ThemeGalleryPublic
