import React, { useState } from "react"
import {
	Dialog,
	DialogTitle,
	DialogActions,
	Button,
	Typography,
	IconButton,
	ThemeProvider,
	Grid,
	Link,
	Table,
	TableBody,
	TableContainer,
	TableCell,
	TableRow
} from "@material-ui/core"
import {
	Close as CloseIcon
} from "@material-ui/icons"

import { Divider } from "../../../../components"

import { renderComponent } from "../../../../utils/node"

import useStyles from "./styles"

import theme from "../../../../styles/theme"


const WarningUploadModal = () => {
	const classes = useStyles()

	const proportions = [
		{ value: "Tela cheia", dimension: "(Recomendado) 1920px / 1080px" },
		{ value: "16:9", dimension: "1920px / 1080px" },
		{ value: "8:5", dimension: "1920px / 1200px" },
		{ value: "4:3", dimension: "1920px / 1440px" },
		{ value: "2:1", dimension: "1920px / 960px" }
	]

	const [opened, setOpened] = useState(true)

	const handleClose = () => {
		setOpened(false)
	}

	return (
		<ThemeProvider theme={theme}>
			<Dialog
				open={opened}
				onClose={handleClose}
				className={classes.dialog}
				fullWidth
			>
				<IconButton onClick={handleClose} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>

				<DialogTitle>
					Proporções de banners disponíveis
				</DialogTitle>

				<Grid
					container
					direction="column"
					className={classes.dialogContainer}
				>

					<Grid container direction="column" className={classes.tableGrid}>
						<Typography variant="h3" className={classes.tableHeadTitle}>
							Proporções
						</Typography>

						<TableContainer className={classes.tableContainer}>
							<Table size="small" padding="default" className={classes.table}>
								<TableBody>
									{proportions.map((proportion, index) => (
										<TableRow>
											<TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}>
												{proportion.value}
											</TableCell>
											<TableCell align="right" style={{ backgroundColor: index % 2 === 0 ? "#F4F4F4" : "#F9F9F9" }}>
												{proportion.dimension}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>

					<Divider size="large" orientation="horizontal" />

					<Grid
						container
						alignItems="center"
						justify="center"
					>
						<p>
							Quer alterar a proporção? Acesse nosso
							{" "}
							<Link href="/live-editor">
								Editor de Design
							</Link>
							.
						</p>
					</Grid>
				</Grid>

				<DialogActions>
					<Button onClick={handleClose} color="primary" variant="contained">
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</ThemeProvider>
	)
}

WarningUploadModal.open = () => renderComponent(
	"warning-upload-modal",
	<WarningUploadModal />
)

export default WarningUploadModal
