import React, { useState } from "react"
import { Button, Grid, TextField, Link, CircularProgress } from "@material-ui/core"
import { CustomContainer, Divider, Notification } from "../../components"
import api from "../../services/api"

const Sitemap = () => {
	const [url, setUrl] = useState("")
	const [linkToFile, setLinkToFile] = useState("")
	const [generatingSitemap, setGeneratingSitemap] = useState(false)

	const handleChangeUrl = (event) => {
		event.preventDefault()

		setUrl(event.target.value)
	}

	const generateSitemap = async (event) => {
		event.preventDefault()

		setGeneratingSitemap(true)
		setLinkToFile("")

		try {
			const response = await api.get("/sitemap-generator", {
				params: {
					url
				}
			})

			const { data: xmltext } = response

			const file = new Blob([xmltext], { type: "text/plain" })
			setLinkToFile(window.URL.createObjectURL(file))

			Notification.success({
				message: "Sitemap generated successfully! Now you can download it on the link below!"
			})
		} catch (error) {
			Notification.error({
				message: "Could not generate sitemap!"
			})
		}

		setGeneratingSitemap(false)
	}

	return (
		<CustomContainer maxWidth="400px">
			<Divider orientation="horizontal" size="xxlarge" />

			<form onSubmit={generateSitemap}>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<TextField
							fullWidth
							color="secondary"
							label="URL"
							value={url}
							onChange={handleChangeUrl}
							required
						/>
					</Grid>

					<Grid item xs={12}>
						<Button
							fullWidth
							type="submit"
							color="primary"
							variant="contained"
							disabled={generatingSitemap}
							endIcon={generatingSitemap && <CircularProgress size={20} />}
						>
							Get Sitemap
						</Button>
					</Grid>

					<Grid item xs={12}>
						<Grid container justify="center">
							{linkToFile && (
								<Link href={linkToFile} download="sitemap.xml">
									Download sitemap
								</Link>
							)}
						</Grid>
					</Grid>
				</Grid>
			</form>
		</CustomContainer>
	)
}

export default Sitemap
