// export const layoutHeaderValids = (logoPosition, menuPosition) => {
// 	let invalidMenuPosition = []
// 	if (logoPosition === "center") {
// 		invalidMenuPosition = ["opposite-logo"]
// 	}
// 	return invalidMenuPosition
// }

// eslint-disable-next-line import/prefer-default-export
export const buildHeaderLivePreviewStyle = (
	headerBanner1SectionData = {},
	logoPosition,
	menuPosition
) => {
	const styleLivePreview = {
		navbarContainer: {
			selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .navbar-container`,
			props: {}
		},
		logoContainer: {
			selector: `#page-section-${headerBanner1SectionData.id} .header-banner-1-content .navbar-container .navbar-content .logo-container`,
			props: {}
		},
		logoContent: {
			selector: `#page-section-${headerBanner1SectionData.id}  .header-banner-1-content .navbar-container .navbar-content .logo-container .logo-content`,
			props: {}
		},
		mobileMenuContainer: {
			selector: `#page-section-${headerBanner1SectionData.id} .mobile-menu-container`,
			props: {}
		},
		desktopMenuContainer: {
			selector: `#page-section-${headerBanner1SectionData.id} .desktop-menu-container`,
			props: {}
		}
	}

	/**
	* NAVBAR CONTAINER
	*/
	if (logoPosition === "left") {
		styleLivePreview.navbarContainer.props["justify-content"] = "flex-start"
	} else if (logoPosition === "center") {
		styleLivePreview.navbarContainer.props["justify-content"] = "center"
	} else if (logoPosition === "right") {
		styleLivePreview.navbarContainer.props["justify-content"] = "flex-end"
	}

	/**
	* LOGO CONTENT
	*/
	if (menuPosition === "bottom-logo" && logoPosition === "left") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "flex-start"
	} else if (menuPosition === "bottom-logo" && logoPosition === "right") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "flex-end"
	} else if (menuPosition === "bottom-logo") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "center"
	} else if (menuPosition === "top-logo" && logoPosition === "left") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "flex-start"
	} else if (menuPosition === "top-logo" && logoPosition === "right") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "flex-end"
	} else if (menuPosition === "top-logo") {
		styleLivePreview.logoContent.props.order = 1
		styleLivePreview.logoContent.props["align-self"] = "center"
	} else if (menuPosition === "opposite-logo" && logoPosition === "left") {
		styleLivePreview.logoContent.props.order = 0
	} else if (menuPosition === "opposite-logo" && logoPosition === "right") {
		styleLivePreview.logoContent.props.order = 1
	} else if (menuPosition === "opposite-logo") {
		styleLivePreview.logoContent.props.order = 0
	} else if (menuPosition === "right-logo" && logoPosition === "left") {
		styleLivePreview.logoContent.props.order = 0
	} else if (menuPosition === "right-logo" && logoPosition === "right") {
		styleLivePreview.logoContent.props.order = 1
	} else {
		styleLivePreview.logoContent.props.order = 0
	}

	/**
	* LOGO CONTAINER
	*/
	if (menuPosition === "top-logo") {
		styleLivePreview.logoContainer.props["flex-direction"] = "column"
		styleLivePreview.logoContainer.props.width = ""
	} else if (menuPosition === "bottom-logo") {
		styleLivePreview.logoContainer.props["flex-direction"] = "column"
		styleLivePreview.logoContainer.props.width = ""
	} else if (menuPosition === "opposite-logo" && logoPosition === "center") {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
		styleLivePreview.logoContainer.props["justify-content"] = "center"
	} else if (menuPosition === "opposite-logo") {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
		styleLivePreview.logoContainer.props.width = "100%"
		styleLivePreview.logoContainer.props["justify-content"] = "space-between"
	} else if (menuPosition === "right-logo" && logoPosition === "left") {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
		styleLivePreview.logoContainer.props.width = "100%"
		styleLivePreview.logoContainer.props["justify-content"] = "flex-start"
	} else if (menuPosition === "right-logo" && logoPosition === "center") {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
		styleLivePreview.logoContainer.props.width = "100%"
		styleLivePreview.logoContainer.props["justify-content"] = "center"
	} else if (menuPosition === "right-logo") {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
		styleLivePreview.logoContainer.props.width = "100%"
		styleLivePreview.logoContainer.props["justify-content"] = "flex-end"
	} else {
		styleLivePreview.logoContainer.props["flex-direction"] = "row"
	}

	/**
	* MOBILE MENU CONTAINER
	*/
	if (menuPosition === "top-logo") {
		styleLivePreview.mobileMenuContainer.props.order = 0
	} else if (menuPosition === "opposite-logo" && logoPosition === "left") {
		styleLivePreview.mobileMenuContainer.props.order = 1
	} else if (menuPosition === "opposite-logo" && logoPosition === "right") {
		styleLivePreview.mobileMenuContainer.props.order = 0
	} else if (menuPosition === "right-logo" && logoPosition === "right") {
		styleLivePreview.mobileMenuContainer.props.order = 0
	} else {
		styleLivePreview.mobileMenuContainer.props.order = 1
	}

	if (logoPosition === "center" && menuPosition === "opposite-logo") {
		styleLivePreview.mobileMenuContainer.props.display = "flex"
	} else if (logoPosition === "center" && menuPosition === "right-logo") {
		styleLivePreview.mobileMenuContainer.props.display = "flex"
	} else if (headerBanner1SectionData.properties.menu_type === "mobile") {
		styleLivePreview.mobileMenuContainer.props.display = "flex"
	} else {
		styleLivePreview.mobileMenuContainer.props.display = "none"
	}

	/**
	* DESKTOP MENU CONTAINER
	*/
	if (menuPosition === "top-logo") {
		styleLivePreview.desktopMenuContainer.props.order = 0
	} else if (menuPosition === "opposite-logo" && logoPosition === "left") {
		styleLivePreview.desktopMenuContainer.props.order = 1
	} else if (menuPosition === "opposite-logo" && logoPosition === "right") {
		styleLivePreview.desktopMenuContainer.props.order = 0
	} else if (menuPosition === "right-logo" && logoPosition === "right") {
		styleLivePreview.desktopMenuContainer.props.order = 0
	} else {
		styleLivePreview.desktopMenuContainer.props.order = 1
	}

	if (logoPosition === "center" && menuPosition === "opposite-logo") {
		styleLivePreview.desktopMenuContainer.props.display = "none"
	} else if (logoPosition === "center" && menuPosition === "right-logo") {
		styleLivePreview.desktopMenuContainer.props.display = "none"
	} else if (headerBanner1SectionData.properties.menu_type === "desktop") {
		styleLivePreview.desktopMenuContainer.props.display = "flex"
	} else {
		styleLivePreview.desktopMenuContainer.props.display = "none"
	}

	return styleLivePreview
}
