import { makeStyles } from "@material-ui/core"
import colors from "../../../../styles/colors"


const useStyles = makeStyles(theme => ({
	additionalContainerDivider: {
		margin: theme.spacing(0.5, 0, 1, 0)
	},
	selectedIcon: {
		color: colors.greyScale[1],
		backgroundColor: "rgba(0, 0, 0, 0.09)",
		borderRadius: "10%",
		transition: "0.3s all"
	},
	textTransformIcon: {
		width: "1.4rem",
		height: "1.4rem",
		color: colors.greyScale[3]
	},
	selectTextTransformIcon: {
		backgroundColor: "rgba(0, 0, 0, 0.09)",
		borderRadius: "10%",
		transition: "0.3s all",
		padding: theme.spacing(1)
	}
}))

export default useStyles
