import { makeStyles, createStyles, withStyles, LinearProgress } from "@material-ui/core"

import colors from "../../../../styles/colors"

const useStyles = makeStyles(theme => createStyles({
	dropzoneContainer: {
		border: `1px dashed ${colors.greyScale[6]}`,
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		cursor: "pointer",
		padding: theme.spacing() * 10,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen
		}),
		"&:hover": {
			transition: theme.transitions.create("all", {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen
			}),
			borderColor: theme.palette.primary.main,
			backgroundColor: "#fdfdfd"
		},
		"& img": {
			width: "126px",
			height: "auto"
		},
		"& button": {
			width: "160px"
		}
	},
	progressTitle: {
		fontWeight: "bolder"
	},
	progressSubTitle: {
		color: "#847D7D"
	}
}))

export const Progress = withStyles(theme => createStyles({
	colorPrimary: {
		backgroundColor: "#F8F8F8"
	},
	barColorPrimary: {
		backgroundColor: "#66CC42"
	},
	root: {
		height: 35,
		width: "80%",
		borderRadius: 4,
		transition: theme.transitions.create("all", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen
		})
	}
}))(LinearProgress)

export default useStyles
