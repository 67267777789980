import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles(() => ({
	colorPickerContainer: {
		"& .chrome-picker": {
			boxShadow: "none !important"
		}
	},
	cancelButton: {
		opacity: "0.8"
	},

	/*
		This margin using magic numbers is related with the fact that the colorpicker lib
		uses magic numbers on their element
	*/
	buttonsContainer: {
		padding: "0 16px"
	}
}))

export default useStyles
