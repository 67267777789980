import { makeStyles } from "@material-ui/core"

import colors from "../../../styles/colors"

const useStyles = makeStyles(() => ({
	listItemActive: {
		position: "relative",
		"&:before": {
			position: "absolute",
			content: "close-quote",
			width: "4px",
			height: "100%",
			left: 0,
			top: 0,
			backgroundColor: colors.palette.primary
		}
	}
}))

export default useStyles
