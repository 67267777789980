import React from "react"
import PropTypes from "prop-types"
import { Grid, Switch as MdSwitch } from "@material-ui/core"
import LiveEditorTypography from "../LiveEditorTypography"

const Switch = (props) => {
	const { label, value, onChange } = props
	return (
		<Grid
			container
			justify="space-between"
			alignItems="center"
		>
			<Grid item xs>
				<LiveEditorTypography
					type="fieldTitle"
				>
					{label}
				</LiveEditorTypography>
			</Grid>
			<Grid item>
				<MdSwitch
					checked={value}
					onChange={onChange}
					color="secondary"
				/>
			</Grid>
		</Grid>
	)
}

Switch.defaultProps = {
	label: ""
}

Switch.propTypes = {
	label: PropTypes.string,
	value: PropTypes.bool.isRequired,
	onChange: PropTypes.func.isRequired
}
export default Switch
