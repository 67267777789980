import React, { useState } from "react"
import { useParams, useHistory } from "react-router-dom"

import {
	Grid,
	CircularProgress,
	MenuItem,
	TextField,
	Checkbox,
	FormControlLabel,
	Button,
	FormControl,
	InputLabel,
	Select,
	FormHelperText,
	Container,
	IconButton,
	Typography,
	RadioGroup,
	Radio
} from "@material-ui/core"
import { CloudUploadOutlined as CloudUploadIcon } from "@material-ui/icons"
import useDidMount from "../../../hooks/useDidMount"
import useValidation from "../../../hooks/useValidation"

import { useTestimonialStore } from "../../../store"

import api from "../../../services/api"

import { Loading, Portlet, Dropzone, Divider, Notification, CustomContainer } from "../../../components"

import useStyles from "./styles"
import useCustomStyles from "../../../styles/custom"

import TestimonialEditSkeleton from "../../../skeletons/TestimonialEdit"

const Testimonial = () => {
	const params = useParams()
	const { testimonial_id } = params
	const history = useHistory()
	const classes = useStyles()
	const customClasses = useCustomStyles()
	const testimonialStore = useTestimonialStore()
	const {
		validation,
		clearValidation,
		triggerValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const [loadingTestimonialData, setLoadingTestimonialData] = useState(true)
	const [savingTestimonialData, setSavingTestimonialData] = useState(false)
	const [testimonialData, setTestimonialData] = useState({})
	const [testimonialPictureData, setTestimonialPictureData] = useState({
		wasUpdated: false,
		data: null
	})
	const [workOptions, setWorkOptions] = useState([])

	const getTestimonialData = async () => {
		try {
			const { data } = await api.get(`/site/testimonial?id=${testimonial_id}`)
			setTestimonialData(data)
			testimonialStore.setTestimonialData({
				author: data.author || " ",
				testimonial_id
			})

			setLoadingTestimonialData(false)
		} catch (error) {
			triggerUndeterminedValidation(error)
		}
	}

	const handleSaveTestimonialData = async () => {
		setSavingTestimonialData(true)

		const promises = []
		try {
			if (testimonialPictureData.wasUpdated) {
				const formData = new FormData()
				formData.append("testimonial_file", testimonialPictureData.data)

				promises.push(
					api.put(`/site/testimonial/${testimonial_id}/picture`, formData, {
						headers: {
							"Content-Type": "multipart/data"
						}
					})
				)
			}

			promises.push(api.put(`/site/testimonial/${testimonial_id}`, testimonialData))

			await Promise.all(promises)

			setTestimonialPictureData({ wasUpdated: false, data: null })

			await getTestimonialData()

			Notification.success({ message: "Informações salvas com sucesso!" })
		} catch (err) {
			triggerValidation(err)
		}

		setSavingTestimonialData(false)
	}

	const handleChangeTestimonialData = (key, value) => {
		setTestimonialData({
			...testimonialData,
			[key]: value
		})

		clearValidation(key)
	}

	const handleChangeLinkType = value => {
		setTestimonialData({
			...testimonialData,
			link_type: value,
			url: null,
			work_id: null
		})
	}

	const getWorkOptions = async () => {
		try {
			const { data } = await api.get("/site/testimonial/work-list")
			setWorkOptions(data.works)
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const getSelectWorkElement = () => {
		const hasOptions = workOptions.length >= 1

		const workOptionsEl = workOptions.map(option => (
			<MenuItem key={option.id} value={option.id}>
				{option.title}
			</MenuItem>
		))

		let label
		if (hasOptions) {
			label = "Escolha o trabalho"
		} else {
			label = "Não há trabalhos disponíveis"
		}

		return (
			<FormControl fullWidth color="secondary" error={!!validation.selectWork}>
				<InputLabel id="selectWorkLabel" className={!hasOptions ? classes.emptySelectLabel : null}>
					{label}
				</InputLabel>
				<Select
					id="selectWork"
					labelId="selectWorkLabel"
					onChange={({ target }) => {
						handleChangeTestimonialData("work_id", target.value)
					}}
					value={testimonialData.work_id}
					onOpen={() => clearValidation("selectWork")}
				>
					{workOptionsEl}
				</Select>
				{validation.selectWork
					? <FormHelperText>{validation.selectWork}</FormHelperText>
					: null}
			</FormControl>
		)
	}

	const handleCloseTestimonialEdit = () => {
		history.push("/testimonial")
	}

	const handleChangeTestimonialPicture = ([testimonial]) => {
		setTestimonialPictureData({ wasUpdated: true, data: testimonial })

		const pictureSrc = window.URL.createObjectURL(testimonial)
		handleChangeTestimonialData("picture_src", pictureSrc)
	}

	const handleRemoveTestimonialPicture = () => {
		setTestimonialPictureData({ wasUpdated: true, data: null })
		handleChangeTestimonialData("picture_src", null)
	}

	useDidMount(() => {
		getTestimonialData()
		getWorkOptions()
	})

	return (
		<CustomContainer maxWidth="1080px" position="start">
			<Portlet>
				<Loading
					loading={loadingTestimonialData}
					customLoadingElement={<TestimonialEditSkeleton />}
				>
					<Grid container spacing={2} alignItems="flex-start">
						<Grid item xs={12} md={6} lg={7}>
							{testimonialData.picture_src ? (
								<Container
									className={[classes.pictureContainer, classes.noBorder]}
									disableGutters
									style={{ backgroundImage: `url(${testimonialData.picture_src})` }}
								/>
							) : (
								<Dropzone onDrop={handleChangeTestimonialPicture}>
									<Container className={classes.pictureContainer} disableGutters>
										<IconButton className={classes.pictureUploadButton}>
											<CloudUploadIcon />
										</IconButton>
										<Divider size="large" orientation="horizontal" />
										<Typography variant="h3">Subir a foto do autor do depoimento</Typography>
										<Typography className={classes.pictureHelperText}>
											Tamanho mínimo recomendado: 480px de largura
										</Typography>
									</Container>
								</Dropzone>
							)}

							{testimonialData.picture_src && (
								<>
									<Divider orientation="horizontal" size="medium" />
									<Grid container spacing={2} justify="space-between">
										<Grid item>
											<Button
												variant="text"
												color="primary"
												endIcon={false && <CircularProgress size={20} />}
												onClick={handleRemoveTestimonialPicture}
											>
												Remover foto
											</Button>
										</Grid>

										<Grid item>
											<Dropzone style={{ alignSelf: "flex-end" }} onDrop={handleChangeTestimonialPicture}>
												<Button variant="contained" color="primary" endIcon={<CloudUploadIcon />}>
													Alterar foto
												</Button>
											</Dropzone>
										</Grid>
									</Grid>
								</>
							)}

						</Grid>

						<Grid item xs={12} md={6} lg={5}>
							<TextField
								id="author"
								label="Autor"
								fullWidth
								color="secondary"
								value={testimonialData.author}
								required
								onChange={({ target }) => handleChangeTestimonialData("author", target.value)}
								error={!!validation.author}
								helperText={validation.author}
							/>

							<Divider size="medium" orientation="horizontal" />

							<TextField
								id="text"
								label="Depoimento"
								fullWidth
								color="secondary"
								multiline
								value={testimonialData.text}
								onChange={({ target }) => handleChangeTestimonialData("text", target.value)}
								error={!!validation.text}
								helperText={validation.text}
							/>

							<Divider size="medium" orientation="horizontal" />

							<TextField
								id="video_link"
								label="Link do vídeo do depoimento"
								fullWidth
								color="secondary"
								value={testimonialData.video_link}
								onChange={({ target }) => handleChangeTestimonialData("video_link", target.value)}
								error={!!validation.video_link}
								helperText={validation.video_link}
							/>

							<Divider size="xlarge" orientation="horizontal" />

							<FormControl component="fieldset" fullWidth>
								<RadioGroup
									row
									className={classes.radioGroup}
									value={testimonialData.link_type}
									onChange={({ target }) => {
										handleChangeLinkType(target.value)
									}}
								>
									<FormControlLabel value="work" control={<Radio />} label="Vincular a um trabalho" />
									<FormControlLabel value="external" control={<Radio />} label="Link externo" />
								</RadioGroup>
							</FormControl>

							<Divider size="small" orientation="horizontal" />

							{testimonialData.link_type === "external" ? (
								<TextField
									id="url"
									label="Digite o link"
									fullWidth
									color="secondary"
									value={testimonialData.url}
									onChange={({ target }) => handleChangeTestimonialData("url", target.value)}
									error={!!validation.url}
									helperText={validation.url}
								/>
							) : (
								getSelectWorkElement()
							)}

							<Divider size="medium" orientation="horizontal" />

							<FormControlLabel
								control={(
									<Checkbox
										checked={testimonialData.open_link_in_new_tab}
										color="secondary"
										onChange={({ target }) => handleChangeTestimonialData("open_link_in_new_tab", target.checked)}
									/>
								)}
								label="Abrir em uma nova aba"
							/>

							<Divider size="large" orientation="horizontal" />

							<Grid container spacing={2}>
								<Grid item xs={6}>
									<Button
										color="inherit"
										variant="text"
										fullWidth
										className={customClasses.cancelButton}
										onClick={handleCloseTestimonialEdit}
									>
										Cancelar
									</Button>
								</Grid>
								<Grid item xs={6}>
									<Button
										color="primary"
										variant="contained"
										endIcon={savingTestimonialData && <CircularProgress size={20} />}
										disabled={savingTestimonialData}
										fullWidth
										onClick={handleSaveTestimonialData}
									>
										Salvar
									</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Loading>
			</Portlet>
		</CustomContainer>
	)
}

export default Testimonial
