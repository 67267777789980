import { createStyles, makeStyles } from "@material-ui/core"

import { isMobile } from "../../utils/checkDevice"

import colors from "../../styles/colors"

const useStyles = makeStyles(theme => createStyles({
	announcementContainer: {
		display: "flex",
		alignItems: "center"
	},
	announcementIcon: {
		color: theme.palette.warning.main,
		width: 40,
		height: 40,
		marginRight: theme.spacing(3)
	},
	card: {
		height: "100%",
		position: "relative"
	},
	testimonialCardActionContainer: {
		position: "absolute",
		padding: theme.spacing() * 1
	},
	cardPicture: {
		height: isMobile ? 300 : 360,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: colors.greyScale[9]
	},
	cardPictureAvatar: {
		height: 300,
		width: "100%",
		color: colors.greyScale[10]
	},
	cardPictureIcon: {
		position: "absolute",
		height: 80,
		width: 80
	},
	cardContent: {
		marginTop: theme.spacing(4),
		padding: theme.spacing(0, 3, 3, 3)
	},
	cardLink: {
		textDecoration: "none",
		fontFamily: "Open Sans",
		fontSize: "12px",
		lineHeight: "24px",
		color: colors.palette.link
	},
	cardHeaderButtons: {
		display: "flex",
		justifyContent: "flex-end",
		marginRight: -theme.spacing(3)
	},
	cardText: {
		marginTop: theme.spacing(1),
		fontFamily: "Open Sans",
		fontStyle: "normal",
		fontWeight: "normal",
		fontSize: "12px",
		lineHeight: "24px",
		display: "-webkit-box",
		WebkitLineClamp: 5,
		WebkitBoxOrient: "vertical",
		overflow: "hidden"
	},
	menuItemIcon: {
		color: colors.greyScale[4],
		marginRight: theme.spacing(2)
	}
}))

export default useStyles
