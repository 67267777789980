import { createStyles, makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => createStyles({
	container: {
		borderRadius: "5px",
		boxShadow: "0px 0px 10px #ECECEC",

		width: "100%",
		height: props => props.heightContainer ?? "calc(100vh - 60px)",
		maxHeight: "628px",

		color: "#3F3D56",

		margin: "0 auto",
		position: "relative",
		[theme.breakpoints.down("sm")]: {
			height: "100% !important",
			maxHeight: "none"
		}
	},

	premiumChipContainer: {
		position: "absolute",
		top: "15px",
		left: "25px"
	},
	contentContainer: {
		[theme.breakpoints.down("sm")]: {
			order: 1
		}
	},
	contentWrapper: {
		height: "100%",
		padding: theme.spacing(3),
		boxSizing: "content-box",
		[theme.breakpoints.down("sm")]: {
			boxSizing: "inherit"
		}
	},

	textContainer: {
		height: "100%",
		maxWidth: "95%",
		margin: "0 auto",
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(2),
			textAlign: "center"
		}
	},

	btnActiveContainer: {
		width: "100%",
		maxWidth: "250px",
		textAlign: "center",
		marginTop: theme.spacing(2)
	},
	captionButton: {
		fontSize: "12px",
		lineHeight: "32px",
		fontWeight: "400"
	},

	illustrationContainer: {
		height: "100%",
		width: "100%",
		"& *": {
			height: "100%"
		},
		[theme.breakpoints.down("sm")]: {
			background: props => props.backgroundMobile,
			maxHeight: "40vh",
			minHeight: "280px"
		}
	},
	illustrationWrapper: {
		position: "relative"
	},
	illustrationContent: {
		position: "absolute",
		width: "100%",
		[theme.breakpoints.down("sm")]: {
			minHeight: "280px"
		}
	},
	illustrationImage: {
		width: "100%",
		background: props => `url(${props.illustrationBackground}) no-repeat center bottom`,
		backgroundSize: "100% 100%",
		[theme.breakpoints.down("sm")]: {
			background: "none !important",
			position: "relative"
		}
	},
	illustrationImageMobile: {
		display: "none",
		[theme.breakpoints.down("sm")]: {
			display: "block",
			height: "80%",
			position: "absolute",
			bottom: 0,
			right: 0,
			left: 0,
			margin: "0 auto"
		}
	}
}))

export default useStyles
