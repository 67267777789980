import { makeStyles } from "@material-ui/core"

import colors from "../../../../styles/colors"

const bgColor = colors.greyScale[11]

const useStyles = makeStyles((theme) => ({
	notFullWidth: {
		padding: theme.spacing(0, 2)
	},
	sortableItemContainer: {
		backgroundColor: bgColor,
		border: `1px solid ${colors.greyScale[9]}`,
		width: "100%",
		display: "grid",
		gridTemplateColumns: "auto 1fr auto auto",
		height: theme.spacing(6.75),
		padding: theme.spacing(0, 1),

		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.04)"
		}
	},
	sortableItemDragHandle: {
		padding: theme.spacing(1)
	},
	sortableItemTitle: {
		textAlign: "left"
	},
	headerAndFooterListItem: {
		borderTop: `1px solid ${colors.greyScale[9]}`,
		borderBottom: `1px solid ${colors.greyScale[9]}`,
		paddingTop: theme.spacing(1.5),
		paddingBottom: theme.spacing(1.5),
		backgroundColor: bgColor
	},
	listItemText: {
		marginLeft: theme.spacing(-2),
		paddingRight: theme.spacing(1)
	},
	addSectionButton: {
		borderRadius: 0,
		border: `1px dashed ${colors.greyScale[9]}`,
		height: theme.spacing(6.75),
		boxShadow: "none"
	},
	addSectionButtonTypo: {
		display: "flex",
		alignItems: "center"
	},
	addSectionButtonIcon: {
		marginRight: theme.spacing(1),
		fontSize: theme.spacing(3.25)
	}
}))

export default useStyles
