import React from "react"
import { Grid, MenuItem } from "@material-ui/core"
import {
	GroupField,
	ColorPicker,
	Select,
	UploadField,
	Checkbox
} from "../../../components"
import { Divider } from "../../../../../components"

import { useLiveEditorStore } from "../../../../../store"

function LayoutEditor() {
	const liveEditorStore = useLiveEditorStore()
	const headerBanner1SectionIndexData = liveEditorStore.getSectionByHtmlTag("header_index")
	const headerBanner1SectionInsideData = liveEditorStore.getSectionByHtmlTag("header_inside")
	const generalStyleData = liveEditorStore.getStyleByType("general")
	const imagesSiteContent = liveEditorStore.getSiteContentByType("images")

	const { favicon: faviconSiteContent } = imagesSiteContent

	const faviconUrl = faviconSiteContent?.content?.url
		?? "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAkCAQAAABLCVATAAABJklEQVR4Ae3VPYrbUBRA4a+RJ1VSJswvGG8mhYsswRhtxT/YmNEuhtEiAmpcJjBJOQsYyyBMsF0qXcjDjNF7pAnJOd0tTqN3r/yLfPCsFfrsvUje+ao91RdvRfBGpX3Fzy6c4UKh0Ua6U+gJKLSJFgKa5FAjoE23W6hRWpp4UKeHNnIZgMzIS0rom1shXHqKDW3cgoHC2tq9PriyiQvl4JPjr8nBEIxjQo0MA8dguneHTN09VL7ySBfgsXtoCdYnoQpMu4cmZ0Oz7qEHcH8SmoOye6iWoe8QTH+4QWYb8/lHYGj/W+YjyOPe0YtLcGehUpm7Adfq2BV5coWQa9/TlnYcLG2uTj8jtUdTM6VtxBlJNGCXnGn+1PFfCegl/Y4aKz1/Lf/5CShXgVKz4A8DAAAAAElFTkSuQmCC"

	const updateCSSVariablesOnLivePreview = ({
		livePreviewValue: value,
		selectorSuffix: suffix
	}) => {
		const livePreviewChanges = [{
			action: liveEditorStore.livePreviewActions.INSERT_PURE_CSS,
			selector: `root-css-${suffix}`,
			value
		}]

		liveEditorStore.addLivePreviewChanges(livePreviewChanges)
	}

	const updateStylePropertiesOnStore = ({ styleId, styleProperties: properties }) => {
		const styleProperties = {
			styleId,
			properties
		}

		liveEditorStore.setStyleProperties(styleProperties)
	}

	const updateSectionPropertiesOnStore = ({ sectionId, sectionProperties: properties }) => {
		const sectionProperties = {
			sectionId,
			properties
		}

		liveEditorStore.setSectionData(sectionProperties)
	}


	const handleChangePageContainerBackgroundColorOnLivePreview = (color) => {
		const livePreviewValue = `:root {
				--page-container-background: ${color}
			}`

		const selectorSuffix = "page-container-background"
		updateCSSVariablesOnLivePreview({ selectorSuffix, livePreviewValue })
	}

	const handleChangePageContainerBackgroundColor = (color) => {
		const styleId = generalStyleData.id

		const styleProperties = {
			page_container_background: color
		}

		updateStylePropertiesOnStore({ styleId, styleProperties })

		handleChangePageContainerBackgroundColorOnLivePreview(color)
	}

	const handleChangePageContentWidth = (event) => {
		const value = event?.target?.value

		const livePreviewValue = `:root {
				--page-section-content-width: ${value}
			}`

		const selectorSuffix = "page-section-content-width"
		updateCSSVariablesOnLivePreview({ selectorSuffix, livePreviewValue })

		liveEditorStore.setStyleProperties({
			styleId: generalStyleData.id,
			properties: {
				page_section_content_width: value
			}
		})
	}

	const handleChangePageContentMaxWidth = (event) => {
		const value = event?.target?.checked ? "1366px" : "100%"

		const livePreviewValue = `:root {
				--page-section-content-max-width: ${value}
			}`

		const selectorSuffix = "page-section-content-max-width"
		updateCSSVariablesOnLivePreview({ selectorSuffix, livePreviewValue })

		liveEditorStore.setStyleProperties({
			styleId: generalStyleData.id,
			properties: {
				page_section_content_max_width: value
			}
		})
	}

	const handleChangeFavicon = (blobUrl) => {
		liveEditorStore.setSiteContent({
			images: {
				favicon: {
					...faviconSiteContent,
					content: {
						...faviconSiteContent.content,
						url: blobUrl
					}
				}
			}
		})
	}

	const getNavbarWidth = () => {
		const currentValue = headerBanner1SectionIndexData.properties.navbar_section_content_max_width
		switch (currentValue) {
		case "100%":
			return "screen"

		case "":
			return "site"

		case "1366px":
			return "1366px"

		default:
			return "site"
		}
	}

	const handleChangeNavbarWidth = (event) => {
		const { value } = event.target

		const options = {
			site: {
				livePreviewValue: {
					"max-width": "var(--page-section-content-max-width)",
					width: "var(--page-section-content-width)"
				},
				liveEditorValue: {
					navbar_section_content_max_width: "",
					navbar_section_width: ""
				}
			},
			screen: {
				livePreviewValue: {
					"max-width": "100%",
					width: "100%"
				},
				liveEditorValue: {
					navbar_section_content_max_width: "100%",
					navbar_section_width: "100%"
				}
			},
			"1366px": {
				livePreviewValue: {
					"max-width": "1366px",
					width: "100%"
				},
				liveEditorValue: {
					navbar_section_content_max_width: "1366px",
					navbar_section_width: "100%"
				}
			}
		}

		const selectedOption = options[value]

		liveEditorStore.addLivePreviewChanges([
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionIndexData.id} .header-banner-1-content .navbar-container .navbar-content`,
				value: selectedOption.livePreviewValue
			},
			{
				action: liveEditorStore.livePreviewActions.CHANGE_ELEMENT_STYLE,
				selector: `#page-section-${headerBanner1SectionInsideData.id} .header-banner-1-content .navbar-container .navbar-content`,
				value: selectedOption.livePreviewValue
			}
		])

		updateSectionPropertiesOnStore({
			sectionId: headerBanner1SectionIndexData.id,
			sectionProperties: selectedOption.liveEditorValue
		})

		updateSectionPropertiesOnStore({
			sectionId: headerBanner1SectionInsideData.id,
			sectionProperties: selectedOption.liveEditorValue
		})
	}

	const siteWidthOptions = [
		{
			value: "100%",
			label: "100% da tela"
		},
		{
			value: "90%",
			label: "90% da tela"
		},
		{
			value: "80%",
			label: "80% da tela"
		},
		{
			value: "70%",
			label: "70% da tela"
		}
	]
	const SiteEditor = (
		<GroupField label="Site">
			<ColorPicker
				enableOpacity
				label="Cor de fundo"
				value={generalStyleData.properties.page_container_background || "#ffffff"}
				onChange={handleChangePageContainerBackgroundColor}
				onPreviewChange={handleChangePageContainerBackgroundColorOnLivePreview}
			/>

			<Divider orientation="horizontal" size="medium" />

			<Select
				label="Largura"
				value={generalStyleData.properties.page_section_content_width || "100%"}
				onChange={handleChangePageContentWidth}
			>
				{siteWidthOptions.map(option => (
					<MenuItem key={option.label} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>

			<Divider orientation="horizontal" size="small" />

			<Checkbox
				label="Limitar largura à 1366px"
				value={generalStyleData.properties.page_section_content_max_width === "1366px"}
				onChange={handleChangePageContentMaxWidth}
			/>

			<Divider orientation="horizontal" size="medium" />

			<UploadField
				label="Favicon"
				description="Imagem quadrada com tamanho máximo de 128x128 - PNG"
				typeImage="favicon"
				imageUrl={faviconUrl}
				onChange={handleChangeFavicon}
			/>
		</GroupField>
	)

	const navbarMaxWidthOptions = [
		{
			value: "screen",
			label: "100% da largura da tela"
		},
		{
			value: "site",
			label: "Limitada à largura do site"
		},
		{
			value: "1366px",
			label: "Limitada à 1366px"
		}
	]

	const NavbarEditor = (
		<GroupField label="Barra de navegação">
			<Select
				label="Largura"
				value={getNavbarWidth()}
				onChange={handleChangeNavbarWidth}
			>
				{navbarMaxWidthOptions.map(option => (
					<MenuItem key={option.label} value={option.value}>
						{option.label}
					</MenuItem>
				))}
			</Select>
		</GroupField>
	)

	return (
		<Grid container spacing={2}>
			<Divider orientation="horizontal" size="small" />

			<Grid item xs={12}>
				{SiteEditor}
			</Grid>

			<Grid item xs={12}>
				{NavbarEditor}
			</Grid>
		</Grid>
	)
}

export default LayoutEditor
