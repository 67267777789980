import React, { useState } from "react"

import {
	Grid,
	ListItem,
	ListItemText,
	List,
	ListItemSecondaryAction,
	IconButton,
	Fab,
	TextField,
	Dialog,
	Button,
	DialogTitle,
	DialogContent,
	DialogActions,
	FormGroup,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Menu as MaterialMenu,
	MenuItem,
	Select,
	CircularProgress,
	FormHelperText,
	Link as MaterialLink,
	Tooltip
} from "@material-ui/core"

import {
	Add as AddIcon,
	MoreVert as MoreVertIcon,
	VisibilityOutlined as VisibilityIcon,
	VisibilityOffOutlined as VisibilityOffIcon,
	DragIndicator as DragIndicatorAxisYIcon,
	Close as CloseIcon
} from "@material-ui/icons"

import api from "../../services/api"

import { Portlet, Loading, PopConfirm, Notification, Sortable } from "../../components"

import useStyles from "./styles"
import useCustomStyles from "../../styles/custom"

import MenuSkeleton from "../../skeletons/Menu"

import useValidation from "../../hooks/useValidation"
import useDidMount from "../../hooks/useDidMount"
import { useSiteStore } from "../../store"

const defaultMenuItemDialogData = {
	title: "",
	url: "",
	group_id: "",
	category_id: "",
	work_id: "",
	page_id: "",
	link_type: "",
	open_link_in_new_tab: false
}

const linkTypeOptions = [
	{ value: "", name: "", label: "" },
	{
		value: "page",
		name: "Página",
		label: "Escolha a página",
		noOptions: "Não há páginas disponíveis"
	},
	{
		value: "category",
		name: "Categoria",
		label: "Escolha a categoria",
		noOptions: "Não há categorias disponíveis"
	},
	{
		value: "work",
		name: "Trabalho",
		label: "Escolha o trabalho",
		noOptions: "Não há trabalhos disponíveis"
	},
	{ value: "external", name: "Link externo", label: "Digite o link" }
]

const Menu = () => {
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const siteStore = useSiteStore()
	const {
		validation,
		clearValidation,
		triggerValidation,
		triggerUndeterminedValidation
	} = useValidation()

	const [menuList, setMenuList] = useState([])

	const [savingMenuItem, setSavingMenuItem] = useState(false)
	const [loadingMenuList, setLoadingMenuList] = useState(true)

	const initialMenuItemDialogData = { defaultMenuItemDialogData, openDialog: false }
	const [menuItemDialogData, setMenuItemDialogData] = useState(initialMenuItemDialogData)
	const [selectLinkOptions, setSelectLinkOptions] = useState([])

	const [editMenuData, setEditMenuData] = useState({ anchorEl: null })

	const handleChangeMenuList = (values) => {
		setMenuList(values)
	}

	const getMenuList = async () => {
		try {
			const { data } = await api.get("/site/menu/list")
			const { siteMenuList } = data

			handleChangeMenuList(siteMenuList)
			setLoadingMenuList(false)
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const putMenuOrder = async (valuesReordered) => {
		await api.put("/site/menu/all/order", valuesReordered)
			.then(() => {
				Notification.success({ message: "Ordem dos menus alterada com sucesso!" })
			}).catch(() => {
				Notification.error({ message: "Falha ao alterar a ordem dos menus..." })
			})
	}

	const handleSortStart = () => {
		const cursorType = "grabbing"

		Sortable.handleSortStart(cursorType)
	}

	const handleSortEnd = ({ oldIndex, newIndex }) => {
		const arrayInfo = {
			values: menuList,
			oldIndex,
			newIndex,
			orderAsc: true
		}

		Sortable.handleSortEnd(arrayInfo, handleChangeMenuList, putMenuOrder)
	}

	const openEditMenu = (currentTarget, menuItemData) => {
		setEditMenuData({ anchorEl: currentTarget, menuItemData })
	}

	const closeEditMenu = () => {
		setEditMenuData({ anchorEl: null })
	}

	const openMenuItemDialog = (data = defaultMenuItemDialogData, isCreating = true) => {
		closeEditMenu()
		clearValidation("title")
		clearValidation("selectLink")
		clearValidation("url")
		const partialTitle = isCreating ? "Criar" : "Editar"
		setMenuItemDialogData({ ...data, openDialog: true, partialTitle })
	}

	const closeMenuItemDialog = () => {
		setMenuItemDialogData({ openDialog: false })
	}

	const handleMenuItemDialogInputChange = changedData => {
		setMenuItemDialogData({ ...menuItemDialogData, ...changedData })
	}

	const handleSelectLinkChange = event => {
		const { value } = event.target
		const linkData = {
			...{ url: null, group_id: null, category_id: null, work_id: null, page_id: null },
			[`${menuItemDialogData.link_type}_id`]: value
		}

		setMenuItemDialogData({ ...menuItemDialogData, ...linkData })
	}

	const toggleMenuItemVisibility = async menuItemData => {
		const dataBackup = menuList

		const updatedData = menuList.map(item => {
			if (item.id === menuItemData.id) {
				return {
					...item,
					hidden: !item.hidden
				}
			}
			return item
		})
		handleChangeMenuList(updatedData)

		const payload = {
			id: menuItemData.id,
			hidden: !menuItemData.hidden
		}

		try {
			await api.put(`/site/menu/${menuItemData.id}`, payload)
		} catch (err) {
			handleChangeMenuList(dataBackup)
			triggerValidation(err)
		}
	}

	const handleSaveMenuItem = async () => {
		setSavingMenuItem(true)

		try {
			const menuId = menuItemDialogData.id

			if (menuId) {
				await api.put(`/site/menu/${menuId}`, menuItemDialogData)
			} else {
				await api.post("/site/menu", menuItemDialogData)
			}
			await getMenuList()

			Notification.success({
				message: menuId ? (
					"Informações alteradas com sucesso!"
				) : (
					"Menu criado com sucesso!"
				)
			})

			closeMenuItemDialog()
		} catch (err) {
			triggerValidation(err)
		}

		setSavingMenuItem(false)
	}

	const handleDeleteMenuItem = async menuItemId => {
		closeEditMenu()

		PopConfirm.open({
			title: "Tem certeza?",
			description: "Deletar um item do menu é uma ação irreversível.",
			confirmButtonText: "Deletar",
			onConfirm: async () => {
				try {
					await api.delete(`/site/menu?id=${menuItemId}`)
					await getMenuList()

					Notification.success({ message: "Menu deletado com sucesso!" })
				} catch (err) {
					triggerUndeterminedValidation(err)
				}
			}
		})
	}

	const getMenuItemsToRender = () => {
		const arrLength = menuList.length
		const lastItemId = arrLength > 0 ? menuList[arrLength - 1].id : null

		const menuItems = menuList.map((menuItem, index) => {
			const url = (menuItem.link_type === "external" ? "" : siteStore.state.url) + menuItem.url

			const linkElement = url
				? (
					<MaterialLink href={url} target="_blank" rel="noopener" className={menuItem.hidden ? classes.listItemTextVisibilityOff : null}>
						{url}
					</MaterialLink>
				)
				: "Sua URL aparecerá aqui..."

			return (
				<Sortable.SortableSectionItem
					key={`menuItem-${menuItem.id}`}
					index={index}
					value={menuItem}
					disabled={!(menuList.length > 1)}
				>
					<ListItem
						key={menuItem.id}
						divider={lastItemId !== menuItem.id}
						className={classes.listItem}
					>
						<Grid container spacing={1} alignItems="center">
							<Grid item>
								{
									menuList.length > 1
									&& (
										<Sortable.DragHandle>
											<Tooltip title="Ordenar item" placement="top" arrow>
												<IconButton
													size="small"
												>
													<DragIndicatorAxisYIcon
														className={customClasses.contentActionButtonDark}
														style={{ cursor: "grab" }}
													/>
												</IconButton>
											</Tooltip>
										</Sortable.DragHandle>
									)
								}
							</Grid>

							<Grid item style={{ flex: 1 }}>
								<Grid container justify="space-between">
									<Grid item xs={9}>
										<ListItemText
											primary={menuItem.title}
											primaryTypographyProps={{
												noWrap: true,
												className: `${classes.listItemTitle} ${menuItem.hidden
													? classes.listItemTextVisibilityOff : null}`
											}}
											secondary={linkElement}
											secondaryTypographyProps={{
												noWrap: false
											}}
										/>
									</Grid>
									<Grid item xs={3}>
										<ListItemSecondaryAction className={classes.secondaryAction}>

											<Tooltip title={menuItem.hidden ? "Tornar item visível" : "Ocultar item"} placement="top" arrow>
												<IconButton
													className={classes.secondaryActionButton}
													onClick={() => toggleMenuItemVisibility(menuItem)}
												>
													{menuItem.hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
												</IconButton>
											</Tooltip>

											<Tooltip title="Mais ações" placement="top" arrow>
												<IconButton
													className={classes.secondaryActionButton}
													onClick={({ currentTarget }) => openEditMenu(currentTarget, menuItem)}
												>
													<MoreVertIcon />
												</IconButton>
											</Tooltip>
										</ListItemSecondaryAction>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</ListItem>
				</Sortable.SortableSectionItem>
			)
		})

		return menuItems
	}

	const getSelectLinkTypeOptionsElement = () => linkTypeOptions.map(option => (
		<MenuItem key={option.value} value={option.value}>
			{option.name}
		</MenuItem>
	))

	const getSelectLinkOptions = async () => {
		try {
			const { data } = await api.get("/site/menu/link-list")
			const { links } = data
			setSelectLinkOptions(links)
		} catch (err) {
			triggerUndeterminedValidation(err)
		}
	}

	const getSelectLinkOptionsToRender = () => {
		const selectedLinkType = menuItemDialogData.link_type
		const filteredLinkOptions = selectLinkOptions.filter(
			option => option.type === selectedLinkType && option.title
		)

		return filteredLinkOptions.map(option => (
			<MenuItem key={option.id} value={option.id}>
				{option.title}
			</MenuItem>
		))
	}

	const getSelectLinkToRender = () => {
		const hasOptions = getSelectLinkOptionsToRender().length >= 1

		const linkTypeData = linkTypeOptions.find(item => item.value === menuItemDialogData.link_type)
		let label
		if (hasOptions) {
			label = linkTypeData ? linkTypeData.label : null
		} else {
			label = linkTypeData ? linkTypeData.noOptions : null
		}

		return (
			<FormControl fullWidth required color="secondary" error={!!validation.selectLink}>
				<InputLabel id="selectLinkLabel" className={!hasOptions ? classes.emptySelectLabel : null}>
					{label}
				</InputLabel>
				<Select
					id="selectLink"
					labelId="selectLinkLabel"
					onChange={handleSelectLinkChange}
					value={menuItemDialogData[`${menuItemDialogData.link_type}_id`]}
					onOpen={() => clearValidation("selectLink")}
				>
					{getSelectLinkOptionsToRender()}
				</Select>
				<FormHelperText>{validation.selectLink}</FormHelperText>
			</FormControl>
		)
	}

	useDidMount(() => {
		getMenuList()
		getSelectLinkOptions()
	})

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Portlet>
					<Fab color="primary" onClick={() => openMenuItemDialog()}>
						<AddIcon />
					</Fab>
				</Portlet>
			</Grid>

			<Grid item xs={12}>
				<Portlet disablePadding>
					<Loading loading={loadingMenuList} customLoadingElement={<MenuSkeleton />}>
						<Sortable.SectionContainer
							pressDelay
							axis="y"
							lockAxis="y"
							useDragHandle
							onSortStart={handleSortStart}
							onSortEnd={handleSortEnd}
						>
							<List disablePadding>{getMenuItemsToRender()}</List>
						</Sortable.SectionContainer>
					</Loading>
				</Portlet>
			</Grid>

			<Dialog open={menuItemDialogData.openDialog} onClose={closeMenuItemDialog}>
				<IconButton onClick={closeMenuItemDialog} className={classes.closeIcon}>
					<CloseIcon />
				</IconButton>
				<DialogTitle>
					{menuItemDialogData.partialTitle}
					{" "}
					item no menu
				</DialogTitle>
				<DialogContent>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<TextField
								id="title"
								label="Título do menu"
								variant="standard"
								size="small"
								fullWidth
								required
								color="secondary"
								value={menuItemDialogData.title}
								onChange={({ target }) => handleMenuItemDialogInputChange({ title: target.value })}
								error={!!validation.title}
								helperText={validation.title}
								onKeyDown={() => clearValidation("title")}
							/>
						</Grid>
						<Grid item xs={12}>
							<FormControl fullWidth required color="secondary" error={!!validation.linkType}>
								<InputLabel id="selectLinkTypeLabel">Apontar para:</InputLabel>
								<Select
									id="linkType"
									labelId="selectLinkTypeLabel"
									value={menuItemDialogData.link_type}
									onChange={({ target }) => {
										handleMenuItemDialogInputChange({
											link_type: target.value,
											url: "",
											group_id: null,
											category_id: null,
											work_id: null,
											page_id: null
										})
									}}
									onOpen={() => clearValidation("linkType")}
								>
									{getSelectLinkTypeOptionsElement()}
								</Select>
								<FormHelperText>{validation.linkType}</FormHelperText>
							</FormControl>
						</Grid>
						<Grid
							item
							className={!menuItemDialogData.link_type ? classes.transparentGrid : null}
							xs={12}
						>
							{menuItemDialogData.link_type === "external" ? (
								<TextField
									id="url"
									label={linkTypeOptions.find(option => option.value === "external").label}
									placeholder="ex.: https//picsize.com.br"
									fullWidth
									required
									color="secondary"
									onChange={({ target }) => handleMenuItemDialogInputChange({ url: target.value })}
									value={menuItemDialogData.url}
									error={!!validation.url}
									helperText={validation.url}
									onKeyDown={() => clearValidation("url")}
								/>
							) : (
								getSelectLinkToRender()
							)}
						</Grid>
						<Grid item xs={12}>
							<FormGroup row>
								<FormControlLabel
									control={(
										<Checkbox
											id="openLinkInNewTab"
											checked={menuItemDialogData.open_link_in_new_tab}
											onChange={({ target }) => {
												handleMenuItemDialogInputChange({
													open_link_in_new_tab: target.checked
												})
											}}
										/>
									)}
									label="Abrir em uma nova janela"
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.dialogActions}>
					<Button onClick={closeMenuItemDialog} className={classes.cancelButton}>
						Cancelar
					</Button>

					<Button
						onClick={handleSaveMenuItem}
						variant="contained"
						color="primary"
						endIcon={savingMenuItem && <CircularProgress size={20} />}
						disabled={savingMenuItem}
					>
						Salvar
					</Button>
				</DialogActions>
			</Dialog>

			<MaterialMenu
				anchorEl={editMenuData.anchorEl}
				keepMounted
				open={Boolean(editMenuData.anchorEl)}
				onClose={closeEditMenu}
			>
				<MenuItem
					onClick={() => openMenuItemDialog(editMenuData.menuItemData, false)}
				>
					Editar
				</MenuItem>
				<MenuItem onClick={() => handleDeleteMenuItem(editMenuData.menuItemData.id)}>
					Excluir
				</MenuItem>
			</MaterialMenu>
		</Grid>
	)
}

export default Menu
