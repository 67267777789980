import axios from "axios"

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	withCredentials: true
})

api.interceptors.response.use(
	(response) => Promise.resolve(response),
	async (error) => {
		const status = error && error.response && error.response.status
		const errorName = error && error.response && error.response.data && error.response.data.error

		if (status === 401 && errorName !== "NotLoggedIn") {
			// In case the user is not authenticated
			window.location = `${process.env.REACT_APP_GALLERY_PRO_URL}/login`
			return
		}

		return Promise.reject(error)
	}
)

export default api
