import React, { useState, useRef } from "react"
import {
	Grid,
	IconButton,
	Typography,
	Avatar,
	Button,
	MenuItem,
	Menu,
	ListItem,
	ListItemIcon,
	ListItemText
} from "@material-ui/core"
import {
	ExpandMore as ExpandMoreIcon,
	VisibilityOutlined as VisibilityOutlinedIcon,
	ExitToApp as ExitToAppIcon,
	ShoppingCartOutlined as ShoppingCartOutlinedIcon
	// PersonOutline as PersonOutlineIcon,
	// PaymentOutlined as PaymentOutlinedIcon
} from "@material-ui/icons"

import Divider from "../Divider"

import { isMobile } from "../../utils/checkDevice"
import { CheckUserPlan, siteProPlansUrl } from "../../utils/plan"
import { supportContactInfo, financesContactInfo } from "../../utils/contact"

import { logout } from "../../services/auth"

import { useCustomerStore, useSiteStore } from "../../store"

import useStyles from "./styles"

import useCustomStyles from "../../styles/custom"

import incompleteSiteWarningModal from "./IncompleteSiteWarningModal"

const Header = () => {
	const customerStore = useCustomerStore()
	const siteStore = useSiteStore()
	const classes = useStyles()
	const customClasses = useCustomStyles()

	const menuButtonElement = useRef()

	const [menuOpened, setMenuOpened] = useState(false)

	const userHasFreePlan = CheckUserPlan([26])

	const handleOpenMenu = () => {
		setMenuOpened(true)
	}

	const handleCloseMenu = () => {
		setMenuOpened(false)
	}

	const handleLogout = () => {
		logout()
		handleCloseMenu()
	}

	const handleOpenIncompleteSiteWarningModal = (event) => {
		event.preventDefault()

		incompleteSiteWarningModal.open({
			customerSiteUrl: siteStore.state.url,
			exampleSiteUrl: siteStore.state.theme_url || "https://placeholder.picsize.com.br"
		})
	}

	const showIncompleteSiteWarningModal = () => {
		const notEnoughBanners = customerStore.state.user_usage.total_banners === 0
		const notEnoughWorks = customerStore.state.user_usage.total_works < 2

		if (notEnoughBanners && notEnoughWorks) {
			return true
		}

		return false
	}

	return (
		<Grid container justify="flex-end" alignItems="center" className={classes.headerContainer}>
			<span
				style={{
					color: "#e97a00",
					widht: "100%",
					flex: window.innerWidth < 800 ? undefined : 1,
					textAlign: "center",
					fontSize: window.innerWidth < 800 ? "12px" : undefined
				}}
			>
				{/* eslint-disable-next-line */}
				<b>Novos números:</b> Financeiro → <a style={{ color: "#e97a00", textDecoration: "underline" }} href={financesContactInfo().whatsappLink} target="_blank">+55 {financesContactInfo().phoneNumber}</a> | Suporte Técnico → <a style={{ color: "#e97a00", textDecoration: "underline" }} href={supportContactInfo().whatsappLink} target="_blank">+55 {supportContactInfo().phoneNumber}</a>
			</span>

			<Button
				variant="contained"
				color="secondary"
				href={!showIncompleteSiteWarningModal() && siteStore.state.url}
				onClick={showIncompleteSiteWarningModal() && handleOpenIncompleteSiteWarningModal}
				target="blank"
			>
				<VisibilityOutlinedIcon />

				<Divider orientation="vertical" size="small" />

				Ver site
			</Button>

			<Divider orientation="vertical" size="medium" />

			{userHasFreePlan && (
				<>
					{isMobile ? (
						<IconButton
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							<ShoppingCartOutlinedIcon />
						</IconButton>
					) : (
						<Button
							variant="contained"
							className={customClasses.seePlansButton}
							href={siteProPlansUrl}
							target="blank"
						>
							<ShoppingCartOutlinedIcon />

							<Divider orientation="vertical" size="small" />

							Faça o Upgrade
						</Button>
					)}
				</>
			)}

			<Divider orientation="vertical" size="medium" />

			<Avatar
				src={customerStore.state.user_data.avatar}
				className={classes.avatar}
			/>

			<Divider orientation="vertical" size="small" />

			{!isMobile && (
				<Typography className={classes.customerName}>
					{customerStore.state.user_data.display_name}
				</Typography>
			)}

			<Menu
				anchorEl={menuButtonElement.current}
				open={menuOpened}
				onClose={handleCloseMenu}
				PaperProps={{
					style: {
						marginTop: 45
					}
				}}
			>
				<MenuItem component={ListItem} onClick={handleLogout}>
					<ListItemIcon>
						<ExitToAppIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Sair" />
				</MenuItem>
				{/* <MenuItem
					component="a"
					target="blank"
					href={`${process.env.REACT_APP_GALLERY_PRO_URL}/account`}
				>
					<ListItemIcon>
						<PersonOutlineIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Minha conta" />
				</MenuItem>
				<MenuItem
					component="a"
					target="blank"
					href={`${process.env.REACT_APP_GALLERY_PRO_URL}/financial`}
				>
					<ListItemIcon>
						<PaymentOutlinedIcon fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Financeiro" />
				</MenuItem> */}
			</Menu>

			<IconButton ref={menuButtonElement} onClick={handleOpenMenu}>
				<ExpandMoreIcon />
			</IconButton>
		</Grid>
	)
}

export default Header
