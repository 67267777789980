import React from "react"
import { Skeleton } from "@material-ui/lab"
import { Grid } from "@material-ui/core"

import { Divider } from "../components"

const IntegrationsSkeleton = () => {
	const integrationsItems = []

	// eslint-disable-next-line
	for (let i = 0; i <= 1; i++) {
		integrationsItems.push(
			<Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
				<Skeleton animation="wave" variant="rect" width={250} height={30} />

				<Divider size="medium" orientation="horizontal" />

				<Skeleton animation="wave" variant="rect" height={80} />
			</Grid>
		)
	}
	return (
		<Grid container spacing={2}>
			{integrationsItems}
		</Grid>
	)
}

export default IntegrationsSkeleton
