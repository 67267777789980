import React, { useState } from "react"

import { Button, Grid, Box } from "@material-ui/core"

import PropTypes from "prop-types"
import LiveEditorTypography from "../LiveEditorTypography"
import useStyles from "./styles"
import MediaSystem from "../../../../components/MediaSystem"

const ImageSelect = ({ label, currentImage, onSelect }) => {
	const [openMediaSystem, setOpenMediaSystem] = useState(false)

	const classes = useStyles()

	const handleOpenMediaSystem = () => {
		setOpenMediaSystem(true)
	}

	const handleCloseMediaSystem = () => {
		setOpenMediaSystem(false)
	}

	const handleOkMediaSystem = (imageUrl) => {
		onSelect(imageUrl)
	}

	const handleRemoveImage = () => {
		onSelect(null)
	}

	return (
		<>
			<Grid
				container
				justify="space-between"
				alignItems="center"
				spacing={1}
			>
				<Grid item xs={12}>
					<LiveEditorTypography
						type="fieldTitle"
					>
						{label}
					</LiveEditorTypography>
				</Grid>

				<Grid item xs={12} className={classes.backgroundBoxContainer}>
					<Box
						style={{ background: currentImage }}
						className={classes.backgroundBox}
					/>
				</Grid>

				<Grid item xs={12}>
					<Grid container spacing={2}>
						<Grid item xs={6}>
							<Button
								onClick={handleRemoveImage}
								color="primary"
								fullWidth
							>
								Remover
							</Button>
						</Grid>

						<Grid item xs={6}>
							<Button
								color="secondary"
								variant="contained"
								onClick={handleOpenMediaSystem}
								fullWidth
							>
								{currentImage ? "Alterar" : "Inserir"}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			<MediaSystem
				onClose={handleCloseMediaSystem}
				onOk={handleOkMediaSystem}
				open={openMediaSystem}
			/>
		</>
	)
}

ImageSelect.propTypes = {
	label: PropTypes.string,
	currentImage: PropTypes.string.isRequired,
	onSelect: PropTypes.func.isRequired
}

ImageSelect.defaultProps = {
	label: ""
}

export default ImageSelect
