import React from "react"
import PropTypes from "prop-types"
import { Container } from "@material-ui/core"

import useStyles from "./styles"

const CustomContainer = (props) => {
	const { children, position, maxWidth } = props
	const marginLeft = position === "start" ? 0 : "auto"
	const marginRight = position === "end" ? 0 : "auto"

	const classes = useStyles({ marginLeft, marginRight, maxWidth })

	return (
		<Container className={classes.container} maxWidth={false} disableGutters>
			{children}
		</Container>
	)
}

CustomContainer.defaultProps = {
	children: <div />,
	position: "center",
	maxWidth: "100%"
}

CustomContainer.propTypes = {
	children: PropTypes.oneOfType(PropTypes.node, PropTypes.arrayOf(PropTypes.node)),
	position: PropTypes.oneOf("start", "center", "end"),
	maxWidth: PropTypes.string
}

export default CustomContainer
